const getBaseURL = () => {
  if (window.location.hostname !== '') {
    let apiPort = process.env.NODE_ENV === 'development' ? 3333 : 4954;
    if (window.location.hostname.includes('teste.')) {
      apiPort = 4955;
    }
    return `${window.location.protocol}//${window.location.hostname}:${apiPort}`;
    // return 'http://teste.telessaude.speedysistemas.com.br:4955';
  }
  return '';
};

export const ConfigApi = {
  baseURL: getBaseURL(),

  // URLs
  municipioURL: 'municipios',
  colaboradorURL: 'colaboradores',
  colaboradorPerfilURL: 'colaboradorPerfis',
  colaboradorVinculoURL: 'colaboradorVinculos',
  colaboradorCategoriaURL: 'colaboradorCategorias',
  financiadorURL: 'financiadores',
  unidadeConservacaoURL: 'unidadesConservacao',
  territorioURL: 'territorios',
  regionalURL: 'regionais',
  comunidadeURL: 'comunidades',
  auditoriaURL: 'auditorias',
  pacienteURL: 'pacientes',
  apiInfoURL: 'apiInfo',
  colaboradorEspecialidadeURL: 'colaboradorEspecialidades',
  funcaoURL: 'funcoes',
  atendimentoURL: 'atendimentos',
  atendimentoTipoURL: 'atendimentoTipos',
  atendimentoTipoEnfURL: 'atendimentoTiposEspecifico',
  regulacaoURL: 'regulacoes',
  educacaoURL: 'educacoes',
  impressoTipoURL: 'impressosTipo',
  estruturaPontoURL: 'estruturasPonto',
  dashboardURL: 'dashboard',
  identidadeGeneroURL: 'identidadesGenero',
  feriadoURL: 'feriados',
  configAgendaURL: 'configAgendas',
  agendamentoURL: 'agendamentos',
  agendaBloqueioURL: 'agendaBloqueios',
  atendimentoAnexoURL: 'atendimentoAnexos',
};

// process.env.NODE_ENV === 'development'
// ? 'http://192.168.0.102:3333'
// : 'https://telesaude.speedysistemas.com.br:4954',

export const comorbidades = [
  {
    label: 'DM',
    value: 'comorbidadeDm',
  },
  {
    label: 'HAS',
    value: 'comorbidadeHas',
  },
  {
    label: 'TB',
    value: 'comorbidadeTb',
  },
  {
    label: 'HS',
    value: 'comorbidadeHs',
  },
  {
    label: 'Não Possui',
    value: 'comorbidadeNaoPossui',
  },
  {
    label: 'Não verificado',
    value: 'comorbidadeNaoVerificado',
  },
  {
    label: 'Outras',
    value: 'comorbidadeOutras',
  },
];

export const filterType = {
  INTEGER: 1,
  DECIMAL: 2,
  TEXT: 0,
  DATE: 3,
};

export const listaSituacaoAgendamento = [
  { value: 0, label: 'Aguardando' },
  { value: 1, label: 'Em Andamento' },
  { value: 2, label: 'Realizado' },
  { value: 3, label: 'Não realizado' },
];

export const listaMotivoNaoAtAgendamento = [
  { value: 0, label: 'Falta do Paciente' },
  { value: 1, label: 'Dificuldade de acesso à internet' },
  { value: 2, label: 'Outros problemas técnicos' },
  { value: 3, label: 'Falta do profissional de saúde' },
];

export const listaPeriodicidadeAcompanhamento = [
  { value: 0, label: 'Não se aplica' },
  { value: 1, label: 'Retorno' },
  { value: 2, label: 'Sim - semanal' },
  { value: 3, label: 'Sim - quinzenal' },
  { value: 4, label: 'Sim - mensal' },
  { value: 5, label: 'Sim - bimestral' },
  { value: 6, label: 'Sim - trimestral' },
  { value: 7, label: 'Sim - semestral' },
  { value: 8, label: 'Sim - anual' },
];

export const listaTipoPacienteAtendimento = [
  { value: 0, label: 'Hiperdia' },
  { value: 1, label: 'Mulher' },
  { value: 2, label: 'Idoso' },
  { value: 3, label: 'Criança' },
  { value: 4, label: 'Adolescente' },
  { value: 5, label: 'Homem' },
  { value: 6, label: 'Pessoa com deficiência(PCD)' },
];

export const cfgPtBr = {
  firstDayOfWeek: 0,
  dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
  dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
  dayNamesMin: ['Do', 'Seg', 'Te', 'Qua', 'Qui', 'Sex', 'Sa'],
  monthNames: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],
  monthNamesShort: [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez',
  ],
  today: 'Hoje',
  clear: 'Limpa',
};

export const listaUfs = [
  { label: 'AC', value: 'AC' },
  { label: 'AL', value: 'AL' },
  { label: 'AP', value: 'AP' },
  { label: 'AM', value: 'AM' },
  { label: 'BA', value: 'BA' },
  { label: 'CE', value: 'CE' },
  { label: 'DF', value: 'DF' },
  { label: 'ES', value: 'ES' },
  { label: 'GO', value: 'GO' },
  { label: 'MA', value: 'MA' },
  { label: 'MT', value: 'MT' },
  { label: 'MS', value: 'MS' },
  { label: 'MG', value: 'MG' },
  { label: 'PA', value: 'PA' },
  { label: 'PB', value: 'PB' },
  { label: 'PR', value: 'PR' },
  { label: 'PE', value: 'PE' },
  { label: 'PI', value: 'PI' },
  { label: 'RJ', value: 'RJ' },
  { label: 'RN', value: 'RN' },
  { label: 'RS', value: 'RS' },
  { label: 'RO', value: 'RO' },
  { label: 'RR', value: 'RR' },
  { label: 'SC', value: 'SC' },
  { label: 'SP', value: 'SP' },
  { label: 'SE', value: 'SE' },
  { label: 'TO', value: 'TO' },
];

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';

import ButtonSp from '../../components/ButtonSp';
import {
  calcNaxItemsPage,
  errorHandle,
  extensaoFile,
  formatDate,
  padLeft,
  validateFields,
} from '../../util/functions';
import { AtendimentoAnexoModel } from '../../util/Models';
import AtendimentoAnexoService from '../../services/AtendimentoAnexoService';

import LabelSp from '../../components/LabelSp';
import PdfView from '../../components/PdfView';
import DataTableSp from '../../components/DataTableSp';
import AuthService from '../../services/AuthService';
import { showMessage } from '../../components/MessageDialog';
import InputTextSp from '../../components/InputTextSp';
import ImageView from '../../components/ImageView';

function CrudAnexo(props) {
  CrudAnexo.propTCpes = {
    viewMode: PropTypes.bool.isRequired,
    handleBack: PropTypes.func.isRequired,
    idSelected: PropTypes.number.isRequired,
  };

  const [showPdfView, setShowPdfView] = useState(false);
  const [imgPdfSrc, setImgPdfSrc] = useState();
  const [fileName, setFileName] = useState();
  const [atendimentoAnexo, setAtendimentoAnexo] = useState(new AtendimentoAnexoModel());
  const [anexos, setAnexos] = useState([]);
  const [cadastroAnexoVisivel, setCadastroAnexoVisivel] = useState(false);
  const [showImgView, setShowImgView] = useState(false);

  const pageLimit = calcNaxItemsPage(6, 10, 16);

  const { idSelected, handleBack } = props;
  // useCallbacks
  const handleBuscarAnexos = useCallback(async idAtendimento => {
    const r = await AtendimentoAnexoService.findAll({ idAtendimento, limit: 200 });
    setAnexos(r.items);
  }, []);

  // functions
  async function handleSalvarAnexo() {
    if (atendimentoAnexo.file) {
      if (atendimentoAnexo.file.size > 3000001) {
        toast.warn('Anexo não pode ter tamanho superior a 3M');
        return;
      }
    }

    try {
      const { data, file, nome } = atendimentoAnexo;
      const objEnviar = { nome, data, file, idAtendimento: idSelected };
      await AtendimentoAnexoService.insert(objEnviar);
      setCadastroAnexoVisivel(false);
      handleBuscarAnexos(idSelected);
    } catch (err) {
      errorHandle(err);
    }
  }

  async function handleExcluirAnexo(id) {
    showMessage('Confirmação', 'Confirma a exclusão do anexo?', async idx => {
      if (idx === 1) {
        await AtendimentoAnexoService.delete(id);
        handleBuscarAnexos(idSelected);
      }
    });
  }

  async function getFile(tipoAnexo, id) {
    if ('PDF'.includes(tipoAnexo)) {
      try {
        const retorno = await AtendimentoAnexoService.getFile(id, true);
        const srcPdf = URL.createObjectURL(retorno);
        setImgPdfSrc(srcPdf);
        setShowPdfView(true);
      } catch (err) {
        errorHandle(err);
      }
    } else if ('PNG JPG JFIF'.includes(tipoAnexo)) {
      const retorno = await AtendimentoAnexoService.getFile(id);

      const srcImg = URL.createObjectURL(retorno);
      setImgPdfSrc(srcImg);
      setShowImgView(true);
    } else {
      showMessage('Confirmação', 'Confirma o download?', async idx => {
        if (idx === 1) {
          let file;
          let nomeAnexo = 'anexo.file';
          try {
            file = await AtendimentoAnexoService.getFile(id);

            const { type } = file;
            nomeAnexo = `anexo_desenvolvimento_${id}.${extensaoFile(type)}`;
          } catch (err) {
            errorHandle(err);
          }
          if (file) {
            const blob = new Blob([file]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', nomeAnexo);
            document.body.appendChild(link);
            link.click();
          }
        }
      });
    }
  }

  // useEffects
  useEffect(() => {
    handleBuscarAnexos(idSelected);
  }, [handleBuscarAnexos, idSelected]);

  // renders
  function renderCadastroAnexo() {
    function renderFooter() {
      return (
        <div>
          <ButtonSp
            label="Confirmar"
            icon="pi pi-check"
            disabled={!validateFields(atendimentoAnexo, ['nome', 'file'])}
            onClick={() => {
              handleSalvarAnexo();
            }}
          />
          <ButtonSp
            label="Cancelar"
            icon="pi pi-times"
            onClick={() => setCadastroAnexoVisivel(false)}
            className="p-button-secondary"
          />
        </div>
      );
    }

    return (
      <Dialog
        closable={false}
        header="Anexo"
        footer={renderFooter()}
        visible={cadastroAnexoVisivel}
        style={{ minWidth: '350px', maxWidth: '600px', width: '95%' }}
        modal
        onHide={() => {
          setCadastroAnexoVisivel(false);
        }}
      >
        <div className="p-grid">
          <div className="p-col-12 p-sm-12 p-lg-12 p-fluid">
            <InputTextSp
              type="file"
              required
              accept=".png, .jpg, .pdf, .jfif"
              value={fileName}
              onChange={e => {
                const nome = e.target.files[0]?.name || '';

                const file = e.target.files && e.target.files[0] ? e.target.files[0] : null;
                setFileName(e.target.value);
                setAtendimentoAnexo({
                  ...atendimentoAnexo,
                  nome,
                  file,
                });
              }}
            />
            <span>Tamanho máximo permitido para cada anexo é de 3Mb</span>
          </div>
          <div className="p-col-12 p-sm-12 p-lg-12 p-fluid">
            <LabelSp>Nome</LabelSp>
            <InputTextSp
              value={atendimentoAnexo.nome}
              maxLength={100}
              required
              onChange={e => {
                setAtendimentoAnexo({
                  ...atendimentoAnexo,
                  nome: e?.target.value,
                });
              }}
            />
          </div>
        </div>
      </Dialog>
    );
  }

  return (
    <>
      {showPdfView ? (
        <PdfView
          title="Anexo"
          src={imgPdfSrc}
          visible={showPdfView}
          setVisible={() => {
            setImgPdfSrc(undefined);
            setShowPdfView(false);
          }}
        />
      ) : null}

      {showImgView ? (
        <ImageView
          title="Imagem"
          src={imgPdfSrc}
          visible={showImgView}
          setVisible={() => {
            setImgPdfSrc(undefined);
            setShowImgView(false);
          }}
        />
      ) : null}

      {renderCadastroAnexo()}

      <div className="p-col-12 p-fluid">
        <DataTableSp
          value={anexos}
          style={{ marginBottom: '2px' }}
          paginator
          rows={pageLimit}
          responsive
        >
          <Column
            field="id"
            body={rowData => padLeft(rowData.id, 6)}
            header="Id"
            className="grid-col-id"
          />
          <Column
            field="tipo"
            header="Tipo Arquivo"
            className="grid-col grid-col-center"
            style={{ width: 150 }}
            body={rowData => rowData.tipoArquivo}
          />
          <Column
            field="data"
            className="grid-col-data-hora grid-col-center"
            header="Data Upload"
            body={rowData => formatDate(rowData.data, 'dd/MM/yyyy')}
          />
          <Column field="nome" className="grid-col" header="Nome" />
          <Column
            className="gid-col-acoes-70"
            bodyStyle={{ textAlign: 'end' }}
            body={renderButtonOp}
          />
        </DataTableSp>
      </div>

      <div className="p-col-12 p-lg-12" style={{ textAlign: 'start' }}>
        <ButtonSp
          className="buttons"
          title="Inserir Anexo"
          label="Inserir"
          icon="pi pi-plus-circle"
          type="button"
          onClick={() => {
            setAtendimentoAnexo(new AtendimentoAnexoModel());
            setFileName(undefined);
            setCadastroAnexoVisivel(true);
          }}
        />
        <ButtonSp
          className="p-button-secondary"
          label="Voltar"
          title="Voltar"
          icon="pi pi-chevron-circle-left"
          onClick={handleBack}
        />
      </div>
    </>
  );
  function renderButtonOp(rowData) {
    let icone = 'pi pi-download';
    let label = 'Download';
    let classButton = 'botao-pequeno p-button-success buttons';
    switch (rowData.tipoArquivo) {
      case 'PDF':
        icone = 'pi pi-file-pdf';
        label = 'Visualizar PDF';
        classButton = 'botao-pequeno p-button-success buttons';
        break;
      case 'JPG':
      case 'PNG':
      case 'JFIF':
        icone = 'pi pi-image';
        label = 'Visualizar Imagem';
        classButton = 'botao-pequeno p-button-success buttons';
        break;
      default:
        break;
    }

    return (
      <>
        <ButtonSp
          className={classButton}
          title={label}
          icon={icone}
          onClick={() => getFile(rowData.tipoArquivo, rowData.id)}
        />
        <ButtonSp
          className="botao-pequeno p-button-danger buttons"
          title="Excluir"
          icon="pi pi-trash"
          disabled={!AuthService.checkRoles('ATENDIMENTO_UPDATE')}
          onClick={() => handleExcluirAnexo(rowData.id)}
        />
      </>
    );
  }
}

export default CrudAnexo;

// import { addMonths } from 'date-fns';
import { addDays } from 'date-fns';
import { BaseService } from './BaseService';
import { ConfigApi } from '../config/Constantes';
import { geraFiltroHttp, strToDate } from '../util/functions';

class AtendimentoService extends BaseService {
  constructor() {
    super(ConfigApi.atendimentoURL);
    // this.filter = new Filter();
  }

  getFilter() {
    return new Filter();
  }

  async getProntuario(reportProntuarioOptions) {
    this.setLoading(true);
    try {
      const filtros = geraFiltroHttp(reportProntuarioOptions, true);

      const response = await this.api.get(`${this.url}/printProntuario/pdf${filtros}`, {
        responseType: 'blob',
      });

      return response.data;
    } finally {
      this.setLoading(false);
    }
  }

  async getImpresso(idAtendimentoImpresso) {
    this.setLoading(true);
    try {
      const response = await this.api.get(`${this.url}/impressos/${idAtendimentoImpresso}`, {
        responseType: 'blob',
      });

      return response.data;
      // let retornoBase64 = Buffer.from(response.data, 'binary').toString('base64');
      // if (retornoBase64) {
      //   retornoBase64 = `data:application/pdf;base64,${retornoBase64}`;
      // }
      // return retornoBase64;
    } finally {
      this.setLoading(false);
    }
  }

  async exportCSV(filter) {
    this.setLoading(true);
    try {
      const filterGet = geraFiltroHttp(filter);
      const response = await this.api.get(`${this.url}/export/csv${filterGet}`, {
        responseType: 'arraybuffer',
      });

      const path = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = path;
      link.setAttribute('download', 'atendimentos.csv');
      document.body.appendChild(link);
      link.click();

      // let retornoBase64 = Buffer.from(response.data, 'binary').toString('base64');
      // if (retornoBase64) {
      //   retornoBase64 = `data:text/csv;base64,${retornoBase64}`;
      // }

      // return retornoBase64;
    } finally {
      this.setLoading(false);
    }
  }
}

class Filter {
  constructor() {
    this.id = undefined;
    this.idComunidade = undefined;
    this.idAtendimentoTipo = undefined;
    this.idAtendimentoTipoEspecifico = 0;
    this.idColaborador = undefined;
    this.dataInicio = strToDate('2019-01-01');
    this.dataFim = addDays(new Date(), 1);
    this.idPaciente = undefined;
    this.pacienteNome = '';
    this.colaboradorNome = '';
    this.cartaoSus = '';
    this.cpf = '';
    this.listaSituacao = '["0", "1"]';

    this.limit = 0;
    this.page = 0;
  }
}
export default new AtendimentoService();

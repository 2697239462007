import React, { useCallback } from 'react';
import { Calendar } from 'primereact/calendar';
import PropTypes from 'prop-types';
import { addMonths } from 'date-fns';
import { Container } from './styles';
import { getTimeZoneOffSet, newDateOnly, padLeft } from '../../util/functions';
import { cfgPtBr } from '../../config/Constantes';
// import { Container } from './styles';

export default function CalendarAgendaSp(props) {
  CalendarAgendaSp.propTypes = {
    required: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    feriados: PropTypes.arrayOf(PropTypes.any),
    blockOldDate: PropTypes.bool,
  };

  CalendarAgendaSp.defaultProps = {
    required: false,
    disabled: false,
    blockOldDate: false,
    feriados: [],
  };
  const today = newDateOnly();
  const { required, disabled, className, feriados, blockOldDate, ...rest } = props;

  let { value } = props;

  if (blockOldDate && value < today) {
    value = today;
  }

  const timeZone = getTimeZoneOffSet() || 0;
  const disabledDates = feriados.map(e => {
    if (typeof e === 'string') {
      const dataStr = `${e.substring(0, 10)}T${padLeft(timeZone.toString(), 2)}:00:00.000Z`;
      return new Date(dataStr);
    }
    return e;
  });

  const classNames = required && !disabled ? `p-error ${className || ''}` : className || '';

  const dateTemplate = useCallback(
    date => {
      const feriadoMesDateStr = JSON.parse(JSON.stringify(feriados || []));
      const dataCalendario = new Date(date.year, date.month, date.day, 0, 0, 0, 0);

      const dataCalendarioStr = `${date.year}-${padLeft(date.month + 1, 2)}-${padLeft(
        date.day,
        2
      )}T00:00:00.000Z`;

      if (Array.isArray(feriadoMesDateStr) && feriadoMesDateStr.includes(dataCalendarioStr)) {
        return <span className="p-disabled feriado item-calendar">{date.day}</span>;
      }

      if (dataCalendario < today || dataCalendario > addMonths(today, 2)) {
        return (
          <span
            className="p-disabled item-calendar"
            style={{ textDecoration: 'line-through' }}
          >
            {date.day}
          </span>
        );
      }

      return date.day;
    },
    [feriados, today]
  );

  return (
    <Container>
      <Calendar
        {...rest}
        dateFormat="dd/mm/yy"
        locale={cfgPtBr}
        readOnlyInput
        inline
        disabled={disabled}
        value={value || undefined}
        className={classNames}
        dateTemplate={dateTemplate}
        yearNavigator={false}
        disabledDates={disabledDates}
        minDate={blockOldDate ? today : addMonths(today, -3)}
        maxDate={addMonths(today, 12)}
      />
      <div className="rodape">
        <div style={{ flex: 1 }}>
          <div className="div-legenda feriado">&nbsp;</div>
          <span style={{ alignSelf: 'center' }}>Feriados/Recessos</span>
        </div>
        <div style={{ flex: 1 }}>
          <div className="div-legenda indisponivel">
            <span>&nbsp;</span>
          </div>
          <span style={{ alignSelf: 'center' }}>
            {blockOldDate ? 'Indisponível' : 'Agenda anterior'}
          </span>
        </div>
      </div>
    </Container>
  );
}

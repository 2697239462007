/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { Column } from 'primereact/column';

import ButtonSp from '../../components/ButtonSp';
import DropDownLazy from '../../components/DropdownLazy';

import LabelSp from '../../components/LabelSp';
import { showMessage } from '../../components/MessageDialog';
import ConfigAgendaService from '../../services/ConfigAgendaService';
import ColaboradorService from '../../services/ColaboradorService';
import {
  calcNaxItemsPage,
  cloneObj,
  errorHandle,
  formatDate,
  newTimeOnly,
  validateFields,
} from '../../util/functions';
import { ConfigAgendaDetalheModel, ConfigAgendaModel, TpColaborador } from '../../util/Models';
import { StateScreen } from '../constants';

import { cfgPtBr } from '../../config/Constantes';
import CalendarSp from '../../components/CalendarSp';
import AuthService from '../../services/AuthService';
import InputTextSp from '../../components/InputTextSp';
import DropdownSp from '../../components/DropdownSp';
import DataTableSp from '../../components/DataTableSp';
import BotaoMenuGrid from '../../components/BotaoMenuGrid';

// import DropdownSp from '../../components/DropdownSp';

function ConfigAgendaCrud(props) {
  ConfigAgendaCrud.propTypes = {
    stateScreen: PropTypes.string.isRequired,
    idSelected: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  ConfigAgendaCrud.defaultProps = {
    messageConfirmation: 'Confirma os dados',
    showConfirmation: false,
  };

  const { stateScreen, idSelected, onClose } = props;

  // states
  const [configAgenda, setConfigAgenda] = useState(new ConfigAgendaModel());
  const [colaboradorSelecionado, setColaboradorSelecionado] = useState(null);
  const [pageLimit] = useState(calcNaxItemsPage(5, 9, 14));

  const [configAgendaDetalhe, setConfigAgendaDetalhe] = useState([]);

  const [horaInicioSelecionada, setHoraInicioSelecionada] = useState(newTimeOnly('08:00:00'));
  const [horaFinalSelecionada, setHoraFinalSelecionada] = useState(newTimeOnly('18:00:00'));
  const [errorLoadRecord, setErrorLoadRecord] = useState(false);

  const [idxConfigAgendaDetalheSelecionada, setIdxConfigAgendaDetalheSelecionada] = useState();
  const [diaSemanaSelecionado, setDiaSemanaSelecionado] = useState();

  const diasSemana = [
    { label: 'Domingo', value: 0 },
    { label: 'Segunda', value: 1 },
    { label: 'Terça', value: 2 },
    { label: 'Quarta', value: 3 },
    { label: 'Quinta', value: 4 },
    { label: 'Sexta', value: 5 },
    { label: 'Sábado', value: 6 },
  ];

  // useCallbacks
  const loadColaborador = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await ColaboradorService.findAll({
        nome: _nome,
        limit: 50,
        inativo: false,
      });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadRecord = useCallback(async _id => {
    try {
      const retorno = await ConfigAgendaService.findById(_id);

      if (retorno.configAgendaDetalhe && Array.isArray(retorno.configAgendaDetalhe)) {
        retorno.configAgendaDetalhe.forEach(e => {
          if (e.horaInicio) {
            e.horaInicio = new Date(e.horaInicio);
          }

          if (e.horaFim) {
            e.horaFim = new Date(e.horaFim);
          }
        });
      }

      if (!retorno.configAgendaDetalhe) {
        retorno.configAgendaDetalhe = [];
      }
      setConfigAgenda(retorno);
      setConfigAgendaDetalhe(retorno.configAgendaDetalhe);

      const colaborador = {
        label: retorno.colaborador.nome,
        value: retorno.colaborador.id,
      };

      setColaboradorSelecionado(colaborador);
      setErrorLoadRecord(false);
    } catch (err) {
      setErrorLoadRecord(true);
      errorHandle(err);
    }
  }, []);

  // funcoes
  function buscaNomeDiaSemana(nDia) {
    switch (nDia) {
      case 0:
        return 'Domingo';
      case 1:
        return 'Segunda';
      case 2:
        return 'Terça';
      case 3:
        return 'Quarta';
      case 4:
        return 'Quinta';
      case 5:
        return 'Sexta';
      case 6:
        return 'Sabado';
      default:
        return '';
    }
  }

  function viewMode() {
    return stateScreen === StateScreen.stView || errorLoadRecord;
  }

  function handleBack() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      showMessage('Confirmação', 'Abandonar mudanças?', idx => {
        if (idx === 1) {
          onClose();
        }
      });
    }
  }

  function handleSave() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      salvarRecord();
    }
  }

  async function salvarRecord() {
    try {
      let retorno;
      const configAgendaSalvar = cloneObj(configAgenda);

      configAgendaSalvar.configAgendaDetalhe = configAgendaDetalhe;

      if (stateScreen === StateScreen.stInsert) {
        retorno = await ConfigAgendaService.insert(configAgendaSalvar);
      } else {
        retorno = await ConfigAgendaService.update(configAgendaSalvar);
      }
      toast.success('Registro salvo com sucesso.');
      onClose(retorno);
    } catch (err) {
      errorHandle(err);
    }
  }

  const carregaDetalhe = registro => {
    let idx;
    if (Array.isArray(configAgendaDetalhe)) {
      idx = configAgendaDetalhe.findIndex(e => e === registro);
    }

    setDiaSemanaSelecionado(registro.diaSemana);
    setHoraFinalSelecionada(registro.horaFim);
    setHoraInicioSelecionada(registro.horaInicio);
    setIdxConfigAgendaDetalheSelecionada(idx);
  };

  const confirmaExclusaoDetalhe = registro => {
    showMessage('Confirmação', `Deseja excluir registro?`, idx => {
      if (idx === 1) {
        const configAgendaDetalhes = [...configAgendaDetalhe] || [];

        let indice;

        if (Array.isArray(configAgendaDetalhe)) {
          indice = configAgendaDetalhe.findIndex(e => e === registro);
        }

        if (indice >= 0) {
          configAgendaDetalhes.splice(indice, 1);
          setConfigAgendaDetalhe(configAgendaDetalhes);
        }
      }
    });
  };

  const salvarDetalhe = () => {
    let detalhe;
    const configAgendaDetalhes = [...configAgendaDetalhe];

    if (idxConfigAgendaDetalheSelecionada >= 0) {
      detalhe = configAgendaDetalhes[idxConfigAgendaDetalheSelecionada];
      detalhe.diaSemana = diaSemanaSelecionado;
      detalhe.horaInicio = horaInicioSelecionada;
      detalhe.horaFim = horaFinalSelecionada;
    } else {
      detalhe = new ConfigAgendaDetalheModel();

      detalhe.diaSemana = diaSemanaSelecionado;
      detalhe.horaInicio = horaInicioSelecionada;
      detalhe.horaFim = horaFinalSelecionada;
      configAgendaDetalhes.push(detalhe);
    }

    if (detalhe && !validaHorarioDetalhe(detalhe)) {
      return;
    }

    setConfigAgendaDetalhe(configAgendaDetalhes);
    setIdxConfigAgendaDetalheSelecionada(undefined);
    setHoraInicioSelecionada(newTimeOnly('08:00:00'));
    setHoraFinalSelecionada(newTimeOnly('18:00:00'));
    setDiaSemanaSelecionado(undefined);
  };

  function validaHorarioDetalhe(configAgendaDetalheVerificar) {
    let validou = true;

    configAgendaDetalhe.forEach((ag, index) => {
      if (index !== idxConfigAgendaDetalheSelecionada) {
        if (configAgendaDetalheVerificar.diaSemana === ag.diaSemana) {
          if (
            ag.horaInicio.getTime() >= configAgendaDetalheVerificar.horaInicio.getTime() &&
            ag.horaInicio.getTime() <= configAgendaDetalheVerificar.horaFim.getTime()
          ) {
            validou = false;
          }
          if (
            ag.horaFim.getTime() <= configAgendaDetalheVerificar.horaFim.getTime() &&
            ag.horaFim.getTime() >= configAgendaDetalheVerificar.horaInicio.getTime()
          ) {
            validou = false;
          }

          if (
            configAgendaDetalheVerificar.horaInicio.getTime() >= ag.horaInicio.getTime() &&
            configAgendaDetalheVerificar.horaInicio.getTime() <= ag.horaFim.getTime()
          ) {
            validou = false;
          }

          if (
            configAgendaDetalheVerificar.horaFim.getTime() <= ag.horaFim.getTime() &&
            configAgendaDetalheVerificar.horaFim.getTime() >= ag.horaInicio.getTime()
          ) {
            validou = false;
          }
        }
      }
    });

    if (!validou) {
      toast.warn('Existe outro horário no mesmo intervalo.');
      return validou;
    }
    return validou;
  }

  // useEffects
  useEffect(() => {
    if (stateScreen === StateScreen.stUpdate || stateScreen === StateScreen.stView) {
      loadRecord(idSelected);
    } else if (stateScreen === StateScreen.stInsert) {
      const novo = new ConfigAgendaModel();

      let colaborador;

      const colaboradorLogado = AuthService.getColaborador();

      switch (colaboradorLogado.idColaboradorCategoria) {
        case TpColaborador.MEDICO:
        case TpColaborador.PSICOLOCO:
        case TpColaborador.ENFERMEIRO:
          colaborador = { label: colaboradorLogado.nome, value: colaboradorLogado.id };
          break;

        default:
          break;
      }

      if (colaborador) {
        novo.colaborador = {
          id: colaborador.value,
          nome: colaborador.label,
        };
        novo.idColaborador = colaborador.value;
      }

      setColaboradorSelecionado(colaborador);

      setConfigAgenda(novo);
    }
  }, [loadRecord, idSelected, stateScreen]);

  const renderPrincipal = () => {
    return (
      <>
        <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
          <LabelSp>Profissional</LabelSp>
          <DropDownLazy
            required
            autoLoad
            disabled={viewMode() || stateScreen === StateScreen.stUpdate}
            placeholder="Selecione"
            onChange={e => {
              const colaborador = e ? { id: e.value, nome: e.label } : null;
              setColaboradorSelecionado(e);
              setConfigAgenda({
                ...configAgenda,
                colaborador,
                idColaborador: e?.value,
              });
            }}
            value={colaboradorSelecionado}
            onFilter={async txtFilter => {
              const retorno = await loadColaborador(txtFilter);
              return retorno;
            }}
          />
        </div>

        <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
          <LabelSp>Nome ConfigAgenda</LabelSp>
          <InputTextSp
            disabled={viewMode()}
            required
            maxLength={100}
            value={configAgenda.nome}
            onChange={e => setConfigAgenda({ ...configAgenda, nome: e.target.value })}
          />
        </div>

        <div className="p-col-12 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Intervalo</LabelSp>
          <DropdownSp
            required
            disabled={viewMode()}
            placeholder="Selecione"
            value={configAgenda.intervaloMinutos}
            options={[
              { value: 15, label: '15 Minutos' },
              { value: 20, label: '20 Minutos' },
              { value: 30, label: '30 Minutos' },
              { value: 40, label: '40 Minutos' },
              { value: 45, label: '45 Minutos' },
              { value: 60, label: '60 Minutos' },
              { value: 90, label: '90 Minutos' },
            ]}
            onChange={e =>
              setConfigAgenda({ ...configAgenda, intervaloMinutos: e.target.value })
            }
          />
        </div>

        <div className="p-col-12 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Inativo?</LabelSp>
          <DropdownSp
            required
            disabled={viewMode()}
            placeholder="Selecione"
            value={configAgenda.inativo}
            options={[
              { value: false, label: 'Não' },
              { value: true, label: 'Sim' },
            ]}
            onChange={e => setConfigAgenda({ ...configAgenda, inativo: e.target.value })}
          />
        </div>

        <div
          className="p-col-12 p-sm-12 p-lg-12 p-fluid"
          style={{ textAlign: 'center', marginTop: 0, marginBottom: 0 }}
        >
          <hr style={{ margin: 0, padding: 0 }} />
          <LabelSp>Horário</LabelSp>
        </div>

        <div className="p-col-12 p-sm-5 p-lg-4 p-fluid">
          <LabelSp>Dia da Semana</LabelSp>

          <DropdownSp
            required
            placeholder="Selecione"
            disabled={viewMode()}
            value={diaSemanaSelecionado}
            options={diasSemana}
            onChange={e => setDiaSemanaSelecionado(e.target.value)}
          />
        </div>

        <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Hr Inicial</LabelSp>
          <CalendarSp
            readOnlyInput
            appendTo={document.body}
            showTime
            timeOnly
            disabled={viewMode()}
            required
            locale={cfgPtBr}
            value={horaInicioSelecionada}
            onChange={e => setHoraInicioSelecionada(e.value)}
          />
        </div>

        <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Hr Final</LabelSp>
          <CalendarSp
            readOnlyInput
            appendTo={document.body}
            showTime
            timeOnly
            disabled={viewMode()}
            required
            locale={cfgPtBr}
            value={horaFinalSelecionada}
            onChange={e => setHoraFinalSelecionada(e.value)}
          />
        </div>

        <div className="p-col-4 p-sm-4 p-lg-4">
          <LabelSp>&#160;</LabelSp>
          <ButtonSp
            className="p-button-success"
            icon="pi pi-save"
            title="Salvar"
            disabled={
              diaSemanaSelecionado === undefined ||
              !horaFinalSelecionada ||
              !horaInicioSelecionada
            }
            onClick={() => salvarDetalhe()}
          />
          <ButtonSp
            className="p-button-danger"
            icon="pi pi-times"
            title="Cancelar"
            onClick={() => {
              setDiaSemanaSelecionado(undefined);
              setHoraInicioSelecionada(newTimeOnly('08:00:00'));
              setHoraFinalSelecionada(newTimeOnly('18:00:00'));
            }}
          />
        </div>

        <div className="p-col-12 p-fluid">
          <DataTableSp
            value={configAgendaDetalhe}
            style={{ marginBottom: '2px' }}
            paginator
            rows={pageLimit}
            responsive
          >
            <Column
              className="grid-col"
              header="Dia da semana"
              body={rowData => buscaNomeDiaSemana(rowData.diaSemana)}
            />

            <Column
              className="grid-col-data"
              header="Hr. Inicio"
              body={rowData => formatDate(rowData.horaInicio, 'HH:mm')}
            />

            <Column
              className="grid-col-data"
              header="Hr. Fim"
              body={rowData => formatDate(rowData.horaFim, 'HH:mm')}
            />

            <Column
              className="gid-col-acoes-35"
              bodyStyle={{ textAlign: 'end' }}
              body={renderButtonOp}
            />
          </DataTableSp>
        </div>

        {/* label-button */}
      </>
    );
  };

  function renderButtonOp(rowData) {
    return (
      <BotaoMenuGrid
        handles={[
          () => {
            carregaDetalhe(rowData);
          },
          () => {
            confirmaExclusaoDetalhe(rowData);
          },
        ]}
        labels={['Alterar', 'Excluir']}
        icons={['pi pi-pencil', 'pi pi-trash']}
        disableds={[
          !AuthService.checkRoles('CONFIG_AGENDA_UPDATE'),
          !AuthService.checkRoles('CONFIG_AGENDA_DELETE'),
        ]}
      />
    );
  }

  // render principal
  const buttons = ['Dados Principais'];
  if (AuthService.checkRoles('ATENDIMENTO_INSERT')) {
    buttons.push('Detalhes do ConfigAgenda');
  }

  return (
    <>
      {renderPrincipal()}

      <div className="p-col-12 p-lg-12" style={{ textAlign: 'start' }}>
        {!viewMode() ? (
          <ButtonSp
            className="p-button-success"
            icon="pi pi-save"
            label="Salvar"
            showConfirmation
            disabled={
              !validateFields(configAgenda, ['nome', 'idColaborador', 'intervaloMinutos']) ||
              configAgendaDetalhe.length === 0
            }
            onClick={handleSave}
          />
        ) : null}
        <ButtonSp
          className="p-button-secondary"
          label="Voltar"
          icon="pi pi-chevron-circle-left"
          onClick={handleBack}
        />
      </div>
    </>
  );
}

export default ConfigAgendaCrud;

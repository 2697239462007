import styled from 'styled-components';

export const Container = styled.div`
  .p-dialog-titlebar {
    margin: 0px !important;
    padding: 5px !important;
  }

  .p-dialog-titlebar-icon {
    width: 35px !important;
    text-align: center;
  }

  margin: 0;
  padding: 0;

  th {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  td {
    padding-left: 4px !important;
    padding-right: 4px !important;
    padding-top: 1px !important;
    padding-bottom: 1px !important;
    height: 31px !important;
  }
`;

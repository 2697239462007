export function loginRequest(email, senha, redirectTo) {
  return {
    type: '@auth/LOGIN_REQUEST',
    payload: { email, senha },
    redirectTo,
  };
}

export function loginSuccess(token, decodedToken, colaborador) {
  return {
    type: '@auth/LOGIN_SUCCESS',
    payload: { token, decodedToken, colaborador },
  };
}

export function loginFailure() {
  return {
    type: '@auth/LOGIN_FAILURE',
  };
}

export function logout() {
  return {
    type: '@auth/LOGOUT',
  };
}

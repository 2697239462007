import { BaseService } from './BaseService';
import { ConfigApi } from '../config/Constantes';

class AtendimentoAnexoService extends BaseService {
  constructor() {
    super(`${ConfigApi.atendimentoAnexoURL}`);
  }

  getFilter() {
    return new Filter();
  }

  async insert(body) {
    delete body.id;

    const data = new FormData();
    Object.keys(body).forEach(k => {
      data.append(k, body[k]);
    });

    this.setLoading(true);
    try {
      const response = await this.api.post(this.url, data);
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }

  async getFile(id) {
    this.setLoading(true);
    try {
      const response = await this.api.get(`${this.url}/${id}`, {
        responseType: 'blob',
      });

      return response.data;
    } finally {
      this.setLoading(false);
    }
  }

  async getFileBase64(id) {
    this.setLoading(true);
    try {
      const response = await this.api.get(`${this.url}/${id}`, {
        responseType: 'arraybuffer',
      });

      const type = response.headers['content-type'];

      let retornoBase64 = Buffer.from(response.data, 'binary').toString('base64');
      if (retornoBase64 && type !== 'application/pdf') {
        retornoBase64 = `data:${type};base64,${retornoBase64}`;
      }

      return retornoBase64;
    } finally {
      this.setLoading(false);
    }
  }
}

class Filter {
  constructor() {
    this.nome = '';
    this.idAtendimento = undefined;
    this.inativo = '';
    this.limit = 11;
    this.page = 0;
  }
}
export default new AtendimentoAnexoService();

import styled from 'styled-components';

export const Container = styled.div`
  height: 36px;
  padding: 0;
  margin: 0;
  margin-top: 3px;
  /* background: #fff; */
  .title {
    font-size: 20px;
    font-weight: bold;
    /* margin-left: 2px; */
  }
  .buttons {
    float: right;
    height: 2.2em;
  }
`;

export const ContainerSearch = styled.div`
  .search {
    width: 120px;
  }

  /* 640px (40em x 16px) */
  @media (min-width: 40em) {
    .search {
      width: 300px;
    }
  }
`;

import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputTextSp from '../InputTextSp';
import LabelSp from '../LabelSp';
import { Container } from './styles';
import ButtonSp from '../ButtonSp';
import CheckboxSp from '../CheckboxSp';
import { ReportProntuarioOptions } from '../../util/Models';
import PdfView from '../PdfView';
import AtendimentoService from '../../services/AtendimentoService';
import { errorHandle } from '../../util/functions';
import DropdownSp from '../DropdownSp';

export default function ProntuarioReport(props) {
  ProntuarioReport.propTypes = {
    paciente: PropTypes.object,
  };

  const { visible, setVisible, paciente } = props;

  const [reportProntuarioOptions, setReportProntuarioOptions] = useState(
    new ReportProntuarioOptions()
  );
  const [protuarioSrc, setProtuarioSrc] = useState('');
  const [showProntuario, setShowProntuario] = useState(false);

  // useCalbacks
  const printProntuario = async reportProntuarioOps => {
    try {
      const retorno = await AtendimentoService.getProntuario(reportProntuarioOps);
      const srcPdf = URL.createObjectURL(retorno);
      setProtuarioSrc(srcPdf);

      setShowProntuario(true);
    } catch (err) {
      errorHandle(err);
    }
  };

  // use effects
  useEffect(() => {
    setReportProntuarioOptions({
      ...reportProntuarioOptions,
      idPaciente: paciente.id,
      pacienteNome: paciente.nome,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paciente]);

  const footer = (
    <div>
      <ButtonSp
        label="Gerar"
        icon="pi pi-print"
        onClick={() => {
          printProntuario(reportProntuarioOptions);
        }}
        className="p-button-primary"
      />
      <ButtonSp
        label="Voltar"
        icon="pi pi-chevron-circle-left"
        onClick={() => {
          setVisible(false);
        }}
        className="p-button-secondary"
      />
    </div>
  );

  const renderOpcoes = () => {
    return (
      <>
        <div className="p-col-12 p-sm-6 p-lg-6 p-fluid">
          <CheckboxSp
            inputId="chkShowProfissional"
            onChange={e => {
              setReportProntuarioOptions({
                ...reportProntuarioOptions,
                showProfissional: e.checked,
              });
            }}
            checked={reportProntuarioOptions.showProfissional}
            caption="Dados do Profissional"
          />
        </div>

        <div className="p-col-12 p-sm-6 p-lg-6 p-fluid">
          <CheckboxSp
            inputId="chkShowQueixaPrincipal"
            onChange={e => {
              setReportProntuarioOptions({
                ...reportProntuarioOptions,
                showQueixaPrincipal: e.checked,
              });
            }}
            checked={reportProntuarioOptions.showQueixaPrincipal}
            caption="Queixa Principal"
          />
        </div>

        <div className="p-col-12 p-sm-6 p-lg-6 p-fluid">
          <CheckboxSp
            inputId="chkShowMedicamento"
            onChange={e => {
              setReportProntuarioOptions({
                ...reportProntuarioOptions,
                showMedicamento: e.checked,
              });
            }}
            checked={reportProntuarioOptions.showMedicamento}
            caption="Medicamentos Uso Regular"
          />
        </div>

        <div className="p-col-12 p-sm-6 p-lg-6 p-fluid">
          <CheckboxSp
            inputId="chkShowHistoriaClinica"
            onChange={e => {
              setReportProntuarioOptions({
                ...reportProntuarioOptions,
                showHistoriaClinica: e.checked,
              });
            }}
            checked={reportProntuarioOptions.showHistoriaClinica}
            caption="História Clínica"
          />
        </div>

        <div className="p-col-12 p-sm-6 p-lg-6 p-fluid">
          <CheckboxSp
            inputId="chkShowComorbidades"
            onChange={e => {
              setReportProntuarioOptions({
                ...reportProntuarioOptions,
                showComorbidades: e.checked,
              });
            }}
            checked={reportProntuarioOptions.showComorbidades}
            caption="Comorbidades"
          />
        </div>

        <div className="p-col-12 p-sm-6 p-lg-6 p-fluid">
          <CheckboxSp
            inputId="chkShowPrescricoes"
            onChange={e => {
              setReportProntuarioOptions({
                ...reportProntuarioOptions,
                showPrescricoes: e.checked,
              });
            }}
            checked={reportProntuarioOptions.showPrescricoes}
            caption="Prescrições Realizadas"
          />
        </div>

        <div className="p-col-12 p-sm-6 p-lg-6 p-fluid">
          <CheckboxSp
            inputId="chkShowExames"
            onChange={e => {
              setReportProntuarioOptions({
                ...reportProntuarioOptions,
                showExames: e.checked,
              });
            }}
            checked={reportProntuarioOptions.showExames}
            caption="Exames laboratoriais"
          />
        </div>

        <div className="p-col-12 p-sm-6 p-lg-6 p-fluid">
          <CheckboxSp
            inputId="chkShowOrientacoes"
            onChange={e => {
              setReportProntuarioOptions({
                ...reportProntuarioOptions,
                showOrientacoes: e.checked,
              });
            }}
            checked={reportProntuarioOptions.showOrientacoes}
            caption="Orientações Realizadas"
          />
        </div>

        <div className="p-col-12 p-sm-6 p-lg-6 p-fluid">
          <CheckboxSp
            inputId="chkShowHipoteseDiagnostica"
            onChange={e => {
              setReportProntuarioOptions({
                ...reportProntuarioOptions,
                showHipoteseDiagnostica: e.checked,
              });
            }}
            checked={reportProntuarioOptions.showHipoteseDiagnostica}
            caption="Hipótese Diagnóstica"
          />
        </div>

        <div className="p-col-12 p-sm-6 p-lg-6 p-fluid">
          <CheckboxSp
            inputId="chkShowAtendimentoMulher"
            onChange={e => {
              setReportProntuarioOptions({
                ...reportProntuarioOptions,
                showAtendimentoMulher: e.checked,
              });
            }}
            checked={reportProntuarioOptions.showAtendimentoMulher}
            caption="Saúde da Mulher"
          />
        </div>

        <div className="p-col-12 p-sm-6 p-lg-6 p-fluid">
          <CheckboxSp
            inputId="chkShowAnexos"
            onChange={e => {
              setReportProntuarioOptions({
                ...reportProntuarioOptions,
                showAnexos: e.checked,
              });
            }}
            checked={reportProntuarioOptions.showAnexos}
            caption="Anexos do Atendimento"
          />
        </div>
      </>
    );
  };

  return (
    <>
      {showProntuario ? (
        <PdfView
          title="Prontuário"
          src={protuarioSrc}
          visible={showProntuario}
          setVisible={() => {
            setProtuarioSrc(undefined);
            setShowProntuario(false);
          }}
        />
      ) : null}

      {visible ? (
        <Dialog
          footer={footer}
          visible={visible}
          style={{ minWidth: '380px', maxWidth: '600px', width: '95%' }}
          modal
          closable={false}
          appendTo={document.body}
          header="Impressão de Prontuário"
          onHide={() => {
            setVisible(false);
          }}
        >
          <Container>
            <div className="p-grid">
              <div className="p-col-12 p-sm-8 p-lg-8 p-fluid">
                <LabelSp>Paciente</LabelSp>
                <InputTextSp
                  value={reportProntuarioOptions.pacienteNome}
                  maxLength={100}
                  required
                  disabled
                />
              </div>
              <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
                <LabelSp>Exibir Atendimentos</LabelSp>
                <DropdownSp
                  value={reportProntuarioOptions.qtdMaxAtendimentos}
                  options={[
                    { label: '3 Últimos', value: 3 },
                    { label: '5 Últimos', value: 5 },
                    { label: '10 Últimos', value: 10 },
                    { label: 'Todos', value: 9999 },
                  ]}
                  filterInputAutoFocus={false}
                  onChange={e => {
                    setReportProntuarioOptions({
                      ...reportProntuarioOptions,
                      qtdMaxAtendimentos: e?.value,
                    });
                  }}
                />
              </div>
              <div className="p-col-12 p-sm-12 p-lg-12 p-fluid">
                <LabelSp>Opções a serem impressas</LabelSp>
                <div
                  className="p-grid p-col-12 p-fluid p-margin-0 group-box"
                  style={{ paddingBottom: 4 }}
                >
                  {renderOpcoes()}
                </div>
              </div>
            </div>
          </Container>
        </Dialog>
      ) : null}
    </>
  );
}

/* eslint-disable no-nested-ternary */
import React, { useRef, useState } from 'react';

import PropTypes from 'prop-types';

import { Chart } from 'primereact/chart';

import {
  optionsGraficoAtendimentosComunidade,
  optionsGraficoBeneficiarioMunicipio,
  optionsGraficoAtendimentosMunicipio,
} from './chartConfig';
import { calcHeightGraphiFromRecords } from '../../util/functions';
import 'chartjs-plugin-labels';
import Panel from '../../components/Panel';
import GraficoView from '../../components/GraficoView';

function Page2(props) {
  Page2.propTypes = {
    tipoApuracao: PropTypes.number,
    // dataGraficoAtMeses: PropTypes.object,
  };

  const {
    tipoApuracao,
    dataGraficoAtendimentosComunidade,
    dataGraficoAtendimentosMunicipio,
    dataGraficoBeneficiarioMunicipio,
    comunidadeSemAt,
    municipioSemAt,
  } = props;

  Page2.defaultProps = {
    required: false,
    autoFocus: false,
  };

  const [graficoRefSelecionado, setGraficoRefSelecionado] = useState();
  const [graficoSelecionadoOptions, setGraficoSelecionadoOptions] = useState();
  const [graficoViewVisible, setGraficoViewVisible] = useState(false);
  const [heightGraficoOriginal, setGeightGraficoOriginal] = useState();

  const chartAtendimentoComunidade = useRef();
  const chartAtendimentoMunicipio = useRef();
  const chartBeneficiarioMunicipio = useRef();

  const pCol = 'p-col-12 p-lg-8 p-xl-7';

  const strQtdComunidadeSemAt =
    Array.isArray(comunidadeSemAt) && comunidadeSemAt.length > 0
      ? `(${comunidadeSemAt.length})`
      : '';
  const strQtdMunicipioSemAt =
    Array.isArray(municipioSemAt) && municipioSemAt.length > 0
      ? `(${municipioSemAt.length})`
      : '';

  const renderComunidadeSemAt = () => {
    if (Array.isArray(comunidadeSemAt)) {
      return comunidadeSemAt.map(e => {
        return (
          <span
            key={e.id}
            className="grid-col-nowrap "
            style={{ fontSize: 14, display: 'block', fontStyle: 'bold', fontColor: '#333333' }}
          >
            {e.comunidade}
          </span>
        );
      });
    }
    return null;
  };

  const renderMunicipioSemAt = () => {
    if (Array.isArray(municipioSemAt)) {
      return municipioSemAt.map(e => {
        return (
          <span
            key={e.id}
            className="grid-col-nowrap "
            style={{ fontSize: 14, display: 'block', fontStyle: 'bold', fontColor: '#333333' }}
          >
            {e.municipio}
          </span>
        );
      });
    }
    return null;
  };

  return (
    <>
      {graficoViewVisible && (
        <GraficoView
          chartRef={graficoRefSelecionado}
          visible={graficoViewVisible}
          setVisible={setGraficoViewVisible}
          options={graficoSelecionadoOptions}
          heightGraficoOriginal={heightGraficoOriginal}
        />
      )}
      <>
        {tipoApuracao !== 3 && (
          <>
            <div className={pCol}>
              <Panel
                header="Atendimentos por Comunidade no Período"
                style={{ height: '100%' }}
                handleView={() => {
                  setGraficoViewVisible(true);
                  setGraficoRefSelecionado(chartAtendimentoComunidade);
                  setGraficoSelecionadoOptions({
                    ...optionsGraficoAtendimentosComunidade,
                    title: {
                      ...optionsGraficoAtendimentosComunidade.title,
                      text: 'Atendimentos por Comunidade no Período',
                    },
                  });
                  setGeightGraficoOriginal(
                    calcHeightGraphiFromRecords(dataGraficoAtendimentosComunidade.records)
                  );
                }}
              >
                <Chart
                  ref={chartAtendimentoComunidade}
                  id="atendimento-comunidade"
                  type="horizontalBar"
                  data={dataGraficoAtendimentosComunidade}
                  options={{
                    ...optionsGraficoAtendimentosComunidade,
                    title: {
                      ...optionsGraficoAtendimentosComunidade.title,
                      text: 'Atendimentos por Comunidade no Período',
                    },
                  }}
                  height={String(
                    calcHeightGraphiFromRecords(dataGraficoAtendimentosComunidade.records)
                  )}
                />
              </Panel>
            </div>

            <div className={pCol}>
              <Panel
                header="Atendimentos por Município no Período"
                style={{ height: '100%' }}
                handleView={() => {
                  setGraficoViewVisible(true);
                  setGraficoRefSelecionado(chartAtendimentoMunicipio);
                  setGraficoSelecionadoOptions({
                    ...optionsGraficoAtendimentosMunicipio,
                    title: {
                      ...optionsGraficoAtendimentosMunicipio.title,
                      text: 'Atendimentos por Município no Período',
                    },
                  });

                  setGeightGraficoOriginal(
                    calcHeightGraphiFromRecords(dataGraficoAtendimentosMunicipio.records)
                  );
                }}
              >
                <Chart
                  ref={chartAtendimentoMunicipio}
                  id="atendimento-comunidade"
                  type="horizontalBar"
                  data={dataGraficoAtendimentosMunicipio}
                  options={{
                    ...optionsGraficoAtendimentosMunicipio,
                    title: {
                      ...optionsGraficoAtendimentosMunicipio.title,
                      text: 'Atendimentos por Município no Período',
                    },
                  }}
                  height={String(
                    calcHeightGraphiFromRecords(dataGraficoAtendimentosMunicipio.records)
                  )}
                />
              </Panel>
            </div>
          </>
        )}
        {tipoApuracao === 1 && (
          <div className={pCol}>
            <Panel
              header="Nr. Beneficiários por Município no Período"
              style={{ height: '100%' }}
              handleView={() => {
                setGraficoViewVisible(true);
                setGraficoRefSelecionado(chartBeneficiarioMunicipio);
                setGraficoSelecionadoOptions({
                  ...optionsGraficoBeneficiarioMunicipio,
                  title: {
                    ...optionsGraficoBeneficiarioMunicipio.title,
                    text: 'Nr. Beneficiários por Município no Período',
                  },
                });
                setGeightGraficoOriginal(
                  calcHeightGraphiFromRecords(dataGraficoBeneficiarioMunicipio.records)
                );
              }}
            >
              <Chart
                ref={chartBeneficiarioMunicipio}
                id="beneficiario-municipio"
                type="horizontalBar"
                data={dataGraficoBeneficiarioMunicipio}
                options={{
                  ...optionsGraficoBeneficiarioMunicipio,
                  title: {
                    ...optionsGraficoBeneficiarioMunicipio.title,
                    text: 'Nr. Beneficiários por Município no Período',
                  },
                }}
                height={String(
                  calcHeightGraphiFromRecords(dataGraficoBeneficiarioMunicipio.records)
                )}
              />
            </Panel>
          </div>
        )}

        {tipoApuracao !== 3 && (
          <>
            <div className={pCol}>
              <Panel
                header={`Comunidades sem Atendimento no Período${strQtdComunidadeSemAt}`}
                style={{ height: '100%' }}
              >
                {renderComunidadeSemAt()}
              </Panel>
            </div>

            <div className={pCol}>
              <Panel
                header={`Municipios sem Atendimento no Período${strQtdMunicipioSemAt}`}
                style={{ height: '100%' }}
              >
                {renderMunicipioSemAt()}
              </Panel>
            </div>
          </>
        )}
      </>
    </>
  );
}

export default Page2;

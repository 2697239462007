import { BaseService } from './BaseService';
import { ConfigApi } from '../config/Constantes';
import { firstDayMonth, geraFiltroHttp, lastDayMonth } from '../util/functions';

class DashboardService extends BaseService {
  constructor() {
    super(ConfigApi.dashboardURL);
  }

  getFilter() {
    return new Filter();
  }

  async getDashBoard(filter) {
    this.setLoading(true);
    try {
      const filterGet = geraFiltroHttp(filter);
      const response = await this.api.get(`${this.url}${filterGet}`);
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }
}

class Filter {
  constructor() {
    this.mesAnoApuracao = firstDayMonth(new Date());
    this.dataInicio = firstDayMonth(new Date());
    this.dataFim = lastDayMonth(new Date());
    this.idProfissional = null;
    this.tipoApuracao = 1; // 1-todos, 2-apenas teleatendimento, 3-apenas teleeducacao
    this.limit = 0;
    this.page = 0;
    this.teleEducacaoTipo = '0';
    this.tipoPeriodo = 2; // 1-ano, //2-mes ano
  }
}
export default new DashboardService();

// import { addMonths } from 'date-fns';
import { BaseService } from './BaseService';
import { ConfigApi } from '../config/Constantes';
import { geraFiltroHttp, strToDate } from '../util/functions';

class EducacaoService extends BaseService {
  constructor() {
    super(ConfigApi.educacaoURL);
    // this.filter = new Filter();
  }

  getFilter() {
    return new Filter();
  }

  async exportCSV(filter) {
    this.setLoading(true);
    try {
      const filterGet = geraFiltroHttp(filter);
      const response = await this.api.get(`${this.url}/export/csv${filterGet}`, {
        responseType: 'arraybuffer',
      });

      const path = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = path;
      link.setAttribute('download', 'teleeducacao.csv');
      document.body.appendChild(link);
      link.click();

      // let retornoBase64 = Buffer.from(response.data, 'binary').toString('base64');
      // if (retornoBase64) {
      //   retornoBase64 = `data:text/csv;base64,${retornoBase64}`;
      // }

      // return retornoBase64;
    } finally {
      this.setLoading(false);
    }
  }
}

class Filter {
  constructor() {
    this.id = undefined;
    this.idColaborador = undefined;
    this.dataInicio = strToDate('2019-01-01');
    this.dataFim = new Date();
    this.idMunicipio = 0;
    this.idComunidade = 0;
    this.tipo = '';
    this.tema = '';

    this.limit = 0;
    this.page = 0;
  }
}
export default new EducacaoService();

import React from 'react';

import PropTypes from 'prop-types';
import { MultiSelect } from 'primereact/multiselect';

export default function MultiSelectSp(props) {
  MultiSelectSp.propTypes = {
    required: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
  };

  MultiSelectSp.defaultProps = {
    required: false,
    className: '',
    disabled: false,
  };

  const { required, className, disabled, ...rest } = props;

  const classNames = required && !disabled ? `p-error ${className || ''}` : className || '';
  return <MultiSelect {...rest} disabled={disabled} className={classNames} />;
}

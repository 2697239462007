import styled from 'styled-components';

export const Container = styled.div`
  margin: 0;
  padding: 0;

  .div-status {
    display: inline-block;
    width: 20px;
    border-radius: 3px;
    /* border: 1px solid #525252; */
  }

  .multiselected-item {
    margin: 0 10px 0 0;
    display: inline-block;
    border-radius: 3px;
  }

  .group-box {
    border-style: solid;
    border: 1px solid #969595;
    border-radius: 4px;
  }

  td {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
    height: 29px !important;
  }

  .legenda {
    display: flex;
    flex: 1;
    border-radius: 2px;
    border-style: solid;
    border-width: 1px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-color: #c8c8c8;
    margin-top: 1px;

    flex-direction: column;
    span {
      align-self: center;
    }
  }

  .legenda-linha {
    margin: 2px;
    display: flex;
    flex: 1;
    flex-direction: row;
  }

  .div-legenda {
    display: inline-block;
    width: 22px;
    margin-right: 3px;
    margin-left: 5px;
  }

  .aguardando {
    border-radius: 4px;
    border-style: solid;
    border-width: 2px;
    border-color: #007ad9;
    background: #007ad9;
  }

  .em-agendimento {
    border-radius: 4px;
    border-style: solid;
    border-width: 2px;
    border-color: #ebd300;
    background: #ebd300;
  }

  .atendido {
    border-radius: 4px;
    border-style: solid;
    border-width: 2px;
    border-color: #6ecc65;
    background: #6ecc65;
  }

  .nao-realizado {
    border-radius: 4px;
    border-style: solid;
    border-width: 2px;
    border-color: #ee3902;
    background: #ee3902;
  }

  .livre {
    border-radius: 4px;
    border-style: solid;
    border-width: 2px;
    border-color: #b8b6b6;
    background: #c8c8c8;
  }

  .espaco-vago {
    border-radius: 4px;
    border-style: solid;
    border-width: 0px;
  }
`;

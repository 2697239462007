import { BaseService } from './BaseService';
import { ConfigApi } from '../config/Constantes';

class FinanciadorService extends BaseService {
  constructor() {
    super(`${ConfigApi.financiadorURL}`);
  }

  getFilter() {
    return new Filter();
  }
}

class Filter {
  constructor() {
    this.nome = '';
    this.limit = 11;
    this.page = 0;
  }
}
export default new FinanciadorService();

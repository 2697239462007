import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputTextSp from '../InputTextSp';
import { formatFloat, strNumBrToNumber } from '../../util/functions';

export default function CurrencyInput(props) {
  CurrencyInput.propTypes = {
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    digits: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onlyPositive: PropTypes.bool,
  };

  CurrencyInput.defaultProps = {
    value: 0,
    digits: 2,
    onlyPositive: true,
  };

  // eslint-disable-next-line no-unused-vars
  const { onChange, onBlur, onFocus, value, digits, onlyPositive, ...inputProps } = props;
  const propValue = onlyPositive && (props.value || 0) < 0 ? 0 : props.value || 0;
  const internalDigits = digits >= 0 ? digits : 2;
  const [internalValue, setInternalValue] = useState(Number(props.value) || 0);
  const [formatedValue, setFormatedValue] = useState(
    formatFloat(propValue, internalDigits, 'pt-BR')
  );

  useEffect(() => {
    setFormatedValue(formatFloat(propValue, internalDigits, 'pt-BR'));
    setInternalValue(Number(propValue));
  }, [internalDigits, propValue]);

  const handleChange = event => {
    setFormatedValue(event.target.value);
    const valNumeric = strNumBrToNumber(event.target.value, 0);

    setInternalValue(Number(valNumeric.toFixed(internalDigits)));
  };

  const handleBlur = event => {
    setFormatedValue(formatFloat(internalValue, internalDigits, 'pt-BR'));

    if (onBlur || onChange) {
      event.persist();
    }

    if (onBlur) {
      onBlur(event);
    }

    if (onChange) {
      onChange(event, internalValue);
    }
  };

  const handleFocus = event => {
    setFormatedValue(formatFloat(internalValue, internalDigits, 'pt-BR').replace(/\./g, ''));
    if (onFocus) {
      event.persist();
      onFocus(event);
    }
    const { target } = event;
    setTimeout(() => {
      target.select();
    }, 100);
  };
  const filterType = onlyPositive ? 'money' : 'num';
  return (
    <InputTextSp
      style={{ textAlign: 'end' }}
      {...inputProps}
      value={formatedValue}
      onChange={handleChange}
      keyfilter={filterType}
      onBlur={handleBlur}
      onFocus={handleFocus}
    />
  );
}

import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { Column } from 'primereact/column';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Container } from './styles';

import AtendimentoService from '../../services/AtendimentoService';
import FinanciadorService from '../../services/FinanciadorService';
import AuthService from '../../services/AuthService';
import history from '../../services/history';
import {
  getPageParams,
  errorHandle,
  calcNaxItemsPage,
  isScreenMobile,
  formatDate,
  formatFloat,
  padLeft,
  isNumber,
} from '../../util/functions';
import LabelSp from '../../components/LabelSp';

import { showMessage } from '../../components/MessageDialog';

import BotaoMenuGrid from '../../components/BotaoMenuGrid';

import AtendimentoCrud from './crud';
import { StateScreen } from '../constants';
import CrudHeader from '../../components/CrudHeader';
import DataTableSp from '../../components/DataTableSp';
import DropDownLazy from '../../components/DropdownLazy';
import ColaboradorService from '../../services/ColaboradorService';
import ComunidadeService from '../../services/ComunidadeService';
import CalendarSp from '../../components/CalendarSp';
import { cfgPtBr } from '../../config/Constantes';
import AtendimentoTipoService from '../../services/AtendimentoTipoService';
import InputMaskSp from '../../components/InputMaskSp';

import ImpressoAtendimento from '../../components/ImpressoAtendimento';
import MunicipioService from '../../services/MunicipioService';
import MultiSelectSp from '../../components/MultiSelectSp';
import { SitAtendimento, TpColaboradorPerfil } from '../../util/Models';
import ProntuarioReport from '../../components/ProntuarioReport';
import RegionalService from '../../services/RegionalService';

export default function Atendimento(props) {
  // useSelectors
  const filterVisible = useSelector(state => state.global.filterVisible);

  // useMemo
  const pageParams = useMemo(() => getPageParams(props.match.params, props.location.search), [
    props.location.search,
    props.match.params,
  ]);

  const filterService = useMemo(() => {
    const colaborador = AuthService.getColaborador();
    const f = AtendimentoService.getFilter();
    if (colaborador && colaborador.municipioAcesso) {
      f.idMunicipio = colaborador.municipioAcesso.id;
      f.municipioAcesso = {
        label: colaborador.municipioAcesso.nome,
        value: colaborador.municipioAcesso.id,
      };
    } else {
      f.municipioAcesso = undefined;
    }
    return f;
  }, []);

  // useStates

  const toBack = pageParams.toBack || '/atendimentos';

  const [filter, setFilter] = useState(filterService);
  const [atendimentos, setAtendimentoes] = useState([]);
  const [comunidade, setComunidade] = useState(null);
  const [colaborador, setColaborador] = useState(null);
  const [atendimentoTipo, setAtendimentoTipo] = useState(null);
  const [financiador, setFinanciador] = useState(null);
  const [municipio, setMunicipio] = useState(null);
  const [regional, setRegional] = useState(null);

  const [pageLimit, setPageLimit] = useState(filterService.limit);
  const [first, setFirst] = useState(0);
  const [pageSelected, setPageSelected] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [funcTimeOut, setFuncTimeOut] = useState();
  const [pacienteProntuario, setPacienteProntuario] = useState();
  const [showProntuario, setShowProntuario] = useState(false);
  const [showImpressos, setShowImpressos] = useState(false);
  const [idAtendimentoSelecionado, setIdAtendimentoSelecionado] = useState(0);
  const [listaSituacaoSelecionada, setListaSituacaoSelecionada] = useState(
    JSON.parse(filter.listaSituacao)
  );

  const listaSituacao = [
    { label: 'Em Atendimento', value: '0' },
    { label: 'Realizado', value: '1' },
  ];

  // useCallbacks
  const loadRegional = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await RegionalService.findAll({ nome: _nome, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadColaborador = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await ColaboradorService.findAll({
        nome: _nome,
        limit: 50,
        listaIdCategorias: '[1,2,3]',
      });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadMunicipio = useCallback(async (_nome, uf) => {
    if (_nome !== undefined) {
      const r = await MunicipioService.findAll({ nome: _nome, uf, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadComunidade = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await ComunidadeService.findAll({ nome: _nome, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadAtendimentoTipo = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await AtendimentoTipoService.findAll({ nome: _nome, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadFinanciador = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await FinanciadorService.findAll({ nome: _nome, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const calcLimit = useCallback(() => {
    let ret;
    if (filterVisible) {
      ret = calcNaxItemsPage(6, 10, 16);
    } else {
      ret = calcNaxItemsPage(13, 17, 22);
    }
    setPageLimit(ret);
    return ret;
  }, [filterVisible]);

  const handleBuscar = useCallback(
    async (_filter, _page, resetPage = true) => {
      _filter.page = _page || 0;
      _filter.limit = calcLimit();
      try {
        const result = await AtendimentoService.findAll(_filter);
        setAtendimentoes(result.items);

        setTotalRecords(result.totalRecords);
        if (resetPage) {
          setFirst(0);
        }
      } catch (err) {
        errorHandle(err);
      }
    },
    [calcLimit]
  );

  const setFilterAndSearch = useCallback(
    async _filterValue => {
      if (JSON.stringify(_filterValue) !== JSON.stringify(filter)) {
        setFilter(_filterValue);

        if (funcTimeOut) {
          clearTimeout(funcTimeOut);
        }
        const func = setTimeout(async () => {
          handleBuscar(_filterValue);
        }, 800);
        setFuncTimeOut(func);
      }
    },
    [filter, funcTimeOut, handleBuscar]
  );

  function onPage(event) {
    const pagina = event.first / event.rows;
    setPageSelected(pagina);
    setFirst(event.first);
    handleBuscar(filter, pagina, false);
  }

  function validateAuthColaborador(idColaborador) {
    if (
      AuthService.getColaborador().id !== idColaborador &&
      AuthService.getColaborador().idColaboradorPerfil !== TpColaboradorPerfil.ADMINISTRADOR &&
      AuthService.getColaborador().idColaboradorPerfil !== TpColaboradorPerfil.GESTOR_FAS
    ) {
      toast.error(
        'Você não tem permissão para alterar/excluir atendimentos de outros profissionais.'
      );
      return false;
    }
    return true;
  }

  // useCallbacks
  const excluirRegistro = useCallback(
    async _id => {
      try {
        await AtendimentoService.delete(_id);
        toast.success('Registro excluído com sucesso.');
        handleBuscar(filter);
      } catch (err) {
        errorHandle(err);
      }
    },
    [filter, handleBuscar]
  );

  const doExportCSV = useCallback(async (_filter, lgpd) => {
    try {
      await AtendimentoService.exportCSV({ ..._filter, lgpd });
    } catch (err) {
      errorHandle(err);
    }
  }, []);

  // functions
  function corStatus(status) {
    switch (status) {
      case SitAtendimento.EM_ATENDIMENTO:
        return '#ebd300';
      case SitAtendimento.REALIZADO:
        return '#6ecc65';
      default:
        return ' ';
    }
  }

  function getTitle() {
    const titleDefault = 'Teleatendimento';
    let titleAdd = '';

    if (pageParams.stateScreen === StateScreen.stSearch) {
      titleAdd = '';
    }
    if (pageParams.stateScreen === StateScreen.stInsert) {
      titleAdd = '(Novo)';
    }
    if (pageParams.stateScreen === StateScreen.stUpdate) {
      titleAdd = `(Alterando Id: ${pageParams.idSelected})`;
    }
    if (pageParams.stateScreen === StateScreen.stView) {
      titleAdd = ` (Visualizando Id: ${pageParams.idSelected})`;
    }

    if (!isScreenMobile()) {
      return `${titleDefault} ${titleAdd}`;
    }

    return titleDefault;
  }

  const confirmaExclusao = useCallback(
    registro => {
      const mensagem = registro.idAgendamento
        ? 'Existe um agendamento vinculado. A exclusão fará com que o agendamento fique com situação "Aguardando". Confirma Exclusão?'
        : 'Confirma a exclusão do registro?';
      showMessage('Confirmação', mensagem, idx => {
        if (idx === 1) {
          excluirRegistro(registro.id);
        }
      });
    },
    [excluirRegistro]
  );

  // useEffects
  useEffect(() => {
    setMunicipio(filter.municipioAcesso);
    if (pageParams.stateScreen === StateScreen.stSearch) {
      handleBuscar(filter, 0, true);
    }
    // desativado para evitar que a cada vez que o atendimento digitasse o sistema buscasse
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageParams.stateScreen]);

  // useEffects
  // Responsavel por recalcular a quantidade de registros da grid quando a div do filtro ocultar
  // ou ficar visivel
  useEffect(() => {
    if (pageParams.stateScreen === StateScreen.stSearch) {
      calcLimit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcLimit]);

  const printProntuario = async paciente => {
    setPacienteProntuario(paciente);
    setShowProntuario(true);
  };

  // renders
  return (
    <Container className="container-page">
      {showProntuario ? (
        <ProntuarioReport
          paciente={pacienteProntuario}
          visible={showProntuario}
          setVisible={() => {
            setShowProntuario(false);
          }}
        />
      ) : null}

      {showImpressos ? (
        <ImpressoAtendimento
          idAtendimento={idAtendimentoSelecionado}
          visible={showImpressos}
          setVisible={setShowImpressos}
        />
      ) : null}

      <div className="p-grid">
        <CrudHeader
          title={getTitle()}
          disabledButtonInsert={!AuthService.checkRoles('ATENDIMENTO_INSERT')}
          showButtonFilter={pageParams.stateScreen === StateScreen.stSearch}
          showButtonInsert
          showButtonSearch
          optionsButtonMenu={{
            handles: [() => doExportCSV(filter), () => doExportCSV(filter, true)],
            labels: ['Exportar para CSV', 'Exportar para CSV(LGPD)'],
            icons: ['pi pi-cloud-download', 'pi pi-cloud-download'],
            disableds: [!AuthService.checkRoles('ATENDIMENTO_EXPORT')],
          }}
          titleFilterDefault="Nome"
          handleFilterDefault={text => {
            filter.pacienteNome = text;
            setFilter({ ...filter, pacienteNome: text });
            handleBuscar(filter);
          }}
          handleClearFilters={() => {
            const idMunicipioTemp = filter.municipioAcesso ? filter.idMunicipio : 0;

            setFilter({
              ...filter,
              id: 0,
              idComunidade: 0,
              idAtendimentoTipo: 0,
              idFinanciador: 0,
              idColaborador: 0,
              dataAtendimento: null,
              idPaciente: 0,
              pacienteNome: '',
              colaboradorNome: '',
              cartaoSus: '',
              cpf: '',
              idMunicipio: idMunicipioTemp,
              idRegional: 0,
            });
            setComunidade(null);
            setColaborador(null);
            setAtendimentoTipo(null);
            setFinanciador(null);
            if (!filter.municipioAcesso) {
              setMunicipio(null);
            }
          }}
          handleButtonInsert={() => history.push('/atendimentos/insert')}
          handleButtonSearch={() => handleBuscar(filter)}
        >
          <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
            <LabelSp htmlFor="dataInicial">De: </LabelSp>
            <CalendarSp
              readOnlyInput
              appendTo={document.body}
              dateFormat="dd/mm/yy"
              yearNavigator
              locale={cfgPtBr}
              id="dataInicial"
              value={filter.dataInicio}
              yearRange="2010:2040"
              onChange={e => {
                setFilterAndSearch({ ...filter, dataInicio: e.value });
              }}
            />
          </div>
          <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
            <LabelSp htmlFor="dataFinal">Até: </LabelSp>
            <CalendarSp
              appendTo={document.body}
              readOnlyInput
              locale={cfgPtBr}
              id="dataFinal"
              dateFormat="dd/mm/yy"
              value={filter.dataFim}
              yearNavigator
              yearRange="2010:2040"
              onChange={e => setFilterAndSearch({ ...filter, dataFim: e.value })}
            />
          </div>

          <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
            <LabelSp>Situação</LabelSp>
            <MultiSelectSp
              value={listaSituacaoSelecionada}
              options={listaSituacao}
              selectedItemTemplate={selectedSituacaoTemplate}
              maxSelectedLabels={4}
              onChange={e => {
                const retorno = Array.isArray(e.value) ? e.value.sort() : [];
                setListaSituacaoSelecionada(retorno);
                setFilterAndSearch({ ...filter, listaSituacao: JSON.stringify(retorno) });
              }}
            />
          </div>

          <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
            <LabelSp>CPF</LabelSp>
            <InputMaskSp
              mask="999.999.999-99"
              value={filter.cpf}
              onChange={e => {
                setFilterAndSearch({ ...filter, cpf: e.target.value });
              }}
            />
          </div>

          <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
            <LabelSp>Cartão SUS</LabelSp>
            <InputMaskSp
              mask="999.9999.9999.9999"
              value={filter.cartaoSus}
              onChange={e => {
                setFilterAndSearch({ ...filter, cartaoSus: e.target.value });
              }}
            />
          </div>

          <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
            <LabelSp>Local Atendimento</LabelSp>
            <DropDownLazy
              autoLoad
              showClear
              placeholder="Todos"
              onChange={e => {
                setFilterAndSearch({ ...filter, idComunidade: e?.value });
                setComunidade(e);
              }}
              value={comunidade}
              onFilter={async txtFilter => {
                const retorno = await loadComunidade(txtFilter);
                return retorno;
              }}
            />
          </div>

          <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
            <LabelSp>Profissional</LabelSp>
            <DropDownLazy
              autoLoad
              showClear
              placeholder="Todos"
              onChange={e => {
                setFilterAndSearch({ ...filter, idColaborador: e?.value });
                setColaborador(e);
              }}
              value={colaborador}
              onFilter={async txtFilter => {
                const retorno = await loadColaborador(txtFilter);
                return retorno;
              }}
            />
          </div>

          <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
            <LabelSp>Tipo Atendimento</LabelSp>
            <DropDownLazy
              showClear
              autoLoad
              placeholder="Todos"
              onChange={e => {
                setFilterAndSearch({ ...filter, idAtendimentoTipo: e?.value });
                setAtendimentoTipo(e);
              }}
              value={atendimentoTipo}
              onFilter={async txtFilter => {
                const retorno = await loadAtendimentoTipo(txtFilter);
                return retorno;
              }}
            />
          </div>

          <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
            <LabelSp>Financiador</LabelSp>
            <DropDownLazy
              autoLoad
              showClear
              placeholder="Todos"
              onChange={e => {
                setFilterAndSearch({ ...filter, idFinanciador: e?.value });
                setFinanciador(e);
              }}
              value={financiador}
              onFilter={async txtFilter => {
                const retorno = await loadFinanciador(txtFilter);
                return retorno;
              }}
            />
          </div>

          <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
            <LabelSp>Município</LabelSp>
            <DropDownLazy
              showClear
              autoLoad
              disabled={filter.municipioAcesso}
              placeholder="Todos"
              onChange={e => {
                setFilterAndSearch({ ...filter, idMunicipio: e?.value });
                setMunicipio(e);
              }}
              value={municipio}
              onFilter={async txtFilter => {
                const retorno = await loadMunicipio(txtFilter);
                return retorno;
              }}
            />
          </div>
          <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
            <LabelSp>Regional/TI</LabelSp>
            <DropDownLazy
              autoLoad
              showClear
              placeholder="Todos"
              onChange={e => {
                setFilterAndSearch({ ...filter, idRegional: e?.value });
                setRegional(e);
              }}
              value={regional}
              onFilter={async txtFilter => {
                const retorno = await loadRegional(txtFilter);
                return retorno;
              }}
            />
          </div>
        </CrudHeader>
        {pageParams.stateScreen === StateScreen.stSearch ? renderSearch() : renderCrud()}
      </div>
    </Container>
  );

  function renderSearch() {
    return (
      <>
        <div className="p-col-12 p-fluid">
          <DataTableSp
            value={atendimentos}
            style={{ marginBottom: '2px' }}
            paginator
            rows={pageLimit}
            lazy
            responsive
            totalRecords={totalRecords}
            first={first}
            onPage={onPage}
          >
            <Column
              field="id"
              header=" "
              style={{ width: 30 }}
              body={rowData => {
                const cor = corStatus(rowData.situacao);
                return (
                  <div
                    title={listaSituacao[rowData.situacao].label}
                    className="div-status"
                    style={{
                      background: cor,
                      color: cor,
                    }}
                  >
                    &nbsp;
                  </div>
                );
              }}
            />
            <Column
              field="id"
              body={rowData => padLeft(rowData.id, 6)}
              header="Id"
              className="grid-col-id p-p-6"
            />
            <Column
              className="grid-col-data"
              header="Data"
              body={rowData => formatDate(rowData.dataAtendimento, 'dd/MM/yyyy')}
            />
            <Column
              className="grid-col-hora"
              header="Hora"
              body={rowData => formatDate(rowData.horaAtendimento, 'HH:mm')}
            />
            <Column
              className="grid-col-hora grid-col-center"
              header="Tempo"
              body={rowData => `${formatFloat(rowData.duracaoAtendimento, 0)} M`}
            />
            <Column field="pacienteNome" className="grid-col" header="Nome Paciente" />
            <Column field="colaboradorNome" className="grid-col" header="Profissional" />
            <Column
              className="grid-col p-p-5"
              style={{ width: 150 }}
              header="Tipo At."
              body={rowData => rowData.atendimentoTipoNome}
            />
            <Column
              className="grid-col p-p-8"
              style={{ width: 300 }}
              header="Local Atendimento"
              body={rowData => rowData.comunidadeNome}
            />

            <Column
              className="gid-col-acoes-35"
              bodyStyle={{ textAlign: 'end' }}
              body={renderButtonOp}
            />
          </DataTableSp>
        </div>
      </>
    );
  }

  function renderButtonOp(rowData) {
    return (
      <BotaoMenuGrid
        handles={[
          () => history.push(`/atendimentos/${rowData.id}?view`),
          () => {
            if (!validateAuthColaborador(rowData.idColaborador)) {
              return;
            }
            history.push(`/atendimentos/${rowData.id}`);
          },
          () => {
            if (!validateAuthColaborador(rowData.idColaborador)) {
              return;
            }
            confirmaExclusao(rowData);
          },
          () => printProntuario({ id: rowData.idPaciente, nome: rowData.pacienteNome }),
          () => {
            setShowImpressos(true);
            setIdAtendimentoSelecionado(rowData.id);
          },
        ]}
        labels={['Visualizar', 'Alterar', 'Excluir', 'Prontuário', 'Impressos']}
        icons={[
          'pi pi-search',
          'pi pi-pencil',
          'pi pi-trash',
          'pi pi-print',
          'pi pi-file-pdf',
        ]}
        disableds={[
          !AuthService.checkRoles('ATENDIMENTO_VIEW'),
          !AuthService.checkRoles('ATENDIMENTO_UPDATE'),
          !AuthService.checkRoles('ATENDIMENTO_DELETE'),
          !AuthService.checkRoles('ATENDIMENTO_PRINT'),
          !AuthService.checkRoles('ATENDIMENTO_PRINT'),
        ]}
      />
    );
  }

  function renderCrud() {
    return (
      <AtendimentoCrud
        idSelected={pageParams.idSelected}
        stateScreen={pageParams.stateScreen}
        closeOnSaveCancel={false}
        toBack={toBack !== '/atendimentos' ? toBack : ''}
        onClose={() => {
          history.push(toBack);
          if (toBack.includes('/atendimentos')) {
            handleBuscar(filter, pageSelected, false);
          }
        }}
      />
    );
  }

  function selectedSituacaoTemplate(option) {
    if (isNumber(option)) {
      const cor = corStatus(Number(option));
      const item = listaSituacao[option];
      return (
        <div className="multiselected-item">
          <div
            className="div-status"
            style={{
              marginRight: 3,
              background: cor,
              color: cor,
            }}
          >
            A
          </div>
          <span>{item.label}</span>
        </div>
      );
    }
    return <span>Todas</span>;
  }
}

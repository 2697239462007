import React, { useCallback, useEffect, useState } from 'react';

import { Dialog } from 'primereact/dialog';
import PropTypes from 'prop-types';
import { Column } from 'primereact/column';
import { toast } from 'react-toastify';
import { Container } from './styles';
import LabelSp from '../LabelSp';

import DropdownSp from '../DropdownSp';

import ButtonSp from '../ButtonSp';

import {
  cloneObj,
  errorHandle,
  extractDateStrFromDate,
  extractTimeStrFromDate,
  formatDate,
  newDateOnly,
  newTimeHourOnly,
} from '../../util/functions';
import AtendimentoTipoService from '../../services/AtendimentoTipoService';
import ColaboradorService from '../../services/ColaboradorService';
import DataTableSp from '../DataTableSp';
import AgendamentoService from '../../services/AgendamentoService';
import AuthService from '../../services/AuthService';
import CalendarAgendaSp from '../CalendarAgendaSp';
import FeriadoService from '../../services/FeriadoService';
import { TpAtendimento, TpColaboradorPerfil } from '../../util/Models';
import CalendarSp from '../CalendarSp';

import { cfgPtBr } from '../../config/Constantes';

export default function HorarioAgendamento(props) {
  HorarioAgendamento.propTypes = {
    visible: PropTypes.bool,
    setVisible: PropTypes.func,
    handleConfirm: PropTypes.func,
    data: PropTypes.object,
    idAgendamentoAtual: PropTypes.number,
    idAtendimentoTipo: PropTypes.number,
  };

  HorarioAgendamento.defaultProps = {
    visible: false,
  };

  const {
    visible,
    setVisible,
    handleConfirm,
    data,
    idAgendamentoAtual,
    idAtendimentoTipo,
  } = props;

  const [filter, setFilter] = useState({
    page: 0,
    limit: 1000,
    idColaborador: undefined,
    idAtendimentoTipo,
    data: data || newDateOnly(),
  });

  const [colaborador, setColaborador] = useState();
  const [horarioEncaixe, setHorarioEncaixe] = useState();

  const [selectedRecord, setSelectedRecord] = useState(null);
  const [records, setRecords] = useState([]);
  const [funcTimeOut, setFuncTimeOut] = useState();
  const [feriados, setFeriados] = useState([]);
  const [showSelecionaHora, setShowSelecionaHora] = useState(false);
  const [colaboradores, setColaboradores] = useState([]);

  const [atendimentoTipos, setAtendimentoTipos] = useState([]);

  // useCallbacks
  const loadAtendimentoTipo = useCallback(async () => {
    const r = await AtendimentoTipoService.findAll({ limit: 9999 });

    const retorno = r.items.map(e => {
      return {
        label: e.nome,
        value: e.id,
      };
    });
    setAtendimentoTipos(retorno);
  }, []);

  const loadFeriado = useCallback(async () => {
    const r = await FeriadoService.findAll({ dezAnos: true });
    if (Array.isArray(r)) {
      const retorno = r.map(e => {
        const strData = `${new Date(e.data).toISOString().substring(0, 10)}T00:00:00.000Z`;

        return strData;
      });

      setFeriados(retorno);
      return;
    }

    setFeriados([]);
  }, []);

  const confirmaHorario = useCallback(() => {
    if (!colaborador) {
      toast.warn('Para encaixar horário, é necessário selecionar o profissional.');
      return;
    }
    if (!filter.idAtendimentoTipo) {
      toast.warn('Para encaixar horário, é necessário selecionar o tipo de atendimento.');
      return;
    }

    setHorarioEncaixe(newTimeHourOnly());
    setShowSelecionaHora(true);
  }, [colaborador, filter.idAtendimentoTipo]);

  const loadColaborador = useCallback(async _idAtendimentoTipo => {
    let listaIdCategorias = '';
    if (_idAtendimentoTipo) {
      listaIdCategorias = `[${TpAtendimento.getColaboradorCategoria(_idAtendimentoTipo)}]`;
    }
    TpAtendimento.listaIdCategorias = `[${_idAtendimentoTipo}]`;
    const r = await ColaboradorService.findAll({
      limit: 99999,
      temConfigAgenda: 'true',
      listaIdCategorias,
    });

    const retorno = r.items.map(e => {
      return {
        label: e.nome,
        value: e,
      };
    });

    setColaboradores(retorno);
  }, []);

  const handleBuscar = useCallback(
    async _filter => {
      setSelectedRecord(undefined);
      const f = cloneObj(_filter);
      f.data = formatDate(f.data, 'yyyy-MM-dd');
      try {
        let result = [];
        if (_filter.idAtendimentoTipo) {
          result = await AgendamentoService.findAll(f);
        }
        const horarios = [];
        result.forEach(e1 => {
          e1.agendaDiaDetalhe.forEach(e2 => {
            let incluiHorario = true;
            if (e2.agendamento && e2.agendamento.id !== idAgendamentoAtual) {
              incluiHorario = false;
            }
            if (incluiHorario) {
              horarios.push({
                hora: e2.hora,
                idColaborador: e1.idColaborador,
                colaboradorNome: e1.colaboradorNome,
                idAtendimentoTipo: e1.idAtendimentoTipo,
                atendimentoTipoNome: e1.atendimentoTipoNome,
                idConfigAgenda: e1.idConfigAgenda,
              });
            }
          });
        });

        horarios.sort((a, b) => {
          if (a.hora < b.hora) {
            return -1;
          }
          if (a.hora > b.hora) {
            return 1;
          }
          return 0;
        });

        setRecords(horarios);
      } catch (err) {
        errorHandle(err);
      }
    },
    [idAgendamentoAtual]
  );

  const setFilterAndSearch = useCallback(
    async _filterValue => {
      if (JSON.stringify(_filterValue) !== JSON.stringify(filter)) {
        setFilter(_filterValue);

        if (funcTimeOut) {
          clearTimeout(funcTimeOut);
        }
        const func = setTimeout(async () => {
          handleBuscar(_filterValue);
        }, 800);
        setFuncTimeOut(func);
      }
    },
    [filter, funcTimeOut, handleBuscar]
  );

  // effects
  useEffect(() => {
    handleBuscar(filter);
    loadFeriado();
    loadAtendimentoTipo();
    loadColaborador(filter.idAtendimentoTipo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // renders
  const footerHora = (
    <div style={{ textAlign: 'center' }}>
      <ButtonSp
        label="Cancelar"
        icon="pi pi-times"
        onClick={() => {
          setShowSelecionaHora(false);
        }}
        className="p-button-danger"
      />
      <ButtonSp
        label="Confirmar"
        icon="pi pi-check"
        onClick={() => {
          if (handleConfirm) {
            const ret = { ...selectedRecord };

            const atendimentoTipo = atendimentoTipos.find(
              e => e.value === filter.idAtendimentoTipo
            );

            ret.atendimentoTipo = { id: atendimentoTipo.value, nome: atendimentoTipo.label };
            ret.colaborador = colaborador;
            ret.idColaborador = colaborador.id;
            ret.idAtendimentoTipo = atendimentoTipo.id;
            ret.horario = new Date(
              `${extractDateStrFromDate(filter.data)}T${extractTimeStrFromDate(
                horarioEncaixe,
                true
              )}:00.000Z`
            );

            handleConfirm(ret);
          }

          setShowSelecionaHora(false);
          setVisible(false);
        }}
      />
    </div>
  );

  const footer = (
    <div style={{ textAlign: 'center' }}>
      <ButtonSp
        label="Cancelar"
        icon="pi pi-times"
        onClick={() => {
          if (setVisible) {
            setVisible(false);
          }
        }}
        className="p-button-danger"
      />
      <ButtonSp
        label="Confirmar"
        icon="pi pi-check"
        disabled={!selectedRecord}
        onClick={() => {
          if (handleConfirm) {
            const ret = { ...selectedRecord };
            ret.atendimentoTipo = { id: ret.idAtendimentoTipo, nome: ret.atendimentoTipoNome };
            ret.colaborador = { id: ret.idColaborador, nome: ret.colaboradorNome };
            ret.horario = new Date(
              `${extractDateStrFromDate(filter.data)}T${extractTimeStrFromDate(
                new Date(ret.hora),
                true
              )}:00.000Z`
            );

            handleConfirm(ret);
          }
          setVisible(false);
        }}
      />
      {AuthService.getColaborador().idColaboradorPerfil !==
      TpColaboradorPerfil.AGENTE_SAUDE_RIBEIRINHO ? (
        <ButtonSp
          label="Encaixar"
          icon="pi pi-calendar"
          className="p-button-warning"
          onClick={() => {
            confirmaHorario();
          }}
        />
      ) : null}
    </div>
  );

  // render input hora
  const renderShowHora = () => {
    return (
      <>
        <Dialog
          closeOnEscape
          closable
          footer={footerHora}
          contentStyle={{ minHeight: '90px' }}
          style={{ minWidth: '300px', maxWidth: '300px', width: '95%' }}
          modal
          header="Seleciona horário"
          visible={showSelecionaHora}
          blockScroll
          onHide={() => {
            setShowSelecionaHora(false);
          }}
        >
          <div className="p-grid">
            <div className="p-col-12 p-fluid">
              <LabelSp>Horário</LabelSp>
              <CalendarSp
                readOnlyInput
                showIcon
                appendTo={document.body}
                showTime
                timeOnly
                required
                stepMinute={10}
                locale={cfgPtBr}
                value={horarioEncaixe}
                onChange={e => setHorarioEncaixe(e.value)}
              />
            </div>
          </div>
        </Dialog>
      </>
    );
  };
  // render principal
  return (
    <Container>
      <Dialog
        closeOnEscape
        closable
        footer={footer}
        contentStyle={{ minHeight: '380px' }}
        style={{ minWidth: '380px', maxWidth: '1024px', width: '95%' }}
        modal
        header="Seleciona horário/profissional"
        visible={visible}
        blockScroll
        onHide={() => {
          if (setVisible) {
            setVisible(false);
          }
        }}
      >
        {showSelecionaHora && renderShowHora()}
        <div className="p-grid">
          <div className="p-col-12 p-sm-12 p-lg-4 p-fluid" style={{ margin: 0, padding: 0 }}>
            <div className="p-col-12 p-sm-12 p-lg-12 p-fluid">
              <LabelSp>Tipo Atendimento</LabelSp>
              <DropdownSp
                required
                placeholder="Selecione"
                options={atendimentoTipos}
                onChange={e => {
                  let { idColaborador } = filter;
                  if (filter.idAtendimentoTipo !== e.value) {
                    idColaborador = undefined;
                    setColaborador(null);
                    loadColaborador(e.value);
                  }
                  setFilterAndSearch({
                    ...filter,
                    idColaborador,
                    idAtendimentoTipo: e.value,
                  });
                }}
                value={filter.idAtendimentoTipo}
              />
            </div>

            <div className="p-col-12 p-sm-12 p-lg-12 p-fluid">
              <LabelSp>Profissional</LabelSp>
              <DropdownSp
                showClear
                filter
                placeholder="Todos"
                options={colaboradores}
                onChange={e => {
                  setColaborador(e?.value);

                  setFilterAndSearch({ ...filter, idColaborador: e?.value?.id });
                }}
                value={colaborador}
              />
            </div>

            <div className="p-col-12 p-sm-12 p-lg-12 p-fluid">
              <LabelSp>Data</LabelSp>
              <CalendarAgendaSp
                feriados={feriados}
                value={filter.data}
                blockOldDate
                onChange={e => {
                  setFilterAndSearch({ ...filter, data: e.value });
                }}
              />
            </div>
          </div>

          <div className="p-col-12 p-sm-12 p-lg-8 p-fluid">
            <LabelSp>Horários do dia: {formatDate(filter.data, 'dd/MM/yyyy')}</LabelSp>
            <DataTableSp
              value={records}
              style={{ marginTop: '2px' }}
              rows={12}
              responsive
              // scrollable
              // scrollHeight="213px"
              selectionMode="single"
              selection={selectedRecord}
              onSelectionChange={e => setSelectedRecord(e.value)}
            >
              <Column
                className="grid-col-hora"
                header="Horário"
                field="hora"
                body={rowData => formatDate(rowData.hora, 'HH:mm')}
              />
              <Column
                style={{ width: 150 }}
                className="grid-col"
                header="Tp. Atendimento"
                field="atendimentoTipoNome"
              />

              <Column className="grid-col" header="Profissional" field="colaboradorNome" />
            </DataTableSp>
          </div>
        </div>
      </Dialog>
    </Container>
  );
}

import { addDays } from 'date-fns';
import { BaseService } from './BaseService';
import { ConfigApi } from '../config/Constantes';
import { newDateOnly } from '../util/functions';

class AgendaBloqueioService extends BaseService {
  constructor() {
    super(ConfigApi.agendaBloqueioURL);
  }

  getFilter() {
    return new Filter();
  }
}

class Filter {
  constructor() {
    this.dataInicio = addDays(newDateOnly(), -10);
    this.dataFim = addDays(newDateOnly(), 60);
    this.idColaborador = undefined;
    this.limit = 0;
    this.page = 0;
  }
}
export default new AgendaBloqueioService();

import AuthService from '../services/AuthService';
import history from '../services/history';

export default function createMenu() {
  const colaborador = AuthService.getColaborador();
  const { perfilColaborador } = colaborador;

  const menuReturn = [
    {
      label: 'Home',
      title: 'Home',
      icon: 'pi pi-fw pi-home',
      to: '/home',
    },
  ];

  let menuCadastros = { items: [] };
  let menuLancamentos = { items: [] };
  let menuRelatorios = { items: [] };
  let menuUtilitarios = { items: [] };

  const menuDashboard = {
    label: 'Dashboard',
    title: 'Dashboard',
    icon: 'pi pi-fw pi-chart-bar',
    to: '/dashboard',
  };

  if (perfilColaborador === 2) {
    menuCadastros = null;
  } else {
    menuRelatorios = {
      title: 'Relatórios',
      label: 'Relatórios',
      icon: 'pi pi-fw pi-folder-open',
      expanded: true,
      items: [],
    };

    menuLancamentos = {
      title: 'Lançamentos',
      label: 'Lançamentos',
      icon: 'pi pi-fw pi-list',
      expanded: true,

      items: [],
    };

    menuCadastros = {
      title: 'Cadastros',
      label: 'Cadastros',
      icon: 'pi pi-fw pi-folder',
      expanded: true,
      items: [],
    };

    menuUtilitarios = {
      title: 'Utilitários',
      label: 'Utilitários',
      icon: 'pi pi-fw pi-cog',
      expanded: true,
      items: [],
    };

    // pi-th-large

    // menu cadastros

    if (
      AuthService.checkRoles(['COLABORADOR_VIEW']) &&
      AuthService.checkRoles(['COLABORADOR_INSERT'])
    ) {
      menuCadastros.items.push({
        title: 'Colaborador',
        label: 'Colaborador',
        icon: 'pi pi-users',
        command: () => history.push('/colaboradores'),
      });
    }

    if (AuthService.checkRoles(['COMUNIDADE_VIEW'])) {
      menuCadastros.items.push({
        title: 'Comunidade',
        label: 'Comunidade',
        icon: 'pi pi-credit-card',
        command: () => history.push('/comunidades'),
      });
    }

    if (AuthService.checkRoles(['COLABORADOR_ESPECIALIDADE_VIEW'])) {
      menuCadastros.items.push({
        title: 'Especialidade',
        label: 'Especialidade',
        icon: 'pi pi-sliders-h',
        command: () => history.push('/colaboradorEspecialidades'),
      });
    }

    if (AuthService.checkRoles(['FINANCIADOR_VIEW'])) {
      menuCadastros.items.push({
        title: 'Financiador',
        label: 'Financiador',
        icon: 'pi pi-money-bill',
        command: () => history.push('/financiadores'),
      });
    }

    if (
      AuthService.checkRoles(['FERIADO_VIEW']) &&
      AuthService.checkRoles(['FERIADO_INSERT'])
    ) {
      menuCadastros.items.push({
        title: 'Feriado',
        label: 'Feriado',
        icon: 'pi pi-calendar',
        command: () => history.push('/feriados'),
      });
    }

    if (AuthService.checkRoles(['PACIENTE_VIEW'])) {
      menuCadastros.items.push({
        title: 'Paciente',
        label: 'Paciente',
        icon: 'pi pi-id-card',
        command: () => history.push('/pacientes'),
      });
    }

    if (AuthService.checkRoles(['UNIDADE_CONSERVACAO_VIEW'])) {
      menuCadastros.items.push({
        title: 'Unidade de Conservação',
        label: 'Unid. Conservação',
        icon: 'pi pi-map-marker',
        command: () => history.push('/unidadesConservacao'),
      });
    }

    if (AuthService.checkRoles(['COLABORADOR_PERFIL_VIEW'])) {
      menuCadastros.items.push({
        title: 'Perfil de Acesso',
        label: 'Perfil de Acesso',
        icon: 'pi pi-key',
        command: () => history.push('/colaboradorPerfis'),
      });
    }

    if (AuthService.checkRoles(['ESTRUTURA_PONTO_VIEW'])) {
      menuCadastros.items.push({
        title: 'Estrutura do Ponto',
        label: 'Estrutura do Ponto',
        icon: 'pi pi-sliders-v',
        command: () => history.push('/estruturasPonto'),
      });
    }
    if (
      AuthService.checkRoles(['CONFIG_AGENDA_VIEW']) &&
      AuthService.checkRoles(['CONFIG_AGENDA_INSERT'])
    ) {
      menuCadastros.items.push({
        title: 'Configuração de Agenda',
        label: 'Configuração de Agenda',
        icon: 'pi pi-calendar',
        command: () => history.push('/configAgendas'),
      });
    }
    // menu Relatorios
    menuRelatorios.items.push({
      title: 'Atendimentos',
      label: 'Atendimentos',
      icon: 'pi pi-print',
      command: () => history.push('/atendimentosReport'),
    });

    menuRelatorios.items.push({
      title: 'Prontuários',
      label: 'Prontuários',
      icon: 'pi pi-print',
      command: () => history.push('/prontuariosReport'),
    });

    // menu Lançamentos

    if (AuthService.checkRoles(['ATENDIMENTO_VIEW'])) {
      menuLancamentos.items.push({
        title: 'Teleatendimento',
        label: 'Teleatendimento',
        icon: 'pi pi-book',
        command: () => history.push('/atendimentos'),
      });
    }

    if (AuthService.checkRoles(['AGENDAMENTO_VIEW'])) {
      menuLancamentos.items.push({
        title: 'Agendamento',
        label: 'Agendamento',
        icon: 'pi pi-table',
        command: () => history.push('/agendamentos'),
      });
    }

    if (AuthService.checkRoles(['EDUCACAO_VIEW'])) {
      menuLancamentos.items.push({
        title: 'Teleeducação',
        label: 'Teleeducação',
        icon: 'pi pi-sitemap',
        command: () => history.push('/educacoes'),
      });
    }
    // menu Utilitários
    if (AuthService.checkRoles(['AUDITORIA_VIEW'])) {
      menuUtilitarios.items.push({
        title: 'Auditoria',
        label: 'Auditoria',
        icon: 'pi pi-list',
        command: () => history.push('/auditorias'),
      });
    }
  }

  if (AuthService.checkRoles(['MINHA_AGENDA_VIEW'])) {
    menuReturn.push({
      title: 'Minha Agenda',
      label: 'Minha Agenda',
      icon: 'pi pi-fw pi-calendar',
      command: () => history.push('/minhaAgenda'),
    });
  }

  if (menuCadastros && menuCadastros.items.length > 0) {
    menuReturn.push(menuCadastros);
  }

  if (menuLancamentos) {
    menuReturn.push(menuLancamentos);
  }

  if (AuthService.checkRoles(['DASHBOARD_VIEW'])) {
    menuReturn.push(menuDashboard);
  }
  // if (menuRelatorios) {
  //   menuReturn.push(menuRelatorios);
  // }

  if (menuUtilitarios && menuUtilitarios.items.length > 0) {
    menuReturn.push(menuUtilitarios);
  }

  // menuReturn.push({
  //   label: 'Configurações',
  //   icon: 'pi pi-fw pi-cog',
  //   to: '/configuracoes',
  // });

  return menuReturn;
}

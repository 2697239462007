/* eslint-disable no-await-in-loop */
import React, { useState, useEffect, useCallback, useMemo } from 'react';

import Gradient from 'javascript-color-gradient';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
// import * as html2canvas from 'html2canvas';
// import { toast } from 'react-toastify';
import { getYear } from 'date-fns';

import { Container, ContainerTitle } from './styles';

import {
  errorHandle,
  formatDate,
  formatFloat,
  isScreenMobile,
  strToDate,
} from '../../util/functions';

import DashboardService from '../../services/DashboardService';
import { cfgPtBr, listaUfs } from '../../config/Constantes';

import { showFilter, hideFilter } from '../../store/modules/global/actions';
import LabelSp from '../../components/LabelSp';
import DropDownLazy from '../../components/DropdownLazy';
import CalendarSp from '../../components/CalendarSp';
import ColaboradorService from '../../services/ColaboradorService';
import AtendimentoTipoService from '../../services/AtendimentoTipoService';
import FinanciadorService from '../../services/FinanciadorService';
import DropdownSp from '../../components/DropdownSp';

import PanelFilter from '../../components/PanelFilter';
import Page1 from './page1';
import Page2 from './page2';
import SelectButtons from '../../components/SelectButtons';
import Page3 from './page3';
import MunicipioService from '../../services/MunicipioService';

export default function Dashboard() {
  const filterService = useMemo(() => DashboardService.getFilter(), []);

  const [ufSelecionada, setUfSelecionada] = useState('AM');
  const [filter, setFilter] = useState(filterService);
  const [funcTimeOut, setFuncTimeOut] = useState();
  const [colaborador, setColaborador] = useState(null);
  const [financiador, setFinanciador] = useState(null);
  const [municipio, setMunicipio] = useState(null);
  const [atendimentoTipo, setAtendimentoTipo] = useState(null);

  const [dataGraficoAtMeses, setDataGraficoAtMeses] = useState();
  const [dataGraficoAtSemanas, setDataGraficoAtSemanas] = useState();
  const [dataGraficoAtendimentosTipo, setDataGraficoAtendimentosTipo] = useState();
  const [dataGraficoAtendimentosPercTipo, setDataGraficoAtendimentosPercTipo] = useState();

  const [
    dataGraficoTeleorientacoesCategoria,
    setDataGraficoTeleorientacoesCategoria,
  ] = useState();
  const [
    dataGraficoTeleorientacoesPercCategoria,
    setDataGraficoTeleorientacoesPercCategoria,
  ] = useState();

  const [dataGraficoAtendimentosComunidade, setDataGraficoAtendimentosComunidade] = useState();
  const [dataGraficoAtendimentosMunicipio, setDataGraficoAtendimentosMunicipio] = useState();
  const [dataGraficoEducacaoMeses, setDataGraficoEducacaoMeses] = useState();
  const [dataGraficoEducacaoPartMeses, setDataGraficoEducacaoPartMeses] = useState();

  const [dataGraficoBeneficiarioMunicipio, setDataGraficoBeneficiarioMunicipio] = useState();
  const [comunidadeSemAt, setComunidadeSemAt] = useState([]);
  const [municipioSemAt, setMunicipioSemAt] = useState([]);

  // acumulado
  const [dataGraficoAtMesesAc, setDataGraficoAtMesesAc] = useState();
  const [dataGraficoAtendimentosTipoAc, setDataGraficoAtendimentosTipoAc] = useState();
  const [dataGraficoAtendimentosPercTipoAc, setDataGraficoAtendimentosPercTipoAc] = useState();
  const [dataGraficoEducacaoMesesAc, setDataGraficoEducacaoMesesAc] = useState();
  const [dataGraficoEducacaoPartMesesAc, setDataGraficoEducacaoPartMesesAc] = useState();

  const [page, setPage] = useState(0);

  const dispatch = useDispatch();
  const filterVisible = useSelector(state => state.global.filterVisible);
  function handleFilterUpdate() {
    if (filterVisible) {
      dispatch(hideFilter());
    } else {
      dispatch(showFilter());
    }
  }

  // const printDashboard = async () => {
  //   // window.focus();
  //   // window.print();

  //   let tempInnerHTML = '';

  //   for (let i = 0; i < graficosIds.length; i++) {
  //     const canvas = await html2canvas(document.getElementById(graficosIds[i].id));
  //     const dataURL = canvas.toDataURL();
  //     const { width } = canvas;

  //     tempInnerHTML += `
  //       <div">
  //         <strong style="font-style=bold; font-size:12px">${graficosIds[i].label}</strong> <br/>
  //         <img src=${dataURL} style='${width}'></img>
  //       </div>
  //     `;
  //   }

  //   if (tempInnerHTML === '') {
  //     toast.warn('Não existe dados para impressão.');
  //     return;
  //   }

  //   tempInnerHTML = `<div style="max-width:1024px">${tempInnerHTML}</div>`;
  //   const printWindow = window.open('');

  //   printWindow.document.body.innerHTML = tempInnerHTML;
  //   await documentReady(printWindow.document, 1);

  //   printWindow.focus();
  //   printWindow.print();
  // };

  // useCallbacks
  const loadColaborador = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await ColaboradorService.findAll({
        nome: _nome,
        limit: 50,
        listaIdCategorias: '[1,2,3]',
      });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadMunicipio = useCallback(async (_nome, uf) => {
    if (_nome !== undefined) {
      const r = await MunicipioService.findAll({ nome: _nome, uf, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadFinanciador = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await FinanciadorService.findAll({
        nome: _nome,
        limit: 50,
      });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadAtendimentoTipo = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await AtendimentoTipoService.findAll({
        nome: _nome,
        limit: 50,
      });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const listaAnos = useCallback(() => {
    const anosInicio = 2015;

    const anoFim = getYear(new Date());
    const lista = [];
    // const mes = formatDate(new Date(), 'MM');
    for (let i = anosInicio; i <= anoFim; i++) {
      const date = strToDate(`${i}-01-01`);
      lista.push({ label: `${i}`, value: date });
    }

    return lista;
  }, []);

  const handleBuscar = useCallback(
    async _filter => {
      try {
        const dashboard = await DashboardService.getDashBoard(_filter);

        const datasetAtMeses = [];
        const datasetAtSemanas = [];
        const datasetAtTipos = [];
        const datasetAtPercTipos = [];

        const datasetEdCategoria = [];
        const datasetEdPercCategoria = [];

        const datasetAtComuniade = [];
        const datasetAtMunicipio = [];
        const datasetEducacoesMeses = [];
        const datasetEducacoesPartMeses = [];
        const datasetBeneficiariosMunicipio = [];

        // acumulado
        const datasetAtMesesAc = [];

        const datasetAtTiposAc = [];
        const datasetAtPercTiposAc = [];
        const datasetEducacoesMesesAc = [];
        const datasetEducacoesPartMesesAc = [];

        let data = [];
        let labels = [];
        let backgroundColors = [];

        // atendimentos por mes

        dashboard.atendimentoMeses.forEach((dado, index) => {
          data.push(dado.quantidade);
          labels.push(dado.nomeMesAno);
          backgroundColors.push(
            _filter.tipoPeriodo === 2 ? backgroundGrafico6[index] : backgroundGrafico12[index]
          );
        });

        datasetAtMeses.push({
          fillColor: '#79D1CF',
          strokeColor: '#79D1CF',
          data,
          backgroundColor: backgroundColors,
        });
        const pageSelected = page;
        setPage(undefined);

        setDataGraficoAtMeses({
          labels,
          datasets: datasetAtMeses,
          records: data.length,
        });

        // atendimentos por semana
        data = [];
        labels = [];
        backgroundColors = [];
        dashboard.atendimentoSemanas.forEach((dado, index) => {
          data.push(dado.quantidade);
          labels.push(dado.semana);
          backgroundColors.push(backgroundGrafico6[index]);
        });

        datasetAtSemanas.push({
          fillColor: '#79D1CF',
          strokeColor: '#79D1CF',
          data,
          backgroundColor: backgroundColors,
        });

        setDataGraficoAtSemanas({
          labels,
          datasets: datasetAtSemanas,
          records: data.length,
        });

        // qtd atendimentos por tipo
        data = [];
        labels = [];
        backgroundColors = [];
        let totalAtendimentos = 0;

        // data.push(0);
        // labels.push('Total de Geral');
        backgroundColors.push(backgroundGrafico6[0]);
        dashboard.atendimentoTipos.forEach((dado, index) => {
          data.push(dado.quantidade);
          labels.push(dado.tipo);
          backgroundColors.push(backgroundGrafico6[index + 1]);
          totalAtendimentos += dado.quantidade;
        });
        // data[0] = totalAtendimentos;

        datasetAtTipos.push({
          fillColor: '#79D1CF',
          strokeColor: '#79D1CF',
          data,
          backgroundColor: backgroundColors,
        });

        setDataGraficoAtendimentosTipo({
          labels,
          datasets: datasetAtTipos,
          records: data.length,
        });

        // % atendimentos por tipo
        data = [];
        labels = [];
        backgroundColors = [];

        dashboard.atendimentoTipos.forEach((dado, index) => {
          const valor = (dado.quantidade / totalAtendimentos) * 100;
          data.push(valor);
          labels.push(`${dado.tipo} (${formatFloat(valor, 1)}%)`);
          backgroundColors.push(backgroundGrafico3[index]);
        });

        datasetAtPercTipos.push({
          fillColor: '#79D1CF',
          strokeColor: '#79D1CF',
          data,
          backgroundColor: backgroundColors,
        });

        setDataGraficoAtendimentosPercTipo({
          labels,
          datasets: datasetAtPercTipos,
          records: data.length,
        });

        // qtd teleorientacoes por tipo
        data = [];
        labels = [];
        backgroundColors = [];
        let totalTeleorientacoes = 0;

        backgroundColors.push(backgroundGrafico6[0]);
        dashboard.teleeducacaoCategoria.forEach((dado, index) => {
          data.push(dado.quantidade);
          labels.push(dado.categoria);
          backgroundColors.push(backgroundGrafico6[index + 1]);
          totalTeleorientacoes += dado.quantidade;
        });

        datasetEdCategoria.push({
          fillColor: '#79D1CF',
          strokeColor: '#79D1CF',
          data,
          backgroundColor: backgroundColors,
        });

        setDataGraficoTeleorientacoesCategoria({
          labels,
          datasets: datasetEdCategoria,
          records: data.length,
        });

        // % teleorientacoes por tipo
        data = [];
        labels = [];
        backgroundColors = [];

        dashboard.teleeducacaoCategoria.forEach((dado, index) => {
          const valor = (dado.quantidade / totalTeleorientacoes) * 100;
          data.push(valor);
          labels.push(`${dado.categoria} (${formatFloat(valor, 1)}%)`);
          backgroundColors.push(backgroundGrafico3[index]);
        });

        datasetEdPercCategoria.push({
          fillColor: '#79D1CF',
          strokeColor: '#79D1CF',
          data,
          backgroundColor: backgroundColors,
        });

        setDataGraficoTeleorientacoesPercCategoria({
          labels,
          datasets: datasetEdPercCategoria,
          records: data.length,
        });

        // atendimentos por comunidade
        data = [];
        labels = [];
        backgroundColors = [];

        dashboard.atendimentoPorComunidade.forEach((dado, index) => {
          data.push(dado.quantidade);
          labels.push(dado.comunidade);
          if (backgroundGrafico2.length > index) {
            backgroundColors.push(backgroundGrafico2[index]);
          } else backgroundColors.push(backgroundGrafico2[backgroundGrafico2.length - 1]);
        });

        datasetAtComuniade.push({
          fillColor: '#79D1CF',
          strokeColor: '#79D1CF',
          data,
          backgroundColor: backgroundColors,
        });

        setDataGraficoAtendimentosComunidade({
          labels,
          datasets: datasetAtComuniade,
          records: data.length,
        });

        // atendimentos por municipio
        data = [];
        labels = [];
        backgroundColors = [];

        dashboard.atendimentoPorMunicipio.forEach((dado, index) => {
          data.push(dado.quantidade);
          labels.push(dado.municipio);
          if (backgroundGrafico2.length > index) {
            backgroundColors.push(backgroundGrafico2[index]);
          } else backgroundColors.push(backgroundGrafico2[backgroundGrafico2.length - 1]);
        });

        datasetAtMunicipio.push({
          fillColor: '#79D1CF',
          strokeColor: '#79D1CF',
          data,
          backgroundColor: backgroundColors,
        });

        setDataGraficoAtendimentosMunicipio({
          labels,
          datasets: datasetAtMunicipio,
          records: data.length,
        });

        // participantes teleorientacoes por mes
        data = [];
        labels = [];
        backgroundColors = [];

        dashboard.teleeducacaoParticipantes.forEach((dado, index) => {
          data.push(dado.quantidadeParticipantes);
          labels.push(dado.nomeMesAno);
          backgroundColors.push(
            _filter.tipoPeriodo === 2 ? backgroundGrafico6[index] : backgroundGrafico12[index]
          );
        });

        datasetEducacoesPartMeses.push({
          fillColor: '#79D1CF',
          strokeColor: '#79D1CF',
          data,
          backgroundColor: backgroundColors,
        });

        setDataGraficoEducacaoPartMeses({
          labels,
          datasets: datasetEducacoesPartMeses,
          records: data.length,
        });

        // teleorientacoes por mes
        data = [];
        labels = [];
        backgroundColors = [];

        dashboard.teleeducacao.forEach((dado, index) => {
          data.push(dado.quantidade);
          labels.push(dado.nomeMesAno);
          backgroundColors.push(
            _filter.tipoPeriodo === 2 ? backgroundGrafico6[index] : backgroundGrafico12[index]
          );
        });

        datasetEducacoesMeses.push({
          fillColor: '#79D1CF',
          strokeColor: '#79D1CF',
          data,
          backgroundColor: backgroundColors,
        });

        setDataGraficoEducacaoMeses({
          labels,
          datasets: datasetEducacoesMeses,
          records: data.length,
        });

        // beneficiarios por municipio
        data = [];
        labels = [];
        backgroundColors = [];

        dashboard.beneficiariosMunicipio.forEach((dado, index) => {
          data.push(dado.quantidadeParticipantes);
          labels.push(dado.municipio);
          if (backgroundGrafico2.length > index) {
            backgroundColors.push(backgroundGrafico2[index]);
          } else backgroundColors.push(backgroundGrafico2[backgroundGrafico2.length - 1]);
        });

        datasetBeneficiariosMunicipio.push({
          fillColor: '#79D1CF',
          strokeColor: '#79D1CF',
          data,
          backgroundColor: backgroundColors,
        });

        setDataGraficoBeneficiarioMunicipio({
          labels,
          datasets: datasetBeneficiariosMunicipio,
          records: data.length,
        });

        // ###### Acumulado ######
        // atendimentos por mes
        data = [];
        labels = [];
        backgroundColors = [];

        dashboard.atendimentoMesesAc.forEach((dado, index) => {
          data.push(dado.quantidade);
          labels.push(dado.nomeMesAno);
          backgroundColors.push(backgroundGrafico12[index]);
        });

        datasetAtMesesAc.push({
          fillColor: '#79D1CF',
          strokeColor: '#79D1CF',
          data,
          backgroundColor: backgroundColors,
        });

        setDataGraficoAtMesesAc({
          labels,
          datasets: datasetAtMesesAc,
          records: data.length,
        });

        // qtd atendimentos por tipo
        data = [];
        labels = [];
        backgroundColors = [];
        totalAtendimentos = 0;

        backgroundColors.push(backgroundGrafico6[0]);
        dashboard.atendimentoTiposAc.forEach((dado, index) => {
          data.push(dado.quantidade);
          labels.push(dado.tipo);
          backgroundColors.push(backgroundGrafico6[index + 1]);
          totalAtendimentos += dado.quantidade;
        });
        // data[0] = totalAtendimentos;

        datasetAtTiposAc.push({
          fillColor: '#79D1CF',
          strokeColor: '#79D1CF',
          data,
          backgroundColor: backgroundColors,
        });

        setDataGraficoAtendimentosTipoAc({
          labels,
          datasets: datasetAtTiposAc,
          records: data.length,
        });

        // % atendimentos por tipo
        data = [];
        labels = [];
        backgroundColors = [];

        dashboard.atendimentoTiposAc.forEach((dado, index) => {
          const valor = (dado.quantidade / totalAtendimentos) * 100;
          data.push(valor);
          labels.push(`${dado.tipo} (${formatFloat(valor, 1)}%)`);
          backgroundColors.push(backgroundGrafico3[index]);
        });

        datasetAtPercTiposAc.push({
          fillColor: '#79D1CF',
          strokeColor: '#79D1CF',
          data,
          backgroundColor: backgroundColors,
        });

        setDataGraficoAtendimentosPercTipoAc({
          labels,
          datasets: datasetAtPercTiposAc,
          records: data.length,
        });

        // teleorientacoes por mes
        data = [];
        labels = [];
        backgroundColors = [];
        dashboard.teleeducacaoAc.forEach((dado, index) => {
          data.push(dado.quantidade);
          labels.push(dado.nomeMesAno);
          backgroundColors.push(backgroundGrafico12[index]);
        });

        datasetEducacoesMesesAc.push({
          fillColor: '#79D1CF',
          strokeColor: '#79D1CF',
          data,
          backgroundColor: backgroundColors,
        });

        setDataGraficoEducacaoMesesAc({
          labels,
          datasets: datasetEducacoesMesesAc,
          records: data.length,
        });

        // teleorientacoes participantes por mes
        data = [];
        labels = [];
        backgroundColors = [];
        dashboard.teleeducacaoParticipantesAc.forEach((dado, index) => {
          data.push(dado.quantidadeParticipantes);
          labels.push(dado.nomeMesAno);
          backgroundColors.push(backgroundGrafico12[index]);
        });

        datasetEducacoesPartMesesAc.push({
          fillColor: '#79D1CF',
          strokeColor: '#79D1CF',
          data,
          backgroundColor: backgroundColors,
        });

        setDataGraficoEducacaoPartMesesAc({
          labels,
          datasets: datasetEducacoesPartMesesAc,
          records: data.length,
        });

        // comunidade sem atendimento
        setComunidadeSemAt(dashboard.comunidadeSemAt);

        // municipio sem atendimento
        setMunicipioSemAt(dashboard.municipioSemAt);

        setTimeout(() => setPage(pageSelected), [100]); // força render em todas na pagina atual para que o tamanho dos graficos sejam ajustados
      } catch (err) {
        errorHandle(err);
      }
    },
    [page]
  );

  const setFilterAndSearch = useCallback(
    async _filterValue => {
      if (JSON.stringify(_filterValue) !== JSON.stringify(filter)) {
        setFilter(_filterValue);

        if (funcTimeOut) {
          clearTimeout(funcTimeOut);
        }
        const func = setTimeout(async () => {
          handleBuscar(_filterValue);
        }, 800);
        setFuncTimeOut(func);
      }
    },
    [filter, funcTimeOut, handleBuscar]
  );

  useEffect(() => {
    handleBuscar(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const buttons = isScreenMobile()
    ? ['Pág 1', 'Pág 2', 'Pág 3']
    : ['Página 1', 'Página 2', 'Página 3'];
  const colors = [1, 1, 1];

  return (
    <Container className="container-page">
      <div className="p-grid">
        <ContainerTitle className="p-col-12">
          <Button
            style={{ float: 'right' }}
            className="p-button-success buttons"
            title="Filtros Adicionais"
            icon="pi pi-filter"
            onClick={handleFilterUpdate}
          />
          <Button
            style={{ float: 'right', marginRight: 5 }}
            className="p-button-secondary buttons"
            title="Buscar"
            icon="pi pi-search"
            type="button"
            onClick={() => handleBuscar(filter)}
          />

          {/* <Button
            style={{ float: 'right', marginRight: 5 }}
            className="p-button-warning buttons desktop-screen"
            title="Imprimir"
            icon="pi pi-print"
            onClick={() => printDashboard(graficosIds[0].id)}
          /> */}

          <SelectButtons
            className="noprint"
            style={{ float: 'right', marginRight: 5 }}
            widthButton={isScreenMobile() ? 45 : 90}
            buttons={buttons}
            colors={colors}
            selectedIndex={page}
            onClick={itemIndex => {
              setPage(itemIndex);
            }}
          />
          <span className="title">Dashboard</span>
          <hr />
        </ContainerTitle>
      </div>
      <div className="p-grid">
        <PanelFilter className="p-grid p-col-12 noprint">
          <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
            <LabelSp>Tipo Período</LabelSp>
            <DropdownSp
              value={filter.tipoPeriodo}
              options={[
                { label: 'Ano', value: 1 },
                { label: 'Mês/Ano', value: 2 },
                { label: 'Período', value: 3 },
              ]}
              filterInputAutoFocus={false}
              onChange={e => {
                let { mesAnoApuracao } = filter;
                if (e?.value === 1) {
                  const ano = formatDate(mesAnoApuracao, 'yyyy');
                  mesAnoApuracao = strToDate(`${ano}-01-01`);
                }
                setFilterAndSearch({ ...filter, mesAnoApuracao, tipoPeriodo: e?.value });
              }}
            />
          </div>
          {filter.tipoPeriodo === 2 && (
            <div className="p-col-6 p-sm-4 p-lg-4 p-fluid">
              <LabelSp htmlFor="dataBase">Mês Apuração</LabelSp>
              <CalendarSp
                view="month"
                appendTo={document.body}
                readOnlyInput
                locale={cfgPtBr}
                dateFormat="mm/yy"
                value={filter.mesAnoApuracao}
                yearNavigator
                yearRange="2010:2040"
                onChange={e => setFilterAndSearch({ ...filter, mesAnoApuracao: e.value })}
              />
            </div>
          )}

          {filter.tipoPeriodo === 1 && (
            <div className="p-col-6 p-sm-4 p-lg-4 p-fluid">
              <LabelSp>Ano Apuração</LabelSp>
              <DropdownSp
                value={filter.mesAnoApuracao}
                options={listaAnos()}
                filterInputAutoFocus={false}
                onChange={e => {
                  setFilterAndSearch({ ...filter, mesAnoApuracao: e?.value });
                }}
              />
            </div>
          )}

          {filter.tipoPeriodo === 3 && (
            <>
              <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
                <LabelSp>De</LabelSp>
                <CalendarSp
                  appendTo={document.body}
                  readOnlyInput
                  locale={cfgPtBr}
                  dateFormat="dd/mm/yy"
                  value={filter.dataInicio}
                  yearNavigator
                  yearRange="2010:2040"
                  onChange={e => setFilterAndSearch({ ...filter, dataInicio: e.value })}
                />
              </div>
              <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
                <LabelSp>Até</LabelSp>
                <CalendarSp
                  appendTo={document.body}
                  readOnlyInput
                  locale={cfgPtBr}
                  dateFormat="dd/mm/yy"
                  value={filter.dataFim}
                  yearNavigator
                  yearRange="2010:2040"
                  onChange={e => setFilterAndSearch({ ...filter, dataFim: e.value })}
                />
              </div>
            </>
          )}
          <div className="p-col-12 p-sm-5 p-lg-5 p-fluid">
            <LabelSp>Tipo Apuração</LabelSp>
            <DropdownSp
              value={filter.tipoApuracao}
              options={[
                { label: 'Teleatendimento/Teleeducação', value: 1 },
                { label: 'Teleatendimento', value: 2 },
                { label: 'Teleeducação', value: 3 },
              ]}
              filterInputAutoFocus={false}
              onChange={e => {
                setFilterAndSearch({ ...filter, tipoApuracao: e?.value });
              }}
            />
          </div>

          <div className="p-col-12 p-sm-3 p-lg-3 p-fluid">
            <LabelSp>Tipo Atendimento</LabelSp>
            <DropDownLazy
              autoLoad
              showClear
              placeholder="Todos"
              onChange={e => {
                setFilterAndSearch({ ...filter, idAtendimentoTipo: e?.value });
                setAtendimentoTipo(e);
              }}
              value={atendimentoTipo}
              onFilter={async txtFilter => {
                const retorno = await loadAtendimentoTipo(txtFilter);
                return retorno;
              }}
            />
          </div>

          <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
            <LabelSp>Tipo Teleeducação</LabelSp>
            <DropdownSp
              value={filter.teleEducacaoTipo}
              options={[
                { value: '0', label: 'Web Palestra' },
                { value: '1', label: 'Teleorientação Individual' },
              ]}
              onChange={e => setFilterAndSearch({ ...filter, teleEducacaoTipo: e?.value })}
            />
          </div>

          <div className="p-col-12 p-sm-5 p-lg-5 p-fluid">
            <LabelSp>Profissional</LabelSp>
            <DropDownLazy
              autoLoad
              showClear
              placeholder="Todos"
              onChange={e => {
                setFilterAndSearch({ ...filter, idColaborador: e?.value });
                setColaborador(e);
              }}
              value={colaborador}
              onFilter={async txtFilter => {
                const retorno = await loadColaborador(txtFilter);
                return retorno;
              }}
            />
          </div>
          <div className="p-col-12 p-sm-5 p-lg-5 p-fluid">
            <LabelSp>Financiador</LabelSp>
            <DropDownLazy
              autoLoad
              showClear
              placeholder="Todos"
              onChange={e => {
                setFilterAndSearch({ ...filter, idFinanciador: e?.value });
                setFinanciador(e);
              }}
              value={financiador}
              onFilter={async txtFilter => {
                const retorno = await loadFinanciador(txtFilter);
                return retorno;
              }}
            />
          </div>
          <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
            <LabelSp>UF</LabelSp>
            <DropdownSp
              placeholder="Selecione"
              value={ufSelecionada}
              options={listaUfs}
              filter
              disabled
              onChange={e => setUfSelecionada(e.target.value)}
            />
          </div>

          <div className="p-col-8 p-sm-5 p-lg-5 p-fluid">
            <LabelSp>Município</LabelSp>
            <DropDownLazy
              autoLoad
              showClear
              placeholder="Todos"
              onChange={e => {
                setFilterAndSearch({ ...filter, idMunicipio: e?.value });
                setMunicipio(e);
              }}
              value={municipio}
              onFilter={async txtFilter => {
                const retorno = await loadMunicipio(txtFilter, ufSelecionada);
                return retorno;
              }}
            />
          </div>
        </PanelFilter>

        <div id="pagina" className="p-grid" style={{ margin: 0, padding: 0, width: '100%' }}>
          {page === 0 ? (
            <Page1
              dataGraficoAtMeses={dataGraficoAtMeses}
              dataGraficoAtSemanas={dataGraficoAtSemanas}
              dataGraficoAtendimentosTipo={dataGraficoAtendimentosTipo}
              dataGraficoAtendimentosPercTipo={dataGraficoAtendimentosPercTipo}
              dataGraficoTeleorientacoesCategoria={dataGraficoTeleorientacoesCategoria}
              dataGraficoTeleorientacoesPercCategoria={dataGraficoTeleorientacoesPercCategoria}
              dataGraficoEducacaoMeses={dataGraficoEducacaoMeses}
              dataGraficoEducacaoPartMeses={dataGraficoEducacaoPartMeses}
              dataGraficoBeneficiarioMunicipio={dataGraficoBeneficiarioMunicipio}
              tipoApuracao={filter.tipoApuracao}
              tipoPeriodo={filter.tipoPeriodo}
              tipoTeleEducacao={filter.teleEducacaoTipo}
            />
          ) : null}
          {page === 1 ? (
            <Page2
              tipoTeleEducacao={filter.teleEducacaoTipo}
              dataGraficoAtendimentosComunidade={dataGraficoAtendimentosComunidade}
              dataGraficoAtendimentosMunicipio={dataGraficoAtendimentosMunicipio}
              dataGraficoBeneficiarioMunicipio={dataGraficoBeneficiarioMunicipio}
              comunidadeSemAt={comunidadeSemAt}
              municipioSemAt={municipioSemAt}
              tipoApuracao={filter.tipoApuracao}
            />
          ) : null}
          {page === 2 ? (
            <Page3
              dataGraficoAtMeses={dataGraficoAtMesesAc}
              dataGraficoAtendimentosTipo={dataGraficoAtendimentosTipoAc}
              dataGraficoAtendimentosPercTipo={dataGraficoAtendimentosPercTipoAc}
              dataGraficoEducacaoMeses={dataGraficoEducacaoMesesAc}
              dataGraficoEducacaoPartMeses={dataGraficoEducacaoPartMesesAc}
              tipoApuracao={filter.tipoApuracao}
              tipoTeleEducacao={filter.teleEducacaoTipo}
              tipoPeriodo={filter.tipoPeriodo}
            />
          ) : null}
        </div>
      </div>
    </Container>
  );
}

const colorGradient = new Gradient();

// backgroundGrafico6
colorGradient.setGradient('#013b01', '#6ecc65');
colorGradient.setMidpoint(5);
const backgroundGrafico6 = colorGradient.getArray();
backgroundGrafico6.push(backgroundGrafico6[backgroundGrafico6.length - 1]);
backgroundGrafico6.push(backgroundGrafico6[backgroundGrafico6.length - 1]);

// backgroundGrafico12
colorGradient.setGradient('#013b01', '#6ecc65');
colorGradient.setMidpoint(12);
const backgroundGrafico12 = colorGradient.getArray();

colorGradient.setGradient('#013b01', '#6ecc65');
colorGradient.setMidpoint(10);
const backgroundGrafico2 = colorGradient.getArray();
// complementando 12 cores repetindo as duas ultimas
backgroundGrafico2.push(backgroundGrafico2[backgroundGrafico2.length - 1]);
backgroundGrafico2.push(backgroundGrafico2[backgroundGrafico2.length - 1]);

colorGradient.setGradient('#013b01', '#6ecc65');
colorGradient.setMidpoint(4);
const backgroundGrafico3 = colorGradient.getArray();

// 013b01
// 015001

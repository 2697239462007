import styled from 'styled-components';

export const ContainerTitle = styled.div`
  height: 50px;
  padding: 0;
  margin: 0;

  /* background: #fff; */
  .title {
    font-size: 20px;
    font-weight: bold;
    /* margin-left: 2px; */
  }
  .buttons {
    float: right;
    height: 2.2em;
    margin-right: 0;
  }
  hr {
    margin-top: 8px;
  }
`;

export const Card = styled.div`
  background-color: #ffffff;
  padding: 1em;
  margin-bottom: 5px;
  border-color: #c8c8c8;
  border-style: solid;
  border-radius: 3px;
  border-width: 1px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;

  .title {
    font-size: 20px;
    font-weight: bold;
  }

  .div-title {
    margin: 10px;
  }

  .result {
    font-size: 22px;
    font-weight: bold;
  }

  .result-icon {
    display: flex;
    justify-content: center;
    align-items: center;

    /* position: absolute;
    top: 10px;
    right: 10px; */

    width: 48px;
    height: 48px;
    text-align: center;
    border-radius: 5px;

    padding: 5px 5px;
    margin: 2px;
  }

  .gren {
    color: #178b51;
    background-color: #b2f5d3;
  }

  .yellow {
    background-color: #f9c851;
  }

  .blue {
    color: #80bef5;
    background-color: #0165bd;
  }
  .red {
    color: #f97316;
    background-color: #feddc7;
  }
`;

export const Container = styled.div`
  margin: 0;
  padding: 0;
`;

/* Dashboard */

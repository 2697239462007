import React from 'react';
import { Switch } from 'react-router-dom';

import Home from '../pages/Home';
import Route from './Route';
import Login from '../pages/Login';
import Colaborador from '../pages/Colaborador';
import AcessDenied from '../pages/AcessDenied';

import NotFound from '../pages/NotFound';
import Financiador from '../pages/Financiador';
import UnidadeConservacao from '../pages/UnidadeConservacao';
import Comunidade from '../pages/Comunidade';
import Auditoria from '../pages/Auditoria';
import ColaboradorPerfil from '../pages/ColaboradorPerfil';
import Paciente from '../pages/Paciente';
import Atendimento from '../pages/Atendimento';
import ColaboradorEspecialidade from '../pages/ColaboradorEspecialidade';
import Educacao from '../pages/Educacao';
import Dashboard from '../pages/Dashboard';
import EstruturaPonto from '../pages/EstruturaPonto';
import Feriado from '../pages/Feriado';
import ConfigAgenda from '../pages/ConfigAgenda';
import Agendamento from '../pages/Agendamento';
import MinhaAgenda from '../pages/MinhaAgenda';
// import Pdf from '../pages/Pdf';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/login" exact component={Login} />
      <Route path="/denied" exact component={AcessDenied} isPrivate />
      <Route path="/home" component={Home} isPrivate />
      <Route path="/dashboard" component={Dashboard} isPrivate />
      {/* <Route path="/pdf" component={Pdf} isPrivate /> */}

      {/* colaboradores  */}
      <Route
        path="/colaboradores/:option"
        component={Colaborador}
        roles={['COLABORADOR_VIEW', 'COLABORADOR_INSERT', 'COLABORADOR_UPDATE']}
      />
      <Route path="/colaboradores" component={Colaborador} roles={['COLABORADOR_VIEW']} />

      {/* Especialidades */}
      <Route
        path="/colaboradorEspecialidades/:option"
        component={ColaboradorEspecialidade}
        roles={[
          'COLABORADOR_ESPECIALIDADE_VIEW',
          'COLABORADOR_ESPECIALIDADE_INSERT',
          'COLABORADOR_ESPECIALIDADE_UPDATE',
        ]}
      />
      <Route
        path="/colaboradorEspecialidades"
        component={ColaboradorEspecialidade}
        roles={['COLABORADOR_ESPECIALIDADE_VIEW']}
      />

      {/* Financiadores */}
      <Route
        path="/financiadores/:option"
        component={Financiador}
        roles={['FINANCIADOR_VIEW', 'FINANCIADOR_INSERT', 'FINANCIADOR_UPDATE']}
      />
      <Route path="/financiadores" component={Financiador} roles={['FINANCIADOR_VIEW']} />

      {/* Unidades de Conservacao */}
      <Route
        path="/unidadesConservacao/:option"
        component={UnidadeConservacao}
        roles={[
          'UNIDADE_CONSERVACAO_VIEW',
          'UNIDADE_CONSERVACAO_INSERT',
          'UNIDADE_CONSERVACAO_UPDATE',
        ]}
      />
      <Route
        path="/unidadesConservacao"
        component={UnidadeConservacao}
        roles={['UNIDADE_CONSERVACAO_VIEW']}
      />

      {/* Comunidades */}
      <Route
        path="/comunidades/:option"
        component={Comunidade}
        roles={['COMUNIDADE_VIEW', 'COMUNIDADE_INSERT', 'COMUNIDADE_UPDATE']}
      />
      <Route path="/comunidades" component={Comunidade} roles={['COMUNIDADE_VIEW']} />

      {/* Auditorias */}
      <Route path="/auditorias/:option" component={Auditoria} roles={['PACIENTE_VIEW']} />
      <Route path="/auditorias" component={Auditoria} roles={['AUDITORIA_VIEW']} />

      {/* Colaborador perfil */}
      <Route
        path="/colaboradorPerfis/:option"
        component={ColaboradorPerfil}
        roles={[
          'COLABORADOR_PERFIL_VIEW',
          'COLABORADOR_PERFIL_INSERT',
          'COLABORADOR_PERFIL_UPDATE',
        ]}
      />
      <Route
        path="/colaboradorPerfis"
        component={ColaboradorPerfil}
        roles={['COLABORADOR_PERFIL_VIEW']}
      />

      {/* Pacientes */}
      <Route path="/pacientes/:option" component={Paciente} roles={['PACIENTE_VIEW']} />
      <Route path="/pacientes" component={Paciente} roles={['PACIENTE_VIEW']} />

      {/* Atendimentos */}
      <Route
        path="/atendimentos/:option"
        component={Atendimento}
        roles={['ATENDIMENTO_VIEW']}
      />
      <Route
        path="/atendimentos"
        component={Atendimento}
        roles={['ATENDIMENTO_VIEW', 'ATENDIMENTO_LIST']}
      />

      {/* Orientações */}
      <Route path="/educacoes/:option" component={Educacao} roles={['EDUCACAO_VIEW']} />
      <Route
        path="/educacoes"
        component={Educacao}
        roles={['EDUCACAO_VIEW', 'EDUCACAO_LIST']}
      />

      {/* Estruturas ponto */}
      <Route
        path="/estruturasPonto/:option"
        component={EstruturaPonto}
        roles={['ESTRUTURA_PONTO_VIEW', 'ESTRUTURA_PONTO_INSERT', 'ESTRUTURA_PONTO_UPDATE']}
      />
      <Route
        path="/estruturasPonto"
        component={EstruturaPonto}
        roles={['ESTRUTURA_PONTO_VIEW']}
      />

      {/* Feiados */}
      <Route path="/feriados/:option" component={Feriado} roles={['FERIADO_VIEW']} />
      <Route path="/feriados" component={Feriado} roles={['FERIADO_VIEW', 'FERIADO_LIST']} />

      {/* Agendas */}
      <Route
        path="/configAgendas/:option"
        component={ConfigAgenda}
        roles={['CONFIG_AGENDA_VIEW']}
      />
      <Route
        path="/configAgendas"
        component={ConfigAgenda}
        roles={['CONFIG_AGENDA_VIEW', 'CONFIG_AGENDA_LIST']}
      />

      {/* Agendamento */}
      <Route
        path="/agendamentos/:option"
        component={Agendamento}
        roles={['AGENDAMENTO_VIEW']}
      />
      <Route
        path="/agendamentos"
        component={Agendamento}
        roles={['AGENDAMENTO_VIEW', 'AGENDAMENTO_LIST']}
      />

      {/* MinhaAgenda */}
      <Route path="/minhaAgenda" component={MinhaAgenda} roles={['MINHA_AGENDA_VIEW']} />

      {/* se não encontrar nenhuma rota, vá para not found */}
      <Route path="/" isPrivate component={NotFound} />
    </Switch>
  );
}

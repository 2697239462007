import styled from 'styled-components';

export const Container = styled.div`
  .rodape {
    display: flex;
    flex: 1;
    border-radius: 2px;
    border-style: solid;
    border-width: 1px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-color: #c8c8c8;
    margin-top: 1px;
    height: 41px;
    flex-direction: row;
    span {
      align-self: center;
    }
  }

  .feriado {
    border-radius: 4px;
    border-style: solid;
    border-width: 2px;
    border-color: #ee3902;
  }

  .item-calendar {
    display: block;
    margin: auto;
    max-width: 25px;
  }

  .indisponivel {
    border-radius: 4px;
    border-style: solid;
    border-width: 2px;
    border-color: #c8c8c8;
    text-align: center;
    span {
      text-decoration: line-through;
    }
  }

  .div-legenda {
    display: inline-block;
    width: 22px;
    margin-right: 3px;
    margin-left: 5px;
    align-self: center;
  }
`;

import { BaseService } from './BaseService';
import { ConfigApi } from '../config/Constantes';

class AtendimentoTipoService extends BaseService {
  constructor() {
    super(ConfigApi.atendimentoTipoURL);
  }

  getFilter() {
    return new Filter();
  }
}

class Filter {
  constructor() {
    this.nome = '';
    this.limit = 0;
    this.page = 0;
  }
}
export default new AtendimentoTipoService();

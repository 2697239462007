/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { Column } from 'primereact/column';

import ButtonSp from '../../components/ButtonSp';
import DropDownLazy from '../../components/DropdownLazy';
import InputCurrencySp from '../../components/InputCurrencySp';

import LabelSp from '../../components/LabelSp';
import { showMessage } from '../../components/MessageDialog';
import EducacaoService from '../../services/EducacaoService';
import ColaboradorService from '../../services/ColaboradorService';
import { calcNaxItemsPage, cloneObj, errorHandle } from '../../util/functions';
import {
  EducacaoParticipanteModel,
  EducacaoModel,
  TpColaborador,
  TpColaboradorPerfil,
} from '../../util/Models';
import { StateScreen } from '../constants';

import ComunidadeService from '../../services/ComunidadeService';
import { cfgPtBr } from '../../config/Constantes';
import CalendarSp from '../../components/CalendarSp';
import AuthService from '../../services/AuthService';
import InputTextSp from '../../components/InputTextSp';
import DropdownSp from '../../components/DropdownSp';
import DataTableSp from '../../components/DataTableSp';
import BotaoMenuGrid from '../../components/BotaoMenuGrid';
import InputTextareaSp from '../../components/InputTextareaSp';
import history from '../../services/history';

// import DropdownSp from '../../components/DropdownSp';

function EducacaoCrud(props) {
  EducacaoCrud.propTypes = {
    stateScreen: PropTypes.string.isRequired,
    idSelected: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    closeOnSaveCancel: PropTypes.bool,
  };

  EducacaoCrud.defaultProps = {
    messageConfirmation: 'Confirma os dados',
    showConfirmation: false,
    closeOnSaveCancel: false,
  };

  const { toBack, closeOnSaveCancel, stateScreen, idSelected, onClose } = props;

  let eBack = '';
  let uBack = '';
  if (toBack) {
    eBack = `&toBack=${toBack}`;
    uBack = `?toBack=${toBack}`;
  }

  // states
  const [educacao, setEducacao] = useState(new EducacaoModel());
  const [colaboradorSelecionado, setColaboradorSelecionado] = useState(null);
  const [comunidadeSelecionada, setComunidadeSelecionada] = useState(null);
  const [pageLimit] = useState(calcNaxItemsPage(5, 9, 14));
  const [quantidadeSelecionada, setQuantidadeSelecionada] = useState(1);
  const [educacaoParticipante, setEducacaoParticipante] = useState([]);
  const [alterandoParticipante, setAlterandoParticipante] = useState(false);

  const [errorLoadRecord, setErrorLoadRecord] = useState(false);

  // useCallbacks

  const loadColaborador = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await ColaboradorService.findAll({
        nome: _nome,
        limit: 50,
        fazPalestra: true,
      });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadComunidade = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await ComunidadeService.findAll({ nome: _nome, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadRecord = useCallback(async _id => {
    try {
      const retorno = await EducacaoService.findById(_id);

      if (retorno.horaInicio) {
        retorno.horaInicio = new Date(retorno.horaInicio);
      }

      if (retorno.horaFim) {
        retorno.horaFim = new Date(retorno.horaFim);
      }

      if (retorno.data) {
        retorno.data = new Date(retorno.data);
      }

      setEducacao(retorno);
      setEducacaoParticipante(retorno.educacaoParticipante);

      const colaborador = {
        label: retorno.colaborador.nome,
        value: retorno.colaborador.id,
      };

      setColaboradorSelecionado(colaborador);
      setErrorLoadRecord(false);
    } catch (err) {
      setErrorLoadRecord(true);
      errorHandle(err);
    }
  }, []);

  // funcoes
  function viewMode() {
    return stateScreen === StateScreen.stView || errorLoadRecord;
  }

  function handleBack() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      showMessage('Confirmação', 'Abandonar mudanças?', idx => {
        if (idx === 1) {
          if (stateScreen === StateScreen.stInsert || closeOnSaveCancel) {
            onClose();
          } else {
            history.push(`/educacoes/${idSelected}?view${eBack}`);
          }
        }
      });
    }
  }

  function validateFieldsEducacao() {
    if (!(educacao.tipo >= 0 && educacao.tipo <= 1)) {
      toast.error('Informe o tipo.');
      return false;
    }

    if (educacao.horaFim < educacao.horaInicio) {
      toast.error('Hora final não pode ser menor que a hora de inicio.');
      return false;
    }

    if (!educacao.idColaborador) {
      toast.error('Falta informar o Palestrante.');
      return false;
    }

    if (!educacao.tema) {
      toast.error('Falta informar o tema.');
      return false;
    }

    if (educacaoParticipante.length === 0) {
      toast.error('É necessário ter pelo menos um participante.');
      return false;
    }

    return true;
  }

  function handleSave() {
    if (!validateFieldsEducacao()) {
      return;
    }

    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      salvarRecord();
    }
  }

  async function salvarRecord() {
    try {
      let retorno;
      const educacaoSalvar = cloneObj(educacao);

      educacaoSalvar.educacaoParticipante = educacaoParticipante;

      if (stateScreen === StateScreen.stInsert) {
        retorno = await EducacaoService.insert(educacaoSalvar);
      } else {
        retorno = await EducacaoService.update(educacaoSalvar);
      }
      toast.success('Registro salvo com sucesso.');
      if (closeOnSaveCancel) {
        onClose(retorno);
      } else {
        history.push(`/educacoes/${retorno.id}?view${eBack}`);
      }
    } catch (err) {
      errorHandle(err);
    }
  }

  const carregaParticipante = registro => {
    setComunidadeSelecionada({
      value: registro.comunidade.id,
      label: registro.comunidade.nome,
    });
    setQuantidadeSelecionada(registro.quantidade);
    setAlterandoParticipante(true);
  };

  const confirmaExclusaoParticipante = registro => {
    showMessage(
      'Confirmação',
      `Deseja excluir o participante "${registro.comunidade.nome}"?`,
      idx => {
        if (idx === 1) {
          const participantes = cloneObj(educacaoParticipante) || [];
          let indice;
          participantes.forEach((e, i) => {
            if (e.comunidade.id === registro.comunidade.id) {
              indice = i;
            }
          });

          if (indice >= 0) {
            participantes.splice(indice, 1);
            setEducacaoParticipante(participantes);
          }
        }
      }
    );
  };

  const verificaPerticipante = () => {
    if (!alterandoParticipante) {
      const participantes = cloneObj(educacaoParticipante);
      let index;
      participantes.forEach((e, i) => {
        if (e.comunidade.id === comunidadeSelecionada.value) {
          index = i;
        }
      });
      if (index >= 0) {
        showMessage(
          'Confirmação',
          'Comunidade já foi lançada. Deseja substituir a quantidade?',
          idx => {
            if (idx === 1) {
              salvarParticipante();
            }
          }
        );
        return;
      }
    }
    salvarParticipante();
  };

  const salvarParticipante = () => {
    const participantes = cloneObj(educacaoParticipante);
    let idx;
    participantes.forEach((e, i) => {
      if (e.comunidade.id === comunidadeSelecionada.value) {
        idx = i;
      }
    });

    if (idx >= 0) {
      participantes[idx].quantidade = quantidadeSelecionada;
    } else {
      const participante = new EducacaoParticipanteModel();
      participante.comunidade = {
        id: comunidadeSelecionada.value,
        nome: comunidadeSelecionada.label,
      };
      participante.idComunidade = comunidadeSelecionada.value;
      participante.quantidade = quantidadeSelecionada;
      participantes.push(participante);
    }

    setEducacaoParticipante(participantes);
    setAlterandoParticipante(false);
    setQuantidadeSelecionada(1);
    setComunidadeSelecionada(null);
  };

  // useEffects
  useEffect(() => {
    if (stateScreen === StateScreen.stUpdate || stateScreen === StateScreen.stView) {
      loadRecord(idSelected);
    } else if (stateScreen === StateScreen.stInsert) {
      const novo = new EducacaoModel();

      let colaborador;

      const colaboradorLogado = AuthService.getColaborador();

      switch (colaboradorLogado.idColaboradorCategoria) {
        case TpColaborador.MEDICO:
        case TpColaborador.PSICOLOCO:
        case TpColaborador.ENFERMEIRO:
          colaborador = { label: colaboradorLogado.nome, value: colaboradorLogado.id };
          break;

        default:
          break;
      }

      if (colaborador) {
        novo.colaborador = {
          id: colaborador.value,
          nome: colaborador.label,
        };
        novo.idColaborador = colaborador.value;
      }

      setColaboradorSelecionado(colaborador);

      setEducacao(novo);
    }
  }, [loadRecord, idSelected, stateScreen]);

  const renderPrincipal = () => {
    const colaboradorLogado = AuthService.getColaborador();
    return (
      <>
        <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
          <LabelSp>Tipo</LabelSp>
          <DropdownSp
            required
            disabled={viewMode()}
            placeholder="Selecione"
            value={educacao.tipo}
            options={[
              { value: 0, label: 'Web Palestra' },
              { value: 1, label: 'Teleorientação Individual' },
            ]}
            onChange={e => setEducacao({ ...educacao, tipo: e.target.value })}
          />
        </div>

        <div className="p-col-6 p-sm-3 p-lg-2 p-fluid">
          <LabelSp>Data</LabelSp>
          <CalendarSp
            readOnlyInput
            appendTo={document.body}
            dateFormat="dd/mm/yy"
            disabled={viewMode()}
            required
            locale={cfgPtBr}
            value={educacao.data}
            onChange={e => setEducacao({ ...educacao, data: e.value })}
          />
        </div>

        <div
          className="p-grid p-col-12 p-sm-3 p-lg-3 p-fluid"
          style={{ padding: 0, margin: 0 }}
        >
          <div className="p-col-6 p-fluid">
            <LabelSp>Hr Inicio</LabelSp>
            <CalendarSp
              readOnlyInput
              appendTo={document.body}
              showTime
              timeOnly
              disabled={viewMode()}
              required
              locale={cfgPtBr}
              value={educacao.horaInicio}
              onChange={e => setEducacao({ ...educacao, horaInicio: e.value })}
            />
          </div>
          <div className="p-col-6 p-fluid">
            <LabelSp>Hr Final</LabelSp>
            <CalendarSp
              readOnlyInput
              appendTo={document.body}
              showTime
              timeOnly
              disabled={viewMode()}
              required
              locale={cfgPtBr}
              value={educacao.horaFim}
              onChange={e => setEducacao({ ...educacao, horaFim: e.value })}
            />
          </div>
        </div>

        <div className="p-col-12 p-sm-3 p-lg-4 p-fluid">
          <LabelSp>Palestrante</LabelSp>
          <DropDownLazy
            required
            autoLoad
            disabled={
              viewMode() ||
              (colaboradorLogado.idColaboradorPerfil !== TpColaboradorPerfil.ADMINISTRADOR &&
                colaboradorLogado.idColaboradorPerfil !== TpColaboradorPerfil.GESTOR)
            }
            placeholder="Selecione"
            onChange={e => {
              const colaborador = e ? { id: e.value, nome: e.label } : null;
              setColaboradorSelecionado(e);
              setEducacao({
                ...educacao,
                colaborador,
                idColaborador: e?.value,
              });
            }}
            value={colaboradorSelecionado}
            onFilter={async txtFilter => {
              const retorno = await loadColaborador(txtFilter);
              return retorno;
            }}
          />
        </div>
        <div className="p-col-12 p-sm-9 p-lg-9 p-fluid">
          <LabelSp>Tema</LabelSp>
          <InputTextSp
            disabled={viewMode()}
            required
            maxLength={100}
            value={educacao.tema}
            onChange={e => setEducacao({ ...educacao, tema: e.target.value })}
          />
        </div>

        <div className="p-col-12 p-sm-3 p-lg-3 p-fluid">
          <LabelSp>Nr. Comunidades</LabelSp>
          <InputCurrencySp digits={0} disabled value={educacaoParticipante.length || 0} />
        </div>

        <div className="p-col-12 p-sm-12 p-lg-12 p-fluid">
          <LabelSp>Profissionais de Apoio</LabelSp>
          <InputTextSp
            disabled={viewMode()}
            maxLength={200}
            value={educacao.profissionaisApoio}
            onChange={e => setEducacao({ ...educacao, profissionaisApoio: e.target.value })}
          />
        </div>

        <div className="p-col-12 p-sm-12 p-lg-12 p-fluid">
          <LabelSp>Assuntos Abordados</LabelSp>
          <InputTextareaSp
            resize={false}
            rows={2}
            value={educacao.assuntosAbordados}
            disabled={viewMode()}
            onChange={e => {
              setEducacao({ ...educacao, assuntosAbordados: e.target.value });
            }}
          />
        </div>

        <div
          className="p-col-12 p-sm-12 p-lg-12 p-fluid"
          style={{ textAlign: 'center', marginTop: -5, marginBottom: 0 }}
        >
          <hr style={{ margin: 0, padding: 0 }} />
          <LabelSp>Comunidades Participantes</LabelSp>
        </div>

        <div className="p-col-12 p-sm-5 p-lg-4 p-fluid">
          <LabelSp>Comunidade</LabelSp>
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon">
              <i
                className="pi pi-info-circle"
                title="Caso não encontre o ponto na lista, solicitar cadastro do mesmo."
              />
            </span>
            <DropDownLazy
              style={{ width: '100%' }}
              required
              autoLoad
              disabled={viewMode()}
              placeholder="Selecione"
              onChange={e => {
                setComunidadeSelecionada(e);
              }}
              value={comunidadeSelecionada}
              onFilter={async txtFilter => {
                const retorno = await loadComunidade(txtFilter);
                return retorno;
              }}
            />
          </div>
        </div>

        <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Quantidade</LabelSp>
          <InputCurrencySp
            digits={0}
            disabled={viewMode()}
            required
            value={quantidadeSelecionada}
            onChange={(e, v) => {
              setQuantidadeSelecionada(v);
            }}
          />
        </div>
        <div className="p-col-4 p-sm-4 p-lg-4">
          <LabelSp>&#160;</LabelSp>
          <ButtonSp
            className="p-button-success"
            icon="pi pi-save"
            title="Salvar"
            disabled={!comunidadeSelecionada || !quantidadeSelecionada}
            onClick={() => verificaPerticipante()}
          />
          <ButtonSp
            className="p-button-danger"
            icon="pi pi-times"
            title="Cancelar"
            onClick={() => {
              setComunidadeSelecionada(null);
              setQuantidadeSelecionada(1);
              setAlterandoParticipante(false);
            }}
          />
        </div>

        <div className="p-col-12 p-fluid">
          <DataTableSp
            value={educacaoParticipante}
            style={{ marginBottom: '2px' }}
            paginator
            rows={pageLimit}
            responsive
          >
            <Column
              className="grid-col"
              header="Comunidade"
              body={rowData => rowData.comunidade.nome}
            />

            <Column
              className="grid-col-val"
              style={{ width: 100 }}
              header="Participantes"
              body={rowData => rowData.quantidade}
            />

            <Column
              className="gid-col-acoes-35"
              bodyStyle={{ textAlign: 'end' }}
              body={renderButtonOp}
            />
          </DataTableSp>
        </div>

        {/* label-button */}
      </>
    );
  };

  function renderButtonOp(rowData) {
    return (
      <BotaoMenuGrid
        handles={[
          () => {
            carregaParticipante(rowData);
          },
          () => {
            confirmaExclusaoParticipante(rowData);
          },
        ]}
        labels={['Alterar', 'Excluir']}
        icons={['pi pi-pencil', 'pi pi-trash']}
        disableds={[
          !AuthService.checkRoles('EDUCACAO_UPDATE'),
          !AuthService.checkRoles('EDUCACAO_DELETE'),
        ]}
      />
    );
  }

  // render principal
  const buttons = ['Dados Principais'];
  if (AuthService.checkRoles('EDUCACAO_INSERT')) {
    buttons.push('Detalhes do Educacao');
  }

  return (
    <>
      {renderPrincipal()}

      <div className="p-col-12 p-lg-12" style={{ textAlign: 'start' }}>
        {!viewMode() ? (
          <ButtonSp
            className="p-button-success"
            icon="pi pi-save"
            label="Salvar"
            showConfirmation
            onClick={handleSave}
          />
        ) : null}

        {viewMode() ? (
          <ButtonSp
            className="p-button-primary"
            icon="pi pi-pencil"
            label="Alterar"
            disabled={!AuthService.checkRoles('EDUCACAO_UPDATE')}
            onClick={() => {
              history.push(`/educacoes/${idSelected}${uBack}`);
            }}
          />
        ) : null}

        <ButtonSp
          className={viewMode() ? 'p-button-secondary' : 'p-button-danger'}
          label={viewMode() ? 'Voltar' : 'Cancelar'}
          icon="pi pi-chevron-circle-left"
          onClick={handleBack}
        />
      </div>
    </>
  );
}

export default EducacaoCrud;

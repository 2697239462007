import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { Column } from 'primereact/column';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Container } from './styles';

import EducacaoService from '../../services/EducacaoService';
import AuthService from '../../services/AuthService';
import history from '../../services/history';
import {
  getPageParams,
  errorHandle,
  calcNaxItemsPage,
  isScreenMobile,
  formatDate,
} from '../../util/functions';
import LabelSp from '../../components/LabelSp';

import { showMessage } from '../../components/MessageDialog';

import BotaoMenuGrid from '../../components/BotaoMenuGrid';

import EducacaoCrud from './crud';
import { StateScreen } from '../constants';
import CrudHeader from '../../components/CrudHeader';
import DataTableSp from '../../components/DataTableSp';
import DropDownLazy from '../../components/DropdownLazy';
import ColaboradorService from '../../services/ColaboradorService';

import CalendarSp from '../../components/CalendarSp';
import { cfgPtBr } from '../../config/Constantes';

import MunicipioService from '../../services/MunicipioService';
import RegionalService from '../../services/RegionalService';
import DropdownSp from '../../components/DropdownSp';
import ComunidadeService from '../../services/ComunidadeService';

export default function Educacao(props) {
  // useSelectors
  const filterVisible = useSelector(state => state.global.filterVisible);

  // useMemo
  const pageParams = useMemo(() => getPageParams(props.match.params, props.location.search), [
    props.location.search,
    props.match.params,
  ]);

  // const filterService = useMemo(() => EducacaoService.getFilter(), []);

  const filterService = useMemo(() => {
    const colaborador = AuthService.getColaborador();
    const f = EducacaoService.getFilter();
    if (colaborador && colaborador.municipioAcesso) {
      f.idMunicipio = colaborador.municipioAcesso.id;
      f.municipioAcesso = {
        label: colaborador.municipioAcesso.nome,
        value: colaborador.municipioAcesso.id,
      };
    } else {
      f.municipioAcesso = undefined;
    }
    return f;
  }, []);

  // useStates
  const toBack = pageParams.toBack || '/educacoes';

  const [filter, setFilter] = useState(filterService);
  const [educacoes, setEducacaoes] = useState([]);

  const [colaborador, setColaborador] = useState(null);
  const [municipio, setMunicipio] = useState(null);
  const [regional, setRegional] = useState(null);
  const [comunidade, setComunidade] = useState(null);

  const [pageLimit, setPageLimit] = useState(filterService.limit);
  const [first, setFirst] = useState(0);
  const [pageSelected, setPageSelected] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [funcTimeOut, setFuncTimeOut] = useState();

  // useCallbacks
  const loadRegional = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await RegionalService.findAll({ nome: _nome, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);
  const loadColaborador = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await ColaboradorService.findAll({
        nome: _nome,
        limit: 50,
        fazPalestra: true,
      });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadComunidade = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await ComunidadeService.findAll({ nome: _nome, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadMunicipio = useCallback(async (_nome, uf) => {
    if (_nome !== undefined) {
      const r = await MunicipioService.findAll({ nome: _nome, uf, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const calcLimit = useCallback(() => {
    let ret;
    if (filterVisible) {
      ret = calcNaxItemsPage(8, 12, 18);
    } else {
      ret = calcNaxItemsPage(13, 17, 22);
    }
    setPageLimit(ret);
    return ret;
  }, [filterVisible]);

  const handleBuscar = useCallback(
    async (_filter, _page, resetPage = true) => {
      _filter.page = _page || 0;
      _filter.limit = calcLimit();
      try {
        const result = await EducacaoService.findAll(_filter);
        setEducacaoes(result.items);

        setTotalRecords(result.totalRecords);
        if (resetPage) {
          setFirst(0);
        }
      } catch (err) {
        errorHandle(err);
      }
    },
    [calcLimit]
  );

  const setFilterAndSearch = useCallback(
    async _filterValue => {
      if (JSON.stringify(_filterValue) !== JSON.stringify(filter)) {
        setFilter(_filterValue);

        if (funcTimeOut) {
          clearTimeout(funcTimeOut);
        }
        const func = setTimeout(async () => {
          handleBuscar(_filterValue);
        }, 800);
        setFuncTimeOut(func);
      }
    },
    [filter, funcTimeOut, handleBuscar]
  );

  function onPage(event) {
    const pagina = event.first / event.rows;
    setPageSelected(pagina);
    setFirst(event.first);
    handleBuscar(filter, pagina, false);
  }

  function validateAuthColaborador(idColaborador) {
    if (
      AuthService.getColaborador().id !== idColaborador &&
      AuthService.getColaborador().idColaboradorPerfil !== 1 &&
      AuthService.getColaborador().idColaboradorPerfil !== 5
    ) {
      toast.error(
        'Você não tem permissão para alterar/excluir orientações de outros palestrantes.'
      );
      return false;
    }
    return true;
  }

  // useCallbacks
  const excluirRegistro = useCallback(
    async _id => {
      try {
        await EducacaoService.delete(_id);
        toast.success('Registro excluído com sucesso.');
        handleBuscar(filter);
      } catch (err) {
        errorHandle(err);
      }
    },
    [filter, handleBuscar]
  );

  const doExportCSV = useCallback(async _filter => {
    try {
      await EducacaoService.exportCSV(_filter);
    } catch (err) {
      errorHandle(err);
    }
  }, []);

  // functions
  function getTitle() {
    const titleDefault = 'Teleeducação';
    let titleAdd = '';

    if (pageParams.stateScreen === StateScreen.stSearch) {
      titleAdd = '';
    }
    if (pageParams.stateScreen === StateScreen.stInsert) {
      titleAdd = '(Novo)';
    }
    if (pageParams.stateScreen === StateScreen.stUpdate) {
      titleAdd = `(Alterando Id: ${pageParams.idSelected})`;
    }
    if (pageParams.stateScreen === StateScreen.stView) {
      titleAdd = ` (Visualizando Id: ${pageParams.idSelected})`;
    }

    if (!isScreenMobile()) {
      return `${titleDefault} ${titleAdd}`;
    }

    return titleDefault;
  }

  const confirmaExclusao = useCallback(
    id => {
      showMessage('Confirmação', 'Confirma a exclusão do registro?', idx => {
        if (idx === 1) {
          excluirRegistro(id);
        }
      });
    },
    [excluirRegistro]
  );

  // useEffects
  useEffect(() => {
    setMunicipio(filter.municipioAcesso);
    if (pageParams.stateScreen === StateScreen.stSearch) {
      handleBuscar(filter, 0, true);
    }
    // desativado para evitar que a cada vez que o educacao digitasse o sistema buscasse
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffects
  // Responsavel por recalcular a quantidade de registros da grid quando a div do filtro ocultar
  // ou ficar visivel
  useEffect(() => {
    if (pageParams.stateScreen === StateScreen.stSearch) {
      calcLimit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcLimit]);

  // renders
  return (
    <Container className="container-page">
      <div className="p-grid">
        <CrudHeader
          title={getTitle()}
          disabledButtonInsert={!AuthService.checkRoles('EDUCACAO_INSERT')}
          showButtonFilter={pageParams.stateScreen === StateScreen.stSearch}
          showButtonInsert
          showButtonSearch
          optionsButtonMenu={{
            handles: [() => doExportCSV(filter)],
            labels: ['Exportar para CSV'],
            icons: ['pi pi-cloud-download'],
            disableds: [!AuthService.checkRoles('EDUCACAO_EXPORT')],
          }}
          titleFilterDefault="Tema"
          handleFilterDefault={text => {
            filter.tema = text;
            setFilter({ ...filter, tema: text });
            handleBuscar(filter);
          }}
          handleClearFilters={() => {
            setFilter({
              ...filter,
              id: 0,
              tema: '',
              idMunicipio: 0,
              idColaborador: 0,
              idComunidade: 0,
            });
            setMunicipio(null);
            setColaborador(null);
            setComunidade(null);
          }}
          handleButtonInsert={() => history.push('/educacoes/insert')}
          handleButtonSearch={() => handleBuscar(filter)}
        >
          <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
            <LabelSp htmlFor="dataInicial">De: </LabelSp>
            <CalendarSp
              readOnlyInput
              appendTo={document.body}
              dateFormat="dd/mm/yy"
              yearNavigator
              locale={cfgPtBr}
              id="dataInicial"
              value={filter.dataInicio}
              yearRange="2010:2040"
              onChange={e => {
                setFilterAndSearch({ ...filter, dataInicio: e.value });
              }}
            />
          </div>
          <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
            <LabelSp htmlFor="dataFinal">Até: </LabelSp>
            <CalendarSp
              appendTo={document.body}
              readOnlyInput
              locale={cfgPtBr}
              id="dataFinal"
              dateFormat="dd/mm/yy"
              value={filter.dataFim}
              yearNavigator
              yearRange="2010:2040"
              onChange={e => setFilterAndSearch({ ...filter, dataFim: e.value })}
            />
          </div>

          <div className="p-col-12 p-sm-5 p-lg-5 p-fluid">
            <LabelSp>Palestrante</LabelSp>
            <DropDownLazy
              autoLoad
              showClear
              placeholder="Todos"
              onChange={e => {
                setFilterAndSearch({ ...filter, idColaborador: e?.value });
                setColaborador(e);
              }}
              value={colaborador}
              onFilter={async txtFilter => {
                const retorno = await loadColaborador(txtFilter);
                return retorno;
              }}
            />
          </div>
          <div className="p-col-12 p-sm-3 p-lg-3 p-fluid">
            <LabelSp>Município</LabelSp>
            <DropDownLazy
              showClear
              autoLoad
              disabled={filter.municipioAcesso}
              placeholder="Todos"
              onChange={e => {
                setFilterAndSearch({ ...filter, idMunicipio: e?.value });
                setMunicipio(e);
              }}
              value={municipio}
              onFilter={async txtFilter => {
                const retorno = await loadMunicipio(txtFilter);
                return retorno;
              }}
            />
          </div>
          <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
            <LabelSp>Tipo Teleeducação</LabelSp>
            <DropdownSp
              value={filter.tipo}
              options={[
                { value: '', label: 'Todos' },
                { value: '0', label: 'Web Palestra' },
                { value: '1', label: 'Teleorientação Individual' },
              ]}
              onChange={e => setFilterAndSearch({ ...filter, tipo: e?.value })}
            />
          </div>
          <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
            <LabelSp>Regional/TI</LabelSp>
            <DropDownLazy
              autoLoad
              showClear
              placeholder="Todos"
              onChange={e => {
                setFilterAndSearch({ ...filter, idRegional: e?.value });
                setRegional(e);
              }}
              value={regional}
              onFilter={async txtFilter => {
                const retorno = await loadRegional(txtFilter);
                return retorno;
              }}
            />
          </div>

          <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
            <LabelSp>Comunidade</LabelSp>
            <DropDownLazy
              autoLoad
              showClear
              placeholder="Todos"
              onChange={e => {
                setFilterAndSearch({ ...filter, idComunidade: e?.value });
                setComunidade(e);
              }}
              value={comunidade}
              onFilter={async txtFilter => {
                const retorno = await loadComunidade(txtFilter);
                return retorno;
              }}
            />
          </div>
        </CrudHeader>
        {pageParams.stateScreen === StateScreen.stSearch ? renderSearch() : renderCrud()}
      </div>
    </Container>
  );

  function renderSearch() {
    return (
      <>
        <div className="p-col-12 p-fluid">
          <DataTableSp
            value={educacoes}
            style={{ marginBottom: '2px' }}
            paginator
            rows={pageLimit}
            lazy
            responsive
            totalRecords={totalRecords}
            first={first}
            onPage={onPage}
          >
            <Column
              className="grid-col-data"
              header="Data"
              body={rowData => formatDate(rowData.data, 'dd/MM/yyyy')}
            />
            <Column
              className="grid-col-data"
              header="Hr. Inicio"
              body={rowData => formatDate(rowData.horaInicio, 'HH:mm')}
            />
            <Column
              className="grid-col-data"
              header="Hr. Fim"
              body={rowData => formatDate(rowData.horaFim, 'HH:mm')}
            />

            <Column field="colaboradorNome" className="grid-col" header="Palestrante" />
            <Column className="grid-col" header="Tema" body={rowData => rowData.tema} />
            <Column
              className="grid-col-val p-p-5"
              style={{ width: 100 }}
              header="Comunidades"
              body={rowData => rowData.quantidadeComunidades}
            />

            <Column
              className="grid-col-val p-p-5"
              style={{ width: 100 }}
              header="Participantes"
              body={rowData => rowData.quantidadeParticipantes}
            />

            <Column
              className="gid-col-acoes-35"
              bodyStyle={{ textAlign: 'end' }}
              body={renderButtonOp}
            />
          </DataTableSp>
        </div>
      </>
    );
  }

  function renderButtonOp(rowData) {
    return (
      <BotaoMenuGrid
        handles={[
          () => history.push(`/educacoes/${rowData.id}?view`),
          () => {
            if (!validateAuthColaborador(rowData.idColaborador)) {
              return;
            }
            history.push(`/educacoes/${rowData.id}`);
          },
          () => {
            if (!validateAuthColaborador(rowData.idColaborador)) {
              return;
            }
            confirmaExclusao(rowData.id);
          },
        ]}
        labels={['Visualizar', 'Alterar', 'Excluir']}
        disableds={[
          !AuthService.checkRoles('EDUCACAO_VIEW'),
          !AuthService.checkRoles('EDUCACAO_UPDATE'),
          !AuthService.checkRoles('EDUCACAO_DELETE'),
        ]}
      />
    );
  }

  function renderCrud() {
    return (
      <EducacaoCrud
        idSelected={pageParams.idSelected}
        stateScreen={pageParams.stateScreen}
        closeOnSaveCancel={false}
        toBack={toBack !== '/educacoes' ? toBack : ''}
        onClose={() => {
          history.push(toBack);
          if (toBack.includes('/educacoes')) {
            handleBuscar(filter, pageSelected, false);
          }
        }}
      />
    );
  }
}

import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import ButtonSp from '../../components/ButtonSp';
import DropDownLazy from '../../components/DropdownLazy';
import DropdownSp from '../../components/DropdownSp';
import CalendarSp from '../../components/CalendarSp';
import InputMaskSp from '../../components/InputMaskSp';
import InputTextSp from '../../components/InputTextSp';
import LabelSp from '../../components/LabelSp';
import { showMessage } from '../../components/MessageDialog';

import PacienteService from '../../services/PacienteService';
import ComunidadeService from '../../services/ComunidadeService';
import MunicipioService from '../../services/MunicipioService';

import { cloneObj, errorHandle, validateFields, validCPF } from '../../util/functions';
import { PacienteModel } from '../../util/Models';
import { StateScreen } from '../constants';
import { cfgPtBr, listaUfs } from '../../config/Constantes';
import IdentidadeGeneroService from '../../services/IdentidadeGeneroService';

function PacienteCrud(props) {
  PacienteCrud.propTypes = {
    stateScreen: PropTypes.string.isRequired,
    idSelected: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  PacienteCrud.defaultProps = {
    messageConfirmation: 'Confirma os dados',
    showConfirmation: false,
  };

  const { stateScreen, idSelected, onClose } = props;

  // states
  const [paciente, setPaciente] = useState(new PacienteModel());
  const [comunidadeSelecionada, setComunidadeSelecionada] = useState(null);
  const [municipioSelecionado, setMunicipioSelecionado] = useState(null);
  const [comunidades, setComunidades] = useState([]);
  const [ufSelecionada, setUfSelecionada] = useState('AM');
  const [errorLoadRecord, setErrorLoadRecord] = useState(false);
  const [identidadesGenero, setIdentidadesGenero] = useState([]);

  // useCallbacks
  const loadComunidade = useCallback(async () => {
    const r = await ComunidadeService.findAll({ limit: 9999 });

    const retorno = r.items.map(e => {
      return {
        label: e.nome,
        value: e.id,
      };
    });

    retorno.push({ label: 'Outras', value: 0 });

    retorno.sort((a, b) => {
      if (a.label > b.label) {
        return 1;
      }
      if (a.label < b.label) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });

    setComunidades(retorno);
  }, []);

  const loadIdentidadeGenero = useCallback(async () => {
    const r = await IdentidadeGeneroService.findAll({ limit: 9999 });

    const retorno = r.items.map(e => {
      return {
        label: e.nome,
        value: e.id,
      };
    });

    setIdentidadesGenero(retorno);
  }, []);

  const loadMunicipioFromComunidade = useCallback(async (pacienteAtual, idComunidade) => {
    if (idComunidade) {
      const r = await ComunidadeService.findAll({ id: idComunidade });
      const retorno = r.items;
      if (Array.isArray(retorno) && retorno.length > 0) {
        if (retorno[0]) {
          setMunicipioSelecionado({
            value: retorno[0].idMunicipio,
            label: retorno[0].municipioNome,
          });
          setPaciente({ ...pacienteAtual, idMunicipio: retorno[0].idMunicipio });
        }
      }
    }
  }, []);

  const loadMunicipio = useCallback(async (_nome, uf) => {
    if (_nome !== undefined) {
      const r = await MunicipioService.findAll({ nome: _nome, uf, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadRecord = useCallback(async _id => {
    try {
      const retorno = await PacienteService.findById(_id);
      if (retorno.dataNascimento) {
        retorno.dataNascimento = new Date(retorno.dataNascimento);
      }

      setPaciente(retorno);

      const comunidade = retorno.comunidade
        ? {
            label: retorno.comunidade.nome,
            value: retorno.comunidade.id,
          }
        : {
            label: 'Outras',
            value: 0,
          };

      const municipio = {
        label: retorno.municipio.nome,
        value: retorno.municipio.id,
      };

      setComunidadeSelecionada(comunidade);
      setMunicipioSelecionado(municipio);
      setErrorLoadRecord(false);
    } catch (err) {
      setErrorLoadRecord(true);
      errorHandle(err);
    }
  }, []);

  // funcoes
  function viewMode() {
    return stateScreen === StateScreen.stView || errorLoadRecord;
  }

  function handleBack() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      showMessage('Confirmação', 'Abandonar mudanças?', idx => {
        if (idx === 1) {
          onClose();
        }
      });
    }
  }

  function handleSave() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      // salvarRecord();
      validaPacienteComMesmoNome();
    }
  }

  async function validaPacienteComMesmoNome() {
    if (stateScreen === StateScreen.stInsert) {
      let pacienteExiste;
      try {
        pacienteExiste = await PacienteService.findByName(paciente.nome);
      } catch (_err) {
        //
      }
      if (pacienteExiste) {
        showMessage(
          'Confirmação',
          'Já existe outro paciente com o mesmo nome. Deseja cadastrar assim mesmo?',
          idx => {
            if (idx === 1) {
              salvarRecord();
            }
          }
        );
      } else {
        salvarRecord();
      }
    } else {
      salvarRecord();
    }
  }
  async function salvarRecord() {
    const pacienteSalvar = cloneObj(paciente);

    if (pacienteSalvar.cpf && !validCPF(pacienteSalvar.cpf)) {
      toast.warn('CPF Inválido.');
      return;
    }
    pacienteSalvar.municipio = { id: pacienteSalvar.idMunicipio };
    if (pacienteSalvar.idComunidade) {
      pacienteSalvar.comunidade = { id: pacienteSalvar.idComunidade };
    } else {
      pacienteSalvar.comunidade = null;
    }

    if (pacienteSalvar.idComunidade === 0) {
      pacienteSalvar.idComunidade = undefined;
      pacienteSalvar.comunidade = null;
    }

    try {
      let retorno;
      if (stateScreen === StateScreen.stInsert) {
        retorno = await PacienteService.insert(pacienteSalvar);
      } else {
        retorno = await PacienteService.update(pacienteSalvar);
      }
      toast.success('Registro salvo com sucesso.');
      onClose(retorno);
    } catch (err) {
      errorHandle(err);
    }
  }

  // useEffects
  useEffect(() => {
    if (stateScreen === StateScreen.stUpdate || stateScreen === StateScreen.stView) {
      loadRecord(idSelected);
    } else if (stateScreen === StateScreen.stInsert) {
      const novo = new PacienteModel();
      setComunidadeSelecionada(null);
      setMunicipioSelecionado(null);
      setPaciente(novo);
    }
    loadComunidade();
    loadIdentidadeGenero();
  }, [loadRecord, idSelected, stateScreen, loadComunidade, loadIdentidadeGenero]);

  // render principal
  return (
    <>
      <>
        <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
          <LabelSp>Nome</LabelSp>
          <InputTextSp
            value={paciente.nome}
            maxLength={100}
            required
            disabled={viewMode()}
            onChange={e => {
              setPaciente({ ...paciente, nome: e.target.value });
            }}
          />
        </div>
        <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Gênero</LabelSp>
          <DropdownSp
            required
            value={paciente.idIdentidadeGenero}
            options={identidadesGenero}
            disabled={viewMode()}
            filterInputAutoFocus={false}
            onChange={e => {
              setPaciente({ ...paciente, idIdentidadeGenero: e.target.value });
            }}
          />
        </div>

        <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Nascimento:</LabelSp>
          <CalendarSp
            dateFormat="dd/mm/yy"
            readOnlyInput
            yearNavigator
            yearRange="1900:2030"
            locale={cfgPtBr}
            value={paciente.dataNascimento}
            disabled={viewMode()}
            showButtonBar
            onChange={e => {
              setPaciente({ ...paciente, dataNascimento: e.target.value });
            }}
          />
        </div>

        <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
          <LabelSp>Nome da Mãe/Responsável</LabelSp>
          <InputTextSp
            value={paciente.nomeMae}
            maxLength={100}
            disabled={viewMode()}
            onChange={e => {
              setPaciente({ ...paciente, nomeMae: e.target.value });
            }}
          />
        </div>
        <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
          <LabelSp>Nome do Pai</LabelSp>
          <InputTextSp
            value={paciente.nomePai}
            maxLength={100}
            disabled={viewMode()}
            onChange={e => {
              setPaciente({ ...paciente, nomePai: e.target.value });
            }}
          />
        </div>
        <div className="p-col-12 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>CPF</LabelSp>
          <InputMaskSp
            mask="999.999.999-99"
            value={paciente.cpf}
            disabled={viewMode()}
            onChange={e => {
              setPaciente({ ...paciente, cpf: e.target.value });
            }}
          />
        </div>

        <div className="p-col-12 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Cartão SUS</LabelSp>
          <InputMaskSp
            mask="999.9999.9999.9999"
            value={paciente.cartaoSus}
            disabled={viewMode()}
            onChange={e => {
              setPaciente({ ...paciente, cartaoSus: e.target.value });
            }}
          />
        </div>

        <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Celular</LabelSp>
          <InputMaskSp
            mask="(99) 99999-9999"
            value={paciente.celular}
            disabled={viewMode()}
            onChange={e => {
              setPaciente({ ...paciente, celular: e.target.value });
            }}
          />
        </div>

        <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Telefone</LabelSp>
          <InputMaskSp
            mask="(99) 9999-9999"
            value={paciente.telefone}
            disabled={viewMode()}
            onChange={e => {
              setPaciente({ ...paciente, telefone: e.target.value });
            }}
          />
        </div>

        <div className="p-col-12 p-sm-3 p-lg-3 p-fluid">
          <LabelSp>Raça</LabelSp>
          <InputTextSp
            value={paciente.raca}
            maxLength={30}
            disabled={viewMode()}
            onChange={e => {
              setPaciente({ ...paciente, raca: e.target.value });
            }}
          />
        </div>

        <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>UF Nascimento</LabelSp>
          <DropdownSp
            disabled={viewMode()}
            placeholder=""
            value={paciente.ufNascimento}
            options={listaUfs}
            filter
            onChange={e => setPaciente({ ...paciente, ufNascimento: e.target.value })}
          />
        </div>

        <div className="p-col-8 p-sm-3 p-lg-3 p-fluid">
          <LabelSp>Naturalidade</LabelSp>
          <InputTextSp
            value={paciente.naturalidade}
            maxLength={40}
            disabled={viewMode()}
            onChange={e => {
              setPaciente({ ...paciente, naturalidade: e.target.value });
            }}
          />
        </div>

        <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
          <LabelSp>Comunidade do Paciente</LabelSp>
          <DropdownSp
            disabled={viewMode()}
            placeholder="Selecione"
            showClear
            filter
            required
            onChange={e => {
              const comunidade = e ? { id: e.value, nome: e.label } : null;
              const pacienteAtual = {
                ...paciente,
                nomeComunidade: '',
                comunidade,
                idComunidade: e?.value,
              };

              setComunidadeSelecionada(e);

              setPaciente(pacienteAtual);
              if (e?.value && e?.value !== paciente.idComunidade) {
                loadMunicipioFromComunidade(pacienteAtual, e?.value);
              }
            }}
            value={comunidadeSelecionada?.value}
            options={comunidades}
          />
        </div>

        <div className="p-col-4 p-sm-3 p-lg-3 p-fluid">
          <LabelSp>UF</LabelSp>
          <DropdownSp
            required
            disabled={viewMode()}
            placeholder="Selecione"
            value={ufSelecionada}
            options={listaUfs}
            filter
            onChange={e => setUfSelecionada(e.target.value)}
          />
        </div>

        <div className="p-col-8 p-sm-5 p-lg-5 p-fluid">
          <LabelSp>Município</LabelSp>
          <DropDownLazy
            required
            autoLoad
            disabled={viewMode()}
            placeholder="Selecione"
            onChange={e => {
              const municipio = e ? { id: e.value, nome: e.label } : null;
              setPaciente({
                ...paciente,
                municipio,
                idMunicipio: e?.value,
              });
            }}
            value={municipioSelecionado}
            onFilter={async txtFilter => {
              const retorno = await loadMunicipio(txtFilter, ufSelecionada);
              return retorno;
            }}
          />
        </div>

        {paciente.idComunidade === 0 && (
          <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
            <LabelSp>Nome Comunidade</LabelSp>
            <InputTextSp
              value={paciente.nomeComunidade}
              maxLength={100}
              required
              disabled={viewMode()}
              onChange={e => {
                setPaciente({ ...paciente, nomeComunidade: e.target.value });
              }}
            />
          </div>
        )}

        <div className="p-col-12 p-lg-12" style={{ textAlign: 'start' }}>
          {!viewMode() ? (
            <ButtonSp
              className="p-button-success"
              icon="pi pi-save"
              label="Salvar"
              disabled={
                !validateFields(paciente, [
                  'nome',
                  'idMunicipio',
                  !paciente.idComunidade ? 'nomeComunidade' : '',
                ])
              }
              showConfirmation
              onClick={handleSave}
            />
          ) : null}
          <ButtonSp
            className="p-button-secondary"
            label="Voltar"
            icon="pi pi-chevron-circle-left"
            onClick={handleBack}
          />
        </div>
      </>
    </>
  );
}

export default PacienteCrud;

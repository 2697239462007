import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { Column } from 'primereact/column';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Container } from './styles';

import ConfigAgendaService from '../../services/ConfigAgendaService';
import AuthService from '../../services/AuthService';
import history from '../../services/history';
import {
  getPageParams,
  errorHandle,
  calcNaxItemsPage,
  isScreenMobile,
} from '../../util/functions';
import LabelSp from '../../components/LabelSp';

import { showMessage } from '../../components/MessageDialog';

import BotaoMenuGrid from '../../components/BotaoMenuGrid';

import ConfigAgendaCrud from './crud';
import { StateScreen } from '../constants';
import CrudHeader from '../../components/CrudHeader';
import DataTableSp from '../../components/DataTableSp';
import DropDownLazy from '../../components/DropdownLazy';
import ColaboradorService from '../../services/ColaboradorService';

export default function ConfigAgenda(props) {
  // useSelectors
  const filterVisible = useSelector(state => state.global.filterVisible);

  // useMemo
  const pageParams = useMemo(() => getPageParams(props.match.params, props.location.search), [
    props.location.search,
    props.match.params,
  ]);

  // const filterService = useMemo(() => ConfigAgendaService.getFilter(), []);

  const filterService = useMemo(() => {
    const colaborador = AuthService.getColaborador();
    const f = ConfigAgendaService.getFilter();
    if (colaborador && colaborador.municipioAcesso) {
      f.idMunicipio = colaborador.municipioAcesso.id;
      f.municipioAcesso = {
        label: colaborador.municipioAcesso.nome,
        value: colaborador.municipioAcesso.id,
      };
    } else {
      f.municipioAcesso = undefined;
    }
    return f;
  }, []);

  // useStates
  const toBack = pageParams.toBack || '/configAgendas';

  const [filter, setFilter] = useState(filterService);
  const [configAgendas, setConfigAgendaes] = useState([]);

  const [colaborador, setColaborador] = useState(null);

  const [pageLimit, setPageLimit] = useState(filterService.limit);
  const [first, setFirst] = useState(0);
  const [pageSelected, setPageSelected] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [funcTimeOut, setFuncTimeOut] = useState();

  // useCallbacks
  const loadColaborador = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await ColaboradorService.findAll({
        nome: _nome,
        limit: 50,
        fazPalestra: true,
      });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const calcLimit = useCallback(() => {
    let ret;
    if (filterVisible) {
      ret = calcNaxItemsPage(8, 12, 18);
    } else {
      ret = calcNaxItemsPage(13, 17, 22);
    }
    setPageLimit(ret);
    return ret;
  }, [filterVisible]);

  const handleBuscar = useCallback(
    async (_filter, _page, resetPage = true) => {
      _filter.page = _page || 0;
      _filter.limit = calcLimit();
      try {
        const result = await ConfigAgendaService.findAll(_filter);
        setConfigAgendaes(result.items);

        setTotalRecords(result.totalRecords);
        if (resetPage) {
          setFirst(0);
        }
      } catch (err) {
        errorHandle(err);
      }
    },
    [calcLimit]
  );

  const setFilterAndSearch = useCallback(
    async _filterValue => {
      if (JSON.stringify(_filterValue) !== JSON.stringify(filter)) {
        setFilter(_filterValue);

        if (funcTimeOut) {
          clearTimeout(funcTimeOut);
        }
        const func = setTimeout(async () => {
          handleBuscar(_filterValue);
        }, 800);
        setFuncTimeOut(func);
      }
    },
    [filter, funcTimeOut, handleBuscar]
  );

  function onPage(event) {
    const pagina = event.first / event.rows;
    setPageSelected(pagina);
    setFirst(event.first);
    handleBuscar(filter, pagina, false);
  }

  // useCallbacks
  const excluirRegistro = useCallback(
    async _id => {
      try {
        await ConfigAgendaService.delete(_id);
        toast.success('Registro excluído com sucesso.');
        handleBuscar(filter);
      } catch (err) {
        errorHandle(err);
      }
    },
    [filter, handleBuscar]
  );

  // functions
  function getTitle() {
    const titleDefault = 'Configuração de Agenda';
    let titleAdd = '';

    if (pageParams.stateScreen === StateScreen.stSearch) {
      titleAdd = '';
    }
    if (pageParams.stateScreen === StateScreen.stInsert) {
      titleAdd = '(Novo)';
    }
    if (pageParams.stateScreen === StateScreen.stUpdate) {
      titleAdd = `(Alterando Id: ${pageParams.idSelected})`;
    }
    if (pageParams.stateScreen === StateScreen.stView) {
      titleAdd = ` (Visualizando Id: ${pageParams.idSelected})`;
    }

    if (!isScreenMobile()) {
      return `${titleDefault} ${titleAdd}`;
    }

    return titleDefault;
  }

  const confirmaExclusao = useCallback(
    id => {
      showMessage('Confirmação', 'Confirma a exclusão do registro?', idx => {
        if (idx === 1) {
          excluirRegistro(id);
        }
      });
    },
    [excluirRegistro]
  );

  // useEffects
  useEffect(() => {
    if (pageParams.stateScreen === StateScreen.stSearch) {
      handleBuscar(filter, 0, true);
    }
    // desativado para evitar que a cada vez que o ConfigAgendas digitasse o sistema buscasse
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffects
  // Responsavel por recalcular a quantidade de registros da grid quando a div do filtro ocultar
  // ou ficar visivel
  useEffect(() => {
    if (pageParams.stateScreen === StateScreen.stSearch) {
      calcLimit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcLimit]);

  // renders
  return (
    <Container className="container-page">
      <div className="p-grid">
        <CrudHeader
          title={getTitle()}
          disabledButtonInsert={!AuthService.checkRoles('CONFIG_AGENDA_INSERT')}
          showButtonFilter={pageParams.stateScreen === StateScreen.stSearch}
          showButtonInsert
          showButtonSearch
          titleFilterDefault="Nome"
          handleFilterDefault={text => {
            filter.tema = text;
            setFilter({ ...filter, tema: text });
            handleBuscar(filter);
          }}
          handleClearFilters={() => {
            setFilter({
              ...filter,
              id: 0,
              nome: '',
              idColaborador: undefined,
            });
            setColaborador(null);
          }}
          handleButtonInsert={() => history.push('/configAgendas/insert')}
          handleButtonSearch={() => handleBuscar(filter)}
        >
          <div className="p-col-12 p-sm-5 p-lg-5 p-fluid">
            <LabelSp>Profissional</LabelSp>
            <DropDownLazy
              autoLoad
              showClear
              placeholder="Todos"
              onChange={e => {
                setFilterAndSearch({ ...filter, idColaborador: e?.value });
                setColaborador(e);
              }}
              value={colaborador}
              onFilter={async txtFilter => {
                const retorno = await loadColaborador(txtFilter);
                return retorno;
              }}
            />
          </div>
        </CrudHeader>
        {pageParams.stateScreen === StateScreen.stSearch ? renderSearch() : renderCrud()}
      </div>
    </Container>
  );

  function renderSearch() {
    return (
      <>
        <div className="p-col-12 p-fluid">
          <DataTableSp
            value={configAgendas}
            style={{ marginBottom: '2px' }}
            paginator
            rows={pageLimit}
            lazy
            responsive
            totalRecords={totalRecords}
            first={first}
            onPage={onPage}
          >
            <Column field="nome" className="grid-col" header="Nome" />
            <Column field="colaboradorNome" className="grid-col" header="Profissional" />

            <Column
              className="grid-col-val"
              style={{ width: 100 }}
              header="Interv. Min."
              body={rowData => rowData.intervaloMinutos}
            />

            <Column
              className="grid-col grid-col-center"
              style={{ width: 90 }}
              header="Inativo"
              body={rowData => (rowData.inativo ? 'Sim' : 'Não')}
            />

            <Column
              className="gid-col-acoes-35"
              bodyStyle={{ textAlign: 'end' }}
              body={renderButtonOp}
            />
          </DataTableSp>
        </div>
      </>
    );
  }

  function renderButtonOp(rowData) {
    return (
      <BotaoMenuGrid
        handles={[
          () => history.push(`/configAgendas/${rowData.id}?view`),
          () => history.push(`/configAgendas/${rowData.id}`),
          () => confirmaExclusao(rowData.id),
        ]}
        labels={['Visualizar', 'Alterar', 'Excluir']}
        disableds={[
          !AuthService.checkRoles('CONFIG_AGENDA_VIEW'),
          !AuthService.checkRoles('CONFIG_AGENDA_UPDATE'),
          !AuthService.checkRoles('CONFIG_AGENDA_DELETE'),
        ]}
      />
    );
  }

  function renderCrud() {
    return (
      <ConfigAgendaCrud
        idSelected={pageParams.idSelected}
        stateScreen={pageParams.stateScreen}
        onClose={() => {
          history.push(toBack);
          handleBuscar(filter, pageSelected, false);
        }}
      />
    );
  }
}

/* eslint-disable no-nested-ternary */
import React, { useRef, useState } from 'react';

import PropTypes from 'prop-types';

import { Chart } from 'primereact/chart';

import {
  optionsGraficoAtendimentosPercTipo,
  optionsGraficoAtendimentosTipo,
  optionsGraficoAtMeses,
  optionsGraficoAtSemanas,
  optionsGraficoEducacaoMeses,
  optionsGraficoTeleorientacoesCategoria,
  optionsGraficoTeleorientacoesPercCategoria,
} from './chartConfig';

import 'chartjs-plugin-labels';
import { Card } from './styles';
import Panel from '../../components/Panel';

import GraficoView from '../../components/GraficoView';

function Page1(props) {
  Page1.propTypes = {
    tipoApuracao: PropTypes.number,
    tipoPeriodo: PropTypes.number,
    tipoTeleEducacao: PropTypes.string,
  };

  Page1.defaultProps = {
    required: false,
    autoFocus: false,
    tipoTeleEducacao: '',
  };
  const {
    tipoApuracao,
    tipoPeriodo,
    dataGraficoAtMeses,
    dataGraficoAtSemanas,
    dataGraficoAtendimentosTipo,
    dataGraficoAtendimentosPercTipo,
    dataGraficoTeleorientacoesCategoria,
    dataGraficoTeleorientacoesPercCategoria,
    dataGraficoEducacaoPartMeses,
    dataGraficoEducacaoMeses,
    tipoTeleEducacao,
  } = props;

  const chartAtendimentoMes = useRef();
  const chartAtendimentoSemana = useRef();
  const chartAtendimentoTipo = useRef();
  const chartAtendimentoTipoPerc = useRef();

  const chartTeleorientacaoTipo = useRef();
  const chartTeleorientacaoTipoPerc = useRef();

  const chartTeleorientacaoPartMes = useRef();
  const chartTeleorientacaoMes = useRef();

  const [graficoRefSelecionado, setGraficoRefSelecionado] = useState();
  const [graficoSelecionadoOptions, setGraficoSelecionadoOptions] = useState();
  const [graficoViewVisible, setGraficoViewVisible] = useState(false);

  const pCol = 'p-col-12 p-lg-6';
  let qtdTeleEducacao = 0;
  let qtdAtendimento = 0;
  let nomeTeleeducacao = 'Teleeducação';

  if (tipoTeleEducacao === '0') {
    nomeTeleeducacao = 'Web Palestras';
  } else if (tipoTeleEducacao === '1') {
    nomeTeleeducacao = 'Teleorientação';
  }

  if (
    dataGraficoTeleorientacoesCategoria &&
    dataGraficoTeleorientacoesCategoria.datasets &&
    Array.isArray(dataGraficoTeleorientacoesCategoria.datasets[0]?.data)
  ) {
    dataGraficoTeleorientacoesCategoria.datasets[0].data.forEach(e => {
      qtdTeleEducacao += e;
    });
  }

  if (
    dataGraficoAtendimentosTipo &&
    dataGraficoAtendimentosTipo.datasets &&
    Array.isArray(dataGraficoAtendimentosTipo.datasets[0]?.data)
  ) {
    dataGraficoAtendimentosTipo.datasets[0].data.forEach(e => {
      qtdAtendimento += e;
    });
  }

  return (
    <>
      {graficoViewVisible && (
        <GraficoView
          chartRef={graficoRefSelecionado}
          visible={graficoViewVisible}
          setVisible={setGraficoViewVisible}
          options={graficoSelecionadoOptions}
        />
      )}

      {tipoPeriodo !== 2 && (
        <>
          <div className={pCol}>
            <Card>
              <div className="div-title">
                <span className="title">Teleatendimentos: {qtdAtendimento}</span>
              </div>
              <span className="result-icon gren">
                <i className="pi pi-book" style={{ fontSize: 32 }} />
              </span>
            </Card>
          </div>

          <div className={pCol}>
            <Card>
              <div className="div-title">
                <span className="title">
                  Número de {nomeTeleeducacao}: {qtdTeleEducacao}
                </span>
              </div>
              <span className="result-icon red">
                <i className="pi pi-sitemap" style={{ fontSize: 32 }} />
              </span>
            </Card>
          </div>
        </>
      )}

      {tipoApuracao !== 3 && (
        <>
          {tipoPeriodo !== 3 && (
            <div className={pCol}>
              <Panel
                header="Número de Atendimentos por Mês"
                style={{ height: '100%' }}
                handleView={() => {
                  setGraficoViewVisible(true);
                  setGraficoRefSelecionado(chartAtendimentoMes);
                  setGraficoSelecionadoOptions({
                    ...optionsGraficoAtMeses,
                    title: {
                      ...optionsGraficoAtMeses.title,
                      text: 'Número de Atendimentos por Mês',
                    },
                  });
                }}
              >
                <Chart
                  ref={chartAtendimentoMes}
                  id="atendimento-mes"
                  type="bar"
                  data={dataGraficoAtMeses}
                  options={{
                    ...optionsGraficoAtMeses,
                    title: {
                      ...optionsGraficoAtMeses.title,
                      text: 'Número de Atendimentos por Mês',
                    },
                  }}
                  height="180"
                />
              </Panel>
            </div>
          )}

          {tipoPeriodo === 2 && (
            <div className={pCol}>
              <Panel
                header="Número de Atendimentos por Semana"
                style={{ height: '100%' }}
                handleView={() => {
                  setGraficoViewVisible(true);
                  setGraficoRefSelecionado(chartAtendimentoSemana);
                  setGraficoSelecionadoOptions({
                    ...optionsGraficoAtSemanas,
                    title: {
                      ...optionsGraficoAtSemanas.title,
                      text: 'Número de Atendimentos por Semana',
                    },
                  });
                }}
              >
                <Chart
                  ref={chartAtendimentoSemana}
                  id="atendimento-semana"
                  type="horizontalBar"
                  data={dataGraficoAtSemanas}
                  options={{
                    ...optionsGraficoAtSemanas,
                    title: {
                      ...optionsGraficoAtSemanas.title,
                      text: 'Número de Atendimentos por Semana',
                    },
                  }}
                  height="180"
                />
              </Panel>
            </div>
          )}

          <div className={pCol}>
            <Panel
              header="Atendimentos por tipo no período"
              style={{ height: '100%' }}
              handleView={() => {
                setGraficoViewVisible(true);
                setGraficoRefSelecionado(chartAtendimentoTipo);
                setGraficoSelecionadoOptions({
                  ...optionsGraficoAtendimentosTipo,
                  title: {
                    ...optionsGraficoAtendimentosTipo.title,
                    text: 'Atendimentos por tipo no período',
                  },
                });
              }}
            >
              <Chart
                ref={chartAtendimentoTipo}
                id="atendimento-total"
                type="horizontalBar"
                data={dataGraficoAtendimentosTipo}
                options={{
                  ...optionsGraficoAtendimentosTipo,
                  title: {
                    ...optionsGraficoAtendimentosTipo.title,
                    text: 'Atendimentos por tipo no período',
                  },
                }}
                height="180"
              />
            </Panel>
          </div>

          <div className={pCol}>
            <Panel
              header="Atendimentos por tipo no período"
              style={{ height: '100%' }}
              handleView={() => {
                setGraficoViewVisible(true);
                setGraficoRefSelecionado(chartAtendimentoTipoPerc);
                setGraficoSelecionadoOptions({
                  ...optionsGraficoAtendimentosPercTipo,
                  title: {
                    ...optionsGraficoAtendimentosPercTipo.title,
                    text: 'Atendimentos por tipo no período',
                  },
                });
              }}
            >
              <Chart
                ref={chartAtendimentoTipoPerc}
                id="atendimento-total-perc1"
                type="doughnut"
                data={dataGraficoAtendimentosPercTipo}
                options={{
                  ...optionsGraficoAtendimentosPercTipo,
                  title: {
                    ...optionsGraficoAtendimentosPercTipo.title,
                    text: 'Atendimentos por tipo no período',
                  },
                }}
                height="180"
              />
            </Panel>
          </div>
        </>
      )}
      <>
        {tipoApuracao !== 2 && (
          <>
            <div className={pCol}>
              <Panel
                header={`${nomeTeleeducacao} por profissional no período`}
                style={{ height: '100%' }}
                handleView={() => {
                  setGraficoViewVisible(true);
                  setGraficoRefSelecionado(chartTeleorientacaoTipo);
                  setGraficoSelecionadoOptions({
                    ...optionsGraficoTeleorientacoesCategoria,
                    title: {
                      ...optionsGraficoTeleorientacoesCategoria.title,
                      text: `${nomeTeleeducacao} por profissional no período`,
                    },
                  });
                }}
              >
                <Chart
                  ref={chartTeleorientacaoTipo}
                  id="teleorientacao-total"
                  type="horizontalBar"
                  data={dataGraficoTeleorientacoesCategoria}
                  options={{
                    ...optionsGraficoTeleorientacoesCategoria,
                    title: {
                      ...optionsGraficoTeleorientacoesCategoria.title,
                      text: `${nomeTeleeducacao} por profissional no período`,
                    },
                  }}
                  height="180"
                />
              </Panel>
            </div>

            <div className={pCol}>
              <Panel
                header={`${nomeTeleeducacao} por profissional no período`}
                style={{ height: '100%' }}
                handleView={() => {
                  setGraficoViewVisible(true);
                  setGraficoRefSelecionado(chartTeleorientacaoTipoPerc);
                  setGraficoSelecionadoOptions({
                    ...optionsGraficoTeleorientacoesPercCategoria,
                    title: {
                      ...optionsGraficoTeleorientacoesPercCategoria.tCategoriaoptionsGraficoTeleorientacoesPercCategoria,
                      text: `${nomeTeleeducacao} por profissional no período`,
                    },
                  });
                }}
              >
                <Chart
                  ref={chartTeleorientacaoTipoPerc}
                  id="teleorientacao-total-perc1"
                  type="doughnut"
                  data={dataGraficoTeleorientacoesPercCategoria}
                  options={{
                    ...optionsGraficoTeleorientacoesPercCategoria,
                    title: {
                      ...optionsGraficoTeleorientacoesPercCategoria.tCategoriaoptionsGraficoTeleorientacoesPercCategoria,
                      text: `${nomeTeleeducacao} por profissional no período`,
                    },
                  }}
                  height="180"
                />
              </Panel>
            </div>
            {tipoPeriodo !== 3 && (
              <>
                <div className={pCol}>
                  <Panel
                    header={`Nr. de participantes em ${nomeTeleeducacao} por Mês`}
                    style={{ height: '100%' }}
                    handleView={() => {
                      setGraficoViewVisible(true);
                      setGraficoRefSelecionado(chartTeleorientacaoPartMes);
                      setGraficoSelecionadoOptions({
                        ...optionsGraficoEducacaoMeses,
                        title: {
                          ...optionsGraficoEducacaoMeses.title,
                          text: `Nr. de participantes em ${nomeTeleeducacao} por Mês`,
                        },
                      });
                    }}
                  >
                    <Chart
                      ref={chartTeleorientacaoPartMes}
                      id="educacao-mes"
                      type="bar"
                      data={dataGraficoEducacaoPartMeses}
                      options={{
                        ...optionsGraficoEducacaoMeses,
                        title: {
                          ...optionsGraficoEducacaoMeses.title,
                          text: `Nr. de participantes em ${nomeTeleeducacao} por Mês`,
                        },
                      }}
                      height="180"
                    />
                  </Panel>
                </div>

                <div className={pCol}>
                  <Panel
                    header={`Nr. de ${nomeTeleeducacao} por Mês`}
                    style={{ height: '100%' }}
                    handleView={() => {
                      setGraficoViewVisible(true);
                      setGraficoRefSelecionado(chartTeleorientacaoMes);
                      setGraficoSelecionadoOptions({
                        ...optionsGraficoEducacaoMeses,
                        title: {
                          ...optionsGraficoEducacaoMeses.title,
                          text: `Nr. de ${nomeTeleeducacao} por Mês`,
                        },
                      });
                    }}
                  >
                    <Chart
                      ref={chartTeleorientacaoMes}
                      id="educacao-mes"
                      type="bar"
                      data={dataGraficoEducacaoMeses}
                      options={{
                        ...optionsGraficoEducacaoMeses,
                        title: {
                          ...optionsGraficoEducacaoMeses.title,
                          text: `Nr. de ${nomeTeleeducacao} por Mês`,
                        },
                      }}
                      height="180"
                    />
                  </Panel>
                </div>
              </>
            )}
          </>
        )}
      </>
    </>
  );
}

export default Page1;

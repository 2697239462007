/* eslint-disable jsx-a11y/label-has-associated-control */
import { Checkbox } from 'primereact/checkbox';
import React from 'react';

// import { Container } from './styles';

export default function CheckboxSp(props) {
  const { caption, inputId, styleLabel, ...rest } = props;

  return (
    <div style={{ margin: 0, padding: 0, paddingBottom: 1 }}>
      <Checkbox inputId={inputId} {...rest}>
        {props.children}
      </Checkbox>
      {caption && (
        <label htmlFor={inputId} className="p-checkbox-label" style={{ ...styleLabel }}>
          {caption}
        </label>
      )}
    </div>
  );
}

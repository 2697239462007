import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import ButtonSp from '../../components/ButtonSp';

import InputTextSp from '../../components/InputTextSp';
import LabelSp from '../../components/LabelSp';
import { showMessage } from '../../components/MessageDialog';

import { errorHandle, isDate, padLeft, validateFields } from '../../util/functions';
import { FeriadoModel } from '../../util/Models';
import { StateScreen } from '../constants';
import FeriadoService from '../../services/FeriadoService';
import { listaUfs } from '../../config/Constantes';

import DropdownSp from '../../components/DropdownSp';
import DropDownLazy from '../../components/DropdownLazy';
import MunicipioService from '../../services/MunicipioService';
// import AuthService from '../../services/AuthService';
import history from '../../services/history';
import InputMaskSp from '../../components/InputMaskSp';

function FeriadoCrud(props) {
  FeriadoCrud.propTypes = {
    stateScreen: PropTypes.string.isRequired,
    idSelected: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    closeOnSaveCancel: PropTypes.bool,
  };

  FeriadoCrud.defaultProps = {
    messageConfirmation: 'Confirma os dados',
    showConfirmation: false,
    closeOnSaveCancel: false,
  };

  const { stateScreen, idSelected, onClose, closeOnSaveCancel } = props;

  // states
  const [feriado, setFeriado] = useState(new FeriadoModel());
  const [errorLoadRecord, setErrorLoadRecord] = useState(false);
  const [ufSelecionada, setUfSelecionada] = useState('AM');
  const [municipioSelecionado, setMunicipioSelecionado] = useState();

  const viewMode = useCallback(() => {
    return stateScreen === StateScreen.stView || errorLoadRecord;
  }, [errorLoadRecord, stateScreen]);

  const loadRecord = useCallback(async _id => {
    try {
      const retorno = await FeriadoService.findById(_id);
      if (retorno.dia && retorno.mes) {
        retorno.diaMes = `${padLeft(retorno.dia, 2)}/${padLeft(retorno.mes, 2)}`;
      }
      const municipio = retorno.municipio
        ? {
            label: retorno.municipio.nome,
            value: retorno.municipio.id,
          }
        : null;
      setMunicipioSelecionado(municipio);
      setUfSelecionada(retorno.municipio ? retorno.municipio.uf : 'AM');

      setFeriado(retorno);
      setErrorLoadRecord(false);
    } catch (err) {
      setErrorLoadRecord(true);
      errorHandle(err);
    }
  }, []);

  const loadMunicipio = useCallback(async (_nome, uf) => {
    if (_nome !== undefined) {
      const r = await MunicipioService.findAll({ nome: _nome, uf, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  // funcoes

  function handleBack() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      showMessage('Confirmação', 'Abandonar mudanças?', idx => {
        if (idx === 1) {
          if (stateScreen === StateScreen.stInsert || closeOnSaveCancel) {
            onClose();
          } else {
            history.push(`/feriados/${idSelected}?view`);
          }
        }
      });
    }
  }

  function handleSave() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      salvarRecord();
    }
  }

  async function salvarRecord() {
    try {
      let retorno;

      const [, mes] = feriado.diaMes.split('/');
      feriado.mes = mes;
      const [dia] = feriado.diaMes.split('/');
      feriado.dia = dia;

      if (!isDate(`2022-${padLeft(feriado.mes, 2)}-${padLeft(feriado.dia, 2)}`)) {
        toast.error('Data inválida.');
        return;
      }

      // '2022/'+padLeft(feriado.mes,2)+padLeft(feriado.dia,2)

      if (stateScreen === StateScreen.stInsert) {
        retorno = await FeriadoService.insert(feriado);
      } else {
        retorno = await FeriadoService.update(feriado);
      }
      toast.success('Registro salvo com sucesso.');
      if (closeOnSaveCancel) {
        onClose(retorno);
      } else {
        history.push(`/feriados/${retorno.id}?view`);
      }
    } catch (err) {
      errorHandle(err);
    }
  }

  // useEffects
  useEffect(() => {
    if (stateScreen === StateScreen.stUpdate || stateScreen === StateScreen.stView) {
      loadRecord(idSelected);
    } else if (stateScreen === StateScreen.stInsert) {
      const novo = new FeriadoModel();

      setFeriado(novo);
    }
  }, [loadRecord, idSelected, stateScreen]);

  // render principal
  return (
    <>
      <>
        <div className="p-col-12 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Dia/Mês</LabelSp>
          <InputMaskSp
            mask="99/99"
            required
            value={feriado.diaMes}
            disabled={viewMode()}
            onChange={e => {
              setFeriado({ ...feriado, diaMes: e.target.value });
            }}
          />
        </div>
        <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
          <LabelSp>Nome</LabelSp>
          <InputTextSp
            value={feriado.nome}
            maxLength={100}
            required
            disabled={viewMode()}
            onChange={e => {
              setFeriado({ ...feriado, nome: e.target.value });
            }}
          />
        </div>

        <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
          <LabelSp>Abrangencia</LabelSp>
          <DropdownSp
            required
            value={feriado.abrangencia}
            options={[
              { value: 0, label: 'Federal' },
              { value: 1, label: 'Municipal' },
              { value: 2, label: 'Estadual' },
            ]}
            disabled={viewMode()}
            filterInputAutoFocus={false}
            onChange={e => {
              const municipio = feriado.abrangencia !== 1 ? feriado.municipio : undefined;
              const idMunicipio = feriado.abrangencia !== 1 ? feriado.idMunicipio : null;
              setMunicipioSelecionado(municipio);
              setFeriado({ ...feriado, municipio, idMunicipio, abrangencia: e.target.value });
            }}
          />
        </div>

        <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
          <LabelSp>UF</LabelSp>
          <DropdownSp
            disabled={viewMode() || feriado.abrangencia !== 1}
            placeholder="Selecione"
            value={ufSelecionada}
            options={listaUfs}
            filter
            onChange={e => setUfSelecionada(e.target.value)}
          />
        </div>

        <div className="p-col-12 p-sm-6 p-lg-6 p-fluid">
          <LabelSp>Município</LabelSp>
          <DropDownLazy
            autoLoad
            showClear
            disabled={viewMode() || feriado.abrangencia !== 1}
            placeholder="Todos"
            onChange={e => {
              const municipio = e ? { id: e.value, nome: e.label } : null;
              setFeriado({
                ...feriado,
                idMunicipio: e?.value,
                municipio,
              });
            }}
            value={municipioSelecionado}
            onFilter={async txtFilter => {
              const retorno = await loadMunicipio(txtFilter, ufSelecionada);
              return retorno;
            }}
          />
        </div>

        <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Inativo?</LabelSp>
          <DropdownSp
            required
            disabled={viewMode()}
            placeholder="Selecione"
            value={feriado.inativo}
            options={[
              { value: false, label: 'Não' },
              { value: true, label: 'Sim' },
            ]}
            onChange={e => setFeriado({ ...feriado, inativo: e.target.value })}
          />
        </div>

        <div className="p-col-12 p-lg-12" style={{ textAlign: 'start' }}>
          {!viewMode() ? (
            <ButtonSp
              className="p-button-success"
              icon="pi pi-save"
              label="Salvar"
              disabled={
                !validateFields(feriado, [
                  'nome',
                  'diaMes',
                  feriado.abrangencia === 1 ? 'idMunicipio' : '',
                ])
              }
              showConfirmation
              onClick={handleSave}
            />
          ) : null}

          {/* {viewMode() ? (
            <ButtonSp
              className="p-button-primary"
              icon="pi pi-pencil"
              label="Alterar"
              disabled={!AuthService.checkRoles('FERIADO_UPDATE')}
              onClick={() => history.push(`/feriados/${idSelected}`)}
            />
          ) : null} */}

          <ButtonSp
            // className={viewMode() ? 'p-button-secondary' : 'p-button-danger'}
            // label={viewMode() ? 'Voltar' : 'Cancelar'}

            className="p-button-secondary"
            label="Voltar"
            icon="pi pi-chevron-circle-left"
            onClick={handleBack}
          />
        </div>
      </>
    </>
  );
}

export default FeriadoCrud;

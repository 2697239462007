import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import PropTypes from 'prop-types';
import { Container } from './styles';

export default function SimplesMessageDialog(props) {
  SimplesMessageDialog.propTypes = {
    title: PropTypes.string,
    visible: PropTypes.bool,
    setVisible: PropTypes.func,
    children: PropTypes.node.isRequired,
  };
  const { visible, title, setVisible, children } = props;

  const footer = (
    <div>
      <Button
        label="Ok"
        icon="pi pi-check"
        onClick={() => {
          if (setVisible) {
            setVisible(false);
          }
        }}
        className="p-button-success"
      />
    </div>
  );

  return (
    <Container>
      <Dialog
        closable={false}
        footer={footer}
        header={title || 'Informação'}
        visible={visible}
        style={{ minWidth: '350px', maxWidth: '450px', width: '95%' }}
        modal
        onHide={() => {
          if (setVisible) {
            setVisible(false);
          }
        }}
      >
        {children}
      </Dialog>
    </Container>
  );
}

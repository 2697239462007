/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { Column } from 'primereact/column';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { addDays, addHours } from 'date-fns';
import { Container } from './styles';

import AgendamentoService from '../../services/AgendamentoService';
import AuthService from '../../services/AuthService';
import history from '../../services/history';
import {
  getPageParams,
  errorHandle,
  calcNaxItemsPage,
  isScreenMobile,
  formatDate,
  isNumber,
  cloneObj,
  newDateOnly,
} from '../../util/functions';
import LabelSp from '../../components/LabelSp';

import { showMessage } from '../../components/MessageDialog';

import BotaoMenuGrid from '../../components/BotaoMenuGrid';

import AgendamentoCrud from './crud';
import { StateScreen } from '../constants';
import CrudHeader from '../../components/CrudHeader';
import DataTableSp from '../../components/DataTableSp';
import DropDownLazy from '../../components/DropdownLazy';
import ColaboradorService from '../../services/ColaboradorService';
import MultiSelectSp from '../../components/MultiSelectSp';

import AtendimentoTipoService from '../../services/AtendimentoTipoService';
import ComunidadeService from '../../services/ComunidadeService';
import CalendarSp from '../../components/CalendarSp';
import { cfgPtBr } from '../../config/Constantes';
import InputMaskSp from '../../components/InputMaskSp';
import { SitAtAgendamento, TpColaboradorPerfil } from '../../util/Models';
import SimplesMessageDialog from '../../components/SimplesMessageDialog';
import {
  handleContatoSolicitante,
  handleLinkVideoConferencia,
  renderContatoSolicitante,
  renderLinkVideoConferencia,
} from './renders';

export default function Agendamento(props) {
  // useSelectors
  const filterVisible = useSelector(state => state.global.filterVisible);

  // useMemo
  const pageParams = useMemo(() => getPageParams(props.match.params, props.location.search), [
    props.location.search,
    props.match.params,
  ]);

  // const filterService = useMemo(() => AgendamentoService.getFilter(), []);
  let colaboradorSolic;
  const filterService = useMemo(() => {
    const colaboradorLogado = AuthService.getColaborador();
    const f = AgendamentoService.getFilter();
    if (colaboradorLogado && colaboradorLogado.municipioAcesso) {
      f.idMunicipio = colaboradorLogado.municipioAcesso.id;
      f.municipioAcesso = {
        label: colaboradorLogado.municipioAcesso.nome,
        value: colaboradorLogado.municipioAcesso.id,
      };
    } else {
      f.municipioAcesso = undefined;
    }

    if (
      colaboradorLogado &&
      colaboradorLogado.idColaboradorPerfil === TpColaboradorPerfil.AGENTE_SAUDE_RIBEIRINHO
    ) {
      const colaboradorSolicitanteSelecionado = {
        label: colaboradorLogado.nome,
        value: colaboradorLogado.id,
      };
      f.idColaboradorSolicitante = colaboradorLogado.id;
      // eslint-disable-next-line react-hooks/exhaustive-deps
      colaboradorSolic = colaboradorSolicitanteSelecionado;
    }

    return f;
  }, []);

  // useStates
  const toBack = pageParams.toBack || '/agendamentos';

  const [filter, setFilter] = useState(filterService);
  const [agendamentos, setAgendamentos] = useState([]);

  const [colaborador, setColaborador] = useState(null);
  const [colaboradorSolicitante, setColaboradorSolicitante] = useState(
    colaboradorSolic || null
  );
  const [comunidade, setComunidade] = useState(null);
  const [atendimentoTipo, setAtendimentoTipo] = useState(null);

  const [pageLimit, setPageLimit] = useState(filterService.limit);
  const [first, setFirst] = useState(0);
  const [pageSelected, setPageSelected] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [funcTimeOut, setFuncTimeOut] = useState();
  const [showContatoSolicitante, setShowContatoSolicitante] = useState(false);
  const [contatoSolicitante, setContatoSolicitante] = useState(false);
  const [linkVideoConferencia, setLinkVideoConferencia] = useState(false);
  const [showLinkVideoConferencia, setShowLinkVideoConferencia] = useState();

  const [listaSituacaoSelecionada, setListaSituacaoSelecionada] = useState(
    JSON.parse(filter.listaSituacao)
  );

  const listaSituacao = [
    { value: '0', label: 'Aguardando' },
    { value: '1', label: 'Em Andamento' },
    { value: '2', label: 'Realizado' },
    { value: '3', label: 'Não realizado' },
  ];

  // useCallbacks
  const loadColaborador = useCallback(async (_nome, atendente = true) => {
    if (_nome !== undefined) {
      const r = await ColaboradorService.findAll({
        nome: _nome,
        limit: 200,
        listaIdCategorias: atendente ? '[1,2,3]' : '',
      });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadComunidade = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await ComunidadeService.findAll({ nome: _nome, limit: 200 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadAtendimentoTipo = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await AtendimentoTipoService.findAll({ nome: _nome, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const calcLimit = useCallback(() => {
    let ret;
    if (filterVisible) {
      ret = calcNaxItemsPage(7, 11, 16);
    } else {
      ret = calcNaxItemsPage(14, 18, 23);
    }
    setPageLimit(ret);
    return ret;
  }, [filterVisible]);

  const handleBuscar = useCallback(
    async (_filter, _page, resetPage = true) => {
      const f = cloneObj(_filter);

      f.page = _page || 0;
      f.limit = calcLimit();
      f.dataFim = new Date(f.dataFim);
      f.dataInicio = new Date(f.dataInicio);
      f.dataFim = addHours(f.dataFim, 23);

      try {
        const result = await AgendamentoService.findAll(f);
        setAgendamentos(result.items);

        setTotalRecords(result.totalRecords);
        if (resetPage) {
          setFirst(0);
        }
      } catch (err) {
        errorHandle(err);
      }
    },
    [calcLimit]
  );

  const setFilterAndSearch = useCallback(
    async _filterValue => {
      if (JSON.stringify(_filterValue) !== JSON.stringify(filter)) {
        setFilter(_filterValue);

        if (funcTimeOut) {
          clearTimeout(funcTimeOut);
        }
        const func = setTimeout(async () => {
          handleBuscar(_filterValue);
        }, 800);
        setFuncTimeOut(func);
      }
    },
    [filter, funcTimeOut, handleBuscar]
  );

  function onPage(event) {
    const pagina = event.first / event.rows;
    setPageSelected(pagina);
    setFirst(event.first);
    handleBuscar(filter, pagina, false);
  }

  function validateAlteracaoExclusao(registro, apenasExclusao) {
    if (
      registro.situacao === SitAtAgendamento.REALIZADO ||
      registro.situacao === SitAtAgendamento.EM_ANDAMENTO
    ) {
      toast.error(
        'Não será possível alterar/excluir o agendamento, pois o atendimento já foi realizado o atendimento.'
      );
      return false;
    }

    if (apenasExclusao && registro.idEducacao) {
      toast.error(
        'Não será possível excluir o agendamento, pois existe uma Triagem vinculada.'
      );
      return false;
    }
    return true;
  }

  function validateAuthColaborador(idColaborador, mensagem = '') {
    if (
      AuthService.getColaborador().id !== idColaborador &&
      AuthService.getColaborador().idColaboradorPerfil !== TpColaboradorPerfil.ADMINISTRADOR &&
      AuthService.getColaborador().idColaboradorPerfil !== TpColaboradorPerfil.GESTOR_FAS &&
      AuthService.getColaborador().idColaboradorPerfil !==
        TpColaboradorPerfil.PROFISSIONAL_SAUDE
    ) {
      const msg =
        mensagem ||
        'Você não tem permissão para alterar/excluir/atender um agendamento de outros solicitantes.';
      toast.error(msg);
      return false;
    }
    return true;
  }

  function validateAuthColaboradorProfissional(idColaborador) {
    if (
      AuthService.getColaborador().id !== idColaborador &&
      AuthService.getColaborador().idColaboradorPerfil !== TpColaboradorPerfil.ADMINISTRADOR &&
      AuthService.getColaborador().idColaboradorPerfil !== TpColaboradorPerfil.GESTOR_FAS
    ) {
      toast.error('Você não tem permissão para iniciar atendimento de outro profissional.');
      return false;
    }
    return true;
  }

  // useCallbacks
  const excluirRegistro = useCallback(
    async _id => {
      try {
        await AgendamentoService.delete(_id);
        toast.success('Registro excluído com sucesso.');
        handleBuscar(filter);
      } catch (err) {
        errorHandle(err);
      }
    },
    [filter, handleBuscar]
  );

  const iniciaAtendimento = useCallback(
    async registro => {
      try {
        const atendimento = await AgendamentoService.iniciaAtendimento(registro);
        showMessage(
          'Confirmação',
          'O atendimento foi iniciado, deseja acessar-lo agora?',
          idx => {
            if (idx === 1) {
              history.push(`/atendimentos/${atendimento.id}?view&toBack=/agendamentos`);
            }
          }
        );
        toast.success('Atendimento iniciado com sucesso.');
        handleBuscar(filter);
      } catch (err) {
        errorHandle(err);
      }
    },
    [filter, handleBuscar]
  );

  const iniciaTriagem = useCallback(
    async registro => {
      try {
        const educacao = await AgendamentoService.iniciaTriagem(registro);
        showMessage('Confirmação', 'A triagem foi lançada, deseja acessar-la agora?', idx => {
          if (idx === 1) {
            history.push(`/educacoes/${educacao.id}?view&toBack=/agendamentos`);
          }
        });
        toast.success('Triagem lançada com sucesso.');
        handleBuscar(filter);
      } catch (err) {
        errorHandle(err);
      }
    },
    [filter, handleBuscar]
  );

  const confirmaInicioAtendimento = useCallback(
    registro => {
      if (registro.situacao !== SitAtAgendamento.AGUARDANDO) {
        toast.error(
          'Agendamento deve estar com status "Aguardando" para iniciar um atendimento.'
        );
        return;
      }

      showMessage(
        'Confirmação',
        'Deseja abrir um atendimento para o agendamento selecionado?',
        idx => {
          if (idx === 1) {
            iniciaAtendimento(registro);
          }
        }
      );
    },
    [iniciaAtendimento]
  );

  const confirmaInicioTriagem = useCallback(
    registro => {
      if (registro.situacao === SitAtAgendamento.NAO_REALIZADO) {
        toast.error(
          'Agendamento não pode estar com status "Não Realizado" para iniciar um atendimento.'
        );
        return;
      }

      showMessage(
        'Confirmação',
        'Deseja lançar a Tiagem para o agendamento selecionado?',
        idx => {
          if (idx === 1) {
            iniciaTriagem(registro);
          }
        }
      );
    },
    [iniciaTriagem]
  );

  const confirmaExclusao = useCallback(
    id => {
      showMessage('Confirmação', 'Confirma a exclusão do registro?', idx => {
        if (idx === 1) {
          excluirRegistro(id);
        }
      });
    },
    [excluirRegistro]
  );

  // functions

  // function corStatus(status) {
  //   switch (status) {
  //     case 0:
  //       return '#ee3902';
  //     case 1:
  //       return '#6ecc65';
  //     case 2:
  //       return '#007ad9';
  //     case 3:
  //       return '#ebd300';
  //     default:
  //       return ' ';
  //   }
  // }

  function corStatus(status) {
    switch (status) {
      case SitAtAgendamento.AGUARDANDO:
        return '#007ad9';
      case SitAtAgendamento.EM_ANDAMENTO:
        return '#ebd300';
      case SitAtAgendamento.REALIZADO:
        return '#6ecc65';
      case SitAtAgendamento.NAO_REALIZADO:
        return '#ee3902';
      default:
        return '#c8c8c8';
    }
  }

  function getTitle() {
    const titleDefault = 'Agendamento';
    let titleAdd = '';

    if (pageParams.stateScreen === StateScreen.stSearch) {
      titleAdd = '';
    }
    if (pageParams.stateScreen === StateScreen.stInsert) {
      titleAdd = '(Novo)';
    }
    if (pageParams.stateScreen === StateScreen.stUpdate) {
      titleAdd = `(Alterando Id: ${pageParams.idSelected})`;
    }
    if (pageParams.stateScreen === StateScreen.stView) {
      titleAdd = ` (Visualizando Id: ${pageParams.idSelected})`;
    }

    if (!isScreenMobile()) {
      return `${titleDefault} ${titleAdd}`;
    }

    return titleDefault;
  }

  // useEffects
  useEffect(() => {
    if (pageParams.stateScreen === StateScreen.stSearch) {
      handleBuscar(filter, 0, true);
    }
    // desativado para evitar que a cada vez que o agendamento digitasse o sistema buscasse
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageParams.stateScreen]);

  // useEffects
  // Responsavel por recalcular a quantidade de registros da grid quando a div do filtro ocultar
  // ou ficar visivel
  useEffect(() => {
    if (pageParams.stateScreen === StateScreen.stSearch) {
      calcLimit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcLimit]);

  const agenteDeSaude =
    AuthService.getColaborador().idColaboradorPerfil ===
    TpColaboradorPerfil.AGENTE_SAUDE_RIBEIRINHO;

  // renders
  return (
    <Container className="container-page">
      <div className="p-grid">
        {showContatoSolicitante ? (
          <SimplesMessageDialog
            title="Contato do Solicitante"
            visible={showContatoSolicitante}
            setVisible={setShowContatoSolicitante}
          >
            {renderContatoSolicitante(contatoSolicitante)}
          </SimplesMessageDialog>
        ) : null}

        {showLinkVideoConferencia ? (
          <SimplesMessageDialog
            title="Link da sala de videoconferência"
            visible={showLinkVideoConferencia}
            setVisible={setShowLinkVideoConferencia}
          >
            {renderLinkVideoConferencia(linkVideoConferencia)}
          </SimplesMessageDialog>
        ) : null}

        <CrudHeader
          title={getTitle()}
          disabledButtonInsert={!AuthService.checkRoles('AGENDAMENTO_INSERT')}
          showButtonFilter={pageParams.stateScreen === StateScreen.stSearch}
          showButtonInsert
          showButtonSearch
          titleFilterDefault="Paciente"
          handleFilterDefault={text => {
            filter.pacienteNome = text;
            setFilter({ ...filter, pacienteNome: text });
            handleBuscar(filter);
          }}
          handleClearFilters={() => {
            let { idColaboradorSolicitante, colaboradorSolicitanteNome } = filter;
            if (
              AuthService.getColaborador().idColaboradorPerfil !==
              TpColaboradorPerfil.AGENTE_SAUDE_RIBEIRINHO
            ) {
              idColaboradorSolicitante = undefined;
              colaboradorSolicitanteNome = '';
            }

            setFilter({
              ...filter,
              id: 0,
              nome: '',
              idColaborador: undefined,
              idComunidade: undefined,
              idColaboradorSolicitante,
              idAgendamentoTipo: undefined,
              idPaciente: undefined,
              dataInicio: addDays(newDateOnly(), -1),
              dataFim: addDays(newDateOnly(), 30),
              pacienteNome: undefined,
              colaboradorNome: undefined,
              colaboradorSolicitanteNome,
              cartaoSus: undefined,
              cpf: undefined,
              listaSituacao: '["0", "1", "2", "3"]',
            });

            setComunidade(null);
            setColaborador(null);
            setAtendimentoTipo(null);
            if (
              AuthService.getColaborador().idColaboradorPerfil !==
              TpColaboradorPerfil.AGENTE_SAUDE_RIBEIRINHO
            ) {
              setColaboradorSolicitante(null);
            }
            setListaSituacaoSelecionada(JSON.parse('["0", "1", "2", "3"]'));
          }}
          handleButtonInsert={() => history.push('/agendamentos/insert')}
          handleButtonSearch={() => handleBuscar(filter)}
        >
          <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
            <LabelSp htmlFor="dataInicial">De: </LabelSp>
            <CalendarSp
              readOnlyInput
              appendTo={document.body}
              dateFormat="dd/mm/yy"
              yearNavigator
              locale={cfgPtBr}
              id="dataInicial"
              value={filter.dataInicio}
              yearRange="2010:2040"
              onChange={e => {
                setFilterAndSearch({ ...filter, dataInicio: e.value });
              }}
            />
          </div>
          <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
            <LabelSp htmlFor="dataFinal">Até: </LabelSp>
            <CalendarSp
              appendTo={document.body}
              readOnlyInput
              locale={cfgPtBr}
              id="dataFinal"
              dateFormat="dd/mm/yy"
              value={filter.dataFim}
              yearNavigator
              yearRange="2010:2040"
              onChange={e => setFilterAndSearch({ ...filter, dataFim: e.value })}
            />
          </div>
          <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
            <LabelSp>CPF</LabelSp>
            <InputMaskSp
              mask="999.999.999-99"
              value={filter.cpf}
              onChange={e => {
                setFilterAndSearch({ ...filter, cpf: e.target.value });
              }}
            />
          </div>

          <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
            <LabelSp>Cartão SUS</LabelSp>
            <InputMaskSp
              mask="999.9999.9999.9999"
              value={filter.cartaoSus}
              onChange={e => {
                setFilterAndSearch({ ...filter, cartaoSus: e.target.value });
              }}
            />
          </div>

          <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
            <LabelSp>Tipo Atendimento</LabelSp>
            <DropDownLazy
              showClear
              autoLoad
              placeholder="Todos"
              onChange={e => {
                setFilterAndSearch({ ...filter, idAtendimentoTipo: e?.value });
                setAtendimentoTipo(e);
              }}
              value={atendimentoTipo}
              onFilter={async txtFilter => {
                const retorno = await loadAtendimentoTipo(txtFilter);
                return retorno;
              }}
            />
          </div>
          <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
            <LabelSp>Local Atendimento</LabelSp>
            <DropDownLazy
              autoLoad
              showClear
              placeholder="Todos"
              onChange={e => {
                setFilterAndSearch({ ...filter, idComunidade: e?.value });
                setComunidade(e);
              }}
              value={comunidade}
              onFilter={async txtFilter => {
                const retorno = await loadComunidade(txtFilter);
                return retorno;
              }}
            />
          </div>

          <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
            <LabelSp>Solicitante</LabelSp>
            <DropDownLazy
              autoLoad
              showClear
              placeholder="Todos"
              disabled={
                AuthService.getColaborador().idColaboradorPerfil ===
                TpColaboradorPerfil.AGENTE_SAUDE_RIBEIRINHO
              }
              onChange={e => {
                setFilterAndSearch({ ...filter, idColaboradorSolicitante: e?.value });
                setColaboradorSolicitante(e);
              }}
              value={colaboradorSolicitante}
              onFilter={async txtFilter => {
                const retorno = await loadColaborador(txtFilter, false);
                return retorno;
              }}
            />
          </div>
          <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
            <LabelSp>Profissional</LabelSp>
            <DropDownLazy
              autoLoad
              showClear
              placeholder="Todos"
              onChange={e => {
                setFilterAndSearch({ ...filter, idColaborador: e?.value });
                setColaborador(e);
              }}
              value={colaborador}
              onFilter={async txtFilter => {
                const retorno = await loadColaborador(txtFilter);
                return retorno;
              }}
            />
          </div>

          <div className="p-col-12 p-sm-12 p-lg-12 p-fluid">
            <LabelSp>Situação Agendamento</LabelSp>
            <MultiSelectSp
              value={listaSituacaoSelecionada}
              options={listaSituacao}
              selectedItemTemplate={selectedSituacaoTemplate}
              maxSelectedLabels={4}
              onChange={e => {
                const retorno = Array.isArray(e.value) ? e.value.sort() : [];
                setListaSituacaoSelecionada(retorno);
                setFilterAndSearch({ ...filter, listaSituacao: JSON.stringify(retorno) });
              }}
            />
          </div>
        </CrudHeader>
        {pageParams.stateScreen === StateScreen.stSearch ? renderSearch() : renderCrud()}
      </div>
    </Container>
  );

  function renderSearch() {
    return (
      <>
        <div className="p-col-12 p-fluid">
          <DataTableSp
            value={agendamentos}
            style={{ marginBottom: '2px' }}
            paginator
            rows={pageLimit}
            lazy
            responsive
            totalRecords={totalRecords}
            first={first}
            onPage={onPage}
          >
            <Column
              field="id"
              header=" "
              style={{ width: 30 }}
              body={rowData => {
                const cor = corStatus(rowData.situacao);
                return (
                  <div
                    title={listaSituacao[rowData.situacao].label}
                    className="div-status"
                    style={{
                      background: cor,
                      color: cor,
                    }}
                  >
                    &nbsp;
                  </div>
                );
              }}
            />

            <Column
              field="dataHoraAgendamento"
              className="grid-col-data-hora"
              header="Data/Hora"
              body={rowData => formatDate(rowData.dataHoraAgendamento, 'dd/MM/yyyy HH:mm')}
            />
            <Column
              field="atendimentoTipoNome"
              className="grid-col"
              header="Tp. Atendimento"
              style={{ width: 130 }}
            />
            <Column field="colaboradorNome" className="grid-col" header="Profissional" />
            <Column field="pacienteNome" className="grid-col" header="Paciente" />
            <Column
              field="comunidadeAtendimentoNome"
              className="grid-col"
              header="Local Atendimento"
            />
            <Column
              field="id"
              className="grid-col grid-col-center grid-col-data"
              header="Triagem?"
              body={rowData => {
                return rowData.idEducacao && <span className="pi pi-check-square" />;
              }}
            />

            <Column
              className="gid-col-acoes-35"
              bodyStyle={{ textAlign: 'end' }}
              body={renderButtonOp}
            />
          </DataTableSp>
        </div>
      </>
    );
  }

  function renderButtonOp(rowData) {
    const temAtendimento =
      rowData.situacao === SitAtAgendamento.REALIZADO ||
      rowData.situacao === SitAtAgendamento.EM_ANDAMENTO;

    const temTeleEducacao = !!rowData.idEducacao;

    return (
      <BotaoMenuGrid
        handles={[
          () => history.push(`/agendamentos/${rowData.id}?view`),
          () => {
            if (!validateAuthColaborador(rowData.idColaboradorSolicitante)) {
              return;
            }
            if (!validateAlteracaoExclusao(rowData)) {
              return;
            }
            history.push(`/agendamentos/${rowData.id}`);
          },
          () => {
            if (!validateAuthColaborador(rowData.idColaboradorSolicitante)) {
              return;
            }
            if (!validateAlteracaoExclusao(rowData, true)) {
              return;
            }

            confirmaExclusao(rowData.id);
          },
          () => {
            if (!validateAuthColaboradorProfissional(rowData.idColaborador)) {
              return;
            }
            confirmaInicioAtendimento(rowData);
          },
          () => {
            history.push(`/atendimentos/${rowData.idAtendimento}?view&toBack=/agendamentos`);
          },
          () => {
            confirmaInicioTriagem(rowData);
          },
          () => {
            history.push(`/educacoes/${rowData.idEducacao}?view&toBack=/agendamentos`);
          },
          () => {
            handleContatoSolicitante(
              rowData.idColaboradorSolicitante,
              setContatoSolicitante,
              setShowContatoSolicitante
            );
          },
          () => {
            handleLinkVideoConferencia(
              rowData.idComunidade,
              setLinkVideoConferencia,
              setShowLinkVideoConferencia
            );
          },
        ]}
        labels={[
          'Visualizar',
          'Alterar',
          'Excluir',
          !temAtendimento && !agenteDeSaude ? 'Iniciar Atendimento' : '',
          temAtendimento && !agenteDeSaude ? 'Acessar Atendimento' : '',
          !temTeleEducacao && !agenteDeSaude ? 'Lançar Triagem' : '',
          temTeleEducacao && !agenteDeSaude ? 'Acessar Triagem' : '',
          !agenteDeSaude ? 'Contato do Solicitante' : '',
          'Link videoconferência',
        ]}
        icons={[
          'pi pi-search',
          'pi pi-pencil',
          'pi pi-trash',
          'pi pi-book',
          'pi pi-book',
          'pi pi-sitemap',
          'pi pi-sitemap',
          'pi pi-user',
          'pi pi-video',
        ]}
        disableds={[
          !AuthService.checkRoles('AGENDAMENTO_VIEW'),
          !AuthService.checkRoles('AGENDAMENTO_UPDATE'),
          !AuthService.checkRoles('AGENDAMENTO_DELETE'),
          !AuthService.checkRoles('ATENDIMENTO_INSERT'),
          !AuthService.checkRoles('ATENDIMENTO_VIEW'),
          !AuthService.checkRoles('EDUCACAO_INSERT'),
          !AuthService.checkRoles('EDUCACAO_VIEW'),
          false,
        ]}
      />
    );
  }

  function renderCrud() {
    return (
      <AgendamentoCrud
        idSelected={pageParams.idSelected}
        stateScreen={pageParams.stateScreen}
        toBack={toBack !== '/agendamentos' ? toBack : ''}
        closeOnSaveCancel={toBack.includes('/agendamentos')}
        setContatoSolicitante={setContatoSolicitante}
        setShowContatoSolicitante={setShowContatoSolicitante}
        setLinkVideoConferencia={setLinkVideoConferencia}
        setShowLinkVideoConferencia={setShowLinkVideoConferencia}
        onClose={() => {
          history.push(toBack);
          if (toBack.includes('/agendamentos')) {
            handleBuscar(filter, pageSelected, false);
          }
        }}
      />
    );
  }

  function selectedSituacaoTemplate(option) {
    if (isNumber(option)) {
      const cor = corStatus(Number(option));
      const item = listaSituacao[option];
      return (
        <div className="multiselected-item">
          <div
            className="div-status"
            style={{
              marginRight: 3,
              background: cor,
              color: cor,
            }}
          >
            A
          </div>
          <span>{item.label}</span>
        </div>
      );
    }
    return <span>Todas</span>;
  }
}

/* eslint-disable no-nested-ternary */
import React, { useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { Chart } from 'primereact/chart';
import Panel from '../../components/Panel';

import {
  optionsGraficoAtendimentosPercTipo,
  optionsGraficoAtendimentosTipo,
  optionsGraficoAtMeses,
  optionsGraficoEducacaoMeses,
} from './chartConfig';

import 'chartjs-plugin-labels';
import { Card } from './styles';

import GraficoView from '../../components/GraficoView';

function Page3(props) {
  Page3.propTypes = {
    tipoApuracao: PropTypes.number,
    tipoTeleEducacao: PropTypes.string,

    // dataGraficoAtMeses: PropTypes.object,
  };

  Page3.defaultProps = {
    required: false,
    autoFocus: false,
    tipoTeleEducacao: '',
  };

  const {
    tipoApuracao,
    dataGraficoAtMeses,
    dataGraficoAtendimentosTipo,
    dataGraficoAtendimentosPercTipo,
    dataGraficoEducacaoMeses,
    dataGraficoEducacaoPartMeses,
    tipoTeleEducacao,
  } = props;

  const chartAtendimentoMes = useRef();
  const chartAtendimentoTipo = useRef();
  const chartAtendimentoTipoPerc = useRef();
  const chartTeleEducacaoMes = useRef();
  const chartTeleEducacaoPartMes = useRef();

  const [graficoRefSelecionado, setGraficoRefSelecionado] = useState();
  const [graficoSelecionadoOptions, setGraficoSelecionadoOptions] = useState();
  const [graficoViewVisible, setGraficoViewVisible] = useState(false);

  const pCol = 'p-col-12 p-lg-6';

  let qtdTeleEducacao = 0;
  let qtdAtendimento = 0;
  let nomeTeleeducacao = 'Teleeducação';

  if (tipoTeleEducacao === '0') {
    nomeTeleeducacao = 'Web Palestras';
  } else if (tipoTeleEducacao === '1') {
    nomeTeleeducacao = 'Teleorientação';
  }

  if (
    dataGraficoEducacaoMeses &&
    dataGraficoEducacaoMeses.datasets &&
    Array.isArray(dataGraficoEducacaoMeses.datasets[0]?.data)
  ) {
    dataGraficoEducacaoMeses.datasets[0].data.forEach(e => {
      qtdTeleEducacao += e;
    });
  }

  if (
    dataGraficoAtMeses &&
    dataGraficoAtMeses.datasets &&
    Array.isArray(dataGraficoAtMeses.datasets[0]?.data)
  ) {
    dataGraficoAtMeses.datasets[0].data.forEach(e => {
      qtdAtendimento += e;
    });
  }

  return (
    <>
      {graficoViewVisible && (
        <GraficoView
          chartRef={graficoRefSelecionado}
          visible={graficoViewVisible}
          setVisible={setGraficoViewVisible}
          options={graficoSelecionadoOptions}
        />
      )}

      <div className={pCol}>
        <Card>
          <div className="div-title">
            <span className="title">Teleatendimentos: {qtdAtendimento}</span>
          </div>
          <span className="result-icon gren">
            <i className="pi pi-book" style={{ fontSize: 32 }} />
          </span>
        </Card>
      </div>
      <div className={pCol}>
        <Card>
          <div className="div-title">
            <span className="title">
              Número de {nomeTeleeducacao}: {qtdTeleEducacao}
            </span>
          </div>
          <span className="result-icon red">
            <i className="pi pi-sitemap" style={{ fontSize: 32 }} />
          </span>
        </Card>
      </div>

      {tipoApuracao !== 3 && (
        <>
          <div className={pCol}>
            <Panel
              header="Nr. de Atendimentos por Mês(Acumulado)"
              style={{ height: '100%' }}
              handleView={() => {
                setGraficoViewVisible(true);
                setGraficoRefSelecionado(chartAtendimentoMes);
                setGraficoSelecionadoOptions({
                  ...optionsGraficoAtMeses,
                  title: {
                    ...optionsGraficoAtMeses.title,
                    text: 'Nr. de Atendimentos por Mês(Acumulado)',
                  },
                });
              }}
            >
              <Chart
                ref={chartAtendimentoMes}
                id="atendimento-mes"
                type="bar"
                data={dataGraficoAtMeses}
                options={{
                  ...optionsGraficoAtMeses,
                  title: {
                    ...optionsGraficoAtMeses.title,
                    text: 'Nr. de Atendimentos por Mês(Acumulado)',
                  },
                }}
                height="180"
              />
            </Panel>
          </div>

          <div className={pCol}>
            <Panel
              header="Atendimentos por Tipo(Acumulado)"
              style={{ height: '100%' }}
              handleView={() => {
                setGraficoViewVisible(true);
                setGraficoRefSelecionado(chartAtendimentoTipo);
                setGraficoSelecionadoOptions({
                  ...optionsGraficoAtendimentosTipo,
                  title: {
                    ...optionsGraficoAtendimentosTipo.title,
                    text: 'Atendimentos por Tipo(Acumulado)',
                  },
                });
              }}
            >
              <Chart
                ref={chartAtendimentoTipo}
                id="atendimento-total"
                type="horizontalBar"
                data={dataGraficoAtendimentosTipo}
                options={{
                  ...optionsGraficoAtendimentosTipo,
                  title: {
                    ...optionsGraficoAtendimentosTipo.title,
                    text: 'Atendimentos por Tipo(Acumulado)',
                  },
                }}
                height="180"
              />
            </Panel>
          </div>

          <div className={pCol}>
            <Panel
              header="Atendimentos por Tipo(Acumulado)"
              style={{ height: '100%' }}
              handleView={() => {
                setGraficoViewVisible(true);
                setGraficoRefSelecionado(chartAtendimentoTipoPerc);
                setGraficoSelecionadoOptions({
                  ...optionsGraficoAtendimentosPercTipo,
                  title: {
                    ...optionsGraficoAtendimentosPercTipo.title,
                    text: 'Atendimentos por Tipo(Acumulado)',
                  },
                });
              }}
            >
              <Chart
                ref={chartAtendimentoTipoPerc}
                id="atendimento-total-perc"
                type="doughnut"
                data={dataGraficoAtendimentosPercTipo}
                options={{
                  ...optionsGraficoAtendimentosPercTipo,
                  title: {
                    ...optionsGraficoAtendimentosPercTipo.title,
                    text: 'Atendimentos por Tipo(Acumulado)',
                  },
                }}
                height="180"
              />
            </Panel>
          </div>
        </>
      )}
      <>
        {tipoApuracao !== 2 && (
          <>
            <div className={pCol}>
              <Panel
                header={`Participantes ${nomeTeleeducacao}/Mês(Acumulado)`}
                style={{ height: '100%' }}
                handleView={() => {
                  setGraficoViewVisible(true);
                  setGraficoRefSelecionado(chartTeleEducacaoPartMes);
                  setGraficoSelecionadoOptions({
                    ...optionsGraficoEducacaoMeses,
                    title: {
                      ...optionsGraficoEducacaoMeses.title,
                      text: `Participantes ${nomeTeleeducacao}/Mês(Acumulado)`,
                    },
                  });
                }}
              >
                <Chart
                  ref={chartTeleEducacaoPartMes}
                  id="educacao-mes"
                  type="bar"
                  data={dataGraficoEducacaoPartMeses}
                  options={{
                    ...optionsGraficoEducacaoMeses,
                    title: {
                      ...optionsGraficoEducacaoMeses.title,
                      text: `Participantes ${nomeTeleeducacao}/Mês(Acumulado)`,
                    },
                  }}
                  height="180"
                />
              </Panel>
            </div>
            <div className={pCol}>
              <Panel
                header={`Nr. de ${nomeTeleeducacao} por Mês(Acumulado)`}
                style={{ height: '100%' }}
                handleView={() => {
                  setGraficoViewVisible(true);
                  setGraficoRefSelecionado(chartTeleEducacaoMes);
                  setGraficoSelecionadoOptions({
                    ...optionsGraficoEducacaoMeses,
                    title: {
                      ...optionsGraficoEducacaoMeses.title,
                      text: `Nr. de ${nomeTeleeducacao} por Mês(Acumulado)`,
                    },
                  });
                }}
              >
                <Chart
                  ref={chartTeleEducacaoMes}
                  id="educacao-mes"
                  type="bar"
                  data={dataGraficoEducacaoMeses}
                  options={{
                    ...optionsGraficoEducacaoMeses,
                    title: {
                      ...optionsGraficoEducacaoMeses.title,
                      text: `Nr. de ${nomeTeleeducacao} por Mês(Acumulado)`,
                    },
                  }}
                  height="180"
                />
              </Panel>
            </div>
          </>
        )}
      </>
    </>
  );
}

export default Page3;

// import { addMonths } from 'date-fns';
import { addDays } from 'date-fns';
import { BaseService } from './BaseService';
import { ConfigApi } from '../config/Constantes';
import { newDateOnly } from '../util/functions';
// import { newDateTimeEndOnly, newDateTimeStartOnly } from '../util/functions';

class AgendaService extends BaseService {
  constructor() {
    super(ConfigApi.agendamentoURL);
    this.filterMinhaAgenda = new Filter();
    this.filterMinhaAgenda.data = newDateOnly();
    this.filterMinhaAgenda.tipo = 1;
  }

  getFilter() {
    return new Filter();
  }

  getFilterAgendaDia() {
    return this.filterMinhaAgenda;
  }

  async iniciaAtendimento(body) {
    try {
      const response = await this.api.put(`${this.url}/atendimento/${body.id}`, body);
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }

  async iniciaTriagem(body) {
    try {
      const response = await this.api.put(`${this.url}/educacao/${body.id}`, body);
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }
}

class Filter {
  constructor() {
    this.id = undefined;
    this.idColaborador = undefined;
    this.idComunidade = undefined;
    this.idColaboradorSolicitante = undefined;
    this.idAgendamentoTipo = undefined;
    this.idPaciente = undefined;
    this.dataInicio = addDays(newDateOnly(), -1);
    this.dataFim = addDays(newDateOnly(), 30);
    this.pacienteNome = undefined;
    this.colaboradorNome = undefined;
    this.colaboradorSolicitanteNome = undefined;
    this.cartaoSus = undefined;
    this.cpf = undefined;

    this.listaSituacao = '["0", "1", "2", "3"]';

    this.limit = 0;
    this.page = 0;
  }
}
export default new AgendaService();

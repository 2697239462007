import { BaseService } from './BaseService';
import { ConfigApi, filterType } from '../config/Constantes';
import { padLeft } from '../util/functions';

class PacienteService extends BaseService {
  constructor() {
    super(`${ConfigApi.pacienteURL}`);
    this.lookupConfig = new LookupConfig();
    this.lookupConfig.serviceInstance = this;
  }

  async findByName(name) {
    this.setLoading(true);
    try {
      const response = await this.api.get(`${this.url}/nome/${name}`);
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }

  getFilter() {
    return new Filter();
  }

  getLookupConfig(handleConfirm) {
    this.lookupConfig.handleConfirm = handleConfirm;
    return this.lookupConfig;
  }
}

class Filter {
  constructor() {
    this.nome = '';
    this.cpf = '';
    this.cartaoSus = '';
    this.comunidadeNome = '';
    this.idComunidade = undefined;
    this.idMunicipio = undefined;
    this.limit = 11;
    this.page = 0;
  }
}

class LookupConfig {
  constructor() {
    this.serviceInstance = null;
    this.title = 'Localiza';
    this.defaultFilter = 'nome';
    this.handleConfirm = _record => {};
    this.filters = [
      {
        name: 'id',
        caption: 'Id',
        type: filterType.INTEGER,
      },
      {
        name: 'nome',
        caption: 'Nome',
        type: filterType.TEXT,
      },
      {
        name: 'cpf',
        caption: 'CPF',
        type: filterType.TEXT,
      },
      {
        name: 'cartaoSus',
        caption: 'Cartão SUS',
        type: filterType.TEXT,
      },
    ];
    this.columns = [
      {
        name: 'id',
        caption: 'Id',
        handleFormatValue: val => {
          return padLeft(val, 6);
        },
        width: undefined,
        cssClassName: 'grid-col-id',
      },
      {
        name: 'cpf',
        caption: 'CPF',
        width: 120,
      },
      {
        name: 'cartaoSus',
        caption: 'Cartão SUS',
        width: 150,
      },
      {
        name: 'nome',
        caption: 'Nome',
      },
    ];
  }
}

export default new PacienteService();

import React, { createRef, useCallback, useEffect, useState } from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import PropTypes from 'prop-types';
import { Editor } from 'primereact/editor';
import { Container } from './styles';
import CalendarSp from '../CalendarSp';
import { cfgPtBr, listaUfs } from '../../config/Constantes';
import LabelSp from '../LabelSp';
import { AtendimentoImpressoModel, TpImpresso } from '../../util/Models';
import DropdownSp from '../DropdownSp';
import ImpressoTipoService from '../../services/ImpressoTipoService';
import { cloneObj, validateFields } from '../../util/functions';
import InputTextSp from '../InputTextSp';
import InputTextareaSp from '../InputTextareaSp';

export default function EditorSp(props) {
  EditorSp.propTypes = {
    handleConfirm: PropTypes.func,
    visible: PropTypes.bool,
    setVisible: PropTypes.func,
    disabled: PropTypes.bool,
  };

  EditorSp.defaultValue = {
    disabled: false,
  };

  const {
    impressoSelected,
    handleConfirm,
    visible,
    setVisible,
    disabled,
    pacienteNome,
  } = props;
  const cancellRef = createRef();
  const [impresso, setImpresso] = useState(new AtendimentoImpressoModel());
  const [listaImpressoTipo, setListaImpressoTipo] = useState([]);
  const [inserindo, setInserindo] = useState(false);

  const textoEncaminhamentoFAS = `
  <br>ENCAMINHO ${(
    pacienteNome || ''
  ).toUpperCase()} PARA AVALIAÇÃO E/OU ACOMPANHAMENTO NA REDE DE ATENÇÃO À SAÚDE(RAS) DO MUNICÍPIO, APÓS CONSULTA REALIZADA POR TELEATENDIMENTO DE FORMA SÍNCRONA.
  <br><br><br><br><br>
  IMPRESSÃO:
  <br><br><br><br><br>
  CONDUTA SUGERIDA: `;

  // useCallbacks
  const loadImpressoTipo = useCallback(async () => {
    const r = await ImpressoTipoService.findAll({ limit: 150 });

    const retorno = r.items.map(e => {
      return {
        label: e.nome,
        value: e.id,
      };
    });

    setListaImpressoTipo(retorno);
  }, []);

  const validate = useCallback(() => {
    const fieldsValidate = ['data', 'hora', 'idImpressoTipo'];
    if (impresso.idImpressoTipo === TpImpresso.ENCAMINHAMENTO) {
      fieldsValidate.push('dadosClinicos');
      fieldsValidate.push('examesRealizados');
      fieldsValidate.push('tratamentosInstituidos');
      fieldsValidate.push('observacoes');
      fieldsValidate.push('especialidade');
    } else {
      fieldsValidate.push('texto');
    }

    return validateFields(impresso, fieldsValidate);
  }, [impresso]);

  useEffect(() => {
    if (impressoSelected) {
      const impressoTemp = cloneObj(impressoSelected);

      impressoTemp.data = new Date(impressoTemp.data);
      impressoTemp.hora = new Date(impressoTemp.hora);

      setTimeout(() => setImpresso(impressoTemp || new AtendimentoImpressoModel()), 200);
    } else {
      setInserindo(true);
    }
  }, [impressoSelected]);

  useEffect(() => {
    if (
      cancellRef &&
      cancellRef.current &&
      cancellRef.current.element &&
      cancellRef.current.element.focus
    ) {
      cancellRef.current.element.focus();
    }
    loadImpressoTipo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const footer = (
    <div>
      {!disabled && (
        <Button
          label="Confirmar"
          icon="pi pi-check"
          onClick={() => {
            if (setVisible) {
              setVisible(false);
            }
            if (handleConfirm) {
              handleConfirm(impresso);
            }
          }}
          className="p-button-success"
          disabled={!validate()}
        />
      )}

      <Button
        label="Cancelar"
        icon="pi pi-times"
        onClick={() => {
          if (setVisible) {
            setVisible(false);
          }
        }}
        ref={cancellRef}
        className="p-button-danger"
      />
    </div>
  );
  const header = (
    <>
      <span className="ql-formats">
        <select className="ql-header" defaultValue="0" title="Tamanho da Fonte">
          <option value="0">Normal</option>
          <option value="2">Grande</option>
          <option value="1">Enorme</option>
        </select>

        <button className="ql-bold" aria-label="Bold" title="Negrito" />
        <button className="ql-italic" aria-label="Italic" title="Itálico" />
        <button className="ql-underline" aria-label="Underline" title="Sublinhado" />
      </span>

      <span className="ql-formats">
        <button
          type="button"
          className="ql-list"
          value="ordered"
          aria-label="Ordered List"
          title="Marcador"
        />
        <button
          type="button"
          className="ql-list"
          value="bullet"
          aria-label="Unordered List"
          title="Numeração"
        />

        <select className="ql-align" title="Alinhamento">
          <option />
          <option value="center" />
          <option value="right" />
          {/* <option value="justify" /> */}
        </select>
      </span>
    </>
  );

  return (
    <Container>
      <Dialog
        closable={false}
        footer={footer}
        header="Informações para o Impresso"
        visible={visible}
        style={{ minWidth: '350px', maxWidth: '900px', width: '95%' }}
        contentStyle={{ minHeight: '470px', paddingTop: 8, paddingBottom: 8 }}
        modal
        onHide={() => {
          if (setVisible) {
            setVisible(false);
          }
        }}
      >
        <div className="p-grid">
          <div
            className="p-grid p-col-12 p-sm-6 p-lg-6 p-fluid"
            style={{ padding: 0, margin: 0 }}
          >
            <div className="p-col-7 p-fluid">
              <LabelSp>Data</LabelSp>
              <CalendarSp
                readOnlyInput
                appendTo={document.body}
                dateFormat="dd/mm/yy"
                required
                disabled={disabled}
                locale={cfgPtBr}
                value={impresso.data}
                onChange={e => setImpresso({ ...impresso, data: e.value })}
              />
            </div>
            <div className="p-col-5 p-fluid">
              <LabelSp>Hora</LabelSp>
              <CalendarSp
                readOnlyInput
                appendTo={document.body}
                showTime
                timeOnly
                required
                disabled={disabled}
                locale={cfgPtBr}
                value={impresso.hora}
                onChange={e => setImpresso({ ...impresso, hora: e.value })}
              />
            </div>
          </div>
          <div className="p-col-12 p-sm-6 p-lg-6 p-fluid">
            <LabelSp>Tipo do Impresso</LabelSp>
            <DropdownSp
              required
              placeholder="Selecione"
              value={impresso.idImpressoTipo}
              options={listaImpressoTipo}
              disabled={disabled}
              onChange={e => {
                let impressoTipo = listaImpressoTipo.find(i => e.value === i.value);
                impressoTipo = {
                  id: impressoTipo.value,
                  nome: impressoTipo.label,
                };

                let { texto } = impresso;

                if (e?.value === TpImpresso.ENCAMINHAMENTO_FAS && inserindo) {
                  texto = textoEncaminhamentoFAS;
                }

                setImpresso({
                  ...impresso,
                  texto,
                  impressoTipo,
                  idImpressoTipo: e?.value,
                });
              }}
            />
          </div>
          {impresso.idImpressoTipo !== TpImpresso.ENCAMINHAMENTO ? (
            <div className="p-col-12 p-sm-12 p-lg-12 p-fluid">
              <Editor
                style={{ height: '350px' }}
                value={impresso.texto}
                readOnly={disabled}
                onTextChange={e => setImpresso({ ...impresso, texto: e.htmlValue })}
                headerTemplate={header}
              />
            </div>
          ) : (
            <>
              <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
                <LabelSp>RG</LabelSp>
                <InputTextSp
                  maxLength={20}
                  value={impresso.rgNumero}
                  disabled={disabled}
                  onChange={e => {
                    setImpresso({ ...impresso, rgNumero: e.target.value });
                  }}
                />
              </div>
              <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
                <LabelSp>Orgão Exp. RG</LabelSp>
                <InputTextSp
                  maxLength={20}
                  value={impresso.orgaoExpedidorRg}
                  disabled={disabled}
                  onChange={e => {
                    setImpresso({ ...impresso, orgaoExpedidorRg: e.target.value });
                  }}
                />
              </div>
              <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
                <LabelSp>UF Emissão RG</LabelSp>
                <DropdownSp
                  disabled={disabled}
                  placeholder=""
                  value={impresso.ufEmissaoRg}
                  options={listaUfs}
                  filter
                  onChange={e => setImpresso({ ...impresso, ufEmissaoRg: e.target.value })}
                />
              </div>

              <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
                <LabelSp>Data Emissão RG:</LabelSp>
                <CalendarSp
                  appendTo={document.body}
                  dateFormat="dd/mm/yy"
                  readOnlyInput
                  yearNavigator
                  yearRange="1900:2030"
                  locale={cfgPtBr}
                  value={impresso.dataEmissaoRg}
                  disabled={disabled}
                  showButtonBar
                  onChange={e => {
                    setImpresso({ ...impresso, dataEmissaoRg: e.target.value });
                  }}
                />
              </div>

              <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
                <LabelSp>Certidão Nascimento</LabelSp>
                <InputTextSp
                  maxLength={20}
                  value={impresso.certidaoNascimentoNumero}
                  disabled={disabled}
                  onChange={e => {
                    setImpresso({ ...impresso, certidaoNascimentoNumero: e.target.value });
                  }}
                />
              </div>
              <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
                <LabelSp>Livro Cert. Nascimento</LabelSp>
                <InputTextSp
                  maxLength={6}
                  value={impresso.certidaoNascimentoLivro}
                  disabled={disabled}
                  onChange={e => {
                    setImpresso({ ...impresso, certidaoNascimentoLivro: e.target.value });
                  }}
                />
              </div>
              <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
                <LabelSp>Folha Cert. Nascimento</LabelSp>
                <InputTextSp
                  maxLength={6}
                  value={impresso.certidaoNascimentoFolha}
                  disabled={disabled}
                  onChange={e => {
                    setImpresso({ ...impresso, certidaoNascimentoFolha: e.target.value });
                  }}
                />
              </div>
              <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
                <LabelSp>Emissão Cert. Nasc.</LabelSp>
                <CalendarSp
                  appendTo={document.body}
                  dateFormat="dd/mm/yy"
                  readOnlyInput
                  yearNavigator
                  yearRange="1900:2030"
                  locale={cfgPtBr}
                  value={impresso.certidaoNascimentoEmissao}
                  disabled={disabled}
                  showButtonBar
                  onChange={e => {
                    setImpresso({ ...impresso, certidaoNascimentoEmissao: e.target.value });
                  }}
                />
              </div>

              <div className="p-col-12 p-sm-6 p-lg-6 p-fluid">
                <LabelSp>Dados Clínicos</LabelSp>
                <InputTextareaSp
                  required
                  resize={false}
                  rows={4}
                  value={impresso.dadosClinicos}
                  disabled={disabled}
                  onChange={e => {
                    setImpresso({ ...impresso, dadosClinicos: e.target.value });
                  }}
                />
              </div>

              <div className="p-col-12 p-sm-6 p-lg-6 p-fluid">
                <LabelSp>Exames Realizados</LabelSp>
                <InputTextareaSp
                  required
                  resize={false}
                  rows={4}
                  value={impresso.examesRealizados}
                  disabled={disabled}
                  onChange={e => {
                    setImpresso({ ...impresso, examesRealizados: e.target.value });
                  }}
                />
              </div>
              <div className="p-col-12 p-sm-6 p-lg-6 p-fluid">
                <LabelSp>Tratamentos Instituídos</LabelSp>
                <InputTextareaSp
                  required
                  resize={false}
                  rows={4}
                  value={impresso.tratamentosInstituidos}
                  disabled={disabled}
                  onChange={e => {
                    setImpresso({ ...impresso, tratamentosInstituidos: e.target.value });
                  }}
                />
              </div>

              <div className="p-col-12 p-sm-6 p-lg-6 p-fluid">
                <LabelSp>Observação</LabelSp>
                <InputTextareaSp
                  required
                  resize={false}
                  rows={4}
                  value={impresso.observacoes}
                  disabled={disabled}
                  onChange={e => {
                    setImpresso({ ...impresso, observacoes: e.target.value });
                  }}
                />
              </div>

              <div className="p-col-12 p-sm-6 p-lg-6 p-fluid">
                <LabelSp>Especialidade</LabelSp>
                <InputTextSp
                  required
                  maxLength={100}
                  value={impresso.especialidade}
                  disabled={disabled}
                  onChange={e => {
                    setImpresso({ ...impresso, especialidade: e.target.value });
                  }}
                />
              </div>
              <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
                <LabelSp>CID</LabelSp>
                <InputTextSp
                  maxLength={15}
                  value={impresso.cid}
                  disabled={disabled}
                  onChange={e => {
                    setImpresso({ ...impresso, cid: e.target.value });
                  }}
                />
              </div>
            </>
          )}
        </div>
      </Dialog>
    </Container>
  );
}

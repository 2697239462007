import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { Column } from 'primereact/column';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Container } from './styles';

import PacienteService from '../../services/PacienteService';
import AuthService from '../../services/AuthService';
import history from '../../services/history';
import {
  getPageParams,
  errorHandle,
  padLeft,
  calcNaxItemsPage,
  isScreenMobile,
} from '../../util/functions';
import LabelSp from '../../components/LabelSp';

import { showMessage } from '../../components/MessageDialog';
import InputTextSp from '../../components/InputTextSp';

import BotaoMenuGrid from '../../components/BotaoMenuGrid';

import PacienteCrud from './crud';
import { StateScreen } from '../constants';
import CrudHeader from '../../components/CrudHeader';
import DataTableSp from '../../components/DataTableSp';
import DropDownLazy from '../../components/DropdownLazy';
import MunicipioService from '../../services/MunicipioService';
import ComunidadeService from '../../services/ComunidadeService';

import ProntuarioReport from '../../components/ProntuarioReport';

export default function Paciente(props) {
  // useSelectors
  const filterVisible = useSelector(state => state.global.filterVisible);

  // useMemo
  const pageParams = useMemo(() => getPageParams(props.match.params, props.location.search), [
    props.location.search,
    props.match.params,
  ]);

  const filterService = useMemo(() => PacienteService.getFilter(), []);

  // useStates
  const toBack = pageParams.toBack || '/pacientes';

  const [filter, setFilter] = useState(filterService);
  const [pacientes, setPacientees] = useState([]);
  const [comunidade, setComunidade] = useState(null);
  const [municipio, setMunicipio] = useState(null);

  const [showProntuario, setShowProntuario] = useState(false);
  const [pacienteProntuario, setPacienteProntuario] = useState();

  const [pageLimit, setPageLimit] = useState(filterService.limit);
  const [pageSelected, setPageSelected] = useState(0);
  const [first, setFirst] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [funcTimeOut, setFuncTimeOut] = useState();

  // useCallbacks
  const loadMunicipio = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await MunicipioService.findAll({ nome: _nome, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadComunidade = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await ComunidadeService.findAll({ nome: _nome, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const calcLimit = useCallback(() => {
    let ret;
    if (filterVisible) {
      ret = calcNaxItemsPage(10, 14, 19);
    } else {
      ret = calcNaxItemsPage(13, 17, 22);
    }
    setPageLimit(ret);
    return ret;
  }, [filterVisible]);

  const handleBuscar = useCallback(
    async (_filter, _page, resetPage = true) => {
      _filter.page = _page || 0;
      _filter.limit = calcLimit();
      try {
        const result = await PacienteService.findAll(_filter);
        setPacientees(result.items);

        setTotalRecords(result.totalRecords);
        if (resetPage) {
          setFirst(0);
        }
      } catch (err) {
        errorHandle(err);
      }
    },
    [calcLimit]
  );

  const setFilterAndSearch = useCallback(
    async _filterValue => {
      if (JSON.stringify(_filterValue) !== JSON.stringify(filter)) {
        setFilter(_filterValue);

        if (funcTimeOut) {
          clearTimeout(funcTimeOut);
        }
        const func = setTimeout(async () => {
          handleBuscar(_filterValue);
        }, 800);
        setFuncTimeOut(func);
      }
    },
    [filter, funcTimeOut, handleBuscar]
  );

  function onPage(event) {
    const pagina = event.first / event.rows;
    setPageSelected(pagina);
    setFirst(event.first);
    handleBuscar(filter, pagina, false);
  }

  // useCallbacks
  const excluirRegistro = useCallback(
    async _id => {
      try {
        await PacienteService.delete(_id);
        toast.success('Registro excluído com sucesso.');
        handleBuscar(filter);
      } catch (err) {
        errorHandle(err);
      }
    },
    [filter, handleBuscar]
  );

  // functions
  function getTitle() {
    const titleDefault = 'Paciente';
    let titleAdd = '';

    if (pageParams.stateScreen === StateScreen.stSearch) {
      titleAdd = '';
    }
    if (pageParams.stateScreen === StateScreen.stInsert) {
      titleAdd = '(Novo)';
    }
    if (pageParams.stateScreen === StateScreen.stUpdate) {
      titleAdd = `(Alterando Id: ${pageParams.idSelected})`;
    }
    if (pageParams.stateScreen === StateScreen.stView) {
      titleAdd = ` (Visualizando Id: ${pageParams.idSelected})`;
    }

    if (!isScreenMobile()) {
      return `${titleDefault} ${titleAdd}`;
    }

    return titleDefault;
  }

  const confirmaExclusao = useCallback(
    id => {
      showMessage('Confirmação', 'Confirma a exclusão do registro?', idx => {
        if (idx === 1) {
          excluirRegistro(id);
        }
      });
    },
    [excluirRegistro]
  );

  const printProntuario = async paciente => {
    setPacienteProntuario(paciente);
    setShowProntuario(true);
  };

  // useEffects
  useEffect(() => {
    if (pageParams.stateScreen === StateScreen.stSearch) {
      handleBuscar(filter, 0, true);
    }
    // desativado para evitar que a cada vez que o colaborador digitasse o sistema buscasse
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffects
  // Responsavel por recalcular a quantidade de registros da grid quando a div do filtro ocultar
  // ou ficar visivel
  useEffect(() => {
    if (pageParams.stateScreen === StateScreen.stSearch) {
      calcLimit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcLimit]);

  // renders
  return (
    <Container className="container-page">
      {showProntuario ? (
        <ProntuarioReport
          paciente={pacienteProntuario}
          visible={showProntuario}
          setVisible={() => {
            setShowProntuario(false);
          }}
        />
      ) : null}
      <div className="p-grid">
        <CrudHeader
          title={getTitle()}
          disabledButtonInsert={!AuthService.checkRoles('PACIENTE_INSERT')}
          showButtonFilter={pageParams.stateScreen === StateScreen.stSearch}
          showButtonInsert
          showButtonSearch
          titleFilterDefault="Buscar por Nome"
          handleFilterDefault={text => {
            filter.nome = text;
            setFilter({ ...filter, nome: text });
            handleBuscar(filter);
          }}
          handleClearFilters={() => {
            setFilter({
              ...filter,
              nome: '',
              email: '',
              comunidadeNome: '',
              idMunicipio: 0,
              idComunidade: 0,
            });
            setComunidade(null);
            setMunicipio(null);
          }}
          handleButtonInsert={() => history.push('/pacientes/insert')}
          handleButtonSearch={() => handleBuscar(filter)}
        >
          <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
            <LabelSp>Município</LabelSp>
            <DropDownLazy
              autoLoad
              showClear
              placeholder="Todos"
              onChange={e => {
                setFilterAndSearch({ ...filter, idMunicipio: e?.value });
                setMunicipio(e);
              }}
              value={municipio}
              onFilter={async txtFilter => {
                const retorno = await loadMunicipio(txtFilter);
                return retorno;
              }}
            />
          </div>
          <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
            <LabelSp>Comunidade</LabelSp>
            <DropDownLazy
              autoLoad
              showClear
              placeholder="Todas"
              onChange={e => {
                setFilterAndSearch({ ...filter, idComunidade: e?.value });
                setComunidade(e);
              }}
              value={comunidade}
              onFilter={async txtFilter => {
                const retorno = await loadComunidade(txtFilter);
                return retorno;
              }}
            />
          </div>
          <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
            <LabelSp>Nome Comunidade</LabelSp>
            <InputTextSp
              value={filter.comunidadeNome}
              maxLength={100}
              onChange={e => {
                setFilterAndSearch({ ...filter, comunidadeNome: e.target.value });
              }}
            />
          </div>
        </CrudHeader>
        {pageParams.stateScreen === StateScreen.stSearch ? renderSearch() : renderCrud()}
      </div>
    </Container>
  );

  function renderSearch() {
    return (
      <>
        <div className="p-col-12 p-fluid datatable-doc-demo">
          <DataTableSp
            value={pacientes}
            style={{ marginBottom: '2px' }}
            paginator
            rows={pageLimit}
            lazy
            responsive
            totalRecords={totalRecords}
            first={first}
            onPage={onPage}
          >
            <Column
              field="id"
              body={rowData => padLeft(rowData.id, 6)}
              header="Id"
              className="grid-col-id"
            />
            <Column field="cpf" className="grid-col" header="CPF" style={{ width: 120 }} />

            <Column
              field="cartaoSus"
              className="grid-col "
              header="Cartão SUS"
              style={{ width: 140 }}
            />
            <Column field="nome" className="grid-col" header="Nome" />
            <Column field="nomeMae" className="grid-col" header="Nome da Mãe" />

            <Column
              className="grid-col p-p-5"
              style={{ width: 250 }}
              header="Município"
              body={rowData => rowData.municipioNome}
            />
            <Column
              className="gid-col-acoes-35"
              bodyStyle={{ textAlign: 'end' }}
              body={renderButtonOp}
            />
          </DataTableSp>
        </div>
      </>
    );
  }

  function renderButtonOp(rowData) {
    return (
      <BotaoMenuGrid
        handles={[
          () => history.push(`/pacientes/${rowData.id}?view`),
          () => history.push(`/pacientes/${rowData.id}`),
          () => confirmaExclusao(rowData.id),
          () => printProntuario({ id: rowData.id, nome: rowData.nome }),
        ]}
        disableds={[
          false,
          !AuthService.checkRoles('PACIENTE_UPDATE'),
          !AuthService.checkRoles('PACIENTE_DELETE'),
          !AuthService.checkRoles('ATENDIMENTO_PRINT'),
        ]}
        icons={['pi pi-search', 'pi pi-pencil', 'pi pi-trash', 'pi pi-print']}
        labels={['Visualizar', 'Alterar', 'Excluir', 'Prontuário']}
      />
    );
  }

  function renderCrud() {
    return (
      <PacienteCrud
        idSelected={pageParams.idSelected}
        stateScreen={pageParams.stateScreen}
        onClose={() => {
          history.push(toBack);
          handleBuscar(filter, pageSelected, false);
        }}
      />
    );
  }
}

import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import ButtonSp from '../../components/ButtonSp';
import DropDownLazy from '../../components/DropdownLazy';
import DropdownSp from '../../components/DropdownSp';

import InputTextSp from '../../components/InputTextSp';
import LabelSp from '../../components/LabelSp';
import { showMessage } from '../../components/MessageDialog';

import RegionalService from '../../services/RegionalService';
import ComunidadeService from '../../services/ComunidadeService';
import TerritorioService from '../../services/TerritorioService';
import { cloneObj, errorHandle, validateFields } from '../../util/functions';
import { ComunidadeModel } from '../../util/Models';
import { StateScreen } from '../constants';

import UnidadeConservacaoService from '../../services/UnidadeConservacaoService';
import { listaUfs } from '../../config/Constantes';
import MunicipioService from '../../services/MunicipioService';
import FinanciadorService from '../../services/FinanciadorService';
import EstruturaPontoService from '../../services/EstruturaPontoService';

function ComunidadeCrud(props) {
  ComunidadeCrud.propTypes = {
    stateScreen: PropTypes.string.isRequired,
    idSelected: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  ComunidadeCrud.defaultProps = {
    messageConfirmation: 'Confirma os dados',
    showConfirmation: false,
  };

  const { stateScreen, idSelected, onClose } = props;

  // states
  const [comunidade, setComunidade] = useState(new ComunidadeModel());
  const [regionalSelecionado, setRegionalSelecionado] = useState(null);
  const [territorioSelecionado, setTerritorioSelecionado] = useState(null);
  const [unidadeConservacaoSelecionado, setUnidadeConservacaoSelecionado] = useState(null);
  const [ufSelecionada, setUfSelecionada] = useState('AM');
  const [municipioSelecionado, setMunicipioSelecionado] = useState(null);
  const [financiadorSelecionado, setFinanciadorSelecionado] = useState(null);

  const [estruturaPontoSelecionada, setEstruturaPontoSelecionada] = useState(null);

  const [errorLoadRecord, setErrorLoadRecord] = useState(false);

  // useCallbacks
  const loadRegional = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await RegionalService.findAll({ nome: _nome, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadEstruturaPonto = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await EstruturaPontoService.findAll({ nome: _nome, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadTerritorio = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await TerritorioService.findAll({ nome: _nome, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadFinanciador = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await FinanciadorService.findAll({ nome: _nome, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadUnidadeConservacao = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await UnidadeConservacaoService.findAll({ nome: _nome, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadMunicipio = useCallback(async (_nome, uf) => {
    if (_nome !== undefined) {
      const r = await MunicipioService.findAll({ nome: _nome, uf, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadRecord = useCallback(async _id => {
    try {
      const retorno = await ComunidadeService.findById(_id);

      setComunidade(retorno);
      const regional = {
        label: retorno.regional.nome,
        value: retorno.regional.id,
      };
      const territorio = {
        label: retorno.territorio.nome,
        value: retorno.territorio.id,
      };

      const unidadeConservacao = {
        label: retorno.unidadeConservacao.nome,
        value: retorno.unidadeConservacao.id,
      };

      const municipio = {
        label: retorno.municipio.nome,
        value: retorno.municipio.id,
      };

      const financiador = retorno.financiador
        ? {
            label: retorno.financiador.nome,
            value: retorno.financiador.id,
          }
        : null;

      const estruturaPonto = retorno.estruturaPonto
        ? {
            label: retorno.estruturaPonto.nome,
            value: retorno.estruturaPonto.id,
          }
        : null;

      setRegionalSelecionado(regional);
      setTerritorioSelecionado(territorio);
      setUnidadeConservacaoSelecionado(unidadeConservacao);
      setMunicipioSelecionado(municipio);
      setUfSelecionada(municipio.uf);
      setFinanciadorSelecionado(financiador);
      setEstruturaPontoSelecionada(estruturaPonto);
      setErrorLoadRecord(false);
    } catch (err) {
      setErrorLoadRecord(true);
      errorHandle(err);
    }
  }, []);

  // funcoes
  function viewMode() {
    return stateScreen === StateScreen.stView || errorLoadRecord;
  }

  function handleBack() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      showMessage('Confirmação', 'Abandonar mudanças?', idx => {
        if (idx === 1) {
          onClose();
        }
      });
    }
  }

  function handleSave() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      salvarRecord();
    }
  }

  async function salvarRecord() {
    try {
      let retorno;
      const comunidadeSalvar = cloneObj(comunidade);
      // if (!comunidadeSalvar.idEstruturaPonto) {
      //   comunidadeSalvar.idFinanciador = undefined;
      //   comunidadeSalvar.financiador = null;
      // }
      if (stateScreen === StateScreen.stInsert) {
        retorno = await ComunidadeService.insert(comunidadeSalvar);
      } else {
        retorno = await ComunidadeService.update(comunidadeSalvar);
      }
      toast.success('Registro salvo com sucesso.');
      onClose(retorno);
    } catch (err) {
      errorHandle(err);
    }
  }

  // useEffects
  useEffect(() => {
    if (stateScreen === StateScreen.stUpdate || stateScreen === StateScreen.stView) {
      loadRecord(idSelected);
    } else if (stateScreen === StateScreen.stInsert) {
      const novo = new ComunidadeModel();
      setRegionalSelecionado(null);
      setTerritorioSelecionado(null);
      setUnidadeConservacaoSelecionado(null);
      setMunicipioSelecionado(null);
      setFinanciadorSelecionado(null);
      setEstruturaPontoSelecionada(null);

      setComunidade(novo);
    }
  }, [idSelected, loadRecord, stateScreen]);

  // render principal
  return (
    <>
      <>
        <div className="p-col-12 p-sm-3 p-lg-3 p-fluid">
          <LabelSp>Nome</LabelSp>
          <InputTextSp
            value={comunidade.nome}
            maxLength={100}
            required
            disabled={viewMode()}
            onChange={e => {
              setComunidade({ ...comunidade, nome: e.target.value });
            }}
          />
        </div>

        <div className="p-col-12 p-sm-3 p-lg-3 p-fluid">
          <LabelSp>Estrutura do Ponto</LabelSp>
          <DropDownLazy
            autoLoad
            disabled={viewMode()}
            showClear
            placeholder="Sem ponto de conectividade"
            onChange={e => {
              const estruturaPonto = e ? { id: e.value, nome: e.label } : null;
              setComunidade({
                ...comunidade,
                estruturaPonto,
                idEstruturaPonto: e?.value,
              });
            }}
            value={estruturaPontoSelecionada}
            onFilter={async txtFilter => {
              const retorno = await loadEstruturaPonto(txtFilter);
              return retorno;
            }}
          />
        </div>

        <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
          <LabelSp>Regional/TI</LabelSp>
          <DropDownLazy
            required
            autoLoad
            disabled={viewMode()}
            placeholder="Selecione"
            onChange={e => {
              const regional = e ? { id: e.value, nome: e.label } : null;
              setComunidade({
                ...comunidade,
                regional,
                idRegional: e?.value,
              });
            }}
            value={regionalSelecionado}
            onFilter={async txtFilter => {
              const retorno = await loadRegional(txtFilter);
              return retorno;
            }}
          />
        </div>

        <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Território</LabelSp>
          <DropDownLazy
            required
            autoLoad
            disabled={viewMode()}
            placeholder="Selecione"
            onChange={e => {
              const territorio = e ? { id: e.value, nome: e.label } : null;
              setComunidade({
                ...comunidade,
                territorio,
                idTerritorio: e?.value,
              });
            }}
            value={territorioSelecionado}
            onFilter={async txtFilter => {
              const retorno = await loadTerritorio(txtFilter);
              return retorno;
            }}
          />
        </div>

        <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
          <LabelSp>UC/TI</LabelSp>
          <DropDownLazy
            required
            autoLoad
            disabled={viewMode()}
            placeholder="Selecione"
            onChange={e => {
              const unidadeConservacao = e ? { id: e.value, nome: e.label } : null;
              setComunidade({
                ...comunidade,
                unidadeConservacao,
                idUnidadeConservacao: e?.value,
              });
            }}
            value={unidadeConservacaoSelecionado}
            onFilter={async txtFilter => {
              const retorno = await loadUnidadeConservacao(txtFilter);
              return retorno;
            }}
          />
        </div>

        <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>UF</LabelSp>
          <DropdownSp
            required
            disabled={viewMode()}
            placeholder="Selecione"
            value={ufSelecionada}
            options={listaUfs}
            filter
            onChange={e => setUfSelecionada(e.target.value)}
          />
        </div>

        <div className="p-col-8 p-sm-4 p-lg-4 p-fluid">
          <LabelSp>Município</LabelSp>
          <DropDownLazy
            required
            autoLoad
            disabled={viewMode()}
            placeholder="Selecione"
            onChange={e => {
              const municipio = e ? { id: e.value, nome: e.label } : null;
              setComunidade({
                ...comunidade,
                municipio,
                idMunicipio: e?.value,
              });
            }}
            value={municipioSelecionado}
            onFilter={async txtFilter => {
              const retorno = await loadMunicipio(txtFilter, ufSelecionada);
              return retorno;
            }}
          />
        </div>

        <div className="p-col-12 p-sm-3 p-lg-3 p-fluid">
          <LabelSp>Financiador</LabelSp>
          <DropDownLazy
            required={!!comunidade.idEstruturaPonto}
            autoLoad
            disabled={viewMode()}
            placeholder="Selecione"
            onChange={e => {
              const financiador = e ? { id: e.value, nome: e.label } : null;
              setComunidade({
                ...comunidade,
                financiador,
                idFinanciador: e?.value,
              });
            }}
            value={financiadorSelecionado}
            onFilter={async txtFilter => {
              const retorno = await loadFinanciador(txtFilter);
              return retorno;
            }}
          />
        </div>

        <div className="p-col-12 p-sm-12 p-lg-12 p-fluid">
          <LabelSp>Link Videoconferência</LabelSp>
          <InputTextSp
            value={comunidade.linkVideoConferencia}
            maxLength={255}
            disabled={viewMode()}
            onChange={e => {
              setComunidade({ ...comunidade, linkVideoConferencia: e.target.value });
            }}
          />
        </div>

        <div className="p-col-12 p-lg-12" style={{ textAlign: 'start' }}>
          {!viewMode() ? (
            <ButtonSp
              className="p-button-success"
              icon="pi pi-save"
              label="Salvar"
              disabled={
                !validateFields(comunidade, [
                  'nome',
                  'idRegional',
                  'idTerritorio',
                  'idUnidadeConservacao',
                  comunidade.idEstruturaPonto ? 'idFinanciador' : '',
                  'idMunicipio',
                ])
              }
              showConfirmation
              onClick={handleSave}
            />
          ) : null}
          <ButtonSp
            className="p-button-secondary"
            label="Voltar"
            icon="pi pi-chevron-circle-left"
            onClick={handleBack}
          />
        </div>
      </>
    </>
  );
}

export default ComunidadeCrud;

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { Dialog } from 'primereact/dialog';
import ButtonSp from '../../components/ButtonSp';

import LabelSp from '../../components/LabelSp';
import { showMessage } from '../../components/MessageDialog';
import AgendamentoService from '../../services/AgendamentoService';

import { cloneObj, errorHandle, formatDate } from '../../util/functions';
import { AgendamentoModel, SitAtAgendamento, TpColaboradorPerfil } from '../../util/Models';
import { StateScreen } from '../constants';

import PacienteCrud from '../Paciente/crud';

import InputTextSp from '../../components/InputTextSp';
import DropdownSp from '../../components/DropdownSp';
import InputCurrencySp from '../../components/InputCurrencySp';
import {
  listaMotivoNaoAtAgendamento,
  listaPeriodicidadeAcompanhamento,
  listaSituacaoAgendamento,
  listaTipoPacienteAtendimento,
} from '../../config/Constantes';

import { Container } from './styles';
import PacienteService from '../../services/PacienteService';
import { store } from '../../store';
import { showLookup } from '../../store/modules/dialog/actions';
import IdentidadeGeneroService from '../../services/IdentidadeGeneroService';
import InputTextareaSp from '../../components/InputTextareaSp';
import DropDownLazy from '../../components/DropdownLazy';
import ComunidadeService from '../../services/ComunidadeService';
import ColaboradorService from '../../services/ColaboradorService';
import AuthService from '../../services/AuthService';
import HorarioAgendamento from '../../components/HorarioAgendamento';
import history from '../../services/history';
import { handleContatoSolicitante, handleLinkVideoConferencia } from './renders';

import ProntuarioReport from '../../components/ProntuarioReport';

function AgendamentoCrud(props) {
  AgendamentoCrud.propTypes = {
    stateScreen: PropTypes.string.isRequired,
    idSelected: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    closeOnSaveCancel: PropTypes.bool,
    setShowContatoSolicitante: PropTypes.func,
    setContatoSolicitante: PropTypes.func,
    setShowLinkVideoConferencia: PropTypes.func,
    setLinkVideoConferencia: PropTypes.func,
  };

  AgendamentoCrud.defaultProps = {
    messageConfirmation: 'Confirma os dados',
    showConfirmation: false,
    closeOnSaveCancel: false,
  };

  const {
    toBack,
    stateScreen,
    idSelected,
    onClose,
    closeOnSaveCancel,
    setContatoSolicitante,
    setShowContatoSolicitante,
    setShowLinkVideoConferencia,
    setLinkVideoConferencia,
  } = props;

  let eBack = '';
  let uBack = '';
  if (toBack) {
    eBack = `&toBack=${toBack}`;
    uBack = `?toBack=${toBack}`;
  }

  // states
  const [agendamento, setAgendamento] = useState(new AgendamentoModel());
  const [identidadesGenero, setIdentidadesGenero] = useState([]);
  const [crudPaciente, setCrudPaciente] = useState(false);
  const [selecionaHorario, setSelecionaHorario] = useState(false);
  const [errorLoadRecord, setErrorLoadRecord] = useState(false);
  const [comunidadeSelecionada, setComunidadeSelecionada] = useState(null);
  const [colaboradorSolicitanteSelecionado, setColaboradorSolicitanteSelecionado] = useState(
    null
  );

  const [pacienteProntuario, setPacienteProntuario] = useState();
  const [showProntuario, setShowProntuario] = useState(false);

  // useCallbacks
  const loadColaborador = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await ColaboradorService.findAll({
        nome: _nome,
        limit: 50,
        listaIdCategorias: '[1,2,3]',
        inativo: 'false', // tem q ser string pq se nao for não vai para o filter
      });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);
  const loadIdentidadeGenero = useCallback(async () => {
    const r = await IdentidadeGeneroService.findAll({
      limit: 9999,
    });

    const retorno = r.items.map(e => {
      return {
        label: e.nome,
        value: e.id,
      };
    });

    setIdentidadesGenero(retorno);
  }, []);

  const loadComunidade = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await ComunidadeService.findAll({ nome: _nome, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadRecord = useCallback(async _id => {
    try {
      const retorno = await AgendamentoService.findById(_id);

      if (retorno.paciente.comunidade) {
        retorno.paciente.comunidadeNome = retorno.paciente.comunidade.nome;
      } else {
        retorno.paciente.comunidadeNome = retorno.paciente.nomeComunidade;
      }

      if (retorno.paciente.municipio) {
        retorno.paciente.municipioNome = retorno.paciente.municipio.nome;
      } else {
        retorno.paciente.municipioNome = '';
      }

      if (retorno.paciente.dataNascimento) {
        retorno.paciente.dataNascimento = new Date(retorno.paciente.dataNascimento);
      }
      const comunidade = {
        label: retorno.comunidade.nome,
        value: retorno.comunidade.id,
      };

      const colaboradorSolicitante = {
        label: retorno.colaboradorSolicitante.nome,
        value: retorno.colaboradorSolicitante.id,
      };

      setColaboradorSolicitanteSelecionado(colaboradorSolicitante);

      setAgendamento(retorno);
      setComunidadeSelecionada(comunidade);

      setErrorLoadRecord(false);
    } catch (err) {
      setErrorLoadRecord(true);
      errorHandle(err);
    }
  }, []);

  const printProntuario = async paciente => {
    setPacienteProntuario(paciente);
    setShowProntuario(true);
  };

  // funcoes
  function viewMode() {
    return stateScreen === StateScreen.stView || errorLoadRecord;
  }

  function validateFieldsAgendamentomento() {
    if (!agendamento.idColaboradorSolicitante) {
      toast.error('Falta selecionar o solicitante.');
      return false;
    }
    if (!agendamento.dataHoraAgendamento) {
      toast.error('Falta selecionar a Data/hora.');
      return false;
    }

    if (
      agendamento.situacao === SitAtAgendamento.NAO_REALIZADO &&
      agendamento.motivoNaoAtendimento === null
    ) {
      toast.error('Falta informar o motivo do não atendimento.');
      return false;
    }

    if (!agendamento.idComunidade) {
      toast.error('Falta selecionar o local do atendimento.');
      return false;
    }

    if (!agendamento.idPaciente) {
      toast.error('Falta selecionar o paciente.');
      return false;
    }

    if (agendamento.tipoPaciente === null) {
      toast.error('Falta selecionar o Tipo Atendimento do Paciente.');
      return false;
    }

    if (agendamento.tipoPeriodicidade === null) {
      toast.error('Falta selecionar a Periodicidade de Acompanhamento.');
      return false;
    }

    if (!agendamento.queixaPrincipal || agendamento.queixaPrincipal.trim() === '') {
      toast.error('Falta selecionar a Queixa principal.');
      return false;
    }

    return true;
  }

  const confirmeHorario = retorno => {
    setAgendamento({
      ...agendamento,
      dataHoraAgendamento: retorno.horario,
      idColaborador: retorno.idColaborador,
      colaborador: retorno.colaborador,
      idAtendimentoTipo: retorno.idAtendimentoTipo,
      atendimentoTipo: retorno.atendimentoTipo,
      idConfigAgenda: retorno.idConfigAgenda,
    });
  };

  function handleBack() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      showMessage('Confirmação', 'Abandonar mudanças?', idx => {
        if (idx === 1) {
          if (stateScreen === StateScreen.stInsert || closeOnSaveCancel) {
            onClose();
          } else {
            history.push(`/agendamentos/${idSelected}?view${eBack}`);
          }
        }
      });
    }
  }

  function handleSave() {
    if (!validateFieldsAgendamentomento()) {
      return;
    }
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      salvarRecord();
    }
  }

  async function salvarRecord() {
    try {
      let retorno;
      const agendamentoSalvar = cloneObj(agendamento);

      if (stateScreen === StateScreen.stInsert) {
        retorno = await AgendamentoService.insert(agendamentoSalvar);
      } else {
        retorno = await AgendamentoService.update(agendamentoSalvar);
      }
      toast.success('Registro salvo com sucesso.');
      if (closeOnSaveCancel) {
        onClose(retorno);
      } else {
        history.push(`/agendamentos/${retorno.id}?view${eBack}`);
      }
      // onClose(retorno);
    } catch (err) {
      errorHandle(err);
    }
  }

  // useEffects
  useEffect(() => {
    if (stateScreen === StateScreen.stUpdate || stateScreen === StateScreen.stView) {
      loadRecord(idSelected);
    } else if (stateScreen === StateScreen.stInsert) {
      let colaborador;
      let comunidade;
      const novo = new AgendamentoModel();
      const colaboradorLogado = AuthService.getColaborador();

      if (colaboradorLogado) {
        colaborador = { label: colaboradorLogado.nome, value: colaboradorLogado.id };
        if (colaborador) {
          novo.colaboradorSolicitante = {
            id: colaborador.value,
            nome: colaborador.label,
          };
          novo.idColaboradorSolicitante = colaborador.value;
        }

        if (
          colaboradorLogado.idColaboradorPerfil ===
            TpColaboradorPerfil.AGENTE_SAUDE_RIBEIRINHO &&
          colaboradorLogado.comunidade
        ) {
          comunidade = {
            label: colaboradorLogado.comunidade.nome,
            value: colaboradorLogado.comunidade.id,
          };
          novo.comunidade = {
            id: comunidade.value,
            nome: comunidade.label,
          };
          novo.idComunidade = comunidade.value;
        }
      }

      setAgendamento(novo);
      setColaboradorSolicitanteSelecionado(colaborador);
      setComunidadeSelecionada(comunidade);
    }
    loadIdentidadeGenero();
  }, [loadRecord, idSelected, stateScreen, loadIdentidadeGenero]);

  const lookupPaciente = () => {
    const { dispatch } = store;
    const lookupConfig = PacienteService.getLookupConfig(objReturn => {
      const {
        id,
        nome,
        dataNascimento,
        cartaoSus,
        cpf,
        comunidadeNome,
        municipioNome,
        nomeMae,
        idadeTexto,
        idIdentidadeGenero,
        nomePai,
      } = objReturn;

      const paciente = {
        id,
        nome,
        dataNascimento,
        cartaoSus,
        cpf,
        comunidadeNome,
        nomeMae,
        idadeTexto,
        municipioNome,
        idIdentidadeGenero,
        nomePai,
      };
      setAgendamento({ ...agendamento, paciente, idPaciente: paciente.id });
    });

    dispatch(showLookup(lookupConfig));
  };

  // renders
  const renderCrudPaciente = () => {
    return (
      <Dialog
        visible={crudPaciente}
        contentStyle={{ minHeight: 470 }}
        style={{ minWidth: '380px', maxWidth: '1024px', width: '95%' }}
        modal
        closable={false}
        appendTo={document.body}
        header="Cadastro de Paciente"
        onHide={() => {
          setCrudPaciente(false);
        }}
      >
        <Container className="container-page">
          <div className="p-grid">
            <PacienteCrud
              idSelected={0}
              stateScreen={StateScreen.stInsert}
              onClose={crud => {
                if (crud) {
                  setAgendamento({ ...agendamento, paciente: crud, idPaciente: crud.id });
                }
                setCrudPaciente(false);
              }}
            />
          </div>
        </Container>
      </Dialog>
    );
  };

  let dataSelecaoHorario;
  if (agendamento.dataHoraAgendamento) {
    dataSelecaoHorario = new Date(agendamento.dataHoraAgendamento);
  }

  // render principal
  return (
    <>
      {showProntuario ? (
        <ProntuarioReport
          paciente={pacienteProntuario}
          visible={showProntuario}
          setVisible={() => {
            setShowProntuario(false);
          }}
        />
      ) : null}
      {crudPaciente ? renderCrudPaciente() : null}

      {selecionaHorario ? (
        <HorarioAgendamento
          setVisible={setSelecionaHorario}
          visible={selecionaHorario}
          data={dataSelecaoHorario}
          idAtendimentoTipo={agendamento.idAtendimentoTipo}
          idAgendamentoAtual={agendamento.id}
          handleConfirm={ret => confirmeHorario(ret)}
        />
      ) : null}

      <div className="p-col-12 p-sm-3 p-lg-3 p-fluid">
        <LabelSp>Situação Atendimento</LabelSp>
        <DropdownSp
          placeholder="Selecione"
          value={agendamento.situacao}
          options={listaSituacaoAgendamento}
          disabled={
            viewMode() ||
            stateScreen === StateScreen.stInsert ||
            AuthService.getColaborador().idColaboradorPerfil ===
              TpColaboradorPerfil.AGENTE_SAUDE_RIBEIRINHO
          }
          onChange={e => {
            if (
              e?.value === SitAtAgendamento.REALIZADO ||
              e?.value === SitAtAgendamento.EM_ANDAMENTO
            ) {
              toast.warn(
                'Não é possível definir um agendamento como "Realizado/Em Andamento" manualmente. Use o menu "Iniciar Atendimento".',
                { autoClose: 3000 }
              );
              return;
            }
            let { motivoNaoAtendimento } = agendamento;
            if (e?.value !== SitAtAgendamento.NAO_REALIZADO) {
              motivoNaoAtendimento = null;
            }
            setAgendamento({
              ...agendamento,
              motivoNaoAtendimento,
              situacao: e?.value,
            });
          }}
        />
      </div>

      <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
        <LabelSp>Motivo não atendimento</LabelSp>
        <DropdownSp
          required
          placeholder={
            agendamento.situacao === SitAtAgendamento.NAO_REALIZADO ? 'Selecione' : ''
          }
          value={agendamento.motivoNaoAtendimento}
          options={listaMotivoNaoAtAgendamento}
          disabled={
            viewMode() ||
            agendamento.situacao !== SitAtAgendamento.NAO_REALIZADO ||
            AuthService.getColaborador().idColaboradorPerfil ===
              TpColaboradorPerfil.AGENTE_SAUDE_RIBEIRINHO
          }
          onChange={e => {
            setAgendamento({
              ...agendamento,
              motivoNaoAtendimento: e?.value,
            });
          }}
        />
      </div>

      <div className="p-col-12 p-sm-5 p-lg-5 p-fluid">
        <LabelSp>Solicitante</LabelSp>
        <div className="p-inputgroup">
          <ButtonSp
            disabled={!agendamento.idColaboradorSolicitante}
            icon="pi pi-user"
            className="p-button-secondary buttons"
            title="Contato do Solicitante"
            onClick={() =>
              handleContatoSolicitante(
                agendamento.idColaboradorSolicitante,
                setContatoSolicitante,
                setShowContatoSolicitante
              )
            }
          />
          <DropDownLazy
            required
            autoLoad
            disabled={
              viewMode() ||
              AuthService.getColaborador().idColaboradorPerfil ===
                TpColaboradorPerfil.AGENTE_SAUDE_RIBEIRINHO
            }
            placeholder="Selecione"
            onChange={e => {
              const colaboradorSolicitante = e ? { id: e.value, nome: e.label } : null;
              setColaboradorSolicitanteSelecionado(e);
              setAgendamento({
                ...agendamento,
                colaboradorSolicitante,
                idColaboradorSolicitante: e?.value,
              });
            }}
            value={colaboradorSolicitanteSelecionado}
            onFilter={async txtFilter => {
              const retorno = await loadColaborador(txtFilter);
              return retorno;
            }}
          />
        </div>
      </div>

      <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
        <LabelSp>Data/Hora</LabelSp>
        <div className="p-inputgroup">
          <ButtonSp
            disabled={viewMode()}
            icon="pi pi-calendar"
            className="p-button-primary buttons"
            title="Seleciona Horário/Profissional"
            onClick={() => setSelecionaHorario(true)}
          />

          <InputTextSp
            requiredDisabled
            disabled
            title="Seleciona Horário"
            maxLength={100}
            value={formatDate(agendamento.dataHoraAgendamento, 'dd/MM/yyyy HH:mm')}
            // onChange={e => setAgendamento({ ...agendamento, nome: e.target.value })}
          />
        </div>
      </div>
      <div className="p-col-6 p-sm-6 p-lg-6 p-fluid">
        <LabelSp>Profissional</LabelSp>
        <div className="p-inputgroup">
          <ButtonSp
            disabled={viewMode()}
            icon="pi pi-search"
            className="p-button-secondary buttons"
            title="Seleciona Horário/Profissional"
            onClick={() => setSelecionaHorario(true)}
          />
          <InputTextSp
            requiredDisabled
            disabled
            required
            maxLength={100}
            value={agendamento.colaborador.nome || 'Não definido'}
            // onChange={e => setAgendamento({ ...agendamento, nome: e.target.value })}
          />
        </div>
      </div>

      <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
        <LabelSp>Tipo Atendimento</LabelSp>
        <InputTextSp
          requiredDisabled
          disabled
          required
          maxLength={100}
          value={agendamento.atendimentoTipo.nome || 'Não definido'}
          // onChange={e => setAgendamento({ ...agendamento, nome: e.target.value })}
        />
      </div>

      <div className="p-col-12 p-sm-6 p-lg-6 p-fluid">
        <LabelSp>Local Atendimento</LabelSp>
        <div className="p-inputgroup">
          <ButtonSp
            disabled={!agendamento.idComunidade}
            icon="pi pi-video"
            className="p-button-secondary buttons"
            title="Link da sala de videoconferência"
            onClick={() =>
              handleLinkVideoConferencia(
                agendamento.idComunidade,
                setLinkVideoConferencia,
                setShowLinkVideoConferencia
              )
            }
          />
          <DropDownLazy
            required
            autoLoad
            disabled={viewMode()}
            placeholder="Selecione"
            onChange={e => {
              const comunidade = e ? { id: e.value, nome: e.label } : null;
              setComunidadeSelecionada(e);
              setAgendamento({
                ...agendamento,
                comunidade,
                idComunidade: e?.value,
              });
            }}
            value={comunidadeSelecionada}
            onFilter={async txtFilter => {
              const retorno = await loadComunidade(txtFilter);
              return retorno;
            }}
          />
        </div>
      </div>

      <div className="p-col-12 p-sm-6 p-lg-6 p-fluid">
        <LabelSp>Paciente</LabelSp>
        <div className="p-inputgroup">
          <ButtonSp
            disabled={viewMode()}
            icon="pi pi-search"
            className="p-button-secondary buttons"
            title="Buscar Paciente"
            onClick={() => lookupPaciente()}
          />
          <ButtonSp
            disabled={viewMode()}
            icon="pi pi-plus-circle"
            className="buttons"
            title="Inserir Paciente"
            style={{ marginLeft: 1 }}
            onClick={() => setCrudPaciente(true)}
          />
          {AuthService.checkRoles('ATENDIMENTO_PRINT') && (
            <ButtonSp
              disabled={
                !AuthService.checkRoles('ATENDIMENTO_PRINT') || !agendamento.idPaciente
              }
              icon="pi pi-print"
              className="p-button-warning buttons"
              title="Prontuário"
              style={{ marginLeft: 1 }}
              onClick={() =>
                printProntuario({
                  id: agendamento.idPaciente,
                  nome: agendamento.paciente.nome,
                })
              }
            />
          )}

          <InputTextSp requiredDisabled value={agendamento.paciente.nome} disabled />
        </div>
      </div>

      <div className="p-col-7 p-sm-2 p-lg-2 p-fluid">
        <LabelSp>Gênero</LabelSp>
        <DropdownSp
          disabled
          value={agendamento.paciente.idIdentidadeGenero}
          options={identidadesGenero}
          filterInputAutoFocus={false}
        />
      </div>

      <div className="p-col-5 p-sm-2 p-lg-2 p-fluid">
        <LabelSp>Nascimento</LabelSp>
        <InputTextSp
          value={formatDate(agendamento.paciente.dataNascimento || null, 'dd/MM/yyyy')}
          disabled
        />
      </div>
      <div className="p-col-12 p-sm-3 p-lg-3 p-fluid">
        <LabelSp>Idade</LabelSp>
        <InputTextSp value={agendamento.paciente.idadeTexto} disabled />
      </div>
      <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
        <LabelSp>Cartão SUS</LabelSp>
        <InputTextSp value={agendamento.paciente.cartaoSus} disabled />
      </div>
      <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
        <LabelSp>CPF</LabelSp>
        <InputTextSp value={agendamento.paciente.cpf} disabled />
      </div>
      <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
        <LabelSp>Mãe/Responsável</LabelSp>
        <InputTextSp value={agendamento.paciente.nomeMae} disabled />
      </div>
      <div className="p-col-12 p-sm-3 p-lg-3 p-fluid">
        <LabelSp>Nome do Pai</LabelSp>
        <InputTextSp value={agendamento.paciente.nomePai} disabled />
      </div>
      <div className="p-col-12 p-sm-5 p-lg-5 p-fluid">
        <LabelSp>Comunidade do Paciente</LabelSp>
        <InputTextSp value={agendamento.paciente.comunidadeNome} disabled />
      </div>

      <div className="p-col-12 p-sm-5 p-lg-5 p-fluid">
        <LabelSp>Município do Paciente</LabelSp>
        <InputTextSp value={agendamento.paciente.municipioNome} disabled />
      </div>

      <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
        <LabelSp>Peso(KG)</LabelSp>
        <InputCurrencySp
          digits={3}
          disabled={viewMode()}
          value={agendamento.peso}
          onChange={(e, v) => setAgendamento({ ...agendamento, peso: v })}
        />
      </div>

      <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
        <LabelSp>Altura(M)</LabelSp>
        <InputCurrencySp
          digits={3}
          disabled={viewMode()}
          value={agendamento.altura}
          onChange={(e, v) => setAgendamento({ ...agendamento, altura: v })}
        />
      </div>
      <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
        <LabelSp>Pressão Arterial</LabelSp>
        <InputTextSp
          value={agendamento.pressaoArterial}
          maxLength={30}
          disabled={viewMode()}
          onChange={e => setAgendamento({ ...agendamento, pressaoArterial: e.target.value })}
        />
      </div>

      <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
        <LabelSp>Tipo At. Paciente</LabelSp>
        <DropdownSp
          required
          disabled={viewMode()}
          value={agendamento.tipoPaciente}
          options={listaTipoPacienteAtendimento}
          filterInputAutoFocus={false}
          onChange={e => setAgendamento({ ...agendamento, tipoPaciente: e.value })}
        />
      </div>
      <div className="p-col-12 p-sm-6 p-lg-6 p-fluid">
        <LabelSp>Periodicidade Acompanhamento</LabelSp>
        <DropdownSp
          required
          disabled={viewMode()}
          value={agendamento.tipoPeriodicidade}
          options={listaPeriodicidadeAcompanhamento}
          filterInputAutoFocus={false}
          onChange={e => setAgendamento({ ...agendamento, tipoPeriodicidade: e.value })}
        />
      </div>

      <div className="p-col-12 p-sm-12 p-lg-12 p-fluid">
        <LabelSp>Queixa Principal</LabelSp>
        <InputTextareaSp
          required
          resize={false}
          rows={3}
          value={agendamento.queixaPrincipal}
          disabled={viewMode()}
          onChange={e => {
            setAgendamento({ ...agendamento, queixaPrincipal: e.target.value });
          }}
        />
      </div>

      <div className="p-col-12 p-lg-12" style={{ textAlign: 'start' }}>
        {!viewMode() ? (
          <ButtonSp
            className="p-button-success"
            icon="pi pi-save"
            label="Salvar"
            showConfirmation
            onClick={handleSave}
          />
        ) : null}

        {viewMode() ? (
          <ButtonSp
            className="p-button-primary"
            icon="pi pi-pencil"
            label="Alterar"
            disabled={!AuthService.checkRoles('AGENDAMENTO_UPDATE')}
            onClick={() => {
              if (
                agendamento.situacao === SitAtAgendamento.REALIZADO ||
                agendamento.situacao === SitAtAgendamento.EM_ANDAMENTO
              ) {
                toast.error(
                  'Não será possível alterar o agendamento, pois já existe um atendimento vinculado.'
                );
                return false;
              }
              history.push(`/agendamentos/${idSelected}${uBack}`);
            }}
          />
        ) : null}

        <ButtonSp
          className={viewMode() ? 'p-button-secondary' : 'p-button-danger'}
          label={viewMode() ? 'Voltar' : 'Cancelar'}
          icon="pi pi-chevron-circle-left"
          onClick={handleBack}
        />
      </div>
    </>
  );
}

export default AgendamentoCrud;

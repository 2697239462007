import styled from 'styled-components';

export const Container = styled.div`
  max-width: 350px;
  text-align: center;
  margin: 5px;

  form {
    padding: 20px;
    padding-top: 28px;
    padding-bottom: 15px;
    border-radius: 10px;
    border-width: 1px;
    border-style: solid;
    background-color: #ffff;
    border-color: #e3e4e6;
    /* background-color: #e9eaeb;
    border-color: #e3e4e6; */

    /* opacity: 0.1; */

    display: flex;
    flex-direction: column;
    text-align: start;
  }
  .logo {
    width: 200px;
  }
  .div-google-play {
    display: flex;
    flex: 1;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: rgb(227, 228, 230, 0.7);
    margin: 3px;
    border-radius: 3px;
  }

  .div-google-play-link {
    display: flex;
    flex: 1;
    flex-direction: row;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-bottom: 2px;
  }
`;

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import ButtonSp from '../../components/ButtonSp';
import DropDownLazy from '../../components/DropdownLazy';

import InputCurrencySp from '../../components/InputCurrencySp';

import InputTextSp from '../../components/InputTextSp';
import InputTextareaSp from '../../components/InputTextareaSp';
import LabelSp from '../../components/LabelSp';
import { showMessage, showMessageCustom } from '../../components/MessageDialog';
import AtendimentoTipoService from '../../services/AtendimentoTipoService';
import AtendimentoTipoEspecificoService from '../../services/AtendimentoTipoEspecificoService';
import AtendimentoService from '../../services/AtendimentoService';
import ColaboradorService from '../../services/ColaboradorService';
import {
  calcNaxItemsPage,
  cloneObj,
  errorHandle,
  formatDate,
  generateUUID,
  isScreenMobile,
} from '../../util/functions';
import {
  AtendimentoModel,
  TpColaborador,
  TpAtendimento,
  TpAtendimentoEspecifico,
  TpColaboradorPerfil,
  SitAtendimento,
} from '../../util/Models';
import { StateScreen } from '../constants';

import ComunidadeService from '../../services/ComunidadeService';
import { cfgPtBr, comorbidades } from '../../config/Constantes';
import RegulacaoService from '../../services/RegulacaoService';
import CalendarSp from '../../components/CalendarSp';
import AuthService from '../../services/AuthService';
import DropdownSp from '../../components/DropdownSp';
import MultiSelectSp from '../../components/MultiSelectSp';
import CheckboxSp from '../../components/CheckboxSp';
import SelectButtons from '../../components/SelectButtons';
import { store } from '../../store';
import PacienteService from '../../services/PacienteService';
import { showLookup } from '../../store/modules/dialog/actions';

import PacienteCrud from '../Paciente/crud';
import { Container } from './styles';
import PdfView from '../../components/PdfView';
import DataTableSp from '../../components/DataTableSp';
import BotaoMenuGrid from '../../components/BotaoMenuGrid';
import EditorSp from '../../components/EditorSp';
import history from '../../services/history';
import CrudAnexo from './crudAnexo';
import ProntuarioReport from '../../components/ProntuarioReport';

function AtendimentoCrud(props) {
  AtendimentoCrud.propTypes = {
    stateScreen: PropTypes.string.isRequired,
    idSelected: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    closeOnSaveCancel: PropTypes.bool,
  };

  AtendimentoCrud.defaultProps = {
    messageConfirmation: 'Confirma os dados',
    showConfirmation: false,
    closeOnSaveCancel: false,
  };

  const { toBack, stateScreen, idSelected, onClose, closeOnSaveCancel } = props;

  let eBack = '';
  let uBack = '';
  if (toBack) {
    eBack = `&toBack=${toBack}`;
    uBack = `?toBack=${toBack}`;
  }

  // states
  const [atendimento, setAtendimento] = useState(new AtendimentoModel());
  const [listaAtendimentoTipo, setListaAtendimentoTipo] = useState([]);
  const [listaComorbidades, setListaComorbidades] = useState([]);
  const [comorbidadesSelecionadas, setComorbidadesSelecionadas] = useState([
    'comorbidadeNaoPossui',
  ]);
  const [listaAtendimentoTipoEspecifico, setListaAtendimentoTipoEspecifico] = useState([]);
  const [colaboradorSelecionado, setColaboradorSelecionado] = useState(null);
  const [comunidadeSelecionada, setComunidadeSelecionada] = useState(null);

  const [regulacaoSelecionada, setRegulacaoSelecionada] = useState(null);
  const [errorLoadRecord, setErrorLoadRecord] = useState(false);
  const [paginaSelecionada, setPaginaSelecionada] = useState(0);
  const [crudPaciente, setCrudPaciente] = useState(false);
  const [pacienteProntuario, setPacienteProntuario] = useState();
  const [showProntuario, setShowProntuario] = useState(false);

  const [pageLimit] = useState(calcNaxItemsPage(8, 12, 16));
  const [showEditor, setShowEditor] = useState(false);

  const [impressos, setImpressos] = useState([]);
  const [impressoSelecionado, setImpressoSelecionado] = useState();
  const [editorDisabled, setEditorDisabled] = useState(false);

  const [impressoSrc, setImpressoSrc] = useState(undefined);
  const [showImpresso, setShowImpresso] = useState(false);

  // useCallbacks
  const loadAtendimentoTipo = useCallback(async () => {
    const r = await AtendimentoTipoService.findAll({ limit: 150 });

    const retorno = r.items.map(e => {
      return {
        label: e.nome,
        value: e.id,
      };
    });

    setListaAtendimentoTipo(retorno);
  }, []);

  const loadAtendimentoTipoEspecifico = useCallback(async () => {
    const r = await AtendimentoTipoEspecificoService.findAll({ limit: 150 });

    const retorno = r.items.map(e => {
      return {
        label: e.nome,
        value: e.id,
      };
    });

    setListaAtendimentoTipoEspecifico(retorno);
  }, []);

  const loadColaborador = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await ColaboradorService.findAll({
        nome: _nome,
        limit: 50,
        listaIdCategorias: '[1,2,3]',
        inativo: 'false', // tem q ser string pq se nao for não vai para o filter
      });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadRegulacao = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await RegulacaoService.findAll({ nome: _nome, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadComunidade = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await ComunidadeService.findAll({ nome: _nome, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadRecord = useCallback(async _id => {
    try {
      const retorno = await AtendimentoService.findById(_id);
      if (retorno.paciente.comunidade) {
        retorno.paciente.comunidadeNome = retorno.paciente.comunidade.nome;
      } else {
        retorno.paciente.comunidadeNome = retorno.paciente.nomeComunidade;
      }

      if (retorno.paciente.municipio) {
        retorno.paciente.municipioNome = retorno.paciente.municipio.nome;
      } else {
        retorno.paciente.municipioNome = '';
      }

      if (retorno.paciente.dataNascimento) {
        retorno.paciente.dataNascimento = new Date(retorno.paciente.dataNascimento);
      }

      if (retorno.horaAtendimento) {
        retorno.horaAtendimento = new Date(retorno.horaAtendimento);
      }

      if (retorno.dataAtendimento) {
        retorno.dataAtendimento = new Date(retorno.dataAtendimento);
      }

      if (retorno.dum) {
        retorno.dum = new Date(retorno.dum);
      }

      if (retorno.dataRetorno) {
        retorno.dataRetorno = new Date(retorno.dataRetorno);
      }

      setAtendimento(retorno);
      const impessosTemp = retorno.atendimentoImpresso.map(e => {
        return { ...e, uuid: generateUUID() };
      });

      setImpressos(impessosTemp);

      const colaborador = {
        label: retorno.colaborador.nome,
        value: retorno.colaborador.id,
      };

      const comunidade = {
        label: retorno.comunidade.nome,
        value: retorno.comunidade.id,
      };

      const regulacao = {
        label: retorno.regulacao.nome,
        value: retorno.regulacao.id,
      };

      const comorbidadesPaciente = [];

      if (retorno.comorbidadeDm) {
        comorbidadesPaciente.push('comorbidadeDm');
      }
      if (retorno.comorbidadeHas) {
        comorbidadesPaciente.push('comorbidadeHas');
      }
      if (retorno.comorbidadeTb) {
        comorbidadesPaciente.push('comorbidadeTb');
      }
      if (retorno.comorbidadeHs) {
        comorbidadesPaciente.push('comorbidadeHs');
      }
      if (retorno.comorbidadeNaoPossui) {
        comorbidadesPaciente.push('comorbidadeNaoPossui');
      }
      if (retorno.comorbidadeNaoVerificado) {
        comorbidadesPaciente.push('comorbidadeNaoVerificado');
      }
      if (retorno.comorbidadeOutras) {
        comorbidadesPaciente.push('comorbidadeOutras');
      }

      setComorbidadesSelecionadas(comorbidadesPaciente);
      setColaboradorSelecionado(colaborador);
      setComunidadeSelecionada(comunidade);
      setRegulacaoSelecionada(regulacao);
      setErrorLoadRecord(false);
    } catch (err) {
      setErrorLoadRecord(true);
      errorHandle(err);
    }
  }, []);

  // funcoes
  function viewMode() {
    return stateScreen === StateScreen.stView || errorLoadRecord;
  }

  function handleBack() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      showMessage('Confirmação', 'Abandonar mudanças?', idx => {
        if (idx === 1) {
          if (stateScreen === StateScreen.stInsert || closeOnSaveCancel) {
            onClose();
          } else {
            history.push(`/atendimentos/${idSelected}?view${eBack}`);
          }
        }
      });
    }
  }

  function validateAuthColaborador(idColaborador) {
    if (
      AuthService.getColaborador().id !== idColaborador &&
      AuthService.getColaborador().idColaboradorPerfil !== TpColaboradorPerfil.ADMINISTRADOR &&
      AuthService.getColaborador().idColaboradorPerfil !== TpColaboradorPerfil.GESTOR_FAS
    ) {
      toast.error(
        'Você não tem permissão para alterar/excluir atendimentos de outros profissionais.'
      );
      return false;
    }
    return true;
  }

  function validateFieldsAtendimento() {
    if (
      comorbidadesSelecionadas.length > 1 &&
      (comorbidadesSelecionadas.includes('comorbidadeNaoVerificado') ||
        comorbidadesSelecionadas.includes('comorbidadeNaoPossui'))
    ) {
      toast.error(
        'Tipos de comorbidades "Não verificado ou "Não possui" não devem ser marcadas em conjunto com outras.'
      );
      return false;
    }

    if (!atendimento.duracaoAtendimento) {
      toast.error('Falta informar a Duração(Min.) do Atendimento.');
      return false;
    }

    if (!atendimento.idAtendimentoTipo) {
      toast.error('Falta informar o Tipo de Atendimento.');
      return false;
    }

    if (!atendimento.idColaborador) {
      toast.error('Falta informar o Profissional.');
      return false;
    }

    if (!atendimento.idComunidade) {
      toast.error('Falta informar o Local do Atendimento.');
      return false;
    }

    if (!atendimento.idPaciente) {
      toast.error('Falta informar o Paciente.');
      return false;
    }

    if (!atendimento.idRegulacao) {
      toast.error('Falta informar a Regulação.');
      return false;
    }
    if (!atendimento.idAtendimentoTipoEspecifico) {
      toast.error('Falta informar o Tipo de Atendimento Específico.');
      return false;
    }

    if (!comorbidadesSelecionadas || comorbidadesSelecionadas.length === 0) {
      toast.error('Falta informar as comorbidades.');
      return false;
    }

    if (
      comorbidadesSelecionadas.includes('comorbidadeOutras') &&
      !atendimento.outrasComorbidades
    ) {
      toast.error('Falta informar Outras Comorbidades.');
      return false;
    }

    if (
      atendimento.idAtendimentoTipo === TpAtendimento.MEDICO &&
      !atendimento.hipoteseDiagnostica
    ) {
      toast.error('Falta informar a Hipótese Diagnóstica.');
      return false;
    }

    if (!atendimento.queixaPrincipal) {
      toast.error('Falta informar a Queixa Principal.');
      return false;
    }

    if (!atendimento.historiaClinica) {
      toast.error('Falta informar a História Cínica.');
      return false;
    }

    if (
      atendimento.idAtendimentoTipo === TpAtendimento.MEDICO ||
      atendimento.idAtendimentoTipo === TpAtendimento.ENFERMAGEM
    ) {
      if (!atendimento.prescricoes) {
        toast.error('Falta informar as prescrições.');
        return false;
      }
    }

    // if (!atendimento.examesLaboratoriais) {
    //   toast.error('Falta informar os Exames laboratoriais.');
    //   return false;
    // }
    if (!atendimento.orientacoesRealizadas) {
      toast.error('Falta informar as Orientações.');
      return false;
    }

    if (atendimento.idAtendimentoTipoEspecifico === TpAtendimentoEspecifico.SAUDE_MULHER) {
      if (!atendimento.dum) {
        toast.error('Falta informar a DUM.');
        return false;
      }

      if (!atendimento.igSemanal) {
        toast.error('Falta informar a IG Semanal.');
        return false;
      }

      if (!atendimento.pesoImc) {
        toast.error('Falta informar o Peso/IMC.');
        return false;
      }
    }

    if (atendimento.necessitaAtMedico && !atendimento.necessidadeAtMedico) {
      toast.error('Falta informar a Necessidade de At. Médico.');
      return false;
    }

    if (atendimento.necessitaAtPsicologico && !atendimento.necessidadeAtPsicologico) {
      toast.error('Falta informar a Necessidade de At. Psicológico.');
      return false;
    }

    return true;
  }

  function handleSave() {
    if (!validateFieldsAtendimento()) {
      return;
    }

    const arrayButtons = [
      {
        label: 'Salvar',
        title: 'Apenas Salvar',
        icon: 'pi pi-check',
        className: 'p-button-success',
      },
      {
        label: 'Finalizar',
        title: 'Salvar e Finalizar',
        icon: 'pi pi-check',
        className: 'p-button-secundary',
      },
      {
        label: 'Cancelar',
        title: 'Cancelar operação',
        icon: 'pi pi-times',
        className: 'p-button-danger',
      },
    ];

    if (atendimento.situacao === SitAtendimento.REALIZADO) {
      arrayButtons.splice(1, 1);
    }

    showMessageCustom('Confirmação', 'Confirma os dados?', arrayButtons, idx => {
      if (idx === 1 || (arrayButtons.length === 3 && idx === 2)) {
        if (stateScreen === StateScreen.stView) {
          onClose();
        } else {
          validaAtMesmoDiaEhSalvar(arrayButtons.length === 3 && idx === 2);
        }
      }
    });
  }

  async function validaAtMesmoDiaEhSalvar(finalizar) {
    if (stateScreen === StateScreen.stInsert) {
      let atendimentoExiste = false;
      try {
        const f = cloneObj(AtendimentoService.getFilter());

        f.idColaborador = undefined;
        f.dataInicio = atendimento.dataAtendimento;
        f.dataFim = atendimento.dataAtendimento;
        f.idPaciente = atendimento.idPaciente;

        const retorno = await AtendimentoService.findAll(f);

        if (retorno && Array.isArray(retorno.items) && retorno.items.length > 0) {
          atendimentoExiste = true;
        }
      } catch (_err) {
        //
      }

      if (atendimentoExiste) {
        showMessage(
          'Confirmação',
          'Já existe outro atendiemnto para o mesmo paciente o mesmo profissional nesta data. Deseja cadastrar assim mesmo?',
          idx => {
            if (idx === 1) {
              salvarRecord();
            }
          }
        );
      } else {
        salvarRecord();
      }
    } else {
      salvarRecord(finalizar);
    }
  }

  async function salvarRecord(finalizar) {
    try {
      let retorno;

      if (finalizar) {
        atendimento.situacao = SitAtendimento.REALIZADO;
      }
      atendimento.comorbidadeDm = comorbidadesSelecionadas.includes('comorbidadeDm');
      atendimento.comorbidadeHas = comorbidadesSelecionadas.includes('comorbidadeHas');
      atendimento.comorbidadeTb = comorbidadesSelecionadas.includes('comorbidadeTb');
      atendimento.comorbidadeHs = comorbidadesSelecionadas.includes('comorbidadeHs');
      atendimento.comorbidadeNaoPossui = comorbidadesSelecionadas.includes(
        'comorbidadeNaoPossui'
      );
      atendimento.comorbidadeNaoVerificado = comorbidadesSelecionadas.includes(
        'comorbidadeNaoVerificado'
      );
      atendimento.comorbidadeOutras = comorbidadesSelecionadas.includes('comorbidadeOutras');

      atendimento.atendimentoImpresso = impressos;

      if (stateScreen === StateScreen.stInsert) {
        retorno = await AtendimentoService.insert(atendimento);
      } else {
        retorno = await AtendimentoService.update(atendimento);
      }
      toast.success('Registro salvo com sucesso.');
      if (closeOnSaveCancel) {
        onClose(retorno);
      } else {
        history.push(`/atendimentos/${retorno.id}?view${eBack}`);
      }
    } catch (err) {
      errorHandle(err);
    }
  }

  const salvarImpresso = atendimentoImpresso => {
    // atendimentoImpresso.hora = formatDate(atendimentoImpresso.hora, 'HH:mm:ss');
    // atendimentoImpresso.data = formatDate(atendimentoImpresso.data, 'yyyy-MM-dd');

    const impressosTemp = cloneObj(impressos) || [];
    let indice;
    atendimentoImpresso.persistPDF = true;
    if (atendimentoImpresso.uuid) {
      impressosTemp.forEach((e, i) => {
        if (e.uuid === atendimentoImpresso.uuid) {
          indice = i;
        }
      });

      if (indice >= 0) {
        impressosTemp[indice] = atendimentoImpresso;
        setImpressos(impressosTemp);
      }
    } else {
      atendimentoImpresso.uuid = generateUUID();
      impressosTemp.push(atendimentoImpresso);

      setImpressos(impressosTemp);
    }
  };

  const carregaImpresso = (registro, _viewMode) => {
    setEditorDisabled(_viewMode);
    setImpressoSelecionado(registro);
    setShowEditor(true);
  };

  const confirmaExclusaoImpresso = registro => {
    showMessage('Confirmação', `Deseja excluir o impresso?`, idx => {
      if (idx === 1) {
        const impressosTemp = cloneObj(impressos) || [];
        let indice;
        impressosTemp.forEach((e, i) => {
          if (e.uuid === registro.uuid) {
            indice = i;
          }
        });

        if (indice >= 0) {
          impressosTemp.splice(indice, 1);
          setImpressos(impressosTemp);
        }
      }
    });
  };

  // useEffects
  useEffect(() => {
    loadAtendimentoTipo();
    loadAtendimentoTipoEspecifico();
    setListaComorbidades(comorbidades);
    if (stateScreen === StateScreen.stUpdate || stateScreen === StateScreen.stView) {
      loadRecord(idSelected);
    } else if (stateScreen === StateScreen.stInsert) {
      const novo = new AtendimentoModel();

      let colaborador;
      let atendimentoTipo;

      const colaboradorLogado = AuthService.getColaborador();

      const atendimentoTipoEspecifico = TpAtendimentoEspecifico.getObjDropDown(
        TpAtendimentoEspecifico.OUTROS
      );

      switch (colaboradorLogado.idColaboradorCategoria) {
        case TpColaborador.MEDICO:
          atendimentoTipo = TpAtendimento.getObjDropDown(TpAtendimento.MEDICO);
          colaborador = { label: colaboradorLogado.nome, value: colaboradorLogado.id };
          break;
        case TpColaborador.PSICOLOCO:
          atendimentoTipo = TpAtendimento.getObjDropDown(TpAtendimento.PSICOLOGICO);
          colaborador = { label: colaboradorLogado.nome, value: colaboradorLogado.id };

          break;
        case TpColaborador.ENFERMEIRO:
          atendimentoTipo = TpAtendimento.getObjDropDown(TpAtendimento.ENFERMAGEM);
          colaborador = { label: colaboradorLogado.nome, value: colaboradorLogado.id };
          break;

        default:
          break;
      }
      if (atendimentoTipo) {
        novo.atendimentoTipo = {
          id: atendimentoTipo.value,
          nome: atendimentoTipo.label,
        };
        novo.idAtendimentoTipo = atendimentoTipo.value;
      }

      if (colaborador) {
        novo.colaborador = {
          id: colaborador.value,
          nome: colaborador.label,
        };
        novo.idColaborador = colaborador.value;
      }

      if (atendimentoTipoEspecifico) {
        novo.atendimentoTipoEspecifico = {
          id: atendimentoTipoEspecifico.value,
          nome: atendimentoTipoEspecifico.label,
        };
        novo.idAtendimentoTipoEspecifico = atendimentoTipoEspecifico.value;
      }

      setColaboradorSelecionado(colaborador);
      setComunidadeSelecionada(null);

      setRegulacaoSelecionada(null);

      setAtendimento(novo);
    }
  }, [
    loadRecord,
    idSelected,
    stateScreen,
    loadAtendimentoTipo,
    loadAtendimentoTipoEspecifico,
  ]);

  const printProntuario = async paciente => {
    setPacienteProntuario(paciente);
    setShowProntuario(true);
  };

  const printImpresso = async idAtendimentoImpresso => {
    if (!idAtendimentoImpresso || !viewMode()) {
      toast.warn('É necessário salvar o atendimento atual para que seja gerado o PDF.');
      return;
    }
    try {
      if (!idAtendimentoImpresso) {
        return;
      }

      const retorno = await AtendimentoService.getImpresso(idAtendimentoImpresso);
      const srcPdf = URL.createObjectURL(retorno);
      setImpressoSrc(srcPdf);
      setShowImpresso(true);
    } catch (err) {
      errorHandle(err);
    }
  };

  const lookupPaciente = () => {
    const { dispatch } = store;
    const lookupConfig = PacienteService.getLookupConfig(objReturn => {
      const {
        id,
        nome,
        dataNascimento,
        cartaoSus,
        cpf,
        comunidadeNome,
        municipioNome,
        nomeMae,
        idadeTexto,
      } = objReturn;

      const paciente = {
        id,
        nome,
        dataNascimento,
        cartaoSus,
        cpf,
        comunidadeNome,
        nomeMae,
        idadeTexto,
        municipioNome,
      };
      setAtendimento({ ...atendimento, paciente, idPaciente: paciente.id });
    });

    dispatch(showLookup(lookupConfig));
  };

  // renders
  const renderCrudPaciente = () => {
    return (
      <Dialog
        visible={crudPaciente}
        contentStyle={{ minHeight: 470 }}
        style={{ minWidth: '380px', maxWidth: '1024px', width: '95%' }}
        modal
        closable={false}
        appendTo={document.body}
        header="Cadastro de Paciente"
        onHide={() => {
          setCrudPaciente(false);
        }}
      >
        <Container className="container-page">
          <div className="p-grid">
            <PacienteCrud
              idSelected={0}
              stateScreen={StateScreen.stInsert}
              onClose={crud => {
                if (crud) {
                  setAtendimento({ ...atendimento, paciente: crud, idPaciente: crud.id });
                }
                setCrudPaciente(false);
              }}
            />
          </div>
        </Container>
      </Dialog>
    );
  };

  const renderPrincipal = () => {
    const colaboradorLogado = AuthService.getColaborador();
    return (
      <>
        {showProntuario ? (
          <ProntuarioReport
            paciente={pacienteProntuario}
            visible={showProntuario}
            setVisible={() => {
              setShowProntuario(false);
            }}
          />
        ) : null}

        {crudPaciente ? renderCrudPaciente() : null}
        <div
          className="p-grid p-col-12 p-sm-3 p-lg-3 p-fluid"
          style={{ padding: 0, margin: 0 }}
        >
          <div className="p-col-7 p-fluid">
            <LabelSp>Data</LabelSp>
            <CalendarSp
              readOnlyInput
              appendTo={document.body}
              dateFormat="dd/mm/yy"
              disabled={viewMode()}
              required
              locale={cfgPtBr}
              value={atendimento.dataAtendimento}
              onChange={e => setAtendimento({ ...atendimento, dataAtendimento: e.value })}
            />
          </div>
          <div className="p-col-5 p-fluid">
            <LabelSp>Hora</LabelSp>
            <CalendarSp
              readOnlyInput
              appendTo={document.body}
              showTime
              timeOnly
              disabled={viewMode()}
              required
              locale={cfgPtBr}
              value={atendimento.horaAtendimento}
              onChange={e => setAtendimento({ ...atendimento, horaAtendimento: e.value })}
            />
          </div>
        </div>

        <div className="p-col-12 p-sm-3 p-lg-3 p-fluid">
          <LabelSp>Tipo Atendimento</LabelSp>
          <DropdownSp
            required
            placeholder="Selecione"
            value={atendimento.idAtendimentoTipo}
            options={listaAtendimentoTipo}
            disabled={
              viewMode() ||
              (colaboradorLogado.idColaboradorPerfil !== TpColaboradorPerfil.ADMINISTRADOR &&
                colaboradorLogado.idColaboradorPerfil !== TpColaboradorPerfil.GESTOR_FAS)
            }
            onChange={e => {
              let atendimentoTipo = listaAtendimentoTipo.find(i => e.value === i.value);
              atendimentoTipo = {
                id: atendimentoTipo.value,
                nome: atendimentoTipo.label,
              };

              setAtendimento({
                ...atendimento,
                atendimentoTipo,
                idAtendimentoTipo: e?.value,
              });
            }}
          />
        </div>

        <div className="p-col-12 p-sm-6 p-lg-6 p-fluid">
          <LabelSp>Profissional</LabelSp>
          <DropDownLazy
            required
            autoLoad
            disabled={
              viewMode() ||
              (colaboradorLogado.idColaboradorPerfil !== TpColaboradorPerfil.ADMINISTRADOR &&
                colaboradorLogado.idColaboradorPerfil !== TpColaboradorPerfil.GESTOR_FAS)
            }
            placeholder="Selecione"
            onChange={e => {
              const colaborador = e ? { id: e.value, nome: e.label } : null;
              setColaboradorSelecionado(e);
              setAtendimento({
                ...atendimento,
                colaborador,
                idColaborador: e?.value,
              });
            }}
            value={colaboradorSelecionado}
            onFilter={async txtFilter => {
              const retorno = await loadColaborador(txtFilter);
              return retorno;
            }}
          />
        </div>

        <div className="p-col-12 p-sm-6 p-lg-5 p-fluid">
          <LabelSp>Local Atendimento</LabelSp>
          <DropDownLazy
            required
            autoLoad
            disabled={viewMode()}
            placeholder="Selecione"
            onChange={e => {
              const comunidade = e ? { id: e.value, nome: e.label } : null;
              setComunidadeSelecionada(e);
              setAtendimento({
                ...atendimento,
                comunidade,
                idComunidade: e?.value,
              });
            }}
            value={comunidadeSelecionada}
            onFilter={async txtFilter => {
              const retorno = await loadComunidade(txtFilter);
              return retorno;
            }}
          />
        </div>

        <div className="p-col-12 p-sm-6 p-lg-5 p-fluid">
          <LabelSp>Paciente</LabelSp>
          <div className="p-inputgroup">
            <ButtonSp
              disabled={viewMode()}
              icon="pi pi-search"
              className="p-button-secondary buttons"
              title="Buscar Paciente"
              onClick={() => lookupPaciente()}
            />
            <ButtonSp
              disabled={viewMode()}
              icon="pi pi-plus-circle"
              className="buttons"
              title="Inserir Paciente"
              style={{ marginLeft: 1 }}
              onClick={() => setCrudPaciente(true)}
            />
            <ButtonSp
              disabled={
                !AuthService.checkRoles('ATENDIMENTO_PRINT') || !atendimento.idPaciente
              }
              icon="pi pi-print"
              className="p-button-warning buttons"
              title="Prontuário"
              style={{ marginLeft: 1 }}
              onClick={() =>
                printProntuario({
                  id: atendimento.paciente.id,
                  nome: atendimento.paciente.nome,
                })
              }
            />

            <InputTextSp value={atendimento.paciente.nome} disabled />
          </div>
        </div>
        <div className="p-col-5 p-sm-3 p-lg-2 p-fluid">
          <LabelSp>Nascimento</LabelSp>
          <InputTextSp
            value={formatDate(atendimento.paciente.dataNascimento || null, 'dd/MM/yyyy')}
            disabled
          />
        </div>
        <div className="p-col-7 p-sm-3 p-lg-3 p-fluid">
          <LabelSp>Idade</LabelSp>
          <InputTextSp value={atendimento.paciente.idadeTexto} disabled />
        </div>
        <div className="p-col-6 p-sm-3 p-lg-2 p-fluid">
          <LabelSp>Cartão SUS</LabelSp>
          <InputTextSp value={atendimento.paciente.cartaoSus} disabled />
        </div>
        <div className="p-col-6 p-sm-3 p-lg-2 p-fluid">
          <LabelSp>CPF</LabelSp>
          <InputTextSp value={atendimento.paciente.cpf} disabled />
        </div>
        <div className="p-col-12 p-sm-6 p-lg-5 p-fluid">
          <LabelSp>Mãe/Responsável</LabelSp>
          <InputTextSp value={atendimento.paciente.nomeMae} disabled />
        </div>
        <div className="p-col-12 p-sm-6 p-lg-6 p-fluid">
          <LabelSp>Comunidade do Paciente</LabelSp>
          <InputTextSp value={atendimento.paciente.comunidadeNome} disabled />
        </div>

        <div className="p-col-12 p-sm-6 p-lg-6 p-fluid">
          <LabelSp>Município do Paciente</LabelSp>
          <InputTextSp value={atendimento.paciente.municipioNome} disabled />
        </div>

        <div className="p-col-12 p-sm-6 p-lg-6 p-fluid">
          <LabelSp>Regulação</LabelSp>
          <DropDownLazy
            required
            autoLoad
            disabled={viewMode()}
            placeholder="Selecione"
            onChange={e => {
              const regulacao = e ? { id: e.value, nome: e.label } : null;
              setRegulacaoSelecionada(e);
              setAtendimento({
                ...atendimento,
                regulacao,
                idRegulacao: e?.value,
              });
            }}
            value={regulacaoSelecionada}
            onFilter={async txtFilter => {
              const retorno = await loadRegulacao(txtFilter);
              return retorno;
            }}
          />
        </div>

        <div className="p-col-12 p-sm-6 p-lg-6 p-fluid">
          <LabelSp>Vínculo com Agendamento</LabelSp>
          <InputTextSp
            value={
              atendimento.idAgendamento
                ? `Id Agendamento: ${atendimento.idAgendamento}`
                : 'Sem vínculo'
            }
            disabled
          />
        </div>
      </>
    );
  };

  const renderPaginaSelecionada = () => {
    if (paginaSelecionada === 0) {
      return renderPrincipal();
    }
    if (paginaSelecionada === 1) {
      return renderDetalhe();
    }
    if (paginaSelecionada === 2) {
      return renderDetalheImpressos();
    }
    return <CrudAnexo idSelected={idSelected} viewMode={viewMode} handleBack={handleBack} />;
  };

  const renderSinaisAlarme = () => {
    return (
      <>
        {/* primeira linha */}
        <div className="p-col-6 p-sm-4 p-lg-4 p-fluid">
          <CheckboxSp
            inputId="chkSinalDorAbdominal"
            onChange={e => {
              setAtendimento({ ...atendimento, sinalDorAbdominal: e.checked });
            }}
            checked={atendimento.sinalDorAbdominal}
            caption="Dor Abdominal"
          />
        </div>

        {/* so aparece no display mobile */}
        <div className="p-col-6 p-sm-4 p-lg-4 p-fluid mobile-screen">
          <CheckboxSp
            inputId="chkSinalEpigastralgia"
            onChange={e => {
              setAtendimento({ ...atendimento, sinalEpigastralgia: e.checked });
            }}
            checked={atendimento.sinalEpigastralgia}
            caption="Epigastralgia"
          />
        </div>

        <div className="p-col-6 p-sm-4 p-lg-4 p-fluid">
          <CheckboxSp
            inputId="chkSinalPressaoMaior149"
            onChange={e => {
              setAtendimento({ ...atendimento, sinalPressaoMaior149: e.checked });
            }}
            checked={atendimento.sinalPressaoMaior149}
            caption="PAS ≥ 140x90 mmHg"
          />
        </div>

        <div className="p-col-6 p-sm-4 p-lg-4 p-fluid">
          <CheckboxSp
            inputId="chkSinalQueixaUrinaria"
            onChange={e => {
              setAtendimento({ ...atendimento, sinalQueixaUrinaria: e.checked });
            }}
            checked={atendimento.sinalQueixaUrinaria}
            caption="Queixas Unirárias"
          />
        </div>

        {/* segunda linha */}

        {/* so aparece no display mobile */}
        <div className="p-col-6 p-sm-4 p-lg-4 p-fluid desktop-screen">
          <CheckboxSp
            inputId="chkSinalEpigastralgia"
            onChange={e => {
              setAtendimento({ ...atendimento, sinalEpigastralgia: e.checked });
            }}
            checked={atendimento.sinalEpigastralgia}
            caption="Epigastralgia"
          />
        </div>

        <div className="p-col-6 p-sm-8 p-lg-8 p-fluid">
          <CheckboxSp
            inputId="chkSinalNauseaVomito"
            onChange={e => {
              setAtendimento({ ...atendimento, sinalNauseaVomito: e.checked });
            }}
            checked={atendimento.sinalNauseaVomito}
            caption="Náuseas ou Vômitos"
          />
        </div>

        {/* terceira linha */}

        <div className="p-col-6 p-sm-4 p-lg-4 p-fluid">
          <CheckboxSp
            inputId="chkSinalCefaleia"
            onChange={e => {
              setAtendimento({ ...atendimento, sinalCefaleia: e.checked });
            }}
            checked={atendimento.sinalCefaleia}
            caption="Cefaleia(Tipica Occipital)"
          />
        </div>

        <div className="p-col-6 p-sm-8 p-lg-8 p-fluid">
          <CheckboxSp
            inputId="chkSinalSincromeHemorragica"
            onChange={e => {
              setAtendimento({ ...atendimento, sinalSincromeHemorragica: e.checked });
            }}
            checked={atendimento.sinalSincromeHemorragica}
            caption="Síndromes Homorrágicas"
          />
        </div>
      </>
    );
  };
  const renderDetalhe = () => {
    const tituloHipoteseDiagnostica =
      atendimento.idAtendimentoTipo === TpAtendimento.ENFERMAGEM
        ? 'Diagnóstico de Enfermagem'
        : 'Hipótese Diagnóstica';
    return (
      <>
        <div className="p-col-12">
          <hr />
        </div>

        <div className="p-col-12 p-sm-6 p-lg-7 p-fluid">
          <LabelSp>Paciente</LabelSp>
          <InputTextSp value={atendimento.paciente.nome} disabled />
        </div>
        <div className="p-col-5 p-sm-3 p-lg-2 p-fluid">
          <LabelSp>Nascimento</LabelSp>
          <InputTextSp
            value={formatDate(atendimento.paciente.dataNascimento || null, 'dd/MM/yyyy')}
            disabled
          />
        </div>

        <div className="p-col-7 p-sm-3 p-lg-3 p-fluid">
          <LabelSp>Idade</LabelSp>
          <InputTextSp value={atendimento.paciente.idadeTexto} disabled />
        </div>

        <div className="p-col-4 p-sm-3 p-lg-3 p-fluid">
          <LabelSp>Duração(Min.)</LabelSp>
          <InputCurrencySp
            digits={0}
            disabled={viewMode()}
            required
            value={atendimento.duracaoAtendimento}
            onChange={(e, v) => setAtendimento({ ...atendimento, duracaoAtendimento: v })}
          />
        </div>

        <div className="p-col-8 p-sm-4 p-lg-4 p-fluid">
          <LabelSp>Tipo Atend. Específico</LabelSp>
          <DropdownSp
            required
            placeholder="Selecione"
            value={atendimento.idAtendimentoTipoEspecifico}
            options={listaAtendimentoTipoEspecifico}
            disabled={
              viewMode() ||
              atendimento.idAtendimentoTipo === TpAtendimento.PSICOLOGICO ||
              atendimento.idAtendimentoTipo === TpAtendimento.OUTROS
            }
            onChange={e => {
              let atendimentoTipoEspecifico = listaAtendimentoTipoEspecifico.find(
                i => e.value === i.value
              );

              atendimentoTipoEspecifico = {
                id: atendimentoTipoEspecifico.value,
                nome: atendimentoTipoEspecifico.label,
              };

              setAtendimento({
                ...atendimento,
                atendimentoTipoEspecifico,
                idAtendimentoTipoEspecifico: e?.value,
              });
            }}
          />
        </div>

        <div className="p-col-12 p-sm-5 p-lg-5 p-fluid">
          <LabelSp>Outro Tipo de At. Específico</LabelSp>
          <InputTextSp
            maxLength={100}
            value={atendimento.atTipoEspecificoOutros}
            disabled={
              viewMode() ||
              atendimento.idAtendimentoTipoEspecifico !== TpAtendimentoEspecifico.OUTROS
            }
            onChange={e => {
              setAtendimento({ ...atendimento, atTipoEspecificoOutros: e.target.value });
            }}
          />
        </div>

        <div className="p-col-6 p-sm-6 p-lg-6 p-fluid">
          <LabelSp>Comorbidades</LabelSp>
          <MultiSelectSp
            options={listaComorbidades}
            required
            placeholder="Selecione"
            disabled={viewMode()}
            onChange={e => {
              let { outrasComorbidades } = atendimento;

              if (e.value && !e.value.includes('comorbidadeOutras')) {
                outrasComorbidades = '';
              }
              setComorbidadesSelecionadas(e.value);
              setAtendimento({ ...atendimento, outrasComorbidades });
            }}
            value={comorbidadesSelecionadas}
            maxSelectedLabels={7}
          />
        </div>

        <div className="p-col-6 p-sm-6 p-lg-6 p-fluid">
          <LabelSp>Outras Comorbidades</LabelSp>
          <InputTextSp
            maxLength={100}
            required
            value={atendimento.outrasComorbidades}
            disabled={viewMode() || !comorbidadesSelecionadas.includes('comorbidadeOutras')}
            onChange={e => {
              setAtendimento({ ...atendimento, outrasComorbidades: e.target.value });
            }}
          />
        </div>

        {atendimento.idAtendimentoTipo !== TpAtendimento.PSICOLOGICO && (
          <div className="p-col-12 p-sm-12 p-lg-12 p-fluid">
            <LabelSp>Medicamento Uso Regular</LabelSp>
            <InputTextSp
              maxLength={100}
              value={atendimento.medicamentoRegular}
              disabled={viewMode()}
              onChange={e => {
                setAtendimento({ ...atendimento, medicamentoRegular: e.target.value });
              }}
            />
          </div>
        )}

        <div className="p-col-12 p-sm-5 p-lg-5 p-fluid">
          <LabelSp>Queixa Principal</LabelSp>
          <InputTextareaSp
            required
            resize={false}
            rows={5}
            value={atendimento.queixaPrincipal}
            disabled={viewMode()}
            onChange={e => {
              setAtendimento({ ...atendimento, queixaPrincipal: e.target.value });
            }}
          />
        </div>

        <div className="p-col-12 p-sm-7 p-lg-7 p-fluid">
          <LabelSp>História Clínica</LabelSp>
          <InputTextareaSp
            required
            resize={false}
            rows={5}
            value={atendimento.historiaClinica}
            disabled={viewMode()}
            onChange={e => {
              setAtendimento({ ...atendimento, historiaClinica: e.target.value });
            }}
          />
        </div>
        <div className="p-col-12 p-sm-12 p-lg-12 p-fluid">
          <LabelSp>{tituloHipoteseDiagnostica}</LabelSp>
          <InputTextSp
            maxLength={100}
            required={atendimento.idAtendimentoTipo === TpAtendimento.MEDICO}
            value={atendimento.hipoteseDiagnostica}
            disabled={viewMode()}
            onChange={e => {
              setAtendimento({ ...atendimento, hipoteseDiagnostica: e.target.value });
            }}
          />
        </div>
        {(atendimento.idAtendimentoTipo === TpAtendimento.MEDICO ||
          atendimento.idAtendimentoTipo === TpAtendimento.ENFERMAGEM) && (
          <div className="p-col-12 p-sm-12 p-lg-12 p-fluid">
            <LabelSp>Prescrições Realizadas</LabelSp>
            <InputTextareaSp
              required
              resize={false}
              rows={4}
              value={atendimento.prescricoes}
              disabled={viewMode()}
              onChange={e => {
                setAtendimento({ ...atendimento, prescricoes: e.target.value });
              }}
            />
          </div>
        )}

        {atendimento.idAtendimentoTipoEspecifico === TpAtendimentoEspecifico.SAUDE_MULHER && (
          <>
            <div className="p-col-12 p-sm-4 p-lg-4 p-fluid p-margin-padding-0">
              <div className="p-grid p-margin-padding-0">
                <div className="p-col-6 p-sm-6 p-lg-6 p-fluid">
                  <LabelSp>DUM</LabelSp>
                  <CalendarSp
                    readOnlyInput
                    showButtonBar
                    appendTo={document.body}
                    dateFormat="dd/mm/yy"
                    disabled={viewMode()}
                    required
                    locale={cfgPtBr}
                    value={atendimento.dum}
                    onChange={e => setAtendimento({ ...atendimento, dum: e.value })}
                  />
                </div>

                <div className="p-col-6 p-sm-6 p-lg-6 p-fluid">
                  <LabelSp>IG Semanal</LabelSp>
                  <InputTextSp
                    required
                    maxLength={100}
                    value={atendimento.igSemanal}
                    disabled={viewMode()}
                    onChange={e => {
                      setAtendimento({ ...atendimento, igSemanal: e.target.value });
                    }}
                  />
                </div>

                <div className="p-col-12 p-sm-7 p-lg-7 p-fluid">
                  <LabelSp>Peso/IMC</LabelSp>
                  <InputTextSp
                    required
                    maxLength={100}
                    value={atendimento.pesoImc}
                    disabled={viewMode()}
                    onChange={e => {
                      setAtendimento({ ...atendimento, pesoImc: e.target.value });
                    }}
                  />
                </div>
                <div className="p-col-12 p-sm-5 p-lg-5 p-fluid">
                  <LabelSp className="label-button">&nbsp;</LabelSp>
                  <div
                    className="p-grid p-col-12 p-fluid p-margin-0 p-padding-0 group-box"
                    style={{ paddingBottom: 4 }}
                  >
                    <div className="p-col-12 p-sm-12 p-lg-12 p-fluid" style={{ margin: 2 }}>
                      <CheckboxSp
                        inputId="chkPreNatal"
                        onChange={e => {
                          setAtendimento({ ...atendimento, preNatal: e.checked });
                        }}
                        checked={atendimento.preNatal}
                        caption="Pré-Natal"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-col-12 p-sm-8 p-lg-8 p-fluid">
              <LabelSp>Sinais de Alarme</LabelSp>
              <div
                className="p-grid p-col-12 p-fluid p-margin-0 group-box"
                style={{ paddingBottom: 4 }}
              >
                {renderSinaisAlarme()}
              </div>
            </div>
          </>
        )}

        <div className="p-col-12 p-sm-12 p-lg-12 p-fluid">
          <LabelSp>Exames Laboratoriais Solicitados</LabelSp>
          <InputTextareaSp
            resize={false}
            rows={4}
            value={atendimento.examesLaboratoriais}
            disabled={viewMode()}
            onChange={e => {
              setAtendimento({ ...atendimento, examesLaboratoriais: e.target.value });
            }}
          />
        </div>

        <div className="p-col-12 p-sm-12 p-lg-12 p-fluid">
          <LabelSp>Orientações Realizadas</LabelSp>
          <InputTextareaSp
            required
            resize={false}
            rows={4}
            value={atendimento.orientacoesRealizadas}
            disabled={viewMode()}
            onChange={e => {
              setAtendimento({ ...atendimento, orientacoesRealizadas: e.target.value });
            }}
          />
        </div>

        <div className="p-col-12 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Data Retorno</LabelSp>
          <CalendarSp
            readOnlyInput
            appendTo={document.body}
            dateFormat="dd/mm/yy"
            disabled={viewMode()}
            showButtonBar
            locale={cfgPtBr}
            value={atendimento.dataRetorno}
            onChange={e => setAtendimento({ ...atendimento, dataRetorno: e.value })}
          />
        </div>

        <div className="p-col-12 p-sm-5 p-lg-5 p-fluid">
          <CheckboxSp
            styleLabel={{ fontWeight: 'bold' }}
            inputId="chkNecessitaAtMedico"
            onChange={e => {
              let { necessidadeAtMedico } = atendimento;
              if (!e.checked) {
                necessidadeAtMedico = '';
              }
              setAtendimento({
                ...atendimento,
                necessidadeAtMedico,
                necessitaAtMedico: e.checked,
              });
            }}
            disabled={viewMode()}
            checked={atendimento.necessitaAtMedico}
            caption="Necessita At. Médico(Inf. necessidade)"
          />

          <InputTextSp
            required
            maxLength={100}
            value={atendimento.necessidadeAtMedico}
            disabled={viewMode() || !atendimento.necessitaAtMedico}
            onChange={e => {
              setAtendimento({ ...atendimento, necessidadeAtMedico: e.target.value });
            }}
          />
        </div>

        <div className="p-col-12 p-sm-5 p-lg-5 p-fluid">
          <CheckboxSp
            inputId="chkNecessitaAtPsicologico"
            styleLabel={{ fontWeight: 'bold' }}
            onChange={e => {
              let { necessidadeAtPsicologico } = atendimento;
              if (!e.checked) {
                necessidadeAtPsicologico = '';
              }
              setAtendimento({
                ...atendimento,
                necessidadeAtPsicologico,
                necessitaAtPsicologico: e.checked,
              });
            }}
            disabled={viewMode()}
            checked={atendimento.necessitaAtPsicologico}
            caption="Necessita At. Psicológico(Inf. necessidade)"
          />

          <InputTextSp
            required
            maxLength={100}
            value={atendimento.necessidadeAtPsicologico}
            disabled={viewMode() || !atendimento.necessitaAtPsicologico}
            onChange={e => {
              setAtendimento({ ...atendimento, necessidadeAtPsicologico: e.target.value });
            }}
          />
        </div>
      </>
    );
  };

  const renderDetalheImpressos = () => {
    return (
      <>
        <div className="p-col-12">
          <hr />
        </div>
        {showImpresso ? (
          <PdfView
            title="Impresso"
            src={impressoSrc}
            visible={showImpresso}
            setVisible={() => {
              setImpressoSrc(undefined);
              setShowImpresso(false);
            }}
          />
        ) : null}
        {showEditor ? (
          <EditorSp
            setVisible={setShowEditor}
            visible={showEditor}
            disabled={editorDisabled}
            impressoSelected={impressoSelecionado}
            pacienteNome={atendimento.paciente?.nome}
            handleConfirm={atendimentoImpresso => salvarImpresso(atendimentoImpresso)}
          />
        ) : null}

        <div className="p-col-12 p-sm-6 p-lg-7 p-fluid">
          <LabelSp>Paciente</LabelSp>
          <InputTextSp value={atendimento.paciente.nome} disabled />
        </div>
        <div className="p-col-5 p-sm-3 p-lg-2 p-fluid">
          <LabelSp>Nascimento</LabelSp>
          <InputTextSp
            value={formatDate(atendimento.paciente.dataNascimento || null, 'dd/MM/yyyy')}
            disabled
          />
        </div>

        <div className="p-col-7 p-sm-3 p-lg-3 p-fluid">
          <LabelSp>Idade</LabelSp>
          <InputTextSp value={atendimento.paciente.idadeTexto} disabled />
        </div>

        <div className="p-col-12 p-sm-12 p-lg-12 p-fluid" style={{ textAlign: 'center' }}>
          <hr />
          <LabelSp>Impressos</LabelSp>
          <hr />
        </div>

        <div className="p-col-12 p-lg-12" style={{ textAlign: 'start' }}>
          <ButtonSp
            className="buttons"
            title="Inserir Impresso"
            label="Inserir Impresso"
            icon="pi pi-plus-circle"
            type="button"
            disabled={viewMode()}
            onClick={() => {
              setShowEditor(true);
              setImpressoSelecionado(undefined);
              setEditorDisabled(false);
            }}
          />
        </div>

        <div className="p-col-12 p-fluid">
          <DataTableSp
            value={impressos}
            style={{ marginBottom: '2px' }}
            paginator
            rows={pageLimit}
            responsive
          >
            <Column
              className="grid-col-data"
              header="Data"
              body={rowData => formatDate(rowData.data, 'dd/MM/yyyy')}
            />
            <Column
              className="grid-col-hora"
              header="Hora"
              body={rowData => formatDate(rowData.hora, 'HH:mm')}
            />
            <Column
              className="grid-col"
              header="Tipo"
              body={rowData => rowData.impressoTipo.nome}
            />

            <Column
              className="gid-col-acoes-35"
              bodyStyle={{ textAlign: 'end' }}
              body={renderButtonOp}
            />
          </DataTableSp>
        </div>
      </>
    );
  };

  function renderButtonOp(rowData) {
    return (
      <BotaoMenuGrid
        handles={[
          () => {
            carregaImpresso(rowData, true);
          },
          () => {
            carregaImpresso(rowData, false);
          },
          () => {
            confirmaExclusaoImpresso(rowData);
          },
          () => {
            printImpresso(rowData.id);
          },
        ]}
        labels={['Visualizar', 'Alterar', 'Excluir', 'PDF']}
        icons={['pi pi-search', 'pi pi-pencil', 'pi pi-trash', 'pi pi-cloud-download']}
        disableds={[
          !AuthService.checkRoles('ATENDIMENTO_VIEW'),
          !AuthService.checkRoles('ATENDIMENTO_UPDATE') || viewMode(),
          !AuthService.checkRoles('ATENDIMENTO_UPDATE') || viewMode(),
          !AuthService.checkRoles('ATENDIMENTO_VIEW'),
        ]}
      />
    );
  }

  // render principal
  const buttons = [!isScreenMobile() ? 'Dados Principais' : 'Principais'];
  if (AuthService.checkRoles('ATENDIMENTO_INSERT')) {
    buttons.push('Detalhes');
    buttons.push('Impressos');
    if (stateScreen === StateScreen.stView) {
      buttons.push('Anexos');
    }
  }
  const colors = [1, 1, 2, 2];

  return (
    <>
      <div className="p-col-12 p-lg-12" style={{ padding: 0, margin: 0 }}>
        <SelectButtons
          widthButton={200}
          buttons={buttons}
          colors={colors}
          selectedIndex={paginaSelecionada}
          onClick={itemIndex => setPaginaSelecionada(itemIndex)}
        />
      </div>

      {renderPaginaSelecionada()}

      {paginaSelecionada !== 3 && (
        <div className="p-col-12 p-lg-12" style={{ textAlign: 'start' }}>
          {!viewMode() ? (
            <ButtonSp
              className="p-button-success"
              icon="pi pi-save"
              label="Salvar"
              onClick={handleSave}
            />
          ) : null}

          {viewMode() ? (
            <ButtonSp
              className="p-button-primary"
              icon="pi pi-pencil"
              label="Alterar"
              disabled={!AuthService.checkRoles('ATENDIMENTO_UPDATE')}
              onClick={() => {
                if (!validateAuthColaborador(atendimento.idColaborador)) {
                  return;
                }
                history.push(`/atendimentos/${idSelected}${uBack}`);
              }}
            />
          ) : null}

          <ButtonSp
            className={viewMode() ? 'p-button-secondary' : 'p-button-danger'}
            label={viewMode() ? 'Voltar' : 'Cancelar'}
            icon="pi pi-chevron-circle-left"
            onClick={handleBack}
          />
        </div>
      )}
    </>
  );
}

export default AtendimentoCrud;

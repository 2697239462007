import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100% !important;
  .p-dialog {
    max-height: 99% !important;
  }
  .p-dialog-titlebar {
    margin: 0px !important;
    padding: 2px !important;
  }
  /* .p-dialog-titlebar-close-icon {
    font-size: 19px !important;
  } */

  .p-dialog-titlebar-icon {
    width: 35px !important;
    text-align: center;
  }

  embed {
    height: calc(100vh - 40px);
  }
`;

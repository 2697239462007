import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';

import { Column } from 'primereact/column';
import { toast } from 'react-toastify';

import { addDays } from 'date-fns/esm';
import { Dialog } from 'primereact/dialog';
import { Container } from './styles';

import AgendamentoService from '../../services/AgendamentoService';
import AgendaBloqueioService from '../../services/AgendaBloqueioService';
import AuthService from '../../services/AuthService';
import history from '../../services/history';
import {
  errorHandle,
  calcNaxItemsPage,
  isScreenMobile,
  formatDate,
  newDateOnly,
  cloneObj,
  padLeft,
  newDateTimeHourOnly,
  newTimeHourOnly,
  concatDateAndTime,
} from '../../util/functions';
import LabelSp from '../../components/LabelSp';

import { showMessage } from '../../components/MessageDialog';

import BotaoMenuGrid from '../../components/BotaoMenuGrid';

import DataTableSp from '../../components/DataTableSp';

import ColaboradorService from '../../services/ColaboradorService';
import { AgendaBloqueioModel, SitAtAgendamento, TpColaboradorPerfil } from '../../util/Models';
import CalendarAgendaSp from '../../components/CalendarAgendaSp';
import { ContainerTitle } from '../Dashboard/styles';
import DropdownSp from '../../components/DropdownSp';
import ButtonSp from '../../components/ButtonSp';
import FeriadoService from '../../services/FeriadoService';
import SelectButtons from '../../components/SelectButtons';
import { cfgPtBr } from '../../config/Constantes';
import CalendarSp from '../../components/CalendarSp';

export default function MinhaAgenda() {
  // useMemo
  const filterService = useMemo(() => {
    const colaboradorLogado = AuthService.getColaborador();
    const f = AgendamentoService.getFilterAgendaDia();

    if (colaboradorLogado && colaboradorLogado.municipioAcesso) {
      f.idMunicipio = colaboradorLogado.municipioAcesso.id;
      f.municipioAcesso = {
        label: colaboradorLogado.municipioAcesso.nome,
        value: colaboradorLogado.municipioAcesso.id,
      };
    } else {
      f.municipioAcesso = undefined;
    }

    if (colaboradorLogado) {
      f.idColaborador = colaboradorLogado.id;
    }

    return f;
  }, []);

  // useStates

  const [filter, setFilter] = useState(filterService);
  const [agendamentos, setAgendamentos] = useState([]);
  const [listaColaboradores, setListaColaboradores] = useState([]);
  const [pageLimit] = useState(calcNaxItemsPage(15, 19, 26));
  const [pageLimitBloqueio] = useState(calcNaxItemsPage(15, 19, 26));
  const [funcTimeOut, setFuncTimeOut] = useState();
  const [feriados, setFeriados] = useState([]);
  const [page, setPage] = useState(0);

  const [agendaBloqueios, setAgendaBloqueios] = useState([]);
  const [showSelecionaDataHora, setShowSelecionaDataHora] = useState(false);

  const [dataInicioSelecionada, setDataInicioSelecionada] = useState(newDateOnly());
  const [dataFimSelecionada, setDataFimSelecionada] = useState(newDateOnly());

  const [horaInicioSelecionada, setHoraInicioSelecionada] = useState(newTimeHourOnly());
  const [horaFimSelecionada, setHoraFimSelecionada] = useState(newTimeHourOnly());

  const dialogRef = useRef();

  // useCallbacks
  const loadColaborador = useCallback(async () => {
    const r = await ColaboradorService.findAll({
      limit: 9999,
      inativo: 'false',
      // listaIdCategorias: '[1,2,3]',
    });

    const retorno = r.items.map(e => {
      return {
        label: e.nome,
        value: e.id,
      };
    });

    setListaColaboradores(retorno);
  }, []);

  const loadFeriado = useCallback(async () => {
    const r = await FeriadoService.findAll({ dezAnos: true });
    if (Array.isArray(r)) {
      const retorno = r.map(e => {
        const strData = `${new Date(e.data).toISOString().substring(0, 10)}T00:00:00.000Z`;

        return strData;
      });

      setFeriados(retorno);
      return;
    }

    setFeriados([]);
  }, []);

  const loadAgendaBloqueio = useCallback(async () => {
    const r = await AgendaBloqueioService.findAll({
      dataInicio: addDays(newDateOnly(), -10),
      dataFim: addDays(newDateOnly(), 365),
      idColaborador: filter.idColaborador,
      limit: 999,
    });
    setAgendaBloqueios(r.items);
  }, [filter.idColaborador]);

  const handleBuscar = useCallback(async (_filter, _page) => {
    _filter.page = _page || 0;
    // salvando filtros
    AgendamentoService.getFilterAgendaDia().data = _filter.data;
    AgendamentoService.getFilterAgendaDia().tipo = _filter.tipo;

    const f = cloneObj(_filter);
    f.data = formatDate(f.data, 'yyyy-MM-dd');
    f.mostrarAgenda = 'true';
    try {
      const result = await AgendamentoService.findAll(f);

      const horarios = [];

      result.forEach(e1 => {
        e1.agendaDiaDetalhe.forEach(e2 => {
          let adicionarHorario = true;
          if (_filter.tipo === 2 && !e2.agendamento) {
            adicionarHorario = false;
          }
          if (adicionarHorario) {
            horarios.push({
              hora: e2.hora,
              idColaborador: e1.idColaborador,
              colaboradorNome: e1.colaboradorNome,
              idAtendimentoTipo: e1.idAtendimentoTipo,
              atendimentoTipoNome: e1.atendimentoTipoNome,
              idConfigAgenda: e1.idConfigAgenda,
              agendamento: e2.agendamento,
              situacao: e2.agendamento?.situacao,
            });
          }
        });
      });

      horarios.sort((a, b) => {
        if (a.hora < b.hora) {
          return -1;
        }
        if (a.hora > b.hora) {
          return 1;
        }
        return 0;
      });

      setAgendamentos(horarios);
    } catch (err) {
      errorHandle(err);
    }
  }, []);

  const setFilterAndSearch = useCallback(
    async _filterValue => {
      if (JSON.stringify(_filterValue) !== JSON.stringify(filter)) {
        setFilter(_filterValue);

        if (funcTimeOut) {
          clearTimeout(funcTimeOut);
        }
        const func = setTimeout(async () => {
          handleBuscar(_filterValue);
        }, 800);
        setFuncTimeOut(func);
      }
    },
    [filter, funcTimeOut, handleBuscar]
  );

  // useCallbacks
  const iniciaAtendimento = useCallback(
    async registro => {
      try {
        const atendimento = await AgendamentoService.iniciaAtendimento(registro.agendamento);
        showMessage(
          'Confirmação',
          'O atendimento foi iniciado, deseja acessar-lo agora?',
          idx => {
            if (idx === 1) {
              history.push(`/atendimentos/${atendimento.id}?view&toBack=/minhaAgenda`);
            }
          }
        );
        toast.success('Atendimento iniciado com sucesso.');
        handleBuscar(filter);
      } catch (err) {
        errorHandle(err);
      }
    },
    [filter, handleBuscar]
  );

  const confirmaInicioAtendimento = useCallback(
    registro => {
      if (registro.situacao !== SitAtAgendamento.AGUARDANDO) {
        toast.error(
          'Agendamento deve estar com status "Aguardando" para iniciar um atendimento.'
        );
        return;
      }

      let mensagem = 'Deseja abrir um atendimento para o agendamento selecionado?';

      if (filter.data.getTime() !== newDateOnly().getTime()) {
        mensagem =
          'Este agendamento pertence é de outra data. Deseja abrir um atendimento para o agendamento assim mesmo?';
      }

      showMessage('Confirmação', mensagem, idx => {
        if (idx === 1) {
          iniciaAtendimento(registro);
        }
      });
    },
    [filter.data, iniciaAtendimento]
  );

  const handlePageChange = useCallback(
    idx => {
      if (idx === 1) {
        loadAgendaBloqueio();
      } else {
        handleBuscar(filter);
      }
      setPage(idx);
    },
    [filter, handleBuscar, loadAgendaBloqueio]
  );

  // functions
  function corStatus(status) {
    switch (status) {
      case SitAtAgendamento.AGUARDANDO:
        return '#007ad9';
      case SitAtAgendamento.EM_ANDAMENTO:
        return '#ebd300';
      case SitAtAgendamento.REALIZADO:
        return '#6ecc65';
      case SitAtAgendamento.NAO_REALIZADO:
        return '#ee3902';
      default:
        return '#c8c8c8';
    }
  }

  function getTitle() {
    const titleDefault = 'Minha Agenda';
    const titleAdd = '';

    if (!isScreenMobile()) {
      return `${titleDefault} ${titleAdd}`;
    }

    return titleDefault;
  }

  const handleSalvarBloqueio = async () => {
    const dataHoraInicio = concatDateAndTime(dataInicioSelecionada, horaInicioSelecionada);

    const dataHoraFim = concatDateAndTime(dataFimSelecionada, horaFimSelecionada);

    if (dataHoraInicio >= dataHoraFim) {
      toast.error('Data/hora fim deve ser maior que a data/hora inicio.');
      return;
    }

    const agendaBloqueio = new AgendaBloqueioModel();
    agendaBloqueio.idColaborador = filter.idColaborador;
    agendaBloqueio.dataHoraFim = dataHoraFim;
    agendaBloqueio.dataHoraInicio = dataHoraInicio;

    try {
      await AgendaBloqueioService.insert(agendaBloqueio);
      loadAgendaBloqueio();
    } catch (err) {
      errorHandle(err);
    }

    setShowSelecionaDataHora(false);
  };

  const handleExcluirBloqueio = async id => {
    if (!id) {
      toast.error('Bloqueio não encontrado.');
      return;
    }
    showMessage('Confirmação', `Deseja excluir registro?`, async idx => {
      if (idx === 1) {
        try {
          await AgendaBloqueioService.delete(id);
          loadAgendaBloqueio();
        } catch (err) {
          errorHandle(err);
        }
      }
    });
  };

  // useEffects
  useEffect(() => {
    loadColaborador();
    loadFeriado();
    handleBuscar(filter, 0, true);

    // desativado para evitar que a cada vez que o agendamento digitasse o sistema buscasse
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buttons = isScreenMobile() ? ['Agen.', 'Bloq.'] : ['Agenda', 'Dias Bloq.'];
  const colors = [1, 1, 1];

  const footerHora = (
    <div style={{ textAlign: 'center' }}>
      <ButtonSp
        label="Cancelar"
        icon="pi pi-times"
        onClick={() => {
          setShowSelecionaDataHora(false);
        }}
        className="p-button-danger"
      />
      <ButtonSp
        label="Confirmar"
        icon="pi pi-check"
        onClick={() => {
          handleSalvarBloqueio();
        }}
      />
    </div>
  );

  const renderShowDataHora = () => {
    return (
      <>
        <Dialog
          closeOnEscape
          closable
          footer={footerHora}
          contentStyle={{ minHeight: '90px' }}
          style={{ minWidth: '380px', maxWidth: '580px', width: '95%' }}
          modal
          header="Selecione o Período"
          visible={showSelecionaDataHora}
          blockScroll
          ref={dialogRef}
          onHide={() => {
            setShowSelecionaDataHora(false);
          }}
        >
          <div className="p-grid">
            <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
              <LabelSp>Data Inicio</LabelSp>
              <CalendarSp
                readOnlyInput
                autoFocus
                appendTo={document.body}
                required
                stepMinute={10}
                dateFormat="dd/mm/yy"
                locale={cfgPtBr}
                value={dataInicioSelecionada}
                onChange={e => setDataInicioSelecionada(e.value)}
              />
            </div>
            <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
              <LabelSp>Hora Inicio</LabelSp>
              <CalendarSp
                readOnlyInput
                autoFocus
                appendTo={document.body}
                showTime
                timeOnly
                hourFormat="24"
                required
                stepMinute={10}
                locale={cfgPtBr}
                value={horaInicioSelecionada}
                onChange={e => setHoraInicioSelecionada(e.value)}
              />
            </div>
            <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
              <LabelSp>Data Fim</LabelSp>
              <CalendarSp
                readOnlyInput
                appendTo={document.body}
                required
                stepMinute={10}
                dateFormat="dd/mm/yy"
                locale={cfgPtBr}
                value={dataFimSelecionada}
                onChange={e => setDataFimSelecionada(e.value)}
              />
            </div>
            <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
              <LabelSp>Hora Fim</LabelSp>
              <CalendarSp
                readOnlyInput
                appendTo={document.body}
                showTime
                timeOnly
                hourFormat="24"
                required
                stepMinute={10}
                locale={cfgPtBr}
                value={horaFimSelecionada}
                onChange={e => setHoraFimSelecionada(e.value)}
              />
            </div>
          </div>
        </Dialog>
      </>
    );
  };

  // renders
  return (
    <Container className="container-page">
      <div className="p-grid">{renderSearch()}</div>
    </Container>
  );

  function renderSearch() {
    return (
      <>
        <ContainerTitle className="p-col-12">
          <ButtonSp
            style={{ float: 'right', marginRight: 5 }}
            className="p-button-secondary buttons"
            title="Buscar"
            icon="pi pi-search"
            type="button"
            onClick={() => {
              if (page === 0) {
                handleBuscar(filter);
              } else {
                loadAgendaBloqueio();
              }
            }}
          />

          <SelectButtons
            style={{ float: 'right', marginRight: 5 }}
            widthButton={isScreenMobile() ? 45 : 90}
            buttons={buttons}
            colors={colors}
            selectedIndex={page}
            onClick={itemIndex => {
              handlePageChange(itemIndex);
            }}
          />

          <span className="title">{getTitle()}</span>
          <hr />
        </ContainerTitle>
        {showSelecionaDataHora ? renderShowDataHora() : null}
        {page === 0 ? renderAgenda() : null}
        {page === 1 ? renderBloqueios() : null}
      </>
    );
  }

  function renderBloqueios() {
    return (
      <>
        <div
          className="p-col-12 p-sm-12 p-lg-12 p-fluid"
          style={{ textAlign: 'center', marginTop: 0, marginBottom: 0 }}
        >
          <LabelSp>Dias/Horários bloqueados</LabelSp>
        </div>
        <div className="p-col-12">
          <ButtonSp
            className="buttons"
            title="Inserir"
            label="Inserir"
            icon="pi pi-plus-circle"
            type="button"
            onClick={() => {
              setDataFimSelecionada(newDateTimeHourOnly());
              setDataInicioSelecionada(newDateTimeHourOnly());
              setShowSelecionaDataHora(true);
            }}
          />
        </div>

        <div className="p-col-12 p-fluid">
          <DataTableSp
            value={agendaBloqueios}
            style={{ marginBottom: '2px' }}
            paginator
            rows={pageLimitBloqueio}
            responsive
          >
            <Column
              className="grid-col grid-col-id"
              header="id"
              body={rowData => padLeft(rowData.id, 6)}
            />
            <Column
              header="Data/Hora Inicio"
              body={rowData => formatDate(rowData.dataHoraInicio, 'dd/MM/yyyy HH:mm')}
            />

            <Column
              header="Data/Hora Fim"
              body={rowData => formatDate(rowData.dataHoraFim, 'dd/MM/yyyy HH:mm')}
            />

            <Column
              className="gid-col-acoes-35"
              bodyStyle={{ textAlign: 'end' }}
              body={renderButtonOpBloqueio}
            />
          </DataTableSp>
        </div>
      </>
    );
  }

  function renderAgenda() {
    return (
      <>
        <div className="p-col-12 p-sm-12 p-lg-5 p-fluid">
          <LabelSp>Visualizar Horários</LabelSp>
          <DropdownSp
            value={filter.tipo}
            options={[
              { value: 1, label: 'Todos os Horários' },
              {
                value: 2,
                label: 'Apenas horários agendados',
              },
            ]}
            onChange={e => {
              setFilterAndSearch({ ...filter, tipo: e?.value });
            }}
          />

          <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-fluid">
            <LabelSp>Profissional</LabelSp>
            <DropdownSp
              filter
              disabled={
                AuthService.getColaborador().idColaboradorPerfil !==
                  TpColaboradorPerfil.ADMINISTRADOR &&
                AuthService.getColaborador().idColaboradorPerfil !==
                  TpColaboradorPerfil.GESTOR_FAS
              }
              options={listaColaboradores}
              placeholder="Todos os Profissionais"
              onChange={e => {
                setFilterAndSearch({ ...filter, idColaborador: e?.value });
              }}
              value={filter.idColaborador}
            />
          </div>

          <div className="p-col-12 p-sm-12 p-lg-12 p-fluid">
            <LabelSp htmlFor="dataInicial">Data</LabelSp>
            <CalendarAgendaSp
              feriados={feriados}
              value={filter.data}
              onChange={e => {
                setFilterAndSearch({ ...filter, data: e.value });
              }}
            />
          </div>
          <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-fluid">
            <div className="legenda">
              <div className="legenda-linha">
                <div style={{ flex: 1 }}>
                  <div className="div-legenda livre">&nbsp;</div>
                  <span style={{ alignSelf: 'center' }}>Livre</span>
                </div>
                <div style={{ flex: 1 }}>
                  <div className="div-legenda aguardando">&nbsp;</div>
                  <span>Aguardando</span>
                </div>
                <div style={{ flex: 1 }}>
                  <div className="div-legenda nao-realizado">&nbsp;</div>
                  <span>Não Realizado</span>
                </div>
              </div>
              <div className="legenda-linha">
                <div style={{ flex: 1 }}>
                  <div className="div-legenda em-agendimento">&nbsp;</div>
                  <span>Em Atendimento</span>
                </div>
                <div style={{ flex: 1 }}>
                  <div className="div-legenda atendido">&nbsp;</div>
                  <span style={{ alignSelf: 'center' }}>Atendido</span>
                </div>
                <div style={{ flex: 1 }}>
                  <div className="div-legenda espaco-vago">&nbsp;</div>
                  <span style={{ alignSelf: 'center' }} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-col-12 p-sm-12 p-lg-7 p-fluid">
          <LabelSp>Horários do dia: {formatDate(filter.data, 'dd/MM/yyyy')}</LabelSp>
          <DataTableSp
            value={agendamentos}
            style={{ marginBottom: '2px' }}
            paginator
            rows={pageLimit}
            responsive
            lazy
            totalRecords={agendamentos.length}
            first={0}
            // onPage={onPage}
          >
            <Column
              field="id"
              header=" "
              style={{ width: 30 }}
              body={rowData => {
                const cor = corStatus(rowData.situacao);
                return (
                  <div
                    // title={listaSituacao[rowData.situacao].label}
                    className="div-status"
                    style={{
                      background: cor,
                      color: cor,
                    }}
                  >
                    &nbsp;
                  </div>
                );
              }}
            />

            <Column
              field="dataHoraAgendamento"
              className="grid-col-hora grid-col-center"
              header="Horário"
              body={rowData => formatDate(rowData.hora, 'HH:mm')}
            />
            <Column
              field="pacienteNome"
              className="grid-col"
              header="Paciente"
              body={rowData => rowData.agendamento?.pacienteNome || ''}
            />
            <Column
              field="comunidadeNome"
              className="grid-col"
              header="Local Atendimento"
              body={rowData => rowData.agendamento?.comunidadeAtendimentoNome || ''}
            />

            <Column
              className="gid-col-acoes-35"
              bodyStyle={{ textAlign: 'end' }}
              body={rowData => {
                if (rowData.agendamento) {
                  return renderButtonOp(rowData);
                }
              }}
            />
          </DataTableSp>
        </div>
      </>
    );
  }

  function renderButtonOp(rowData) {
    return (
      <BotaoMenuGrid
        handles={[
          () => {
            confirmaInicioAtendimento(rowData);
          },
          () => {
            history.push(
              `/atendimentos/${rowData.agendamento?.idAtendimento}?view&toBack=/minhaAgenda`
            );
          },
          () => {
            history.push(`/agendamentos/${rowData.agendamento?.id}?view&toBack=/minhaAgenda`);
          },
        ]}
        labels={[
          rowData.situacao === SitAtAgendamento.AGUARDANDO ? 'Iniciar Atendimento' : '',
          rowData.situacao === SitAtAgendamento.EM_ANDAMENTO ||
          rowData.situacao === SitAtAgendamento.REALIZADO
            ? 'Acessar Atendimento'
            : '',
          rowData.agendamento ? 'Acessar Agendamento' : '',
        ]}
        icons={['pi pi-book', 'pi pi-book', 'pi pi-table']}
        disableds={[
          !AuthService.checkRoles('ATENDIMENTO_INSERT'),
          !AuthService.checkRoles('ATENDIMENTO_VIEW'),
        ]}
      />
    );
  }

  function renderButtonOpBloqueio(rowData) {
    return (
      <BotaoMenuGrid
        handles={[
          () => {
            handleExcluirBloqueio(rowData.id);
          },
        ]}
        labels={['Excluir']}
        icons={['pi pi-trash']}
        disableds={[!AuthService.checkRoles('COLABORADOR_DELETE')]}
      />
    );
  }
}

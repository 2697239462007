/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { toast } from 'react-toastify';
import ColaboradorService from '../../services/ColaboradorService';
import ComunidadeService from '../../services/ComunidadeService';
import { errorHandle, extractFoneNumber } from '../../util/functions';

export function renderContatoSolicitante(contatoSolicitante) {
  if (!contatoSolicitante) {
    return <></>;
  }
  return (
    <div>
      <div style={{ padding: 2 }}>
        <strong>Nome: </strong> {contatoSolicitante.nome}
      </div>
      <div style={{ padding: 2 }}>
        <strong>Celular: </strong> {contatoSolicitante.celular}
      </div>
      <div style={{ padding: 2 }}>
        <strong>Whatsapp: </strong>
        {contatoSolicitante.whatsapp && (
          <a
            href={`https://wa.me/${extractFoneNumber(contatoSolicitante.whatsapp)}`}
            target="_blank"
          >
            {contatoSolicitante.whatsapp}
          </a>
        )}
      </div>
    </div>
  );
}

export const handleContatoSolicitante = async (
  idColaboradorSolicitante,
  setContatoSolicitante,
  setShowContatoSolicitante
) => {
  if (idColaboradorSolicitante) {
    try {
      const r = await ColaboradorService.findById(idColaboradorSolicitante);
      if (setContatoSolicitante) {
        setContatoSolicitante({ nome: r.nome, celular: r.celular, whatsapp: r.whatsapp });
      }
      if (setShowContatoSolicitante) {
        setShowContatoSolicitante(true);
      }
    } catch (err) {
      errorHandle(err);
    }
  } else {
    toast.warn('É necessário definir o solicitante.');
  }
};

export function renderLinkVideoConferencia(link) {
  if (!link) {
    return <></>;
  }
  return (
    <div>
      <strong>Link da Sala: </strong>
      <a href={link} target="_blank">
        Click aqui
      </a>
    </div>
  );
}

export const handleLinkVideoConferencia = async (
  idComunidade,
  setLinkVideoConferencia,
  setShowLinkVideoConferencia
) => {
  if (idComunidade) {
    try {
      const response = await ComunidadeService.findAll({ id: idComunidade });
      if (response.items.length === 0) {
        toast.warn('Comunidade não encontrada.');
        return;
      }
      const r = response.items[0];
      if (!r.linkVideoConferencia) {
        toast.warn('Link não cadastrado na comunidade.');
        return;
      }
      if (setLinkVideoConferencia) {
        setLinkVideoConferencia(r.linkVideoConferencia);
      }
      if (setShowLinkVideoConferencia) {
        setShowLinkVideoConferencia(true);
      }
    } catch (err) {
      errorHandle(err);
    }
  } else {
    toast.warn('É necessário definir a comunidade.');
  }
};

import { newDateOnly, newTimeOnly } from './functions';

export const TpAtendimento = {
  MEDICO: 1,
  PSICOLOGICO: 2,
  ENFERMAGEM: 3,
  OUTROS: 4,
  getObjDropDown: id => {
    switch (id) {
      case TpAtendimento.MEDICO:
        return { value: id, label: 'Médico' };
      case TpAtendimento.PSICOLOGICO:
        return { value: id, label: 'Psicólogo' };
      case TpAtendimento.ENFERMAGEM:
        return { value: id, label: 'Enfermagem' };
      case TpAtendimento.OUTROS:
        return { value: id, label: 'Outros' };
      default:
        return undefined;
    }
  },
  getColaboradorCategoria: idAtendimentoTipo => {
    switch (idAtendimentoTipo) {
      case TpAtendimento.MEDICO:
        return TColaboradorCategoria.MEDICO;
      case TpAtendimento.PSICOLOGICO:
        return TColaboradorCategoria.PSICOLOCO;
      case TpAtendimento.ENFERMAGEM:
        return TColaboradorCategoria.ENFERMEIRO;
      case TpAtendimento.OUTROS:
        return TColaboradorCategoria.OUTROS;
      default:
        return TColaboradorCategoria.OUTROS;
    }
  },
};

export const TColaboradorCategoria = {
  ENFERMEIRO: 1,
  MEDICO: 2,
  PSICOLOCO: 3,
  GESTOR: 4,
  OUTROS: 5,
};

export const SitAtAgendamento = {
  AGUARDANDO: 0,
  EM_ANDAMENTO: 1,
  REALIZADO: 2,
  NAO_REALIZADO: 3,
};

export const SitAtendimento = {
  EM_ATENDIMENTO: 0,
  REALIZADO: 1,
};

export const TpImpresso = {
  RECEITUARIO: 1,
  RECEITUARIO_ESPECIAL: 2,
  SOLICITACAO_EXAME: 3,
  ENCAMINHAMENTO: 4,
  ENCAMINHAMENTO_FAS: 5,
};

export const TpAtendimentoEspecifico = {
  SAUDE_MULHER: 1,
  DOENTE_CRONICO: 2,
  OUTROS: 3,
  getObjDropDown: id => {
    switch (id) {
      case TpAtendimentoEspecifico.SAUDE_MULHER:
        return { value: id, label: 'Saúde da Mulher' };
      case TpAtendimentoEspecifico.DOENTE_CRONICO:
        return { value: id, label: 'Doente Crônico' };
      case TpAtendimentoEspecifico.OUTROS:
        return { value: id, label: 'Outros' };
      default:
        return undefined;
    }
  },
};

export const TpColaborador = {
  ENFERMEIRO: 1,
  MEDICO: 2,
  PSICOLOCO: 3,
  GESTOR: 4,
  OUTROS: 5,
};

export const TpColaboradorPerfil = {
  ADMINISTRADOR: 1,
  GESTOR_PUBLICO: 2,
  AGENTE_SAUDE_RIBEIRINHO: 3,
  PROFISSIONAL_SAUDE: 4,
  GESTOR_FAS: 5,
  OUTROS: 6,
};

export class ColaboradorModel {
  constructor() {
    this.id = undefined;
    this.idColaboradorPerfil = null;
    this.idColaboradorVinculo = null;
    this.idColaboradorCategoria = null;
    this.idColaboradorEspecialidade = null;
    this.idComunidade = null;
    this.nome = '';
    this.email = '';
    this.inativo = false;
    this.logaSistema = true;
    this.senha = '';
    this.celular = '';
    this.siglaConselho = '';
    this.numeroRegistro = '';
    this.fazPalestra = false;
    this.whatsapp = '';
    this.recebeMsgWhatsapp = false;
  }
}

export class FinanciadorModel {
  constructor() {
    this.id = undefined;
    this.nome = '';
  }
}

export class AgendaBloqueioModel {
  constructor() {
    this.id = undefined;
    this.idColaborador = undefined;
    this.dataHoraInicio = newTimeOnly();
    this.dataHoraFim = newTimeOnly();
  }
}

export class ColaboradorEspecialidadeModel {
  constructor() {
    this.id = undefined;
    this.nome = '';
    this.descricao = '';
  }
}

export class UnidadeConservacaoModel {
  constructor() {
    this.id = undefined;
    this.nome = '';
  }
}

export class TerritorioModel {
  constructor() {
    this.id = undefined;
    this.nome = '';
  }
}

export class PacienteModel {
  constructor() {
    this.id = undefined;
    this.idMunicipio = null;
    this.idComunidade = null;
    this.identidadeGenero = null;
    this.nome = null;
    this.dataNascimento = null;
    this.cartaoSus = '';
    this.cpf = '';
    this.nomeMae = '';
    this.nomePai = '';
    this.nomeComunidade = null;
    this.telefone = '';
    this.celular = '';
    this.raca = '';
    this.ufNascimento = 'AM';
    this.naturalidade = '';
  }
}

export class MunicipioMode {
  constructor() {
    this.id = undefined;
    this.nome = '';
    this.uf = '';
    this.codigoIbge = 0;
  }
}

export class RegionalModel {
  constructor() {
    this.id = undefined;
    this.nome = '';
  }
}
export class ColaboradorPerfilModel {
  constructor() {
    this.id = undefined;
    this.nome = '';
    this.colaboradorPerfilDetalhe = [];
  }
}

export class EstruturaPontoModel {
  constructor() {
    this.id = undefined;
    this.nome = '';
  }
}

export class ComunidadeModel {
  constructor() {
    this.id = undefined;
    this.idTerritorio = null;
    this.idFinanciador = null;
    this.idUnidadeConservacao = null;
    this.idEstruturaPonto = null;
    this.idRegional = null;
    this.idMunicipio = null;
    this.nome = null;
    this.estruturaPonto = '';
    this.territorio = new TerritorioModel();
    this.financiador = new FinanciadorModel();
    this.unidadeConservacao = new UnidadeConservacaoModel();
    this.municipio = new MunicipioMode();
    this.regional = new RegionalModel();
    this.estruturaPonto = new EstruturaPontoModel();
  }
}

export class AuditoriaModel {
  constructor() {
    this.id = undefined;
    this.idColaborador = null;
    this.dataRegistro = null;
    this.horaRegistro = null;
    this.acao = '';
    this.conteudo = '';
    this.colaborador = new ColaboradorModel();
  }
}

export class FuncaoModel {
  constructor() {
    this.id = undefined;
    this.nome = '';
    this.nomeAgrupamento = '';
    this.descricao = '';
  }
}

export class ColaboradorPerfilFuncaoModel {
  constructor() {
    this.id = undefined;
    this.idColaboradorPerfil = null;
    this.idFuncao = null;
    this.ativo = false;
    this.funcao = new FuncaoModel();
  }
}

export class AtendimentoTipoModel {
  constructor() {
    this.id = undefined;
    this.nome = '';
  }
}

export class AtendimentoTipoEspecificoModel {
  constructor() {
    this.id = undefined;
    this.nome = '';
  }
}

export class RegulacaoModel {
  constructor() {
    this.id = undefined;
    this.nome = '';
  }
}
export class AtendimentoModel {
  constructor() {
    this.id = undefined;
    this.idRegulacao = undefined;
    this.idAtendimentoTipo = undefined;
    this.idComunidade = undefined;
    this.idColaborador = undefined;
    this.idAtendimentoTipoEspecifico = undefined;
    this.idPaciente = undefined;
    this.idAtendimentoMobile = undefined;
    this.situacao = 1;
    this.idDispositivoMobile = '';
    this.dataAtendimento = newDateOnly();
    this.horaAtendimento = newTimeOnly();
    this.duracaoAtendimento = 0;
    this.dataHoraSincronizacao = null;
    this.outrasComorbidades = '';
    this.medicamentoRegular = '';
    this.queixaPrincipal = '';
    this.historiaClinica = '';
    this.hipoteseDiagnostica = '';
    this.prescricoes = '';
    this.dum = null;
    this.igSemanal = '';
    this.pesoImc = '';
    this.sinalDorAbdominal = false;
    this.sinalCefaleia = false;
    this.sinalSincromeHemorragica = false;
    this.sinalNauseaVomito = false;
    this.sinalPressaoMaior149 = false;
    this.sinalQueixaUrinaria = false;
    this.orientacoesRealizadas = '';
    this.dataRetorno = null;
    this.necessidadeAtMedico = '';
    this.comorbidadeDm = false;
    this.comorbidadeHas = false;
    this.comorbidadeTb = false;
    this.comorbidadeHs = false;
    this.comorbidadeNaoPossui = false;
    this.comorbidadeNaoVerificado = false;
    this.comorbidadeOutras = false;
    this.necessitaAtMedico = false;
    this.sinalEpigastralgia = false;
    this.examesLaboratoriais = '';
    this.necessitaAtPsicologico = false;
    this.necessidadeAtPsicologico = '';

    this.paciente = new PacienteModel();
    this.retulacao = new RegulacaoModel();
    this.atendimentoTipo = new AtendimentoTipoModel();
    this.atendimentoTipoEspecifico = new AtendimentoTipoEspecificoModel();
    this.colaborador = new ColaboradorModel();
  }
}

export class AtendimentoAnexoModel {
  constructor() {
    this.id = undefined;
    this.idAtendimento = undefined;
    this.nome = '';
    this.tipoArquivo = '';
    this.data = newDateOnly();
  }
}

export class EducacaoModel {
  constructor() {
    this.id = undefined;
    this.idColaborador = undefined;
    this.tipo = undefined;
    this.data = newDateOnly();
    this.horaInicio = newTimeOnly();
    this.horaFim = newTimeOnly();
    this.tema = '';
    this.oriencacoes = '';
    this.educacaoParticipante = [];
  }
}

export class EducacaoParticipanteModel {
  constructor() {
    this.id = undefined;
    this.idComunidade = undefined;
    this.quantidade = 1;
    this.comunidade = new ComunidadeModel();
  }
}

export class AgendamentoModel {
  constructor() {
    this.id = undefined;
    this.idAtendimentoTipo = undefined;
    this.idComunidade = undefined;
    this.idColaborador = undefined;
    this.idColaboradorSolicitante = undefined;
    this.idPaciente = undefined;
    this.idAtendimentoMobile = undefined;
    this.situacao = 0;
    this.motivoNaoAtendimento = undefined;

    this.dataHoraLancamento = new Date();
    this.dataHoraAgendamento = undefined;
    this.peso = 0;
    this.altura = 0;
    this.pressaoArterial = '';
    this.tipoPeriodicidade = null;
    this.tipoPaciente = null;
    this.queixaPrincipal = '';

    this.paciente = new PacienteModel();
    this.atendimentoTipo = new AtendimentoTipoModel();
    this.colaborador = new ColaboradorModel();
    this.colaboradorSolicitante = new ColaboradorModel();
    this.comunidade = new ComunidadeModel();
  }
}

export class ConfigAgendaModel {
  constructor() {
    this.id = undefined;
    this.idColaborador = undefined;
    this.comunidade = new ComunidadeModel();
    this.intervaloMinutos = 30;
    this.inativo = false;

    this.configAgendaDetalhe = [];
  }
}

export class ConfigAgendaDetalheModel {
  constructor() {
    this.id = undefined;
    this.diaSemana = undefined;
    this.horaInicio = newTimeOnly();
    this.horaFim = newTimeOnly();
  }
}

export class AtendimentoImpressoModel {
  constructor() {
    this.id = undefined;
    this.data = newDateOnly();
    this.hora = newTimeOnly();
    this.idImpressoTipo = undefined;
    this.persistPDF = true;
    this.rgNumero = '';
    this.orgaoExpedidorRg = '';
    this.ufEmissaoRg = '';
    this.dataEmissaoRg = null;
    this.certidaoNascimentoNumero = '';
    this.certidaoNascimentoLivro = '';
    this.certidaoNascimentoFolha = '';
    this.certidaoNascimentoEmissao = null;
    this.dadosClinicos = '';
    this.examesRealizados = '';
    this.tratamentosInstituidos = '';
    this.observacoes = '';
    this.especialidade = '';
    this.cid = '';
  }
}

export class FeriadoModel {
  constructor() {
    this.id = undefined;
    this.nome = '';
    this.dia = undefined;
    this.mes = undefined;
    this.abrangencia = 0;
    this.idMunicipio = undefined;
    this.municipio = undefined;
    this.inativo = false;
  }
}

export class ReportProntuarioOptions {
  constructor() {
    this.idPaciente = undefined;
    this.pacienteNome = '';
    this.showProfissional = true;
    this.showMedicamento = true;
    this.showHistoriaClinica = true;
    this.showComorbidades = true;
    this.showPrescricoes = true;
    this.showExames = true;
    this.showOrientacoes = true;
    this.showHipoteseDiagnostica = true;
    this.showAtendimentoMulher = true;
    this.showQueixaPrincipal = true;
    this.showAnexos = false;
    this.qtdMaxAtendimentos = 5;
  }
}

// import { addMonths } from 'date-fns';
import { BaseService } from './BaseService';
import { ConfigApi } from '../config/Constantes';

class ConfigAgendaService extends BaseService {
  constructor() {
    super(ConfigApi.configAgendaURL);
    // this.filter = new Filter();
  }

  getFilter() {
    return new Filter();
  }
}

class Filter {
  constructor() {
    this.id = undefined;
    this.idColaborador = undefined;
    this.nome = undefined;

    this.limit = 0;
    this.page = 0;
  }
}
export default new ConfigAgendaService();

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Container } from '../../pages/Atendimento/styles';
import InputTextSp from '../InputTextSp';
import LabelSp from '../LabelSp';
import PdfView from '../PdfView';
import ButtonSp from '../ButtonSp';
import {
  errorHandle,
  formatDate,
  generateUUID,
  isScreenMobile,
  strToDate,
} from '../../util/functions';
import DataTableSp from '../DataTableSp';
import AtendimentoService from '../../services/AtendimentoService';
import { AtendimentoModel } from '../../util/Models';

function ImpressoAtendimento(props) {
  ImpressoAtendimento.propTypes = {
    idAtendimento: PropTypes.number,
    visible: PropTypes.bool,
    setVisible: PropTypes.func,
  };

  // ImpressoAtendimento.defaultValue = {
  //   visible: false,
  // };
  const { idAtendimento, visible, setVisible } = props;

  const [impressoSrc, setImpressoSrc] = useState('');
  const [showImpresso, setShowImpresso] = useState(false);
  const [impressos, setImpressos] = useState([]);
  const [atendimento, setAtendimento] = useState(new AtendimentoModel());

  // callback
  const loadRecord = useCallback(async _id => {
    try {
      const retorno = await AtendimentoService.findById(_id);

      if (retorno.paciente.dataNascimento) {
        retorno.paciente.dataNascimento = strToDate(retorno.paciente.dataNascimento);
      }

      if (retorno.horaAtendimento) {
        retorno.horaAtendimento = new Date(retorno.horaAtendimento);
      }

      if (retorno.dataAtendimento) {
        retorno.dataAtendimento = new Date(retorno.dataAtendimento);
      }

      if (retorno.dum) {
        retorno.dum = strToDate(retorno.dum);
      }

      setAtendimento(retorno);
      const impessosTemp = retorno.atendimentoImpresso.map(e => {
        return { ...e, uuid: generateUUID() };
      });

      setImpressos(impessosTemp);
    } catch (err) {
      errorHandle(err);
    }
  }, []);

  // effects
  useEffect(() => {
    if (idAtendimento) {
      loadRecord(idAtendimento);
    }
  }, [idAtendimento, loadRecord]);

  // renders
  // render principal
  const footer = (
    <div style={{ textAlign: 'center' }}>
      <Button
        className="p-button-secondary"
        label="Voltar"
        icon="pi pi-chevron-circle-left"
        onClick={() => {
          if (setVisible) {
            setVisible(false);
          }
        }}
      />
    </div>
  );

  const renderButtonOp = idAtendimentoImpresso => {
    return (
      <ButtonSp
        title="PDF"
        icon="pi pi-file-pdf"
        className="botao-pequeno ui-button-primary"
        onClick={() => {
          printImpresso(idAtendimentoImpresso);
        }}
      />
    );
  };

  const printImpresso = async idAtendimentoImpresso => {
    try {
      if (!idAtendimentoImpresso) {
        return;
      }

      const retorno = await AtendimentoService.getImpresso(idAtendimentoImpresso);
      const srcPdf = URL.createObjectURL(retorno);
      setImpressoSrc(srcPdf);
      setShowImpresso(true);
    } catch (err) {
      errorHandle(err);
    }
  };

  return (
    <Container>
      <Dialog
        closable={false}
        footer={footer}
        header="Impressos do Atendimento"
        visible={visible}
        contentStyle={{ minHeight: isScreenMobile() ? '300px' : '400px' }}
        style={{ minWidth: '380px', maxWidth: '820px', width: '95%' }}
        modal
        onHide={() => {
          if (setVisible) {
            setVisible(false);
          }
        }}
      >
        {showImpresso ? (
          <PdfView
            title="Impresso"
            src={impressoSrc}
            visible={showImpresso}
            setVisible={() => {
              setImpressoSrc(undefined);
              setShowImpresso(false);
            }}
          />
        ) : null}

        <div className="p-grid">
          <div className="p-col-12 p-sm-3 p-lg-2 p-fluid">
            <LabelSp>Data</LabelSp>
            <InputTextSp
              value={formatDate(atendimento.dataAtendimento || null, 'dd/MM/yyyy')}
              disabled
            />
          </div>

          <div className="p-col-12 p-sm-5 p-lg-6 p-fluid">
            <LabelSp>Paciente</LabelSp>
            <InputTextSp value={atendimento.paciente.nome} disabled />
          </div>

          <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
            <LabelSp>Profissional</LabelSp>
            <InputTextSp value={atendimento.colaborador.nome} disabled />
          </div>

          <div className="p-col-12 p-fluid">
            <DataTableSp
              value={impressos}
              style={{ marginBottom: '2px' }}
              paginator
              rows={7}
              responsive
            >
              <Column
                className="grid-col-data"
                header="Data/Hora"
                style={{ width: 140 }}
                body={rowData =>
                  `${formatDate(rowData.data, 'dd/MM/yyyy')} ${formatDate(
                    rowData.hora,
                    'HH:mm'
                  )}`
                }
              />
              <Column
                className="grid-col"
                header="Tipo"
                body={rowData => rowData.impressoTipo.nome}
              />

              <Column
                className="gid-col-acoes-35"
                bodyStyle={{ textAlign: 'end' }}
                body={rowData => renderButtonOp(rowData.id)}
              />
            </DataTableSp>
          </div>
        </div>
      </Dialog>
    </Container>
  );
}

export default ImpressoAtendimento;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import ButtonSp from '../../components/ButtonSp';
import DropDownLazy from '../../components/DropdownLazy';
import DropdownSp from '../../components/DropdownSp';
import InputMaskSp from '../../components/InputMaskSp';
import InputTextSp from '../../components/InputTextSp';
import LabelSp from '../../components/LabelSp';
import { showMessage } from '../../components/MessageDialog';
import AuthService from '../../services/AuthService';
import ColaboradorPerfilService from '../../services/ColaboradorPerfilService';
import ColaboradorService from '../../services/ColaboradorService';
import ColaboradorVinculoService from '../../services/ColaboradorVinculoService';
import ColaboradorEspecialidadeService from '../../services/ColaboradorEspecialidadeService';
import { errorHandle, validateFields } from '../../util/functions';
import { ColaboradorModel, TpColaboradorPerfil } from '../../util/Models';
import { StateScreen } from '../constants';
import ColaboradorCategoriaService from '../../services/ColaboradorCategoriaService';
import MunicipioService from '../../services/MunicipioService';
import { listaUfs } from '../../config/Constantes';
import ComunidadeService from '../../services/ComunidadeService';

function ColaboradorCrud(props) {
  ColaboradorCrud.propTypes = {
    stateScreen: PropTypes.string.isRequired,
    idSelected: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  ColaboradorCrud.defaultProps = {
    messageConfirmation: 'Confirma os dados',
    showConfirmation: false,
  };

  const { stateScreen, idSelected, onClose } = props;

  // useMemos
  const colaboradorLogado = useMemo(() => AuthService.getColaborador(), []);

  // states
  const [colaborador, setColaborador] = useState(new ColaboradorModel());
  const [colaboradorPerfilSelecionado, setColaboradorPerfilSelecionado] = useState(null);
  const [comunidadeSelecionada, setComunidadeSelecionada] = useState(null);

  const [colaboradorVinculoSelecionado, setColaboradorVinculoSelecionado] = useState(null);
  const [colaboradorCategoriaSelecionado, setColaboradorCategoriaSelecionado] = useState(null);
  const [
    colaboradorEspecialidadeSelecionada,
    setColaboradorEspecialidadeSelecionada,
  ] = useState(null);
  const [senha, setSenha] = useState('');
  const [confirmSenha, setConfirmSenha] = useState('');
  const [errorLoadRecord, setErrorLoadRecord] = useState(false);
  const [ufSelecionada, setUfSelecionada] = useState('AM');
  const [municipioAcessoSelecionado, setMunicipioAcessoSelecionado] = useState();

  // useCallbacks
  const loadColaboradorPerfil = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await ColaboradorPerfilService.findAll({ nome: _nome, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadMunicipio = useCallback(async (_nome, uf) => {
    if (_nome !== undefined) {
      const r = await MunicipioService.findAll({ nome: _nome, uf, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadColaboradorVinculo = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await ColaboradorVinculoService.findAll({ nome: _nome, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }

    return [];
  }, []);

  const loadColaboradorEspecialidade = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await ColaboradorEspecialidadeService.findAll({ nome: _nome, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }

    return [];
  }, []);

  const loadComunidade = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await ComunidadeService.findAll({ nome: _nome, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }

    return [];
  }, []);

  const loadColaboradorCategoria = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await ColaboradorCategoriaService.findAll({ nome: _nome, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadRecord = useCallback(async _id => {
    try {
      const retorno = await ColaboradorService.findById(_id);

      setColaborador(retorno);
      const colaboradorPerfil = {
        label: retorno.colaboradorPerfil.nome,
        value: retorno.colaboradorPerfil.id,
      };
      const colaboradorVinculo = {
        label: retorno.colaboradorVinculo.nome,
        value: retorno.colaboradorVinculo.id,
      };

      const colaboradorCategoria = {
        label: retorno.colaboradorCategoria.nome,
        value: retorno.colaboradorCategoria.id,
      };

      const colaboradorEspecialidade = retorno.colaboradorEspecialidade
        ? {
            label: retorno.colaboradorEspecialidade.nome,
            value: retorno.colaboradorEspecialidade.id,
          }
        : null;

      const municipio = retorno.municipioAcesso
        ? {
            label: retorno.municipioAcesso.nome,
            value: retorno.municipioAcesso.id,
          }
        : null;

      const comunidade = retorno.comunidade
        ? {
            label: retorno.comunidade.nome,
            value: retorno.comunidade.id,
          }
        : null;

      setColaboradorPerfilSelecionado(colaboradorPerfil);
      setColaboradorVinculoSelecionado(colaboradorVinculo);
      setColaboradorCategoriaSelecionado(colaboradorCategoria);
      setColaboradorEspecialidadeSelecionada(colaboradorEspecialidade);
      setMunicipioAcessoSelecionado(municipio);
      setComunidadeSelecionada(comunidade);
      setUfSelecionada(retorno.municipioAcesso ? retorno.municipioAcesso.uf : 'AM');
      setErrorLoadRecord(false);
    } catch (err) {
      setErrorLoadRecord(true);
      errorHandle(err);
    }
  }, []);

  // funcoes
  function viewMode() {
    return stateScreen === StateScreen.stView || errorLoadRecord;
  }

  // events
  const doChangeCategoria = e => {
    const especialidade =
      e?.value === 2 || e?.value === 3 ? colaboradorEspecialidadeSelecionada : null;
    const temRegistro = e?.value === 2 || e?.value === 1 || e?.value === 3;
    const idColaboradorEspecialidade =
      e?.value === 2 ? colaborador.idColaboradorEspecialidade : null;
    const colaboradorCategoria = e ? { id: e.value, nome: e.label } : null;

    setColaborador({
      ...colaborador,
      colaboradorCategoria,
      idColaboradorCategoria: e?.value,
      especialidade,
      idColaboradorEspecialidade,
      numeroRegistro: temRegistro ? colaborador.numeroRegistro : '',
      siglaConselho: temRegistro ? colaborador.siglaConselho : '',
    });
    setColaboradorEspecialidadeSelecionada(especialidade);
  };

  const doChangePerfil = e => {
    if (!e) {
      return;
    }

    const colaboradorPerfil = e ? { id: e.value, nome: e.label } : null;
    let { idComunidade, comunidade } = colaborador;

    if (e.value !== TpColaboradorPerfil.AGENTE_SAUDE_RIBEIRINHO) {
      idComunidade = null;
      comunidade = null;
      setComunidadeSelecionada(null);
    }
    setColaborador({
      ...colaborador,
      idComunidade,
      comunidade,
      colaboradorPerfil,
      idColaboradorPerfil: e?.value,
    });
  };

  function handleBack() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      showMessage('Confirmação', 'Abandonar mudanças?', idx => {
        if (idx === 1) {
          onClose();
        }
      });
    }
  }

  function handleSave() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      salvarRecord();
    }
  }

  async function salvarRecord() {
    if (senha || confirmSenha) {
      if (senha !== confirmSenha) {
        toast.warn('Senha e confirmação não conferem.');
        return;
      }

      colaborador.senha = senha;
    }

    try {
      let retorno;
      if (stateScreen === StateScreen.stInsert) {
        retorno = await ColaboradorService.insert(colaborador);
      } else {
        retorno = await ColaboradorService.update(colaborador);
      }
      toast.success('Registro salvo com sucesso.');
      onClose(retorno);
    } catch (err) {
      errorHandle(err);
    }
  }

  // useEffects
  useEffect(() => {
    if (stateScreen === StateScreen.stUpdate || stateScreen === StateScreen.stView) {
      loadRecord(idSelected);
    } else if (stateScreen === StateScreen.stInsert) {
      const novo = new ColaboradorModel();
      setColaboradorPerfilSelecionado(null);
      setColaboradorVinculoSelecionado(null);
      setColaboradorCategoriaSelecionado(null);
      setColaboradorEspecialidadeSelecionada(null);

      setColaborador(novo);
    }
    setSenha('');
    setConfirmSenha('');
  }, [loadRecord, idSelected, stateScreen]);

  const perfilAdministrador = AuthService.getColaborador().idColaboradorPerfil === 1;
  const temEspecialidade =
    colaborador.idColaboradorCategoria === 2 || colaborador.idColaboradorCategoria === 3;
  const temRegistro =
    colaborador.idColaboradorCategoria === 1 ||
    colaborador.idColaboradorCategoria === 2 ||
    colaborador.idColaboradorCategoria === 3;

  // render principal
  return (
    <>
      <>
        <div className="p-col-12 p-sm-3 p-lg-3 p-fluid">
          <LabelSp>E-mail</LabelSp>
          <InputTextSp
            disabled={viewMode()}
            value={colaborador.email}
            maxLength={255}
            required
            type="email"
            onChange={e =>
              setColaborador({
                ...colaborador,
                email: e.target.value,
              })
            }
          />
        </div>
        <div className="p-col-12 p-sm-3 p-lg-3 p-fluid">
          <LabelSp>Nome</LabelSp>
          <InputTextSp
            value={colaborador.nome}
            maxLength={100}
            required
            disabled={viewMode()}
            onChange={e => {
              setColaborador({ ...colaborador, nome: e.target.value });
            }}
          />
        </div>
        <div className="p-col-12 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Celular</LabelSp>
          <InputMaskSp
            mask="(99) 99999-9999"
            value={colaborador.celular}
            disabled={viewMode()}
            onChange={e => {
              setColaborador({ ...colaborador, celular: e.target.value });
            }}
          />
        </div>
        <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>WhatsApp</LabelSp>
          <InputMaskSp
            mask="(99) 99999-9999"
            value={colaborador.whatsapp}
            disabled={viewMode()}
            onChange={e => {
              setColaborador({ ...colaborador, whatsapp: e.target.value });
            }}
          />
        </div>
        <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Recebe Msg?</LabelSp>
          <DropdownSp
            required
            value={colaborador.recebeMsgWhatsapp}
            options={[
              { label: 'Sim', value: true },
              { label: 'Não', value: false },
            ]}
            disabled={viewMode() || !perfilAdministrador}
            filterInputAutoFocus={false}
            onChange={e => {
              setColaborador({ ...colaborador, recebeMsgWhatsapp: e.target.value });
            }}
          />
        </div>
        <div className="p-col-12 p-sm-5 p-lg-5 p-fluid">
          <LabelSp>Perfil</LabelSp>
          <DropDownLazy
            required
            autoLoad
            disabled={viewMode() || !perfilAdministrador}
            placeholder="Selecione"
            onChange={e => {
              doChangePerfil(e);
            }}
            value={colaboradorPerfilSelecionado}
            onFilter={async txtFilter => {
              const retorno = await loadColaboradorPerfil(txtFilter);
              return retorno;
            }}
          />
        </div>

        <div className="p-col-6 p-sm-4 p-lg-4 p-fluid">
          <LabelSp>Vínculo</LabelSp>
          <DropDownLazy
            required
            autoLoad
            disabled={viewMode() || !perfilAdministrador}
            placeholder="Selecione"
            onChange={e => {
              const colaboradorVinculo = e ? { id: e.value, nome: e.label } : null;
              setColaborador({
                ...colaborador,
                idColaboradorVinculo: e?.value,
                colaboradorVinculo,
              });
            }}
            value={colaboradorVinculoSelecionado}
            onFilter={async txtFilter => {
              const retorno = await loadColaboradorVinculo(txtFilter);
              return retorno;
            }}
          />
        </div>

        <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
          <LabelSp>Categoria</LabelSp>
          <DropDownLazy
            required
            autoLoad
            disabled={viewMode() || !perfilAdministrador}
            placeholder="Selecione"
            onChange={e => {
              doChangeCategoria(e);
            }}
            value={colaboradorCategoriaSelecionado}
            onFilter={async txtFilter => {
              const retorno = await loadColaboradorCategoria(txtFilter);
              return retorno;
            }}
          />
        </div>

        <div className="p-col-12 p-sm-3 p-lg-3 p-fluid">
          <LabelSp>Especialidade</LabelSp>
          <DropDownLazy
            autoLoad
            showClear
            disabled={viewMode() || !temEspecialidade || !perfilAdministrador}
            placeholder="Não informada"
            onChange={e => {
              const colaboradorEspecialidade = e ? { id: e.value, nome: e.label } : null;
              setColaborador({
                ...colaborador,
                colaboradorEspecialidade,
                idColaboradorEspecialidade: e?.value,
              });
              setColaboradorEspecialidadeSelecionada(e);
              // necessário para que possa ser manipulado pelo DropDown de categoria
            }}
            value={colaboradorEspecialidadeSelecionada}
            onFilter={async txtFilter => {
              const retorno = await loadColaboradorEspecialidade(txtFilter);
              return retorno;
            }}
          />
        </div>

        <div className="p-col-12 p-sm-3 p-lg-3 p-fluid">
          <LabelSp>Local de Atendimento</LabelSp>
          <DropDownLazy
            autoLoad
            showClear
            disabled={
              viewMode() ||
              !perfilAdministrador ||
              colaborador.idColaboradorPerfil !== TpColaboradorPerfil.AGENTE_SAUDE_RIBEIRINHO
            }
            placeholder="Não Definido"
            onChange={e => {
              const comunidade = e ? { id: e.value, nome: e.label } : null;
              setColaborador({
                ...colaborador,
                comunidade,
                idComunidade: e?.value,
              });
              setComunidadeSelecionada(e);
            }}
            value={comunidadeSelecionada}
            onFilter={async txtFilter => {
              const retorno = await loadComunidade(txtFilter);
              return retorno;
            }}
          />
        </div>

        <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Sigla Conselho</LabelSp>
          <InputTextSp
            value={colaborador.siglaConselho}
            maxLength={10}
            disabled={viewMode() || !temRegistro || !perfilAdministrador}
            onChange={e => {
              setColaborador({ ...colaborador, siglaConselho: e.target.value.toUpperCase() });
            }}
          />
        </div>

        <div className="p-col-8 p-sm-4 p-lg-4 p-fluid">
          <LabelSp>Registro Profissional</LabelSp>
          <InputTextSp
            value={colaborador.numeroRegistro}
            maxLength={10}
            disabled={viewMode() || !temRegistro || !perfilAdministrador}
            onChange={e => {
              setColaborador({ ...colaborador, numeroRegistro: e.target.value });
            }}
          />
        </div>

        <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Inativo?</LabelSp>
          <DropdownSp
            required
            value={colaborador.inativo}
            options={[
              { label: 'Sim', value: true },
              { label: 'Não', value: false },
            ]}
            disabled={viewMode() || !perfilAdministrador}
            filterInputAutoFocus={false}
            onChange={e => {
              setColaborador({ ...colaborador, inativo: e.target.value });
            }}
          />
        </div>

        <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Palestrante?</LabelSp>
          <DropdownSp
            required
            value={colaborador.fazPalestra}
            options={[
              { label: 'Sim', value: true },
              { label: 'Não', value: false },
            ]}
            disabled={viewMode() || !perfilAdministrador}
            filterInputAutoFocus={false}
            onChange={e => {
              setColaborador({ ...colaborador, fazPalestra: e.target.value });
            }}
          />
        </div>

        <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Faz Login?</LabelSp>
          <DropdownSp
            required
            value={colaborador.logaSistema}
            options={[
              { label: 'Sim', value: true },
              { label: 'Não', value: false },
            ]}
            disabled={viewMode() || !perfilAdministrador}
            filterInputAutoFocus={false}
            onChange={e => {
              setColaborador({ ...colaborador, logaSistema: e.target.value });
              setSenha('');
              setConfirmSenha('');
            }}
          />
        </div>

        <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
          <LabelSp>Senha</LabelSp>
          <InputTextSp
            type="password"
            name="txtSenha"
            maxLength={16}
            disabled={
              !colaborador.logaSistema ||
              viewMode() ||
              (colaboradorLogado.idColaboradorPerfil !== 1 &&
                colaborador.id !== colaboradorLogado.id)
            }
            required={stateScreen === StateScreen.stInsert || !!confirmSenha}
            value={senha}
            onChange={e => {
              setSenha(e.target.value);
            }}
          />
        </div>

        <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
          <LabelSp>Confirmação</LabelSp>
          <InputTextSp
            type="password"
            name="txtSenhaConfirmacao"
            maxLength={16}
            disabled={
              !colaborador.logaSistema ||
              viewMode() ||
              (colaboradorLogado.idColaboradorPerfil !== 1 &&
                colaborador.id !== colaboradorLogado.id)
            }
            required={stateScreen === StateScreen.stInsert || !!senha}
            value={confirmSenha}
            onChange={e => {
              setConfirmSenha(e.target.value);
            }}
          />
        </div>
        <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>UF de Acesso</LabelSp>
          <DropdownSp
            disabled={viewMode() || !perfilAdministrador}
            placeholder="Selecione"
            value={ufSelecionada}
            options={listaUfs}
            filter
            onChange={e => {
              setColaborador({
                ...colaborador,
                idMunicipioAcesso: null,
                municipioAcesso: undefined,
              });
              setMunicipioAcessoSelecionado(undefined);
              setUfSelecionada(e.target.value);
            }}
          />
        </div>

        <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
          <LabelSp>Município de Acesso</LabelSp>
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon">
              <i
                className="pi pi-info-circle"
                title="Informando o município, o sistema fará um filtro automatico na tela de teleatendimentos e teleeducação."
              />
            </span>
            <DropDownLazy
              autoLoad
              showClear
              disabled={viewMode() || !perfilAdministrador}
              placeholder="Todos"
              onChange={e => {
                const municipioAcesso = e ? { id: e.value, nome: e.label } : null;
                setColaborador({
                  ...colaborador,
                  idMunicipioAcesso: e?.value,
                  municipioAcesso,
                });
                setMunicipioAcessoSelecionado(e);
              }}
              value={municipioAcessoSelecionado}
              onFilter={async txtFilter => {
                const retorno = await loadMunicipio(txtFilter, ufSelecionada);
                return retorno;
              }}
            />
          </div>
        </div>

        <div className="p-col-12 p-lg-12" style={{ textAlign: 'start' }}>
          {!viewMode() ? (
            <ButtonSp
              className="p-button-success"
              icon="pi pi-save"
              label="Salvar"
              disabled={
                !validateFields(colaborador, [
                  'nome',
                  'email',
                  'idColaboradorPerfil',
                  'idColaboradorVinculo',
                ]) ||
                (stateScreen === StateScreen.stInsert && (!senha || !confirmSenha))
              }
              showConfirmation
              onClick={handleSave}
            />
          ) : null}
          <ButtonSp
            className="p-button-secondary"
            label="Voltar"
            icon="pi pi-chevron-circle-left"
            onClick={handleBack}
          />
        </div>
      </>
    </>
  );
}

export default ColaboradorCrud;

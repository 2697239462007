import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useDispatch } from 'react-redux';

import logo from '../../assets/images/logo300.png';
import logoGooglePlay from '../../assets/images/icons-google-play-480.png';
import { Container } from './styles';

import { loginRequest } from '../../store/modules/auth/actions';
import { validateFields } from '../../util/functions';
import LabelSp from '../../components/LabelSp';
import InputTextSp from '../../components/InputTextSp';

export default function Login() {
  const [email, setEmail] = useState([]);
  const [password, setPassword] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setEmail('');
    setPassword('');
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(loginRequest(email, password, '/home'));
  }

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <div className="p-grid p-fluid">
          <div className="p-col-12" style={{ textAlign: 'center' }}>
            <img className="logo" src={logo} alt="" />
          </div>

          <div className="p-col-12">
            <LabelSp>E-mail</LabelSp>
            <div className="p-inputgroup">
              <InputTextSp
                autoFocus
                type="text"
                name="login"
                placeholder="seu e-mail"
                required
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              <span className="p-inputgroup-addon">
                <i className="pi pi-user" />
              </span>
            </div>
          </div>
          <div className="p-col-12">
            <LabelSp>Senha</LabelSp>
            <div className="p-inputgroup">
              <InputTextSp
                type="password"
                name="password"
                placeholder="sua senha"
                required
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
              <span className="p-inputgroup-addon">
                <i className="pi pi-key" />
              </span>
            </div>
          </div>

          <div className="p-col-12">
            <Button
              type="submit"
              label="Login"
              disabled={!validateFields({ email, password }, ['email', 'password'])}
            />
          </div>
          <div className="div-google-play">
            <span style={{ fontSize: 11, fontWeight: 'bold' }}>Disponível no</span>
            <a href="https://play.google.com/store/apps/details?id=org.fasamazonia.telessaude.app">
              <div className="div-google-play-link">
                <img src={logoGooglePlay} width={20} height={20} alt="" />
                <span style={{ fontSize: 18 }}>Google Play</span>
              </div>
            </a>
          </div>
        </div>
      </form>
    </Container>
  );
}

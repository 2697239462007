import styled from 'styled-components';

export const Container = styled.div`
  margin: 0;
  padding: 0;

  .div-status {
    display: inline-block;
    width: 20px;
    border-radius: 3px;
    /* border: 1px solid #525252; */
  }

  .multiselected-item {
    margin: 0 10px 0 0;
    display: inline-block;
    border-radius: 3px;
  }

  .group-box {
    border-style: solid;
    border: 1px solid #969595;
    border-radius: 4px;
  }

  /* .p-checkbox-label .checkbox-label {
    font-weight: bold;
  } */
`;

import React, { useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import PropTypes from 'prop-types';

import { Container } from './styles';

export default function PdfView(props) {
  PdfView.propTypes = {
    title: PropTypes.string,
    src: PropTypes.string,
    visible: PropTypes.bool,
    setVisible: PropTypes.func,
  };

  PdfView.defaultProps = {
    title: '',
  };

  const { title, src, visible, setVisible } = props;

  useEffect(() => {
    const ele = document.getElementById('embeb_pdf_view');
    ele.src = src;
  }, [src]);

  return (
    <Container>
      <Dialog
        closable
        closeOnEscape
        header={title}
        visible={visible}
        contentStyle={{ margin: 0, padding: 0 }}
        style={{ minWidth: '350px', width: '95%' }}
        modal
        blockScroll
        onHide={() => {
          if (setVisible) {
            setVisible(false);
          }
        }}
      >
        <embed
          id="embeb_pdf_view"
          type="application/pdf"
          frameBorder="0"
          scrolling="auto"
          width="100%"
          className="responsive"
        />
      </Dialog>
    </Container>
  );
}
